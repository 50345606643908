import Subtable from '../explore/subtable/Subtable';
import cn from '../lib/cn';
import ScrollableChartArea from './ScrollableChartArea';

const SubtableWrapper = ({ onHover, onHoverEnd, onToggle, hoveredItem, selection, status }: any) => (
	<div className={cn('flex-1 flex flex-col h-full justify-between')}>
		<ScrollableChartArea>
			<div className={cn('flex-1 relative')}>
				<div className={cn('relative w-full')}>
					<div className="explore-chart__main">
						<Subtable
							onHover={onHover}
							onHoverEnd={onHoverEnd}
							hoveredItem={hoveredItem}
							selection={selection}
							status={status}
							onToggle={onToggle}
						/>
					</div>
				</div>
			</div>
		</ScrollableChartArea>
	</div>
);

export default SubtableWrapper;
