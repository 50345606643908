import { Fragment, useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';

import Button from '../../../common/Button';
import ActionDropdown from '../../../common/DropdownV2';
import RadioButton from '../../../common/RadioButton';
import ButtonTypes from '../../../constants/ButtonTypes';
import MixPanel from '../../../constants/MixPanel';
import RbacActions from '../../../constants/RbacActions';
import { READ_ONLY_ROLE_IDS } from '../../../constants/RoleIds';
import usePermissions from '../../../hooks/usePermissions';
import { track } from '../../../lib/segment';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getGroupByIdSelector } from '../../groups/utils';
import { Role } from '../../roles/model';
import { assignRole, switchRoles, unassignRole } from '../../users/actions';
import { ActorRole } from '../models';

const UserRoleHandler = ({ actor, currentActorId }: any) => {
	const [addNew, setAddNew] = useState(false);
	const [remainingRoles, setRemainingRoles] = useState<Role[]>([]);
	const [openDropdownId, setOpenDropdownId] = useState<string>();
	const { allRoles } = useAppSelector(state => state.admin.roles);
	const editPermissions = usePermissions({
		actions: [RbacActions['Roles/Edit']],
	});
	const dispatch = useAppDispatch();

	useEffect(() => {
		setRemainingRoles(allRoles.filter((role: Role) => !actor.role.map((r: ActorRole) => r.id).includes(role.id)));
	}, [allRoles, actor]);

	function handleDropdownClick(id: string) {
		if (id === 'tmp-role') {
			track(MixPanel.Events.AdminUsersClickAddRole, {
				actorId: currentActorId,
			});
		} else {
			track(MixPanel.Events.AdminUsersClickRole, {
				roleId: id,
				actorId: currentActorId,
			});
		}
		if (openDropdownId === id) {
			setOpenDropdownId(undefined);
		} else {
			setOpenDropdownId(id);
		}
	}

	function handleUnassignRole(roleId: string) {
		track(MixPanel.Events.AdminUsersClickRemoveRole, {
			roleId,
			actorId: currentActorId,
		});
		dispatch(unassignRole(roleId, actor.actorId));
	}

	const actorRoles = actor.role.slice();

	return (
		<div className="role-handler">
			{actorRoles
				.sort((a: ActorRole, b: ActorRole) => {
					if (!a?.name || !b?.name) return 0;
					return a?.name?.localeCompare(b?.name);
				})
				.map((role: ActorRole) => (
					<Fragment>
						{!role.inheritedFrom ? (
							<RoleDropdown
								key={role.id}
								selected={role.name}
								disabled={!editPermissions || READ_ONLY_ROLE_IDS.includes(role.id)}
								onClearSelection={() => {
									handleUnassignRole(role.id);
								}}
								onMouseLeaveDropdown={() => setOpenDropdownId(undefined)}
								onClick={() => {
									handleDropdownClick(role.id);
								}}
								isOpen={openDropdownId && openDropdownId === role.id}
							>
								<RoleDropdown.List
									options={[role, ...remainingRoles]}
									onClick={(newRole: Role) => {
										dispatch(switchRoles(role.id, newRole.id, actor.actorId));
									}}
								/>
							</RoleDropdown>
						) : (
							<ReadOnlyLabel role={role} />
						)}
					</Fragment>
				))}
			{addNew && remainingRoles.length >= 1 && (
				<RoleDropdown
					key={'tmp-role'}
					disabled={!editPermissions}
					selected="Add Role"
					onClearSelection={() => {
						setAddNew(false);
						setOpenDropdownId(undefined);
					}}
					onClick={() => handleDropdownClick('tmp-role')}
					isOpen={openDropdownId === `tmp-role`}
					onMouseLeaveDropdown={() => setOpenDropdownId(undefined)}
				>
					<RoleDropdown.List
						options={[...remainingRoles]}
						onClick={(role: Role) => {
							dispatch(assignRole(role.id, actor.actorId));
							setOpenDropdownId(undefined);
							setAddNew(false);
						}}
					/>
				</RoleDropdown>
			)}
			{!addNew && editPermissions && (
				<Button
					componentType={ButtonTypes.type.SECONDARY}
					onClick={() => {
						setAddNew(true);
						handleDropdownClick('tmp-role');
					}}
					disabled={!editPermissions}
				>
					<MdAdd />
				</Button>
			)}
		</div>
	);
};

const ReadOnlyLabel = ({ role }: any) => {
	const group = useAppSelector(getGroupByIdSelector(role.inheritedFrom));

	return (
		<Button componentType={ButtonTypes.type.SECONDARY} onClick={() => {}} disabled={true}>
			{role?.name} ({group?.name})
		</Button>
	);
};

const RoleDropdown = ({
	selected,
	disabled,
	onClearSelection,
	onClick,
	isOpen,
	placeholder = 'Role',
	children,
	onMouseLeaveDropdown,
}: any) => {
	return (
		<ActionDropdown
			onClick={onClick}
			onClearSelection={onClearSelection}
			placeholder={placeholder}
			disabled={disabled}
			buttonLarge={true}
			allowCancel={true}
			isOpen={isOpen}
			label={selected}
			onMouseLeaveDropdown={onMouseLeaveDropdown}
			limitHeight="50vh"
		>
			{children}
		</ActionDropdown>
	);
};

const RoleDropdownList = ({ options, onClick }: any) => {
	return (
		<div className="filter-dropdown__list">
			<ul>
				{options
					.sort((a: any, b: any) => {
						return a.name.localeCompare(b.name);
					})
					.map((role: Role) => (
						<li key={role.id}>
							<RadioButton
								onClick={() => {
									onClick(role);
								}}
							>
								{role.name}
							</RadioButton>
						</li>
					))}
			</ul>
		</div>
	);
};

RoleDropdown.List = RoleDropdownList;

export default UserRoleHandler;
