import { Modal } from 'antd';

import Button from '../../../common/Button';
import ButtonTypes from '../../../constants/ButtonTypes';

interface DeleteSearchProps {
	isOpen?: boolean;
	onDelete?: (name: string) => void;
	onCancel?: () => void;
}

const DeleteSearch = ({ isOpen, onDelete, onCancel }: DeleteSearchProps) => {
	return (
		<Modal
			open={isOpen}
			width={450}
			footer={null}
			closable={false}
			maskClosable={true}
			onCancel={onCancel}
			zIndex={3000}
			destroyOnClose
		>
			<div className="modal">
				<div className="modal__header">
					<div className="modal__title">Delete Report</div>
				</div>
				<div className="modal__content">
					<p>Click Delete to remove this report from your Saved Searches</p>
					<p>IMPORTANT: This action cannot be undone</p>
				</div>
				<div className="modal__footer">
					<Button componentType={ButtonTypes.type.TERTIARY} onClick={onCancel}>
						Cancel
					</Button>
					<Button onClick={onDelete}>Delete</Button>
				</div>
			</div>
		</Modal>
	);
};

export default DeleteSearch;
