import { useRef } from 'react';
import { MdAddAPhoto } from 'react-icons/md';

interface FileUploadProps {
	onUpload: (image: string) => void;
	onError: (error: string) => void;
	image?: string;
	imageUrl?: string;
	name: string;
}

export default function FileUpload({ image, onUpload, onError, name, imageUrl }: FileUploadProps) {
	const fileUploadRef = useRef<HTMLInputElement>(null);

	const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event?.target?.files?.[0]) {
			const file = event?.target?.files?.[0];
			const regex = /(.*?)\.(jpg|bmp|jpeg|png|tif|tiff|gif|svg)$/;
			if (!file.name.match(regex)) {
				onError('Please select an image');
				return;
			}
			if (file.size > 10000000) {
				onError('Selected image is too large');
				return;
			}
			const reader = new FileReader();
			reader.onload = e => {
				onUpload(e.target?.result as string);
			};
			reader.readAsDataURL(file);
		}
	};

	return (
		<div className="fileupload">
			<label className="fileupload__label" htmlFor={`photoUpload_${name}`}>
				{image || imageUrl ? (
					<img src={image || imageUrl} alt="photo" className="fileupload__image" />
				) : (
					<MdAddAPhoto size={24} color="#ccc" />
				)}
			</label>
			<input
				type="file"
				id={`photoUpload_${name}`}
				ref={fileUploadRef}
				className="fileupload__input"
				accept="image/*"
				onChange={handlePhotoChange}
			/>
		</div>
	);
}
