import { scaleLinear } from 'd3-scale';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';

import { ChartContext } from '../../contexts/ChartContext';
import { IGoalState } from '../../measure/goal/reducer';
import { useAppSelector } from '../../store/hooks';

const GuideLabels = () => {
	const {
		chartState: { mode, chartWidth, innerChartOffsets },
	} = useContext(ChartContext);

	const { dates, chartData, currentIndex } = useAppSelector(state => {
		switch (mode) {
			case 'measure': {
				return state.measure.goal;
			}
		}
		return {} as IGoalState;
	});

	const [hiddenLabelIndexes, setHiddenLabelIndexes] = useState<number[]>([]);

	const xScale = scaleLinear<string>()
		.domain([dates[0], dates[dates.length - 1]])
		.range(['0%', '100%']);

	function isOverLapping(date: Date, currentDatePoint: Date) {
		const currentMonth = currentDatePoint.getMonth();

		if (date.getMonth() === currentMonth) {
			return true;
		}

		return false;
	}

	useEffect(() => {
		function recursivelyCalculateSkippedIndexes(
			dates: Date[],
			width: number,
			minimumLabelWidth = 100,
			skips = 0
		): any {
			if (!dates || dates.length === 0 || skips >= 20) return;

			const newDates = dates.filter((_, index) => index % (skips + 1) === 0);
			if (width / newDates.length >= minimumLabelWidth) return skips;

			return recursivelyCalculateSkippedIndexes(dates, width, minimumLabelWidth, skips + 1);
		}

		if (!dates || !chartWidth || !innerChartOffsets) return;
		const skips = recursivelyCalculateSkippedIndexes(
			dates,
			chartWidth - innerChartOffsets.left - innerChartOffsets.right
		);

		const hiddenArray: any[] = [];

		if (skips > 0) {
			Array.from({ length: dates.length }, (_, i) => ((i + 1) % (skips + 1) === 1 ? 1 : 0))
				.reverse()
				.forEach((visible, index) => {
					if (!visible) hiddenArray.push(index);
				});

			setHiddenLabelIndexes(hiddenArray);
		}
	}, [chartWidth, innerChartOffsets, dates]);

	return (
		<>
			{dates.map((date, i) => (
				<div
					style={
						{
							'--x': xScale(date),
						} as React.CSSProperties
					}
					className={'x-axis__guide-label'}
					data-is-hidden={
						hiddenLabelIndexes.includes(i) || isOverLapping(date, chartData[0].series[currentIndex].date)
					}
					key={date.toString()}
				>
					<div className="x-axis__text">{moment.utc(date).format('MMM')}</div>
				</div>
			))}
		</>
	);
};

export default GuideLabels;
