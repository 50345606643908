interface CaretRightProps {
	size?: number;
}

const CaretRight = ({ size = 6 }: CaretRightProps) => {
	const ratio = 4 / 6;
	const width = size * ratio;
	const height = size;
	return (
		<svg width={width} height={height} viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0 6L7.15493e-08 0L4 3L0 6Z" fill="currentColor" fillOpacity="0.5" />
		</svg>
	);
};

export default CaretRight;
