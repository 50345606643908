import { useEffect } from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';

import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getTemplates } from './actions';

function Templates({ history }: RouteComponentProps<{}>) {
	const dispatch = useAppDispatch();
	const { data = [] } = useAppSelector(state => state.datastudio.templates);

	useEffect(() => {
		dispatch(getTemplates());
	}, []);

	function handleAdd() {
		history.push(`/datastudio/templates/new`);
	}

	return (
		<div className="ds-container">
			<Button componentType={ButtonTypes.type.PRIMARY} classes={['ds-add']} onClick={handleAdd}>
				Add
			</Button>
			<table className="ds-table">
				<tbody>
					{data.map((c, i) => (
						<tr key={i}>
							<td>
								<NavLink className="link--bright" to={`/datastudio/templates/${c.templateId}`}>
									{c.templateName}
								</NavLink>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

export default Templates;
