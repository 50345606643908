import analysisTypes from '../constants/AnalysisTypes';
import GridConstants from '../constants/GridConstants';
import { selectCurrentTime as selectCurrentTimeInReport } from '../dashboard/view/actions';
import { selectCurrentTime } from '../explore/actions';
import cn from '../lib/cn';
import getChartData from '../lib/getChartData';
import getDynamicDataProp from '../lib/getDynamicDataProp';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import BenchmarkChart from './BenchmarkChart';
import ResponsiveContainer from './ResponsizeContainer';
import ScrollableChartArea from './ScrollableChartArea';

const BenchmarkChartWrapper = ({
	onHover,
	onHoverEnd,
	onToggle,
	hoveredItem,
	selection,
	reportId,
}: {
	onHover?: any;
	events?: any;
	onHoverEnd?: any;
	onToggle?: any;
	hoveredItem?: string;
	selection: string[];
	status?: any;
	reportId?: string;
	[key: string]: any;
}) => {
	const dispatch = useAppDispatch();

	const {
		data,
		lifecycle,
		dates,
		interval,
		currentIndex,
		yAxisType,
		indexType,
		columnProps,
		analysisType,
		benchmark,
	} = useAppSelector(state => getChartData(state, reportId));

	const dataProp = getDynamicDataProp(yAxisType, indexType, columnProps);

	function handleSelectCurrentTime(currentIndex: number) {
		if (reportId) {
			dispatch(selectCurrentTimeInReport(reportId, currentIndex));
		} else {
			dispatch(selectCurrentTime(currentIndex));
		}
	}

	return (
		<div className={cn('flex-1 flex flex-col h-full justify-between')}>
			<ScrollableChartArea>
				<div className={cn('flex-1 relative')}>
					<div className={cn('relative w-full h-[250px] wide:h-[360px]')}>
						<ResponsiveContainer className="explore-chart__main">
							{({ width, height }: { width: number; height: number }) => (
								<BenchmarkChart
									reportId={reportId || 'explore-chart'}
									width={width}
									height={height}
									data={data}
									dates={dates}
									interval={interval}
									lifecycle={lifecycle}
									analysisType={analysisType as analysisTypes}
									currentIndex={currentIndex}
									length={GridConstants.ColumnCount}
									colWidth={0}
									selectCurrentTime={handleSelectCurrentTime}
									benchmarkData={benchmark}
									showPie={!benchmark.hidePie}
									dataProp={dataProp}
									columnProps={columnProps}
									onHover={onHover}
									onHoverEnd={onHoverEnd}
									onToggle={onToggle}
									hoveredItem={hoveredItem}
									selection={selection}
								/>
							)}
						</ResponsiveContainer>
					</div>
				</div>
			</ScrollableChartArea>
		</div>
	);
};

export default BenchmarkChartWrapper;
