import { Animate } from 'react-move';

import Constants from '../../constants/Constants';
import getInterpolator from '../../lib/getInterpolator';
import { IPermanentMessage } from './types';

interface PreviewMessageProps {
	opacity?: number;
	text: string | React.ReactNode;
}

const Message = ({ opacity, text }: PreviewMessageProps) => {
	return (
		<div
			className="permanent-messages__bar"
			style={{
				opacity,
			}}
		>
			{typeof text === 'string' ? <div className="permanent-messages__bar__text">{text}</div> : text}
		</div>
	);
};

interface PermanentBarViewProps {
	messages?: IPermanentMessage[];
}

const PermanentBarView = ({ messages }: PermanentBarViewProps) => {
	return (
		<div className="permanent-messages">
			{messages?.map(message => {
				return (
					<Animate
						show={message.show}
						start={{ opacity: 0 }}
						enter={{
							opacity: [1],
							timing: {
								duration: Constants.AnimationDuration,
								ease: Constants.EasingFn,
							},
						}}
						update={[
							{
								opacity: [0],
								timing: {
									duration: 0,
									ease: Constants.EasingFn,
								},
							},
							{
								opacity: [1],
								timing: {
									duration: Constants.AnimationDuration,
									ease: Constants.EasingFn,
								},
							},
						]}
						leave={{
							opacity: [0],
							timing: {
								duration: Constants.AnimationDuration,
								ease: Constants.EasingFn,
							},
						}}
						interpolation={getInterpolator}
					>
						{({ opacity }) => <Message opacity={opacity} text={message.text} />}
					</Animate>
				);
			})}
		</div>
	);
};

export default PermanentBarView;
