import { MdDelete } from 'react-icons/md';

import Button from '../../common/Button';
import TextField from '../../common/TextField';
import ButtonTypes from '../../constants/ButtonTypes';

interface StringListEditProps {
	label: string;
	data: string[];
	onNew: () => void;
	onEdit: (text: string, index: number) => void;
	onDelete: (index: number) => void;
}

export default function StringListEdit({ label, data, onNew, onEdit, onDelete }: StringListEditProps) {
	return (
		<>
			<div className="ds-label">
				<span style={{ marginRight: 16 }}>{label}</span>
				<Button componentType={ButtonTypes.type.SECONDARY} onClick={() => onNew()} large={false}>
					Add new
				</Button>
			</div>
			{data?.map((d: string, i: number) => (
				<div key={i} className="ds-input">
					<TextField value={d} onChange={ev => onEdit(ev.target.value, i)} />
					<MdDelete className="icon" size={16} style={{ marginLeft: 8 }} onClick={() => onDelete(i)} />
				</div>
			))}
			<div style={{ marginBottom: 8 }} />
		</>
	);
}
