import Switch from 'antd/lib/switch';
import produce from 'immer';
import { useEffect, useState } from 'react';

import Button from '../../common/Button';
import TextField from '../../common/TextField';
import ButtonTypes from '../../constants/ButtonTypes';
import Accordion from '../Accordion';
import { FieldDefinition } from './model';

interface FieldMapEditProps {
	defaultDisplayName: string;
	field: FieldDefinition;
	fieldMapType: string;
	setUiFieldMapping: any;
}

export default function FieldMapEdit({
	defaultDisplayName,
	setUiFieldMapping,
	fieldMapType,
	field,
}: FieldMapEditProps) {
	const [expanded, setExpanded] = useState(defaultDisplayName === 'New');
	const [displayName, setDisplayName] = useState(defaultDisplayName);
	const [source, setSource] = useState(field.source);
	const [calculated, setCalculated] = useState(field.calculated);
	const [order, setOrder] = useState(field.order);
	const [unit, setUnit] = useState(field.unit);

	function updateState(displayName: string, field: any) {
		setDisplayName(displayName);
		setSource(field.source);
		setOrder(field.order);
		setCalculated(field.calculated);
		setUnit(field.unit);
	}

	useEffect(() => {
		updateState(defaultDisplayName, field);
	}, [defaultDisplayName, field]);

	function handleRemove() {
		setUiFieldMapping((uiFieldMapping: any) =>
			produce(uiFieldMapping, (draftState: any) => {
				delete draftState[fieldMapType][displayName];
			})
		);
	}

	function handleSave() {
		setUiFieldMapping((uiFieldMapping: any) =>
			produce(uiFieldMapping, (draftState: any) => {
				const field = {
					source,
					calculated,
					unit,
					order,
				};
				if (displayName !== defaultDisplayName) {
					delete draftState[fieldMapType][defaultDisplayName];
				}
				draftState[fieldMapType][displayName] = field;
			})
		);
		setExpanded(false);
	}

	function handleCancel() {
		updateState(defaultDisplayName, field);
		setExpanded(false);
	}

	return (
		<Accordion label={displayName} expanded={expanded} setExpanded={setExpanded} remove={handleRemove}>
			<div className="ds-label">Display name</div>
			<div className="ds-input">
				<TextField value={displayName || ''} onChange={e => setDisplayName(e.target.value)} />
			</div>
			<div className="ds-label">Field name</div>
			<div className="ds-input">
				<TextField value={source} onChange={e => setSource(e.target.value)} />
			</div>
			<div className="ds-label">Order</div>
			<div className="ds-input">
				<TextField value={order} onChange={e => setOrder(e.target.value)} />
			</div>
			<div className="ds-label">Calculated</div>
			<div className="ds-input ds-switch">
				<Switch size="small" checked={calculated} onChange={() => setCalculated(!calculated)} />
			</div>
			<div className="ds-label">Unit</div>
			<div className="ds-input">
				<TextField value={unit} onChange={e => setUnit(e.target.value)} />
			</div>
			<div className="ds-buttons">
				<Button componentType={ButtonTypes.type.TERTIARY} onClick={handleCancel}>
					Cancel
				</Button>
				<Button componentType={ButtonTypes.type.SECONDARY} onClick={handleSave}>
					Save
				</Button>
			</div>
		</Accordion>
	);
}
