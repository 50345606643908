import { useEffect, useState } from 'react';
import { StaticContext, useHistory, useParams } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Button from '../../../../common/Button';
import ConfirmationModal from '../../../../common/ConfirmationModal';
import HeaderWrapper from '../../../../common/HeaderWrapper';
import Stack from '../../../../common/Stack';
import WithPermissions from '../../../../common/WithPermissions';
import ButtonTypes from '../../../../constants/ButtonTypes';
import RbacActions from '../../../../constants/RbacActions';
import urls from '../../../../constants/Urls';
import TopLeftAction from '../../../../layout/TopLeftAction';
import getAccessToken from '../../../../lib/getAccessToken';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { publishSnapshot } from '../../edit/actions';
import { getEnterpriseSurveys } from '../../list/actions';
import { Survey } from '../../list/types';

interface HeaderProps extends RouteComponentProps<{}, StaticContext, { scrollPos?: number }> {}

const Header = (props: HeaderProps) => {
	const {
		location: { state },
	} = props;
	const dispatch = useAppDispatch();
	const history = useHistory();
	const [publishConfirmation, setPublishConfirmation] = useState(false);
	const [publishPending, setPublishPending] = useState(false);
	const { data, loaded, pending, error } = useAppSelector(state => state.snapshotSurveys.list);
	const params = useParams<any>();

	useEffect(() => {
		if (!loaded && !pending && !error) {
			dispatch(getEnterpriseSurveys());
		}
	}, [loaded, pending, error]);

	const survey: Survey | undefined = data.find((survey: Survey) => survey.surveyId === params.id);

	if (!survey) {
		return null;
	}

	async function handlePreviewClick(e: any, survey: Survey) {
		e.stopPropagation();
		const accessToken = await getAccessToken();
		const rbacToken = localStorage.getItem('rbacToken');
		const link = `${urls.LoggedOutApp}survey?id=${survey?.surveyId}&enterpriseId=${survey?.enterpriseId}&accessToken=${accessToken}&rbacToken=${rbacToken}`;
		window.open(link);
	}

	function handleTogglePublish(e: any, survey: Survey) {
		e.stopPropagation();
		return dispatch(
			publishSnapshot({
				surveyIdOrGlobalSurveyId: survey.surveyId,
				isPublished: !survey.isPublished,
			})
		);
	}

	function handleEdit(e: any, survey: Survey) {
		e.stopPropagation();
		history.push(`/self-id/campaigns/edit/${survey.surveyId}`);
	}

	return (
		<WithPermissions actions={[RbacActions['Campaign/View']]}>
			<HeaderWrapper>
				<Stack gap="2rem" flexDirection="row" alignItems="center">
					<TopLeftAction scrollPos={state?.scrollPos} />
					<SurveyStatus
						isPublished={survey.isPublished}
						publishDate={survey.publishDate}
						title={survey.title}
					/>
				</Stack>
				<Stack flexDirection="row" gap=".8rem" alignItems="center">
					<Button componentType={ButtonTypes.type.SECONDARY} onClick={e => handlePreviewClick(e, survey)}>
						Preview
					</Button>
					<WithPermissions actions={[RbacActions['Campaign/Edit']]}>
						<Button componentType={ButtonTypes.type.SECONDARY} onClick={e => handleEdit(e, survey)}>
							Edit
						</Button>
					</WithPermissions>
					<WithPermissions actions={[RbacActions['Campaign/Edit']]}>
						<Button
							componentType={survey.isPublished ? ButtonTypes.type.SECONDARY : ButtonTypes.type.PRIMARY}
							onClick={(e: any) => {
								e.stopPropagation();
								setPublishConfirmation(true);
							}}
						>
							{survey.isPublished ? 'Unpublish' : 'Publish'}
						</Button>
					</WithPermissions>
				</Stack>
				<ConfirmationModal
					title={survey.isPublished ? 'Unpublish survey' : 'Publish survey'}
					confirmationText={survey.isPublished ? 'Unpublish' : 'Publish'}
					onAction={(e: any) => {
						e.stopPropagation();
						setPublishPending(true);
						handleTogglePublish(e, survey)
							.then(() => {
								setPublishPending(false);
								setPublishConfirmation(false);
							})
							.catch(() => {
								setPublishPending(false);
								setPublishConfirmation(false);
							});
					}}
					onCancel={(e: any) => {
						e.stopPropagation();
						setPublishConfirmation(false);
						setPublishPending(false);
					}}
					isOpen={publishConfirmation}
					pending={publishPending}
				>
					{survey.isPublished ? (
						<p>Unpublishing will pause this survey and it will stop accepting responses.</p>
					) : (
						<p>
							Publishing will activate this survey to start accepting responses. If you chose email
							distribution, all recipients will be emailed instructions to access the survey.
						</p>
					)}
				</ConfirmationModal>
			</HeaderWrapper>
		</WithPermissions>
	);
};

const SurveyStatus = ({
	isPublished,
	publishDate,
	title,
}: {
	isPublished: boolean;
	publishDate?: string;
	title: string;
}) => {
	const lightMode = useAppSelector(state => state.auth.userInfo.lightMode);
	return (
		<Stack flexDirection="row" alignItems="center" gap="1.6rem">
			<h3 className="font-title">{title}</h3>
			<div
				style={{
					padding: '0.4rem .8rem',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					borderRadius: '1.6rem',
					background: isPublished ? 'var(--color-graph-1)' : 'var(--color-ui-75)',
				}}
			>
				<span
					className="font-micro-text"
					style={{
						color: lightMode ? 'var(--cd-color-ui-100' : 'var(--cl-color-ui-100)',
					}}
				>
					{isPublished ? 'Published' : !!publishDate ? 'Unpublished' : 'Draft'}
				</span>
			</div>
		</Stack>
	);
};

export default withRouter(Header);
