import LottieAnimation from '../../common/LottieAnimation';
import cn from '../../lib/cn';
import Loader from '../../lottie/graph-loader.json';
import ChartEmptyReport from './ChartEmptyReport';

interface ChartEmptyStateProps {
	generated: boolean;
	generateError?: boolean;
	generateInvalidVariation?: boolean;
	generateNoData?: boolean;
	generateNotAllowed?: boolean;
	generatePending?: boolean;
	isEmptyState: boolean;
	onDelete: () => void;
	title: string;
	children?: any;
	isTextChart?: boolean;
}

const ChartEmptyState = ({
	generated,
	generateError,
	generateInvalidVariation,
	generateNoData,
	generateNotAllowed,
	generatePending,
	isEmptyState,
	onDelete,
	title,
	children,
	isTextChart,
}: ChartEmptyStateProps): JSX.Element | null => {
	if (isTextChart) {
		return <>{children}</>;
	}

	if (generatePending) {
		return (
			<div className={cn('absolute inset-0 flex items-center justify-center')}>
				<LottieAnimation
					autoplay={true}
					loop={true}
					width={60}
					height={60}
					animation={Loader}
				></LottieAnimation>
			</div>
		);
	}

	if (generateError) {
		return (
			<ChartEmptyReport
				title={title}
				text={
					generateNotAllowed
						? 'Sorry, you are not authorized to view this report. Your administrator controls these permissions for your account.'
						: 'Sorry! We’ve encountered an issue displaying this graph.'
				}
				onDelete={onDelete}
			/>
		);
	} else if (generateInvalidVariation) {
		return (
			<ChartEmptyReport
				title={title}
				html={
					<span>
						This report has not yet been activated by your company.{' '}
						<a href="mailto: support@itsdandi.com" target="__blank">
							Contact
						</a>{' '}
						your Dandi Customer Success Representative to turn it on.
					</span>
				}
				onDelete={onDelete}
			/>
		);
	} else if (generateNoData) {
		return <ChartEmptyReport title={title} text="Sorry! There is no data for this report." onDelete={onDelete} />;
	} else if (!generated) {
		return <ChartEmptyReport title={title} text="Sorry! There is no data for this report." onDelete={onDelete} />;
	}

	if (isEmptyState) {
		return null;
	}
	return <>{children}</>;
};

export default ChartEmptyState;
