import lottie from 'lottie-web';
import { useEffect, useRef } from 'react';

interface LottieAnimationProps {
	animation: any;
	width: number;
	height: number;
	autoplay: boolean;
	loop?: boolean;
}

const LottieAnimation = (props: LottieAnimationProps) => {
	const { animation, width, height, autoplay, loop } = props;
	const ref = useRef<SVGSVGElement>(null);

	useEffect(() => {
		if (ref.current) {
			lottie.loadAnimation({
				container: ref.current,
				renderer: 'svg',
				autoplay,
				loop,
				animationData: animation,
				rendererSettings: {
					preserveAspectRatio: 'xMidYMid meet',
					viewBoxOnly: true,
				},
			});
		}
	}, []);

	return (
		<svg
			className="lottie-wrapper"
			viewBox={`0 0 ${width} ${height}`}
			width={width}
			height={height}
			ref={ref}
		></svg>
	);
};

export default LottieAnimation;
