import Tooltip from 'antd/lib/tooltip';
import { useCallback, useEffect, useState } from 'react';

import Button from '../../../common/Button';
import LottieAnimation from '../../../common/LottieAnimation';
import WithPermissions from '../../../common/WithPermissions';
import ButtonTypes from '../../../constants/ButtonTypes';
import MixPanel from '../../../constants/MixPanel';
import RbacActions from '../../../constants/RbacActions';
import Trash from '../../../icons/Trash';
import { track } from '../../../lib/segment';
import Loader from '../../../lottie/graph-loader.json';
import Table from '../../Table';
import useFilteredAndSortedItems from '../../useFilteredAndSortedUsers';
import useSortable from '../../useSortable';
import { useAppDispatch, useAppSelector } from '.././../../store/hooks';
import { deleteActor } from '../actions';
import DeleteModal from '../DeleteModal';
import User from './User';
import UserRoleHandler from './UserRoleHandler';

function UserList() {
	const dispatch = useAppDispatch();
	const { allUsers, usersLoaded, deleteActorPending } = useAppSelector(state => state.admin.users);
	const { searchText } = useAppSelector(state => state.admin.adminConfig);
	const { actorId: currentActorId } = useAppSelector(state => state.auth.userInfo);

	const [actorId, setActorId] = useState<string>();
	const [email, setEmail] = useState<string>();
	const [keepUserOnTop, setKeepUserOnTop] = useState(true);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [userName, setUserName] = useState<string>();

	const { requestSort, sortConfig } = useSortable();

	const usersCopy = useCallback(() => {
		let usersCopy: any[] = allUsers.slice();

		usersCopy = usersCopy.map(user => ({
			...user,
			displayName: user.firstName ? `${user.firstName} ${user.lastName}`.toLowerCase() : user.email.toLowerCase(),

			searchValue: user.firstName ? `${user.firstName} ${user.lastName}`.toLowerCase() : user.email.toLowerCase(),
		}));

		return usersCopy;
	}, [allUsers]);

	const filteredAndSortedUsers = useFilteredAndSortedItems({
		items: usersCopy(),
		searchText,
		sortConfig,
		keepOnTop: keepUserOnTop ? currentActorId : undefined,
	});

	function getUserNameFromActorId(actorId: string) {
		const actor = allUsers.find(a => a.actorId === actorId);
		return actor && actor.firstName ? `${actor.firstName} ${actor.lastName}` : '';
	}

	function handleDelete(actorId: string, email: string) {
		if (actorId !== currentActorId) {
			const userName = getUserNameFromActorId(actorId);
			track(MixPanel.Events.AdminClickDelete, { userName });
			setShowDeleteModal(true);
			setActorId(actorId);
			setUserName(userName);
			setEmail(email);
		}
	}

	function handleAction() {
		dispatch(deleteActor(actorId as string, email as string)).then(() => {
			track(MixPanel.Events.AdminDeleteUser, { userName });
			setShowDeleteModal(false);
		});
	}

	function handleCancel() {
		const userName = getUserNameFromActorId(actorId as string);
		track(MixPanel.Events.AdminDeleteUserCancel, { userName });
		setShowDeleteModal(false);
	}

	useEffect(() => {
		requestSort('displayName', 'string');
	}, []);

	return usersLoaded ? (
		<Table>
			{searchText && filteredAndSortedUsers.length === 0 && (
				<div className="user-table__empty">Sorry, no users match your search query</div>
			)}
			{filteredAndSortedUsers.length > 0 && (
				<Table.HeadRow base={10}>
					<Table.Cell span={3}>
						<Table.Sortable
							onClick={() => {
								requestSort('displayName', 'string');
								setKeepUserOnTop(false);
							}}
							sortActive={sortConfig.key === 'displayName'}
							sortDirection={sortConfig.direction}
						>
							<span>Name</span>
						</Table.Sortable>
					</Table.Cell>
					<Table.Cell span={6}>
						<WithPermissions actions={[RbacActions['Roles/View']]}>
							<span>Role</span>
						</WithPermissions>
					</Table.Cell>
					<Table.Cell span={1}> {''}</Table.Cell>
				</Table.HeadRow>
			)}
			{filteredAndSortedUsers.map(actor => (
				<Table.Row base={10} key={actor.actorId}>
					<Table.Cell span={3}>
						<User currentActorId={currentActorId} actor={actor} />
					</Table.Cell>
					<Table.Cell span={6}>
						<UserRoleHandler actor={actor} currentActorId={currentActorId} />
					</Table.Cell>
					<Table.Cell span={1} justifyContent="flex-end">
						<WithPermissions actions={[RbacActions['Users/Edit']]}>
							<Tooltip
								title={
									currentActorId === actor.actorId ? 'You cannot delete your own account' : 'Delete'
								}
								placement="bottom"
							>
								<div>
									<Button
										componentType={ButtonTypes.type.TERTIARY}
										circle={true}
										onClick={() => handleDelete(actor.actorId, actor.email)}
										disabled={currentActorId === actor.actorId}
									>
										<Trash width={24} />
									</Button>
								</div>
							</Tooltip>
						</WithPermissions>
					</Table.Cell>
				</Table.Row>
			))}
			<WithPermissions actions={[RbacActions['Users/Edit']]}>
				<DeleteModal
					deleteActorPending={deleteActorPending}
					isOpen={showDeleteModal}
					onAction={handleAction}
					onCancel={handleCancel}
				/>
			</WithPermissions>
		</Table>
	) : (
		<div className="user-table--loading">
			<LottieAnimation autoplay={true} loop={true} width={30} height={30} animation={Loader}></LottieAnimation>
		</div>
	);
}

UserList.defaultProps = {
	actors: [],
};

export default UserList;
