import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as uuid from 'uuid';

import Button from '../../common/Button';
import Dropdown from '../../common/Dropdown';
import TextField from '../../common/TextField';
import ButtonTypes from '../../constants/ButtonTypes';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { analysisTypeOptions, bucketOptions, engineMap, engineOptions, mainColumnOptions } from '../constants';
import { getTemplates, saveTemplate } from './actions';

function getDefaultBucketName() {
	return process.env.REACT_APP_ENV !== 'prod' ? bucketOptions[0] : bucketOptions[1];
}

function TemplateEdit({ match, history }: RouteComponentProps<{ id: string }>) {
	const dispatch = useAppDispatch();
	const id = match.params.id;
	const { loaded, data } = useAppSelector(state => state.datastudio.templates);
	const shouldFetch = !loaded;
	const template = id ? data?.find((c: any) => c.templateId === id) : undefined;
	const configurations = useAppSelector(state => state.datastudio.configurations.data);

	useEffect(() => {
		if (shouldFetch) {
			dispatch(getTemplates());
		}
	}, [shouldFetch]);

	const [templateConfigurationId, setTemplateConfigurationId] = useState(template?.templateConfigurationId);
	const [analysisType, setAnalysisType] = useState(template?.analysisType);
	const [engine, setEngine] = useState(template?.engine);
	const [analysisGroup, setAnalysisGroup] = useState(template?.analysisGroup);
	const [sqlFile, setSqlFile] = useState(template?.sqlFile);
	const [templateBucketName, setTemplateBucketName] = useState(
		template?.templateBucketName || getDefaultBucketName()
	);
	const [mainColumn, setMainColumn] = useState(template?.mainColumn);
	const [uniqueName, setUniqueName] = useState(template?.uniqueName);
	const [error, setError] = useState('');

	useEffect(() => {
		if (template) {
			setTemplateConfigurationId(template.templateConfigurationId);
			setAnalysisType(template.analysisType);
			setAnalysisGroup(template.analysisGroup);
			setEngine(template.engine);
			setSqlFile(template.sqlFile);
			setTemplateBucketName(template.templateBucketName);
			setMainColumn(template.mainColumn);
			setUniqueName(template.uniqueName);
		}
	}, [template]);

	const options =
		configurations?.map(c => ({
			label: c.templateName,
			value: c.templateConfigurationId,
		})) || [];
	const selectedOption = options.find(o => o.value === templateConfigurationId);
	const templateConfiguration = configurations?.find(c => c.templateConfigurationId === templateConfigurationId);

	function handleSave() {
		if (
			!templateConfigurationId ||
			!analysisType ||
			!engine ||
			!templateBucketName ||
			(analysisType === 'Percentage' && !mainColumn)
		) {
			setError('Please enter all the fields');
			return;
		}

		const templateObj: any = {
			templateId: template?.templateId || uuid.v4(),
			templateConfigurationId,
			analysisGroup: analysisGroup && analysisGroup !== '' ? analysisGroup : undefined,
			analysisName: templateConfiguration?.analysisName || '',
			templatePath: templateConfigurationId + '.json',
			analysisType,
			engine,
			sqlFile,
			templateBucketName,
			mainColumn,
			uniqueName,
		};
		dispatch(saveTemplate(templateObj, templateConfiguration?.templateName || ''));
		history.push(`/datastudio/templates/${templateObj.templateId}`);
	}

	function handleCancel() {
		history.goBack();
	}

	function handleAnalysisTypeChange(value: string) {
		setAnalysisType(value);
		if (value !== 'Percentage') {
			setMainColumn(undefined);
		}
		setEngine(engineMap[value]);
	}

	return (
		<div className="ds-container">
			<div className="ds-flex">
				<Button componentType={ButtonTypes.type.PRIMARY} onClick={handleSave}>
					Save
				</Button>
				<Button componentType={ButtonTypes.type.SECONDARY} style={{ marginLeft: 8 }} onClick={handleCancel}>
					Cancel
				</Button>
			</div>
			{error && <div className="ds-error">{error}</div>}
			<div className="ds-label">Template id</div>
			<div className="ds-value">{template?.templateId || 'New'}</div>
			<div className="ds-label">Template configuration</div>
			<div className="ds-input">
				<Dropdown
					placeholder="Select"
					options={options}
					selectedOption={selectedOption}
					onClick={(value: any) => setTemplateConfigurationId(value)}
				/>
			</div>
			<div className="ds-label">Template name</div>
			<div className="ds-value">{templateConfiguration?.templateName}</div>
			<div className="ds-label">Analysis Group</div>
			<div className="ds-input">
				<TextField value={analysisGroup || ''} onChange={e => setAnalysisGroup(e.target.value)} />
			</div>
			<div className="ds-label">Analysis name</div>
			<div className="ds-value">{templateConfiguration?.analysisName}</div>
			<div className="ds-label">Template path</div>
			<div className="ds-value">
				{templateConfiguration && templateConfiguration.templateConfigurationId + '.json'}
			</div>
			<div className="ds-label">Analysis type</div>
			<div className="ds-input">
				<Dropdown
					placeholder="Select"
					options={analysisTypeOptions}
					selectedOption={analysisType}
					onClick={(value: any) => handleAnalysisTypeChange(value)}
				/>
			</div>
			<div className="ds-label">Engine</div>
			<div className="ds-input">
				<Dropdown
					placeholder="Select"
					options={engineOptions}
					selectedOption={engine}
					onClick={(value: any) => setEngine(value)}
				/>
			</div>
			<div className="ds-label">SQL file</div>
			<div className="ds-input">
				<TextField value={sqlFile || ''} onChange={e => setSqlFile(e.target.value)} />
			</div>
			{analysisType === 'Percentage' && (
				<>
					<div className="ds-label">Main column</div>
					<div className="ds-input">
						<Dropdown
							placeholder="Select"
							options={mainColumnOptions}
							selectedOption={mainColumn}
							onClick={(value: any) => setMainColumn(value)}
						/>
					</div>
				</>
			)}
			<div className="ds-label">DB table prefix</div>
			<div className="ds-input">
				<TextField value={uniqueName || ''} onChange={e => setUniqueName(e.target.value)} />
			</div>
			<div className="ds-label">Template bucket name</div>
			<div className="ds-value">{templateBucketName}</div>
		</div>
	);
}

export default TemplateEdit;
