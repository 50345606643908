import { RootState } from '../store/store';

const getChartData = (state: RootState, reportId?: string) => {
	if (!reportId) {
		const exploreData = {
			...state.explore.filter,
			...state.explore.chart,
			subtable: state.explore.subtable,
			events: state.timeline?.events,
		};
		return exploreData;
	} else {
		const reportData = state.reports[reportId];

		const dashboardData = {
			...reportData,
			subtable: reportData?.showSubtable || !!reportData?.breakdownSegment ? { ...reportData } : {},
			events: state.timeline?.events,
		};

		return dashboardData;
	}
};

export default getChartData;
