import produce from 'immer';
import moment from 'moment';

import getBenchmarkData from '../../benchmark/getBenchmarkData';
import ActionTypes from '../../constants/ActionTypes';
import AnalysisTypes from '../../constants/AnalysisTypes';
import ChartTypes from '../../constants/ChartTypes';
import parseBoolean from '../../lib/parseBoolean';
import { getStateForGenerateMetricOverlayReport, getStateForGenerateReport } from '../../store/reducerUtils';
import { IndexType, ISegmentData, YAxisType } from '../../types';
import { IExploreChartState } from './types';

const DATE_FORMAT = 'YYYY/MM/DD';

export function getInitialState(): IExploreChartState {
	return {
		analysisDisplayName: '',
		chartType: ChartTypes.Bar,
		data: [],
		dates: [],
		eventIds: [],
		eventsModalOpen: false,
		indexType: IndexType.Indexed,
		allowYAxisToggle: false,
		allowIndexToggle: false,
		columnProps: [],
		currentIndex: 0,
		isSettingsOpen: true,
		lifecycle: '',
		selection: [],
		status: 'idle',
		subAnalysisFields: [],
		yAxisType: YAxisType.Percentage,
		generateMetricOverlayPending: false,
		metricOverlayLifecycle: '',
	};
}

// create shared action types for all statesj
const sharedActions = (state: IExploreChartState, action: any) => {
	switch (action.type) {
		case ActionTypes.SetAnalysisName: {
			const { analysisDisplayName } = action;
			if (!state.analysisDisplayName) {
				return state;
			}
			if (analysisDisplayName !== state.analysisDisplayName) {
				return getInitialState();
			} else {
				return state;
			}
		}
		case ActionTypes.GeneratePending: {
			return {
				...state,
				lifecycle: action.analysisName,
				data: [],
				generatePending: true,
				generateError: false,
				generateNoData: false,
				generateInvalidVariation: false,
				generated: false,
			};
		}
		case ActionTypes.GenerateInvalidVariation:
			if (!action.isSubtable) {
				return {
					...state,
					generatePending: false,
					generateInvalidVariation: true,
				};
			}
			return state;
		case ActionTypes.GenerateNoData:
			const { analysisType } = action;
			if (!action.isSubtable) {
				return {
					...state,
					generatePending: false,
					generateNoData: true,
					dates: [],
					chartType: ChartTypes.Bar,
					analysisType,
				};
			}
			return state;
		case ActionTypes.GenerateRejected: {
			return {
				...state,
				generatePending: false,
				generateError: true,
			};
		}
		case ActionTypes.GenerateMetricOverlayPending: {
			return {
				...state,
				metricOverlayLifecycle: action.analysisName,
				generateMetricOverlayPending: true,
				generateMetricOverlayError: false,
				generateMetricOverlayInvalidVariation: false,
			};
		}
		case ActionTypes.GenerateMetricOverlayRejected: {
			return {
				...state,
				generateMetricOverlayPending: false,
				generateMetricOverlayError: true,
			};
		}
		case ActionTypes.GenerateMetricOverlayNoData:
			return {
				...state,
				generateMetricOverlayPending: false,
				generateMetricOverlayNoData: true,
				dates: [],
				chartType: ChartTypes.Bar,
				metricOverlayAnalysisType: action.analysisType,
			};
		case ActionTypes.GenerateMetricOverlayFulfilled:
			return getStateForGenerateMetricOverlayReport(state, {
				...action,
			});
		case ActionTypes.ClearMetricOverlay:
			return {
				...state,
				metricOverlay: undefined,
			};
		case ActionTypes.GenerateSubtableFulfilled:
			const allowIndexToggle = action?.allowIndexToggle || state?.allowIndexToggle;
			const allowYAxisToggle = action?.allowYAxisToggle || state?.allowYAxisToggle;

			return {
				...state,
				allowIndexToggle,
				allowYAxisToggle,
			};
		case ActionTypes.GenerateFulfilled: {
			const { eventIds, chartType } = state;
			const yAxisType = action?.yAxisType || state?.yAxisType;
			const indexType = action?.indexType || state?.indexType;
			const isSettingsOpen = true;

			return getStateForGenerateReport(state, {
				...action,
				eventIds,
				chartType,
				yAxisType,
				indexType,
				isSettingsOpen,
			});
		}
		case ActionTypes.SelectCurrentTime: {
			const { currentIndex } = action;
			const { dates, analysisType, data } = state;
			let { confidence } = state;
			if (analysisType === AnalysisTypes.LinearRegression && data.length > 1 && currentIndex >= 0) {
				confidence = data[1].series[currentIndex].confidenceScore;
			}

			let currentDate;

			if (currentIndex !== dates.length - 1) {
				currentDate = moment.utc(dates[currentIndex]).format(DATE_FORMAT);
			}
			return {
				...state,
				currentIndex,
				currentDate,
				confidence,
			};
		}
		case ActionTypes.ResetCurrentTime:
			return {
				...state,
				currentIndex: undefined,
				currentDate: undefined,
				generatePending: true,
			};
		case ActionTypes.SelectIntervalInExplore:
		case ActionTypes.SelectIntervalCountInExplore:
		case ActionTypes.SelectIntervalRangeInExplore:
			return {
				...state,
				currentDate: undefined,
			};
		case ActionTypes.SetChartType:
			return {
				...state,
				chartType: action.chartType,
			};
		case ActionTypes.SetBenchmark:
			return {
				...state,
				benchmark: action.benchmark,
				yAxisType: 'percentage',
				chartType: ChartTypes.Pie,
			};
		case ActionTypes.RemoveBenchmark:
			return {
				...state,
				benchmark: undefined,
			};
		case ActionTypes.SetHidePie: {
			return {
				...state,
				benchmark: {
					...state.benchmark,
					hidePie: action.hidePie,
				},
			};
		}
		case ActionTypes.OpenEventsModal: {
			return {
				...state,
				eventsModalOpen: true,
			};
		}
		case ActionTypes.CloseEventsModal: {
			return {
				...state,
				eventsModalOpen: false,
			};
		}
		case ActionTypes.ToggleChartEvent: {
			const { eventIds } = state;
			const { eventId } = action;

			return {
				...state,
				eventIds: eventIds.includes(eventId) ? eventIds.filter(id => id !== eventId) : [...eventIds, eventId],
			};
		}
		case ActionTypes.AddAllChartEvents: {
			const { allEvents } = action;

			return {
				...state,
				eventIds: [...allEvents.map((event: any) => event.eventId)],
			};
		}
		case ActionTypes.RemoveAllChartEvents: {
			return {
				...state,
				eventIds: [],
			};
		}
		case ActionTypes.SetChartEvents: {
			const { eventIds } = action;
			return {
				...state,
				eventIds,
			};
		}
		case ActionTypes.ClearFilters:
			return {
				...state,
				status: 'idle',
				selection: [],
				hoveredItem: undefined,
				data: [],
				dates: [],
				eventIds: [],
				currentIndex: undefined,
				currentDate: undefined,
				analysisType: undefined,
				generated: false,
				generateError: false,
				generateNoData: false,
				lifecycle: '',
				chartType: 'Bar',
				yAxisType: 'percentage',
				indexType: 'indexed',
				benchmark: undefined,
			};
		case ActionTypes.FetchBenchmarkDataPending:
			return {
				...state,
				benchmark: {
					...state.benchmark,
					pending: true,
					error: false,
					noData: false,
				},
			};
		case ActionTypes.FetchBenchmarkDataFulfilled: {
			let attribute = 'Gender';
			if (state.benchmark?.attributes && state.benchmark.attributes.length > 0) {
				attribute = state.benchmark.attributes[0];
			}
			const data = getBenchmarkData(attribute, action.data);

			return {
				...state,
				benchmark: {
					...state.benchmark,
					pending: false,
					data,
				},
			};
		}
		case ActionTypes.FetchBenchmarkDataRejected:
			return {
				...state,
				benchmark: {
					...state.benchmark,
					pending: false,
					error: true,
				},
			};
		case ActionTypes.FetchBenchmarkDataNoData:
			return {
				...state,
				benchmark: {
					...state.benchmark,
					pending: false,
					noData: true,
				},
			};

		case ActionTypes.SetYAxisType: {
			return {
				...state,
				yAxisType: action.payload,
			};
		}

		case ActionTypes.SetIndexType: {
			return {
				...state,
				indexType: action.payload,
			};
		}

		case ActionTypes.SetIsSettingsOpen: {
			return {
				...state,
				isSettingsOpen: action.isSettingsOpen,
			};
		}
		case ActionTypes.SyncExploreChartState: {
			return {
				...state,
				...action.payload,
				allowIndexToggle: parseBoolean(action.payload.allowIndexToggle),
				allowYAxisToggle: parseBoolean(action.payload.allowYAxisToggle),
				showBenchmarkIcon: parseBoolean(action.payload.showBenchmarkIcon),
				benchmark: parseBoolean(action.payload.benchmark),
				currentIndex: !!action?.payload?.currentIndex ? Number(action.payload?.currentIndex) : 0,
			};
		}

		case ActionTypes.ChangeLocation: {
			const pathname = window.location.pathname;
			if (pathname !== '/explore') {
				return getInitialState();
			}
			return state;
		}

		default:
			return state;
	}
};

function idleState(state: IExploreChartState, action: any) {
	switch (action.type) {
		case ActionTypes.SetActiveLabel: {
			const { label } = action;
			return produce(state, draftState => {
				draftState.status = 'activated';
				draftState.hoveredItem = label;

				const dataItem = draftState.data.find(d => d.label === label);
				const dataOverlayItem = draftState.metricOverlay?.data.find((d: ISegmentData) => d.label === label);
				if (dataItem) {
					dataItem.active = true;
				} else if (dataOverlayItem) {
					dataOverlayItem.active = true;
				}
			});
		}
		default:
			return sharedActions(state, action);
	}
}

function activatedState(state: IExploreChartState, action: any) {
	switch (action.type) {
		case ActionTypes.ClearFilters:
			return {
				...state,
				status: 'idle',
				selection: [],
				hoveredItem: undefined,
				data: [],
				dates: [],
				currentIndex: undefined,
				currentDate: undefined,
				analysisType: undefined,
				generated: false,
				generateError: false,
				generateNoData: false,
				lifecycle: '',
				chartType: 'Bar',
				yAxisType: 'percentage',
				indexType: 'indexed',
				benchmark: undefined,
			};
		case ActionTypes.SetActiveLabel: {
			const { label } = action;
			return produce(state, draftState => {
				draftState.hoveredItem = label;

				const dataItem = draftState.data.find(d => d.label === label);
				const dataOverlayItem = draftState.metricOverlay?.data.find((d: ISegmentData) => d.label === label);
				if (dataItem) {
					dataItem.active = true;
				} else if (dataOverlayItem) {
					dataOverlayItem.active = true;
				}
			});
		}

		case ActionTypes.ClearActiveLabel: {
			return produce(state, draftState => {
				draftState.hoveredItem = undefined;
				if (draftState.selection.length === 0) {
					draftState.status = 'idle';
				}

				const dataItem = draftState.data.find(d => d.active);
				const dataOverlayItem = draftState.metricOverlay?.data.find((d: ISegmentData) => d.active);
				if (dataItem) {
					dataItem.active = false;
				} else if (dataOverlayItem) {
					dataOverlayItem.active = false;
				}
			});
		}
		case ActionTypes.ToggleLabel: {
			const { label } = action;
			return produce(state, draftState => {
				if (draftState.selection.includes(label)) {
					draftState.selection = draftState.selection.filter(l => l !== label);
				} else {
					draftState.selection.push(label);
				}

				const item = draftState.data.find(d => d.label === label);
				if (item) {
					item.selected = !item.selected;
				}
			});
		}
		case ActionTypes.ToggleLockedSelection: {
			const { label } = action;
			return produce(state, draftState => {
				draftState.status = 'activatedLocked';
				draftState.selection = [label];
				draftState.hoveredItem = label;
			});
		}
		case ActionTypes.ClearSelection:
			return produce(state, draftState => {
				draftState.selection = [];
				if (draftState.hoveredItem === undefined) {
					draftState.status = 'idle';
				}

				draftState.data.forEach(d => {
					d.selected = false;
				});
			});

		default:
			return sharedActions(state, action);
	}
}

function activatedLockedState(state: IExploreChartState, action: any) {
	switch (action.type) {
		case ActionTypes.ClearFilters:
			return {
				...state,
				status: 'idle',
				selection: [],
				hoveredItem: undefined,
				data: [],
				dates: [],
				currentIndex: undefined,
				currentDate: undefined,
				analysisType: undefined,
				generated: false,
				generateError: false,
				generateNoData: false,
				lifecycle: '',
				chartType: 'Bar',
				yAxisType: 'percentage',
				indexType: 'indexed',
				benchmark: undefined,
			};
		case ActionTypes.ClearSelection:
			return produce(state, draftState => {
				draftState.selection = [];
				draftState.hoveredItem = undefined;
				draftState.status = 'idle';

				draftState.data.forEach(d => {
					d.selected = false;
				});
			});

		case ActionTypes.ToggleLockedSelection: {
			const { label } = action;
			return produce(state, draftState => {
				if (draftState.selection.includes(label)) {
					draftState.selection = [];
					draftState.hoveredItem = undefined;
					draftState.status = 'idle';
				} else {
					draftState.selection = [label];
					draftState.hoveredItem = label;
				}
			});
		}

		default:
			return sharedActions(state, action);
	}
}

export default function chartReducer(state: IExploreChartState = getInitialState(), action: any): IExploreChartState {
	switch (state.status) {
		case 'idle': {
			return idleState(state, action);
		}
		case 'activated': {
			return activatedState(state, action);
		}
		case 'activatedLocked': {
			return activatedLockedState(state, action);
		}
		default: {
			return state;
		}
	}
}
