import { Menu } from 'antd';
import { Fragment } from 'react';

import { useAppDispatch } from '../../../store/hooks';
import { setToDelete, setToEdit } from '../../actions';
import { openModal } from '../../settings/reducer';

interface GoalCardMoreItemsProps {
	open: boolean;
	setOpen: (open: boolean) => void;
	showDelete?: boolean;
	goalId?: string;
}

const GoalCardMoreItems = ({ setOpen, goalId, showDelete }: GoalCardMoreItemsProps) => {
	const dispatch = useAppDispatch();

	const handleEdit = (e: any) => {
		e.domEvent.stopPropagation();
		dispatch(setToEdit(goalId));
		setOpen(false);
		dispatch(openModal('goal'));
	};

	const handleDelete = (e: any) => {
		setOpen(false);
		e.domEvent.stopPropagation();
		dispatch(setToDelete(goalId));
	};

	return (
		<Menu
			onMouseLeave={() => {
				if (setOpen) {
					setOpen(false);
				}
			}}
			className="widget-menu dashboards-menu"
			selectedKeys={[]}
		>
			<Fragment>
				<Menu.Item key="edit-modal" onClick={handleEdit}>
					Edit
				</Menu.Item>
				{showDelete && (
					<Menu.Item key="delete-modal" onClick={handleDelete}>
						Delete
					</Menu.Item>
				)}
			</Fragment>
		</Menu>
	);
};

export default GoalCardMoreItems;
