import AntdPopover from 'antd/lib/popover';
import PropTypes from 'prop-types';
import { ReactNode, useEffect, useRef } from 'react';

interface PopoverProps {
	children?: ReactNode;
	placement?: any;
	trigger?: string;
	content?: any;
	renderContent?: any;
	visible?: boolean;
	overlayClassName?: string;
	getPopupContainer?: any;
	showArrow?: boolean;
	onVisibleChange?: any;
	closeOnScroll?: boolean;
	destroyTooltipOnHide?: boolean;
}

export default function Popover({
	children,
	placement,
	trigger,
	content,
	visible,
	overlayClassName,
	getPopupContainer,
	showArrow,
	onVisibleChange,
	closeOnScroll,
	destroyTooltipOnHide,
}: PopoverProps) {
	const scrollY = useRef<number>(0);

	function handleVisibleChange(visible: boolean) {
		onVisibleChange(visible);
		if (visible) {
			window.addEventListener('keydown', handleKeyDown);
			if (closeOnScroll) {
				scrollY.current = window.scrollY;
				window.addEventListener('scroll', handleScroll);
			}
		} else {
			window.removeEventListener('keydown', handleKeyDown);
			if (closeOnScroll) {
				window.removeEventListener('scroll', handleScroll);
			}
		}
	}

	useEffect(() => {
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	function handleKeyDown(e: any) {
		if (e.keyCode === 27) {
			handleVisibleChange(false);
		}
	}

	function handleScroll() {
		if (Math.abs(window.scrollY - scrollY.current) > 5) {
			handleVisibleChange(false);
		}
	}

	return (
		<AntdPopover
			showArrow={showArrow}
			placement={placement}
			trigger={trigger}
			visible={visible}
			onVisibleChange={handleVisibleChange}
			content={content}
			overlayClassName={overlayClassName}
			getPopupContainer={getPopupContainer}
			destroyTooltipOnHide={destroyTooltipOnHide}
		>
			{children}
		</AntdPopover>
	);
}

Popover.defaultProps = {
	placement: 'bottom',
	trigger: 'click',
	onVisibleChange: () => {},
	closeOnScroll: true,
};

Popover.propTypes = {
	closeOnScroll: PropTypes.bool,
	showArrow: PropTypes.bool,
};
