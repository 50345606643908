interface AlertProps {
	message: string;
}

export default function Alert({ message }: AlertProps) {
	return (
		<div
			style={{
				backgroundColor: 'var(--color-fail)',
				padding: '0.8rem 1.6rem',
			}}
		>
			{message}
		</div>
	);
}
