import axios from 'axios';

import ActionTypes from '../../constants/ActionTypes';
import Urls from '../../constants/Urls';
import errorHandler from '../../lib/errorHandler';
import { getConfigurations } from '../configuration/actions';

export function getTemplates() {
	return async (dispatch: any, getState: any) => {
		try {
			dispatch({
				type: ActionTypes.GetDSTemplatesPending,
			});
			const shouldFetchConfig = !getState().datastudio.configurations.loaded;
			if (shouldFetchConfig) {
				await dispatch(getConfigurations());
			}
			const response = await axios.post(`${Urls.DataStudioApi}templates`, {});
			const data = response.data;
			dispatch({
				type: ActionTypes.GetDSTemplatesFulfilled,
				data,
				configData: getState().datastudio.configurations.data,
			});
		} catch (error: any) {
			errorHandler.report(error);
			console.log(error);
			dispatch({
				type: ActionTypes.GetDSTemplatesRejected,
			});
		}
	};
}

export function saveTemplate(template: any, templateName: string) {
	return async (dispatch: any) => {
		try {
			dispatch({
				type: ActionTypes.SaveTemplatePending,
			});
			await axios.put(`${Urls.DataStudioApi}templates`, template);
			dispatch({
				type: ActionTypes.SaveTemplateFulfilled,
				template,
				templateName,
			});
		} catch (error: any) {
			errorHandler.report(error);
			dispatch({
				type: ActionTypes.SaveTemplateRejected,
			});
		}
	};
}
