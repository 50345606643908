import axios from 'axios';
import { push } from 'connected-react-router';
import flatMap from 'lodash/flatMap';

import { generateUserRolesData } from '../admin/roles/utils';
import { getAuthorizationToken } from '../auth/actions';
import ActionTypes from '../constants/ActionTypes';
import LSKeys from '../constants/LSKeys';
import MixPanel from '../constants/MixPanel';
import Urls from '../constants/Urls';
import decodeJwtToken from '../lib/decodeJwtToken';
import errorHandler from '../lib/errorHandler';
import firebase, { fireDb } from '../lib/firebase';
import { identify, reset, track } from '../lib/segment';

declare global {
	interface Window {
		gapi: any;
	}
}

export function getAccount() {
	return (dispatch: any, getState: any) => {
		const { enterpriseId } = getState().account;

		dispatch({
			type: ActionTypes.GetAccountPending,
		});

		return axios
			.post(`${Urls.AccountApi}accountmeta`, {
				enterpriseId,
			})
			.then(response => {
				const {
					enterpriseId,
					enterpriseName,
					latestDataUpdate,
					logoURL,
					logoLightURL,
					isDataReady,
					memberSince,
					accountSSO,
					customColors,
					...labels
				} = response.data;

				if (accountSSO && accountSSO.sso && accountSSO.sso.length) {
					dispatch({
						type: ActionTypes.SetSSOOptions,
						sso: accountSSO.sso,
					});
				}

				dispatch({
					type: ActionTypes.SetFilterLabels,
					enterpriseId,
					labels,
				});

				return dispatch({
					type: ActionTypes.GetAccountFulfilled,
					enterpriseId,
					enterpriseName,
					latestDataUpdate,
					logoURL,
					logoLightURL,
					isDataReady,
					memberSince,
					customColors,
				});
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.GetAccountRejected,
				});
			});
	};
}

export function getAnalysisTree() {
	return (dispatch: any, getState: any) => {
		const { enterpriseId } = getState().account;

		dispatch({
			type: ActionTypes.GetAnalysisTreePending,
		});

		const docRef = fireDb.collection('analysis_name_tree').doc(enterpriseId);

		return docRef
			.get()
			.then(doc => {
				if (doc.exists) {
					dispatch({
						type: ActionTypes.GetAnalysisTreeFulfilled,
						payload: doc.data(),
					});
				} else {
					dispatch({
						type: ActionTypes.GetAnalysisTreeNoData,
					});
				}
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.GetAnalysisTreeRejected,
				});
			});
	};
}

export function getFeatureFlags() {
	return (dispatch: any, getState: any) => {
		const { enterpriseId } = getState().account;

		dispatch({
			type: ActionTypes.GetFeatureFlagsPending,
		});

		const docRef = fireDb.collection('feature_flags').doc(enterpriseId);

		return docRef
			.get()
			.then(doc => {
				if (doc.exists) {
					dispatch({
						type: ActionTypes.GetFeatureFlagsFulfilled,
						payload: doc.data(),
					});
				} else {
					dispatch({
						type: ActionTypes.GetFeatureFlagsNoData,
					});
				}
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.GetFeatureFlagsRejected,
				});
			});
	};
}

export function getFieldsMeta() {
	return (dispatch: any, getState: any) => {
		const { enterpriseId } = getState().account;

		dispatch({
			type: ActionTypes.GetFieldsMetaPending,
		});

		return axios
			.post(`${Urls.DataStudioApi}meta/fields`, {
				enterpriseId,
			})
			.then(response => {
				const { enterpriseId, ...payload } = response.data;
				return dispatch({
					type: ActionTypes.GetFieldsMetaFulfilled,
					payload,
				});
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.GetFieldsMetaRejected,
				});
			});
	};
}

export function getFields() {
	return (dispatch: any, getState: any) => {
		const { enterpriseId } = getState().account;

		dispatch({
			type: ActionTypes.GetFieldsPending,
		});

		return axios
			.post(`${Urls.DataStudioApi}fields`, {
				enterpriseId,
			})
			.then(response => {
				return dispatch({
					type: ActionTypes.GetFieldsFulfilled,
					payload: response.data,
				});
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.GetFieldsRejected,
				});
			});
	};
}

export function setAccount(enterpriseId: string, redirect: boolean) {
	return async (dispatch: any, getState: any) => {
		localStorage.setItem(LSKeys.EnterpriseId, enterpriseId);

		dispatch({
			type: ActionTypes.SetEnterpriseId,
			enterpriseId,
		});

		return dispatch(getAuthorizationToken()).then(() => {
			const { uid, email, firstName, lastName, disableExploreAccess } = getState().auth.userInfo;
			const { enterpriseId, enterpriseName } = getState().account;
			identify(uid, {
				email: email,
				firstName: firstName,
				lastName: lastName,
				companyId: enterpriseId,
				companyName: enterpriseName,
				createdAt: new Date().toISOString(),
				disableExploreAccess,
				language: 'EN',
			});
			track(MixPanel.Events.LogIn);

			if (redirect) {
				dispatch(push('/'));
				dispatch({
					type: ActionTypes.SetEnterpriseIdComplete,
				});
			}
		});
	};
}

export function getActorInfo() {
	return async (dispatch: any, getState: any) => {
		const { enterpriseId } = getState().account;
		dispatch({
			type: ActionTypes.GetActorInfoPending,
		});
		return axios
			.post(`${Urls.AccountApi}actor/info`, {})
			.then(async response => {
				const { roleIds } = decodeJwtToken();
				return axios
					.post(`${Urls.RbacApi}roles/ids`, {
						enterpriseId,
						roleIds,
					})
					.then(rolesResponse => {
						const rolesData = generateUserRolesData(rolesResponse.data);

						dispatch({
							type: ActionTypes.GetActorInfoFulfilled,
							payload: {
								userInfo: response.data,
								...rolesData,
							},
						});
					});
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.GetActorInfoRejected,
				});
			});
	};
}

export function setActorInfo(userInfo: any) {
	return (dispatch: any) => {
		dispatch({
			type: ActionTypes.SetActorInfoPending,
		});

		if (userInfo.lightMode) {
			localStorage.setItem(LSKeys.LightMode, 'true');
		} else {
			localStorage.removeItem(LSKeys.LightMode);
		}

		return axios
			.put(`${Urls.AccountApi}actor/info`, userInfo)
			.then(() => {
				return axios.post(`${Urls.AccountApi}actor/info`, {}).then(response => {
					dispatch({
						type: ActionTypes.SetActorInfoFulfilled,
						userInfo: response.data,
					});
				});
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.SetActorInfoRejected,
				});
			});
	};
}

export function signOut() {
	return (dispatch: any, getState: any) => {
		const { signOutPending } = getState().auth;
		if (signOutPending) {
			// avoid too many signOut calls!
			return;
		}

		dispatch({
			type: ActionTypes.SignOutPending,
		});

		if (window.gapi.auth2) {
			const instance = window.gapi.auth2.getAuthInstance();
			if (instance) {
				instance.signOut();
			}
		}

		firebase
			.auth()
			.signOut()
			.then(() => {
				track(MixPanel.Events.LogOut);
				reset();
				if (window.zE) {
					window.zE(function () {
						window.zE.hide();
					});
				}
				dispatch({
					type: ActionTypes.SignOutFulfilled,
				});
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.SignOutRejected,
				});
			});
	};
}

export function getNotifications() {
	return (dispatch: any, getState: any) => {
		const { enterpriseId } = getState().account;

		dispatch({
			type: ActionTypes.GetNotificationsPending,
		});
		return axios
			.post(`${Urls.NotificationsApi}notifications`, {
				enterpriseId,
			})
			.then(response => {
				const { notifications } = response.data;
				dispatch({
					type: ActionTypes.GetNotificationsFulfilled,
					notifications,
				});
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.GetNotificationsRejected,
				});
			});
	};
}

export function markNotificationAsRead(notificationId: string) {
	return (dispatch: any, getState: any) => {
		const { enterpriseId } = getState().account;

		const { list } = getState().notifications;
		const notification = list.find((n: any) => n.notificationId === notificationId);

		if (!notification) return;

		if (notification.status === 'Read') {
			dispatch(push(notification.notificationRoute));
			return;
		}

		dispatch({
			type: ActionTypes.MarkNotificationAsReadPending,
			notificationId,
		});
		return axios
			.put(`${Urls.NotificationsApi}notification/status`, {
				notificationId,
				enterpriseId,
			})
			.then(() => {
				dispatch({
					type: ActionTypes.MarkNotificationAsReadFulfilled,
				});
				dispatch(push(notification.notificationRoute));
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.MarkNotificationAsReadRejected,
				});
			});
	};
}

export function getAnalysisInfo() {
	return (dispatch: any, getState: any) => {
		const { enterpriseId } = getState().account;

		dispatch({
			type: ActionTypes.GetAnalysisInfoPending,
		});
		return axios
			.post(`${Urls.AccountApi}get-analysis-info`, {
				enterpriseId,
			})
			.then(response => {
				const templates = flatMap(response.data.packages, p => p.templates);
				dispatch({
					type: ActionTypes.GetAnalysisInfoFulfilled,
					templates,
				});
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.GetAnalysisInfoRejected,
				});
			});
	};
}
