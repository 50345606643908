interface FormErrorProps {
	error: string;
	noPadding?: boolean;
}

export default function FormError(props: FormErrorProps) {
	const { error, noPadding } = props;
	const classNames = ['form-error'];
	if (noPadding) {
		classNames.push('form-error--no-padding');
	}
	return error ? <div className={classNames.join(' ')}>{error}</div> : <div className="form-error--empty" />;
}
