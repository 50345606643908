import { Menu } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import analysisTypes from '../constants/AnalysisTypes';
import ChartTypes from '../constants/ChartTypes';
import mixPanel from '../constants/MixPanel';
import RbacActions from '../constants/RbacActions';
import DraggingTrigger from '../dashboard/DraggingTrigger';
import { DashboardReport } from '../dashboard/models';
import { navigateToExplore } from '../dashboard/view/actions';
import ChartActions from '../editor/chart/ChartActions';
import ChartTitle from '../editor/chart/ChartTitle';
import exportChart from '../editor/chart/exportChart';
import exportCsv from '../editor/chart/exportCsv';
import exportTable from '../editor/chart/exportTable';
import exportMatrixCsv from '../explore/detail/exportCsv';
import exportMatrixAsImage from '../explore/detail/exportDetailData';
import usePermissions from '../hooks/usePermissions';
import getChartData from '../lib/getChartData';
import getPeriod from '../lib/getPeriod';
import { track } from '../lib/segment';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setScrollPosition } from './actions';

export default function DashboardChartHeader({
	dashboard,
	dashboardReport,
	isLocked,
	eventsHidden,
	setEventsHidden,
	isHovered,
	textChartDispatch,
}: {
	dashboard: any;
	isLocked?: boolean;
	eventsHidden: boolean;
	setEventsHidden?: any;
	isHovered: boolean;
	textChartDispatch?: any;
	dashboardReport: DashboardReport;
}) {
	const dispatch = useAppDispatch();
	const { enterpriseName } = useAppSelector(state => state.account);
	const { dashboardId, dashboardName, dateAdded } = dashboard;
	const {
		chartType,
		data,
		columnProps,
		dates,
		subAnalysisFields,
		subAnalysisMapping,
		lifecycle,
		confidence,
		analysisType,
		date,
		interval,
		summary,
		groupSummary,
		attributeName,
		diversityAttributes,
		benchmark,
		sortCriteria,
		expanded,
		tableRowHeight,
		metricOverlay,
		subtable,
	} = useAppSelector(state => getChartData(state, dashboardReport?.reportId));

	const { name, eventIds, reportId, configuration } = dashboardReport;
	const textChartType = dashboardReport?.configuration?.find((c: any) => c.name === 'chartType')?.value;
	const [exportOpen, setExportOpen] = useState(false);
	const [moreOpen, setMoreOpen] = useState(false);

	const disableExploreAccess = !usePermissions({
		actions: [RbacActions['Dashboards/Explore']],
	});

	const options = configuration.reduce(
		(acc: any, c) => ({
			...acc,
			[c.name]: c.value,
		}),
		{}
	);

	const isTextChart = textChartType === ChartTypes.Text;
	const isInsightsChart = textChartType === ChartTypes.InsightsText;
	const isMatrixChart = chartType === ChartTypes.Matrix || subtable?.subtableChartType === ChartTypes.Matrix;
	const isHorizontalBarChart = chartType === ChartTypes.HorizontalBar;
	const isTableChart = chartType === ChartTypes.Table;

	function handleExplore() {
		track(mixPanel.Events.DashboardReportExplore, {
			'Dashboard Name': dashboardName,
			'Report Name': name,
		});

		dispatch(setScrollPosition(window.scrollY));
		dispatch(
			navigateToExplore({
				dashboardId,
				reportId,
				editMode: false,
				rowIndex: undefined,
			})
		);
	}

	function renderExportMenu() {
		return (
			<Menu className="widget-menu" selectedKeys={[]} onClick={handleExportMenuClick}>
				<Menu.Item onClick={handleCSVExport}>Export as CSV</Menu.Item>
				<Menu.Item onClick={handleImageExport}>Export as Image</Menu.Item>
			</Menu>
		);
	}

	function handleCSVExport() {
		track(mixPanel.Events.DashboardReportCSVExport, {
			'Dashboard Name': dashboardName,
			'Report Name': name,
		});
		if (isMatrixChart || isHorizontalBarChart || subtable?.showSubtable) {
			const period = getPeriod(date, interval);
			exportMatrixCsv(
				data,
				summary,
				groupSummary,
				lifecycle,
				analysisType,
				columnProps,
				attributeName,
				diversityAttributes,
				period
			);
		} else {
			exportCsv({
				dashboardName,
				reportName: name,
				analysisType,
				columnProps,
				data,
				dates,
				interval,
				benchmark,
				shouldReturnCsv: false,
				subAnalysisFields,
				subAnalysisMapping,
				chartType,
				metricOverlay,
			});
		}
		setExportOpen(false);
	}

	function handleImageExport() {
		track(mixPanel.Events.DashboardReportImageExport, {
			'Dashboard Name': dashboardName,
			'Report Name': name,
		});

		if (isMatrixChart) {
			const period = getPeriod(date, interval);
			exportMatrixAsImage(
				enterpriseName,
				period,
				data,
				summary,
				groupSummary,
				name,
				lifecycle,
				analysisType,
				columnProps,
				attributeName,
				diversityAttributes,
				sortCriteria,
				expanded
			);
		} else if (isTableChart) {
			exportTable(
				name,
				confidence,
				data,
				lifecycle,
				analysisType,
				columnProps,
				dates,
				interval,
				tableRowHeight,
				sortCriteria,
				expanded,
				options
			);
		} else {
			exportChart({
				reportId,
				title: name,
				analysisType,
				chartType: subtable?.showSubtable ? subtable?.subtableChartType : chartType,
				confidence,
				length,
				data,
				shouldReturnData: false,
				shouldReturnBlob: false,
			});
		}
		setExportOpen(false);
	}

	function handleExportMenuClick({ domEvent }: any) {
		domEvent.stopPropagation();
	}

	return (
		<>
			<DraggingTrigger isLocked={isLocked} />
			<div
				className="editor__chart__html"
				style={{
					position: isTextChart ? 'absolute' : 'relative',
					top: 0,
					left: 0,
					width: '100%',
				}}
			>
				<div className="editor__chart__header">
					<div className="editor__chart__header__title">
						{!(isTextChart && !isInsightsChart) && (
							<ChartTitle
								title={isInsightsChart ? moment.utc(dateAdded).format('MMM DD, yyyy') : name}
								isInsightsChart={isInsightsChart}
								showTooltip={false}
								disableExploreAccess={disableExploreAccess}
								handleExplore={handleExplore}
							/>
						)}
					</div>
					{length > 2 && (
						<div className="editor__chart__header__confidence">
							{(lifecycle === 'Compensation' || analysisType === analysisTypes.LinearRegression) &&
								confidence && <span>Confidence {confidence}%</span>}
							{(isMatrixChart || isHorizontalBarChart) && (
								<span
									style={{
										marginLeft: 8,
									}}
								>
									{getPeriod(date, interval)}
								</span>
							)}
						</div>
					)}
					<ChartActions
						dashboardId={dashboardId}
						dashboardName={dashboardName}
						disableExploreAccess={disableExploreAccess}
						eventIds={eventIds}
						eventsHidden={eventsHidden}
						exportOpen={exportOpen}
						isHovered={isHovered}
						isTextChart={isTextChart || isInsightsChart}
						isInsightsChart={isInsightsChart}
						moreOpen={moreOpen}
						reportId={reportId}
						title={name}
						handleExplore={handleExplore}
						renderExportMenu={renderExportMenu}
						setEventsHidden={setEventsHidden}
						setExportOpen={setExportOpen}
						setMoreOpen={setMoreOpen}
						textChartDispatch={textChartDispatch}
					/>
				</div>
			</div>
		</>
	);
}
