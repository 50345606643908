import cn from '../lib/cn';
import getChartData from '../lib/getChartData';
import { useAppSelector } from '../store/hooks';
import ScrollableChartArea from './ScrollableChartArea';
import StatsChart from './StatsChart';

const StatsChartWrapper = ({ reportId }: { reportId?: string; dashboardId?: string; [key: string]: any }) => {
	const { data } = useAppSelector(state => getChartData(state, reportId));

	return (
		<div className={cn('flex-1 flex flex-col h-full justify-between')}>
			<ScrollableChartArea>
				<div className={cn('flex-1 relative')}>
					<div className={cn('relative w-full')}>
						<div className="explore-chart__main">
							<StatsChart data={data} />
						</div>
					</div>
				</div>
			</ScrollableChartArea>
		</div>
	);
};

export default StatsChartWrapper;
