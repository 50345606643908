import HorizontalBarChart from '../../common/HorizontalBarChart';
import ChartTypes from '../../constants/ChartTypes';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { navigateToDetail } from '../detail/actions';
import Table from '../detail/Table';
import { sortInSubtable, toggleExpandedInSubtable } from './actions';

function Subtable({
	onHover,
	onHoverEnd,
	onToggle,
	hoveredItem,
	selection,
	status,
}: {
	onHover: any;
	onHoverEnd: any;
	onToggle: any;
	hoveredItem: any;
	selection: any;
	status: any;
}) {
	const dispatch = useAppDispatch();
	const {
		lifecycle,
		analysisType,
		employeeAttributes,
		employeeAttribute,
		attributeName,
		sortCriteria,
		columnProps,
		summary,
		groupSummary,
		showGroupSummary,
		expanded,
		subtableChartType,
		data,
		diversityAttributes,
	} = useAppSelector(state => state.explore.subtable);

	function handleNavigateToDetail(...args: any) {
		// @ts-ignore
		dispatch(navigateToDetail(...args));
	}

	if (subtableChartType === ChartTypes.Matrix) {
		return (
			<Table
				lifecycle={lifecycle}
				analysisType={analysisType}
				employeeAttribute={employeeAttribute}
				attributeName={attributeName}
				employeeAttributes={employeeAttributes}
				diversityAttributes={diversityAttributes}
				data={data}
				summary={summary}
				groupSummary={groupSummary}
				showGroupSummary={showGroupSummary}
				expanded={expanded}
				toggleExpansion={() => dispatch(toggleExpandedInSubtable())}
				onSort={props => dispatch(sortInSubtable(props))}
				sortCriteria={sortCriteria}
				onHover={onHover}
				onHoverEnd={onHoverEnd}
				onToggle={onToggle}
				hoveredItem={hoveredItem}
				selection={selection}
				columnProps={columnProps}
				navigateToDetail={handleNavigateToDetail}
			/>
		);
	} else if (subtableChartType === ChartTypes.HorizontalBar) {
		return (
			<HorizontalBarChart
				data={data}
				diversityAttributes={diversityAttributes}
				columnProps={columnProps}
				onHover={onHover}
				onHoverEnd={onHoverEnd}
				onToggle={onToggle}
				selection={selection}
				status={status}
				hoveredItem={hoveredItem}
			/>
		);
	}

	return null;
}

Subtable.defaultProps = {
	data: [],
	diversityAttributes: [],
	employeeAttributes: [],
};

export default Subtable;
