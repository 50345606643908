import moment from 'moment';

import PermanentBarView from './PermanentBarView';
import { IPermanentMessage } from './types';

const PermanentBar = () => {
	const messages: IPermanentMessage[] = [
		{
			text: 'Role preview mode',
			show: !!sessionStorage.getItem('preview'),
		},
		{
			text: "We're performing maintenance today and you may experience some delays. Thanks for your patience!",
			show: moment().isAfter(moment('2100-06-09T13:00:00.000Z', 'YYYY-MM-DDTHH:mm:ss.SSSZ')),
		},
	];

	return <PermanentBarView messages={messages} />;
};

export default PermanentBar;
