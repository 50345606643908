import { Tabs } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { useEffect, useState } from 'react';

import WithPermissions from '../../../common/WithPermissions';
import RbacActions from '../../../constants/RbacActions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import EmptyState, { NoActiveGoals, NoCompleteGoals } from '../../components/EmptyState';
import { setActiveGoalTab } from '../../settings/reducer';
import { IGoal } from '../../types';
import { goalCategoryFromStatus } from '../../utils';
import GoalCard from './GoalCard';

const Goals = () => {
	const { data: goals } = useAppSelector(state => state.measure.goals);
	const { sortCriteria, goalTab } = useAppSelector(state => state.measure.settings);
	const dispatch = useAppDispatch();
	const [highlightedIndex, setHighlightedIndex] = useState(0);
	const [cursorInside, setCursorInside] = useState(false);
	const [completedGoals, setCompletedGoals] = useState<IGoal[]>([]);
	const [activeGoals, setActiveGoals] = useState<IGoal[]>([]);

	useEffect(() => {
		const sortedGoals: IGoal[] = cloneDeep(goals).sort((a: IGoal, b: IGoal) => {
			const firstValue = a[sortCriteria.columnName as keyof IGoal];
			const secondValue = b[sortCriteria.columnName as keyof IGoal];
			const backupFirstValue = a.metricRegistrationRequest.metrics[0].trajectories[0].startDate;
			const backupSecondValue = b.metricRegistrationRequest.metrics[0].trajectories[0].startDate;

			if (!firstValue || !secondValue) {
				const firstCompareableDate = moment(backupFirstValue, 'MM/DD/YYYY');
				const secondCompareableDate = moment(backupSecondValue, 'MM/DD/YYYY');
				const difference = firstCompareableDate.diff(secondCompareableDate);
				return sortCriteria.ascending ? difference : -difference;
			}

			const difference = (firstValue as string).localeCompare(secondValue as string);
			return sortCriteria.ascending ? difference : -difference;
		});

		setActiveGoals(
			sortedGoals.filter(
				goal =>
					goalCategoryFromStatus(goal.goalStatus) === 'active' &&
					goal.metricTrajectoryValues.length > 0 &&
					moment.utc(goal.goalEndDate).isAfter(moment.utc())
			)
		);
		// If end date is before today or if goal status is completed, move the goal to completed
		setCompletedGoals(
			sortedGoals.filter(
				goal =>
					(goalCategoryFromStatus(goal.goalStatus) === 'completed' &&
						goal.metricTrajectoryValues.length > 0) ||
					moment.utc(goal.goalEndDate).isBefore(moment.utc())
			)
		);
	}, [goals]);

	if (completedGoals.length === 0 && activeGoals.length === 0 && goals) {
		return <EmptyState activeTab="goal" />;
	}

	return (
		<WithPermissions actions={[RbacActions['Goals/View']]}>
			<div className="goals" onMouseLeave={() => setCursorInside(false)}>
				<Tabs
					activeKey={goalTab}
					defaultActiveKey="Active"
					className="goals-tabs"
					onChange={(activeKey: string) => {
						dispatch(setActiveGoalTab(activeKey));
					}}
				>
					<Tabs.TabPane tab="Active" key="active">
						{activeGoals.length === 0 && <NoActiveGoals />}
						<div className="goals__grid" data-test="active-goals-grid">
							{activeGoals.length > 0 &&
								activeGoals.map((goal, index) => (
									<GoalCard
										key={goal.goalId + index}
										goal={goal}
										onMouseEnter={() => {
											setHighlightedIndex(index);
											setCursorInside(true);
										}}
										onMouseLeave={() => {
											setHighlightedIndex(-1);
											setCursorInside(false);
										}}
										highlighted={highlightedIndex === index || !cursorInside}
									/>
								))}
						</div>
					</Tabs.TabPane>
					<Tabs.TabPane tab="Completed" key="completed">
						{completedGoals.length === 0 && <NoCompleteGoals />}
						<div className="goals__grid">
							{completedGoals.length > 0 &&
								completedGoals.map((goal, index) => (
									<GoalCard
										key={goal.goalId + index}
										goal={goal}
										onMouseEnter={() => setHighlightedIndex(index)}
										onMouseLeave={() => setHighlightedIndex(-1)}
										highlighted={highlightedIndex === index || !cursorInside}
									/>
								))}
						</div>
					</Tabs.TabPane>
				</Tabs>
			</div>
		</WithPermissions>
	);
};

export default Goals;
