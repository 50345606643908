import BenchmarkModal from '../../benchmark/BenchmarkModal';
import '../../benchmark/styles.scss';
import ChartPicker from '../../common/ChartPicker';
import EventsModal from '../../common/EventsModal';
import InfoModal from '../../common/InfoModal';
import cn from '../../lib/cn';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { closeEventsModal } from './actions';
import DeleteBenchmarkModal from './DeleteBenchmarkModal';
import EmptyState from './EmptyState';

function Chart({ benchmarkEdit, benchmarkToEdit }: any) {
	const dispatch = useAppDispatch();
	const { showSubtable } = useAppSelector(state => state.explore.filter);
	const isEmptyState = useAppSelector(
		state => !state.explore.filter.analysisName && state.explore.filter.mainSegments.length === 0
	);
	let { generatePending, generateError, generateNoData, generateInvalidVariation, generated } = useAppSelector(
		state => state.explore.chart
	);
	const {
		generatePending: generatePendingSubtable,
		error,
		empty,
		invalidVariation,
		generated: generatedSubtable,
	} = useAppSelector(state => state.explore.subtable);
	if (showSubtable) {
		generatePending = generatePendingSubtable;
		generateNoData = empty;
		generateInvalidVariation = invalidVariation;
		generateError = error;
	}
	generated = showSubtable ? generatedSubtable : generated;

	const { benchmark } = useAppSelector(state => state.explore.chart);

	if (isEmptyState) {
		return null;
	}

	let isOther = false;
	if (!benchmark && benchmarkToEdit) {
		isOther = true;
	}
	if (benchmark && benchmarkToEdit) {
		isOther = benchmark.benchmarkId !== benchmarkToEdit.benchmarkId;
	}

	return (
		<div className={cn('explore-chart', 'relative hide-scrollbar overflow-x-auto mx-[2rem] mb-[1.6rem]')}>
			<EmptyState
				generatePending={generatePending}
				generateError={generateError}
				generateInvalidVariation={generateInvalidVariation}
				generateNoData={generateNoData}
				generated={generated}
			>
				<ChartPicker />
			</EmptyState>
			<InfoModal />
			<BenchmarkModal benchmark={benchmarkEdit ? benchmarkToEdit : null} isOther={isOther} />
			<EventsModal onCancel={() => dispatch(closeEventsModal())} />
			<DeleteBenchmarkModal benchmarkToEdit={benchmarkToEdit} />
		</div>
	);
}

export default Chart;
