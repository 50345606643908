import analysisTypes from '../constants/AnalysisTypes';
import GridConstants from '../constants/GridConstants';
import { selectCurrentTime as selectCurrentTimeInReport } from '../dashboard/view/actions';
import { selectCurrentTime } from '../explore/actions';
import cn from '../lib/cn';
import getChartData from '../lib/getChartData';
import getDynamicDataProp from '../lib/getDynamicDataProp';
import hasHiddenEmployeeCount from '../lib/hasHiddenEmployeeCount';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import Events from './Events';
import PieChart from './PieChart';
import ResponsiveContainer from './ResponsizeContainer';
import ScrollableChartArea from './ScrollableChartArea';

const PieChartWrapper = ({
	reportId,
	events,
	onHover,
	onHoverEnd,
	onToggle,
	hoveredItem,
	selection,
	status,
}: {
	reportId?: string;
	events?: any;
	onHover?: any;
	onHoverEnd?: any;
	onToggle?: any;
	hoveredItem?: any;
	selection?: any;
	status?: any;
}) => {
	const dispatch = useAppDispatch();
	const { dynamicPermissions } = useAppSelector(state => state.auth);

	const { data, lifecycle, dates, interval, yAxisType, indexType, columnProps, analysisType, currentIndex } =
		useAppSelector(state => getChartData(state, reportId));

	const dataProp = getDynamicDataProp(yAxisType, indexType, columnProps);
	const employeeCountHidden = hasHiddenEmployeeCount(dynamicPermissions);

	function handleSelectCurrentTime(currentIndex: number) {
		if (reportId) {
			dispatch(selectCurrentTimeInReport(reportId, currentIndex));
		} else {
			dispatch(selectCurrentTime(currentIndex));
		}
	}

	return (
		<div className={cn('flex-1 flex flex-col h-full justify-between')}>
			<ScrollableChartArea>
				<div className={cn('flex-1 relative')}>
					<div className={cn('relative w-full h-[250px] wide:h-[360px]', reportId && '!h-full')}>
						<ResponsiveContainer className="explore-chart__main">
							{({ width, height }: { width: number; height: number }) => (
								<PieChart
									reportId="explore-chart"
									width={width}
									height={height}
									data={data}
									dates={dates}
									interval={interval}
									lifecycle={lifecycle}
									analysisType={analysisType as analysisTypes}
									currentIndex={currentIndex}
									length={GridConstants.ColumnCount}
									colWidth={100}
									selectCurrentTime={(currentIndex: number) => handleSelectCurrentTime(currentIndex)}
									onHover={onHover}
									onHoverEnd={onHoverEnd}
									onToggle={onToggle}
									hoveredItem={hoveredItem}
									selection={selection}
									status={status}
									dataProp={dataProp}
									yAxisType={yAxisType}
									indexType={indexType}
									employeeCountHidden={employeeCountHidden}
									columnProps={columnProps}
								/>
							)}
						</ResponsiveContainer>
					</div>
				</div>
				{events && <Events {...events} />}
			</ScrollableChartArea>
			{/*
				<div>X AXIS</div>
        */}
		</div>
	);
};

export default PieChartWrapper;
