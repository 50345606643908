import { Popover } from 'antd';
import { forwardRef, useRef, useState } from 'react';

import AnalysisTypes from '../../constants/AnalysisTypes';
import ChartTypes from '../../constants/ChartTypes';
import MixPanel from '../../constants/MixPanel';
import exportAsCSV from '../../explore/chart/exportAsCSV';
import exportChart from '../../explore/chart/exportChart';
import exportDetailDataAsCsv from '../../explore/detail/exportCsv';
import exportDetailData from '../../explore/detail/exportDetailData';
import HMoreVertical from '../../icons/HMoreVertical';
import getPeriod from '../../lib/getPeriod';
import { track } from '../../lib/segment';
import { useAppSelector } from '../../store/hooks';
import MoreMenu from './MoreMenu';

const baseClass = 'chart-header';

const ChartHeader = forwardRef<HTMLDivElement, any>((_, forwardedRef) => {
	const { enterpriseName } = useAppSelector(state => state.account);
	const { showSubtable, interval } = useAppSelector(state => state.explore.filter);
	let { confidence, lifecycle, subAnalysisFields, subAnalysisMapping, metricOverlay } = useAppSelector(
		state => state.explore.chart
	);

	const {
		lifecycle: lifecycleSubtable,
		diversityAttributes,
		subtableChartType,
	} = useAppSelector(state => state.explore.subtable);
	const subtable = useAppSelector(state => state.explore.subtable);
	const { data, benchmark, dates, columnProps, currentIndex, chartType, analysisType } = useAppSelector(
		state => state.explore.chart
	);

	if (showSubtable) {
		lifecycle = lifecycleSubtable || '';
	}
	const [moreKeys, setMoreKeys] = useState([]);
	const [moreOpen, setMoreOpen] = useState(false);
	const fallbackRef = useRef<HTMLDivElement>(null);

	let ref = forwardedRef || fallbackRef;

	function handleMoreClick() {
		setMoreOpen(!moreOpen);
		if (!moreOpen) {
			setMoreKeys([]);
		}
	}

	function handleMoreMenuSelect({ selectedKeys }: any) {
		setMoreKeys(selectedKeys);
	}

	function handleExport() {
		track(MixPanel.Events.ExploreExport);
		if (showSubtable && chartType === ChartTypes.Matrix) {
			const {
				date,
				interval,
				data,
				summary,
				groupSummary,
				lifecycle,
				analysisType,
				columnProps,
				attributeName,
				sortCriteria,
				expanded,
			} = subtable;
			const period = getPeriod(date, interval as string);
			exportDetailData(
				enterpriseName,
				period,
				data,
				summary,
				groupSummary,
				null,
				lifecycle,
				analysisType,
				columnProps,
				attributeName,
				diversityAttributes,
				sortCriteria,
				expanded
			);
		} else {
			exportChart({
				lifecycle,
				analysisType,
				columnProps,
				data,
				dates,
				currentIndex,
				interval,
				chartType: showSubtable ? subtableChartType : chartType,
				cb: () => {
					setMoreOpen(false);
					setMoreKeys([]);
				},
			});
		}
	}

	function handleExportAsCSV() {
		track(MixPanel.Events.ExploreExportAsCSV);
		if (showSubtable) {
			const { date, interval, data, summary, groupSummary, lifecycle, analysisType, columnProps, attributeName } =
				subtable;
			const period = getPeriod(date, interval as string);
			exportDetailDataAsCsv(
				data,
				summary,
				groupSummary,
				lifecycle,
				analysisType,
				columnProps,
				attributeName,
				diversityAttributes,
				period
			);
		} else {
			const period = getPeriod(dates[currentIndex], interval);
			exportAsCSV({
				lifecycle,
				analysisType,
				columnProps,
				data,
				currentIndex,
				period,
				benchmark,
				chartType,
				subAnalysisFields,
				subAnalysisMapping,
				metricOverlay,
			});
		}
		setMoreOpen(false);
		setMoreKeys([]);
	}

	return (
		<div className="chart-header-wrapper" ref={ref}>
			<div className={baseClass}>
				<div className={`${baseClass}__title`}>{lifecycle}</div>
				<div className={`${baseClass}__confidence`}>
					{(lifecycle === 'Compensation' || analysisType === AnalysisTypes.LinearRegression) &&
						confidence && <span>Confidence {confidence}%</span>}
				</div>
				<div className={`${baseClass}__icons`} onClick={e => e.stopPropagation()}>
					<Popover
						placement="bottomRight"
						visible={moreOpen}
						onVisibleChange={handleMoreClick}
						content={
							<MoreMenu
								handleExport={handleExport}
								handleExportAsCSV={handleExportAsCSV}
								handleMoreMenuSelect={handleMoreMenuSelect}
								moreKeys={moreKeys}
							/>
						}
						overlayClassName="popover--no-padding"
					>
						<HMoreVertical className="icon" width={24} height={24} data-test="explore-more-menu" />
					</Popover>
				</div>
			</div>
		</div>
	);
});

ChartHeader.displayName = 'ChartHeader';

export default ChartHeader;
