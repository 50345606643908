import SortableHead, { OnSortFunc, SortCriteria } from '../common/SortableHead';
import decimalFormatter from '../lib/decimalFormatter';
import { CollectAttribute } from './model';

interface FieldValuesProps {
	attributes: CollectAttribute[];
	sortCriteria: SortCriteria;
	onSort: OnSortFunc;
}

function renderChangePercentage(changePercentage?: number) {
	if (typeof changePercentage === 'undefined' || changePercentage === 0) {
		return null;
	}
	if (changePercentage > 0) {
		return (
			<span className="collect-card__values__change collect-card__values__change--positive">
				+{decimalFormatter(changePercentage)}%
			</span>
		);
	} else {
		return (
			<span className="collect-card__values__change collect-card__values__change--negative">
				{decimalFormatter(changePercentage)}%
			</span>
		);
	}
}

export default function FieldValues({ attributes, sortCriteria, onSort }: FieldValuesProps) {
	return (
		<table className="collect-card__values">
			<thead>
				<tr>
					<SortableHead
						label="Completion / Change"
						columnName="completionPercentage"
						sortCriteria={sortCriteria}
						onSort={onSort}
						className="collect-card__fields__title"
					/>
				</tr>
			</thead>
			<tbody>
				{attributes.map(a => (
					<tr key={a.fieldName}>
						<td className="collect-card__values__value">
							<span>{decimalFormatter(a.completionPercentage)}%</span>
							{renderChangePercentage(a.changePercentage)}
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}
