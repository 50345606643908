import RbacActions from '../constants/RbacActions';
import usePermissions from '../hooks/usePermissions';
import Forbidden from '../routes/Forbidden';

interface IWithPermissionsProps {
	actions: RbacActions[];
	children: any;
	showMessage?: boolean;
}

const WithPermissions = (props: IWithPermissionsProps) => {
	const { actions, children, showMessage } = props;
	const hasPermission = usePermissions({ actions });

	if (!hasPermission) {
		if (showMessage) {
			return <Forbidden />;
		}
		return null;
	}

	return children;
};

export default WithPermissions;
