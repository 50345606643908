import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import Urls from '../../constants/Urls';
import { AsyncThunkConfig } from '../../store/store';

interface IdentityState {
	status: 'IDLE' | 'STARTED' | 'PENDING' | 'FULFILLED' | 'REJECTED';
	error: string | null;
}

const initialState: IdentityState = {
	status: 'IDLE',
	error: null,
};

export const identitySync = createAsyncThunk<any, void, AsyncThunkConfig>(
	'groups/identitySync',
	async (_, { getState }) => {
		const { enterpriseId } = getState().account;

		const response = await axios.put(`${Urls.RbacApi}identities/sync`, {
			enterpriseId,
		});

		return response.data;
	}
);

export const identitySyncStatus = createAsyncThunk<any, void, AsyncThunkConfig>(
	'groups/identitySyncStatus',
	async (_, { getState }) => {
		const { enterpriseId } = getState().account;

		const response = await axios.post(`${Urls.RbacApi}identities/sync/status`, {
			enterpriseId,
		});

		return response.data;
	}
);

const groupsSlice = createSlice({
	name: 'identity',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(identitySync.pending, () => {});
		builder.addCase(identitySync.fulfilled, (state, action: any) => {
			state.status = action.payload.status;
		});
		builder.addCase(identitySync.rejected, (state: any, action: any) => {
			state.error = action.error.message;
		});
		builder.addCase(identitySyncStatus.pending, () => {});
		builder.addCase(identitySyncStatus.fulfilled, state => {
			state.status = 'IDLE';
		});
		builder.addCase(identitySyncStatus.rejected, (state: any, action) => {
			state.error = action.error.message;
		});
	},
});

export default groupsSlice.reducer;
