import { RouteComponentProps } from 'react-router';

import Button from '../../../common/Button';
import ButtonTypes from '../../../constants/ButtonTypes';
import { useAppDispatch } from '../../../store/hooks';
import HeaderNav from '../common/HeaderNav';
import { setDescription, setTitle } from './editReducer';

export default function CategoriesHeader({ history }: RouteComponentProps) {
	const dispatch = useAppDispatch();

	function handleNew() {
		dispatch(setTitle(''));
		dispatch(setDescription(''));
		history.push('/datastudio/survey/category/edit');
	}

	return (
		<div className="survey-header">
			<HeaderNav />
			<Button componentType={ButtonTypes.type.PRIMARY} onClick={handleNew}>
				New category
			</Button>
		</div>
	);
}
