import { useEffect, useState } from 'react';
import { MdOutlineArchive, MdOutlineUnarchive } from 'react-icons/md';
import { RouteComponentProps } from 'react-router';

import Button from '../../../common/Button';
import ConfirmationModal from '../../../common/ConfirmationModal';
import DeleteModal from '../../../common/DeleteModal';
import LottieAnimation from '../../../common/LottieAnimation';
import Stack from '../../../common/Stack';
import TextField from '../../../common/TextField';
import ButtonTypes from '../../../constants/ButtonTypes';
import Trash from '../../../icons/Trash';
import Loader from '../../../lottie/graph-loader.json';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { archiveCategory, deleteCategory } from './editReducer';
import { fetchCategories } from './reducer';
import { Category } from './types';

export default function Categories({ history }: RouteComponentProps) {
	const [search, setSearch] = useState('');
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [globalCategoryId, setGlobalCategoryId] = useState('');
	const [categoryToArchive, setCategoryToArchive] = useState<Category>();
	const {
		data: activeData,
		archived: archivedData,
		pending,
	} = useAppSelector(state => state.datastudio.survey.categories);
	const [showArchived, setShowArchived] = useState(false);
	const [archiveModalOpen, setArchiveModalOpen] = useState(false);
	const [archivePending, setArchivePending] = useState(false);
	const data = showArchived ? archivedData : activeData;

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(fetchCategories());
	}, []);

	function handleEdit(globalCategoryId: string) {
		history.push(`/datastudio/survey/category/edit?globalCategoryId=${globalCategoryId}`);
	}

	async function handleArchive() {
		try {
			setArchivePending(true);
			await dispatch(
				archiveCategory({
					category: categoryToArchive!,
					shouldArchive: !showArchived,
				})
			);
			dispatch(fetchCategories());
		} finally {
			setArchivePending(false);
			setArchiveModalOpen(false);
		}
	}

	const filteredData = data.filter(d => d.title.toLowerCase().includes(search.toLowerCase()));

	return (
		<div className="survey-content">
			<Stack flexDirection="row" justifyContent="space-between" style={{ margin: '1.6rem 0' }}>
				<div style={{ flex: 1, maxWidth: '600px' }}>
					<TextField
						placeholder="Search category"
						isSearch
						value={search}
						onChange={e => setSearch(e.target.value)}
						showClose
						onClose={() => setSearch('')}
					/>
				</div>
				<Stack
					flexDirection="row"
					justifyContent="flex-end"
					gap=".8rem"
					style={{ marginLeft: '1.6rem', marginBottom: '1.6rem' }}
				>
					<Button
						activated={!showArchived}
						onClick={() => setShowArchived(false)}
						componentType={ButtonTypes.type.SECONDARY}
					>
						Active
					</Button>
					<Button
						activated={showArchived}
						onClick={() => setShowArchived(true)}
						componentType={ButtonTypes.type.SECONDARY}
					>
						Archived
					</Button>
				</Stack>
			</Stack>
			{!!search && !filteredData.length && (
				<div className="empty-search-container">Sorry, no categories match your search query</div>
			)}
			{pending ? (
				<div className="ds-loader">
					<LottieAnimation
						animation={Loader}
						autoplay={true}
						loop={true}
						width={100}
						height={100}
					></LottieAnimation>
				</div>
			) : (
				<div>
					<table className="ds-table">
						<tbody>
							{filteredData.map((d, i) => (
								<tr key={i}>
									<td className="link--plain" onClick={() => handleEdit(d.globalCategoryId!)}>
										{d.title}
									</td>
									<td width="24">
										<Button
											componentType={ButtonTypes.type.TERTIARY}
											circle={true}
											onClick={async () => {
												setCategoryToArchive(d);
												setArchiveModalOpen(true);
											}}
										>
											{showArchived ? (
												<MdOutlineUnarchive size={18} />
											) : (
												<MdOutlineArchive size={18} />
											)}
										</Button>
									</td>
									<td width="24">
										<Button
											componentType={ButtonTypes.type.TERTIARY}
											circle={true}
											onClick={async () => {
												setGlobalCategoryId(d.globalCategoryId);
												setDeleteModalOpen(true);
											}}
										>
											<Trash width={24} />
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<DeleteModal
						isOpen={deleteModalOpen}
						onCancel={() => setDeleteModalOpen(false)}
						onAction={async () => {
							await dispatch(deleteCategory(globalCategoryId));
							await dispatch(fetchCategories());
							setDeleteModalOpen(false);
						}}
						title="Delete category"
					>
						<p>Click Delete to permanently delete this category.</p>
					</DeleteModal>
					<ConfirmationModal
						title={showArchived ? 'Unarchive Category' : 'Archive Category'}
						isOpen={archiveModalOpen}
						pending={archivePending}
						confirmationText={showArchived ? 'Unarchive' : 'Archive'}
						onAction={(e: any) => {
							e.stopPropagation();
							handleArchive();
						}}
						onCancel={(e: any) => {
							e.stopPropagation();
							setArchivePending(false);
							setArchiveModalOpen(false);
						}}
					>
						{!showArchived ? (
							<p>Archiving removes the category from the active view. Do you want to archive?</p>
						) : (
							<p>Unarchiving restores the category to the active view. Do you want to unarchive?</p>
						)}
					</ConfirmationModal>
				</div>
			)}
		</div>
	);
}
