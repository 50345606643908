import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { checkVault } from './actions';

function Vault({ checkVault, match }) {
	const [dashboardId, setDashboardId] = useState();
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		const { vaultId } = match.params;
		checkVault(vaultId).then(dashboardId => {
			setDashboardId(dashboardId);
			setLoaded(true);
		});
	}, []);

	return dashboardId ? (
		<Redirect to={`/dashboard/${dashboardId}`} />
	) : loaded ? (
		<div className="vault">
			<div className="vault__forbidden">
				You may not have access to this link. Please contact customer support.
			</div>
		</div>
	) : null;
}

Vault.propTypes = {
	match: PropTypes.object,
	checkVault: PropTypes.func,
};

const dispatchProps = {
	checkVault,
};

export default connect(null, dispatchProps)(Vault);
