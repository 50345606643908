import { createRef, useLayoutEffect, useRef, useState } from 'react';

import Checkbox from '../../common/Checkbox';
import Radio from '../../common/RadioButton';
import DropdownArrow from '../../icons/DropdownArrow';
import cn from '../../lib/cn';
import { Attribute } from '../../types';

export function isSegmentChecked(segment: Attribute) {
	return segment.attributeValues.some(a => a.checked);
}

export function getSegmentCount(segment: Attribute) {
	return segment.attributeValues.filter(av => av.checked).length;
}

export function getSegmentSelection(segment: Attribute) {
	const selected = segment.attributeValues.find(av => av.checked);
	if (selected) {
		return selected.text;
	}
}

interface SegmentListProps {
	segments: Attribute[];
	onChange: (segmentName: string, clicked?: boolean) => void;
	useRadio?: Boolean;
}

export default function SegmentList({ segments, onChange, useRadio }: SegmentListProps) {
	let listHeight = window.screen.availHeight * 0.75 - 160;
	const maxRowCount = Math.floor(listHeight / 28);
	listHeight = maxRowCount * 28;

	const listRef = useRef<HTMLUListElement>(null);

	const [elRefs, setElRefs] = useState<React.RefObject<any>[]>([]);

	useLayoutEffect(() => {
		setElRefs(elRefs =>
			Array(segments.length)
				.fill(createRef())
				.map((_, i) => elRefs[i] || createRef())
		);
	}, [segments]);

	useLayoutEffect(() => {
		if (!elRefs || !elRefs.length) return;

		const checkedIndexes = segments.filter(segment => segment.checked).map(segment => segments.indexOf(segment));

		let needsToScroll = true;
		checkedIndexes.forEach(index => {
			if (!listRef.current) return;
			if (index * 28 < listRef.current?.clientHeight) {
				needsToScroll = false;
			}
		});

		if (checkedIndexes.length > 0) {
			if (needsToScroll) {
				elRefs[checkedIndexes[checkedIndexes.length - 1]].current?.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
					inline: 'center',
				});
			}
		}
	}, [segments, elRefs]);

	return (
		<div className="filter-dropdown__list segment-list">
			<ul style={{ maxHeight: listHeight }} ref={listRef}>
				{segments.map(
					(segment, i) =>
						!segment.removed && (
							<li
								key={segment.attributeName}
								onClick={() => {
									if (!segment.disabled) {
										onChange(segment.attributeName);
									}
								}}
								className={cn({
									'l-active': isSegmentChecked(segment) || segment.active,
									'l-disabled': segment.disabled,
								})}
							>
								{useRadio ? (
									<Radio
										disabled={segment.disabled}
										checked={segment.checked}
										onClick={() => onChange(segment.attributeName, true)}
									/>
								) : (
									<Checkbox
										checked={segment.checked}
										onClick={() => onChange(segment.attributeName, true)}
										testId={segment.attributeName}
										disabled={segment.disabled}
									/>
								)}

								<span ref={elRefs[i]}>{segment.attributeName}</span>
								{isSegmentChecked(segment) && (
									<span style={{ marginLeft: 4 }}>({getSegmentCount(segment)})</span>
								)}
								<span className="arrow">
									<DropdownArrow
										width={18}
										height={18}
										style={
											segment.active
												? {
														transform: 'rotate(-90deg)',
												  }
												: {}
										}
									/>
								</span>
							</li>
						)
				)}
			</ul>
		</div>
	);
}
