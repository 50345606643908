import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg width={8} height={10} viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M3.646 9.77a.5.5 0 0 0 .708 0L7.536 6.59a.5.5 0 1 0-.708-.708L4 8.71 1.172 5.88a.5.5 0 0 0-.708.708L3.646 9.77ZM3.5.418v9h1v-9h-1Z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgComponent;
