import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect } from 'react';

import LotteAnimation from '../../common/LottieAnimation';
import WithPermissions from '../../common/WithPermissions';
import RbacActions from '../../constants/RbacActions';
import Loader from '../../lottie/graph-loader.json';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchBillingInfo } from './actions';
import CheckoutForm from './CheckoutForm';
import PaymentMethod from './PaymentMethod';

const stripeKey =
	process.env.REACT_APP_ENV === 'prod'
		? 'pk_live_Jqt2xRHFyfCt1RE54RJjpgvB00iyC217gs'
		: 'pk_test_DNLkq1R4N9o8JsAX9HoX0Lj600kzLlHaCT';

const stripePromise = loadStripe(stripeKey);

function Billing() {
	const dispatch = useAppDispatch();
	const { pending, paymentMethod } = useAppSelector(state => state.admin.billing);

	useEffect(() => {
		dispatch(fetchBillingInfo());
	}, []);

	if (pending) {
		return (
			<div className="billing__pending">
				<LotteAnimation animation={Loader} autoplay={true} loop={true} width={60} height={60} />
			</div>
		);
	}

	return (
		<WithPermissions actions={[RbacActions['Billing/View']]} showMessage={true}>
			<div className="billing">
				{paymentMethod ? (
					<PaymentMethod />
				) : (
					<Elements stripe={stripePromise}>
						<CheckoutForm />
					</Elements>
				)}
			</div>
		</WithPermissions>
	);
}

export default Billing;
