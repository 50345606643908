import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} viewBox="0 0 21 21" fill="none" {...props}>
		<rect
			width={12}
			height={14}
			x={17.5}
			y={4.5}
			stroke="currentColor"
			strokeLinejoin="round"
			rx={0.5}
			transform="rotate(90 17.5 4.5)"
		/>
		<path stroke="currentColor" d="M11.5 5v11M6.5 5v11" />
	</svg>
);
export default SvgComponent;
