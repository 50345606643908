// TODO: remove?
const exploreMiddleware = () => (next: any) => (action: any) => {
	// const { pathname } = store.getState().router.location;
	//
	// if (pathname === '/explore' && action.type !== '@@router/LOCATION_CHANGE') {
	//   setTimeout(() => updateUrlIfNeeded(store), 0);
	// }

	return next(action);
};

export default exploreMiddleware;
