import Tabs from 'antd/lib/tabs';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import WithPermissions from '../common/WithPermissions';
import MixPanel from '../constants/MixPanel';
import RbacActions from '../constants/RbacActions';
import callIfAllowed from '../lib/callIfAllowed';
import hasPermission from '../lib/hasPermission';
import { page, track } from '../lib/segment';
import { IAdminTab } from '../roles/types';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import AdminRoutes from './AdminRoutes';
import { adminTabs } from './AdminTabs';
import { fetchGroups } from './groups/reducer';
import { fetchPermissions } from './permissions/reducer';
import { getRoles } from './roles/actions';
import { getActors } from './users/actions';

function Admin() {
	const history = useHistory();
	const { location } = useAppSelector(state => state.router);
	const { staticPermissions } = useAppSelector(state => state.auth);
	const { allGroups } = useAppSelector(state => state.admin.groups);

	const dispatch = useAppDispatch();

	useEffect(() => {
		callIfAllowed({
			actions: [RbacActions['Roles/View']],
			cb: () => dispatch(getRoles()),
			staticPermissions,
		});
		callIfAllowed({
			actions: [RbacActions['Users/View']],
			cb: () => dispatch(getActors()),
			staticPermissions,
		});
		callIfAllowed({
			actions: [RbacActions['Roles/View']],
			cb: () => dispatch(fetchGroups()),
			staticPermissions,
		});
		callIfAllowed({
			actions: [RbacActions['Policies/View']],
			cb: () => dispatch(fetchPermissions()),
			staticPermissions,
		});
	}, []);

	useEffect(() => {
		page(MixPanel.Pages.Admin);
		track(MixPanel.Events.ViewUsers);
	}, []);

	const filteredTabs = adminTabs.filter(tab => {
		if (tab.key === 'groups') {
			if (!allGroups || allGroups.length === 0) {
				return false;
			}
		}

		return hasPermission(staticPermissions, tab.actions);
	});

	return (
		<WithPermissions
			showMessage={true}
			actions={[
				RbacActions['Roles/View'],
				RbacActions['Users/View'],
				RbacActions['Integrations/View'],
				RbacActions['Users/Edit'],
				RbacActions['Billing/View'],
				RbacActions['Settings/View'],
			]}
		>
			<div className="admin-container">
				<Tabs
					defaultActiveKey={location.pathname.split('/')[2] || 'users'}
					onChange={(activeKey: string) => {
						const newActiveTab = adminTabs.find(tab => tab.key === activeKey);
						history.replace(`/admin/${newActiveTab?.key}`);
					}}
				>
					{filteredTabs.map((tab: IAdminTab) => (
						<Tabs.TabPane tab={tab.name} key={tab.key}>
							<AdminRoutes area="content" />
						</Tabs.TabPane>
					))}
				</Tabs>
			</div>
		</WithPermissions>
	);
}

export default Admin;
