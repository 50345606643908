import { SVGProps } from 'react';
import cn from '../lib/cn';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={35} height={36} viewBox="0 0 35 36" fill="none" {...props}>
		<rect width={35} height={35} y={0.5} fill="currentColor" className={cn('text-graph-1')} rx={17.5} />
		<path
			className={cn('text-ui-100-inverted')}
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M22.5 24.666v-8.333M17.5 24.666V11.333M12.5 24.667v-5"
		/>
	</svg>
);
export default SvgComponent;
