import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import Urls from '../../../constants/Urls';
import { AsyncThunkConfig } from '../../../store/store';
import { Question, QuestionState } from './types';

export const fetchQuestions = createAsyncThunk<QuestionState, void, AsyncThunkConfig>(
	'survey/fetchQuestions',
	async () => {
		let pageToken;
		let allData: Question[] = [];
		do {
			const response: any = await axios.post(`${Urls.SurveyStudioApi}questions`, {
				pageToken,
			});
			if (response.data.questions?.length) {
				allData = [...allData, ...response.data.questions];
			}
			pageToken = response.data.pageToken;
		} while (pageToken);

		const data: Question[] = [];
		const archived: Question[] = [];
		allData.forEach(d => {
			const uiElem = d.meta.uiElements.find(u => u.key === 'archive');
			if (uiElem?.value === 'true') {
				archived.push(d);
			} else {
				data.push(d);
			}
		});

		return { data, archived };
	}
);

export const questionsSlice = createSlice({
	name: 'questions',
	initialState: { data: [], archived: [] } as QuestionState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchQuestions.fulfilled, (state, action) => {
			const { data, archived } = action.payload;
			state.data = data;
			state.archived = archived;
			state.pending = false;
			state.loaded = true;
		});
		builder.addCase(fetchQuestions.pending, state => {
			state.pending = true;
			state.error = false;
		});
		builder.addCase(fetchQuestions.rejected, state => {
			state.error = true;
			state.pending = false;
		});
	},
});

export default questionsSlice.reducer;
