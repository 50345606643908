import moment from 'moment';
import { useState } from 'react';
import { useHistory } from 'react-router';

import DatePicker from '../../common/DatePicker';
import Dropdown from '../../common/Dropdown';
import analysisTypes from '../../constants/AnalysisTypes';
import ButtonTypes from '../../constants/ButtonTypes';
import intervalOptions from '../../constants/IntervalOptions';
import MixPanel from '../../constants/MixPanel';
import cn from '../../lib/cn';
import getDatePickerLabel from '../../lib/getDatePickerLabel';
import { track } from '../../lib/segment';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import DateRangeSelection from './DateRangeSelection';
import { selectDate, selectInterval, selectIntervalCount, selectIntervalRange } from './actions';

function IntervalSection({ isSegment }: { isSegment?: boolean }) {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const { showSubtable, interval, intervalCount, startDate, endDate, date } = useAppSelector(
		state => state.explore.filter
	);
	const chart = useAppSelector(state => state.explore.chart);
	const { latestDataUpdate } = useAppSelector(state => state.account);
	const subtable = useAppSelector(state => state.explore.subtable);

	const { analysisType, dates } = chart;
	const generated = showSubtable ? subtable.generated : chart.generated;
	const generatePending = showSubtable ? subtable.generatePending : chart.generatePending;
	const generateNoData = showSubtable ? subtable.empty : chart.generateNoData;
	const generateInvalidVariation = showSubtable ? subtable.invalidVariation : chart.generateInvalidVariation;

	const [arithmeticDropdownOpen, setArithmeticDropdownOpen] = useState(false);

	if (!generated && !generatePending && !generateNoData) {
		return null;
	}

	if (generateInvalidVariation) {
		return null;
	}

	function handleIntervalChange(interval: string) {
		track(MixPanel.Events.ExploreSelectInterval, {
			Interval: interval,
		});
		dispatch(selectInterval(interval, history));
	}

	function handleIntervalCountChange(intervalCount: number) {
		track(MixPanel.Events.ExploreSelectIntervalCount, {
			'Interval Count': intervalCount,
		});

		dispatch(selectIntervalCount(intervalCount, history));
	}

	function handleIntervalRangeChange(startDate: string, endDate: string) {
		track(MixPanel.Events.ExploreSelectIntervalRange, {
			'Start Date': startDate,
			'End Date': endDate,
		});
		dispatch(selectIntervalRange(startDate, endDate, history));
	}

	const handleDateSelect = (d: any) => {
		selectInterval('Month');
		const formattedDate = moment(d).format('YYYY-MM-DD');
		dispatch(selectDate(formattedDate));
		setArithmeticDropdownOpen(false);
	};

	const selectedDate = date ?? moment(latestDataUpdate).format('YYYY-MM-DD');

	return (
		<div className="explore-interval-section">
			{analysisType === analysisTypes.Arithmetic ? (
				<div className={cn('w-full')}>
					<DatePicker
						placeholder={'Month'}
						disabled={false}
						isOpen={arithmeticDropdownOpen}
						label={selectedDate ? moment(selectedDate).format('MMMM yyyy') : 'Month'}
						onClick={() => {
							setArithmeticDropdownOpen(!arithmeticDropdownOpen);
						}}
						selectsRange={false}
						onDateSelect={handleDateSelect}
						formatType="Month"
						selectedDate={selectedDate}
					/>
				</div>
			) : showSubtable || isSegment ? (
				<div className={cn('flex flex-col w-full')}>
					<Dropdown
						selectedStyle={ButtonTypes.selectedStyle.UNDERLINE}
						options={intervalOptions}
						selectedOption={interval}
						onClick={handleIntervalChange}
						marginLeft={-16}
						buttonType={ButtonTypes.type.SECONDARY}
					/>
					<DatePicker
						placeholder={interval}
						disabled={false}
						isOpen={arithmeticDropdownOpen}
						label={getDatePickerLabel(selectedDate, interval as any)}
						onClick={() => {
							setArithmeticDropdownOpen(!arithmeticDropdownOpen);
						}}
						selectsRange={false}
						onDateSelect={handleDateSelect}
						formatType={interval as any}
					/>
				</div>
			) : (
				<DateRangeSelection
					interval={interval}
					selectInterval={handleIntervalChange}
					intervalCount={intervalCount}
					selectIntervalCount={handleIntervalCountChange}
					startDate={startDate}
					endDate={endDate}
					selectIntervalRange={handleIntervalRangeChange}
					dates={dates}
					isLarge={true}
					fromEditor
				/>
			)}
		</div>
	);
}

export default IntervalSection;
