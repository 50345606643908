import classNames from 'classnames';
import { Fragment } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import Masking from '../../common/Masking';
import SortableHead from '../../common/SortableHead';
import Stack from '../../common/Stack';
import Eye from '../../icons/Eye';
import getFormattedValue from '../../lib/getFormattedValue';
import { units } from '../../types';

interface ValueTableProps {
	lifecycle: string;
	analysisType: string;
	employeeAttribute: string;
	employeeAttributes: any[];
	diversityAttributes: any[];
	data: any[];
	summary: any;
	pending: boolean;
	onSort: any;
	sortCriteria: any;
	navigateToDetail: any;
	showGroupSummary: boolean;
	expanded: boolean;
	toggleExpansion: any;
	groupSummary: any;
	attributeName: string;
	headerRef: any;
	headerHeight: number;
	disableLinks: boolean;
	tableActive: boolean;
	columnProps: {
		source: string;
		header: string;
		unit: units;
	}[];
	hoveredItem?: string;
	selection: string[];
	status: string;
}

export default function ValueTable(props: ValueTableProps) {
	const {
		lifecycle,
		analysisType,
		attributeName,
		diversityAttributes,
		data,
		summary,
		groupSummary,
		onSort,
		sortCriteria,
		navigateToDetail,
		showGroupSummary,
		expanded,
		toggleExpansion,
		headerRef,
		headerHeight,
		disableLinks,
		tableActive,
		columnProps,
		hoveredItem,
		selection,
	} = props;

	if (!columnProps) return null;

	const expandable = columnProps.length > 2;

	function getColspan() {
		if (expandable) {
			return expanded ? columnProps.length : 1;
		} else {
			return columnProps.length;
		}
	}

	function handleNavigate(
		lifecycle: string,
		employeeAttributeValue: string,
		diversityAttributeValue: string,
		employees: any,
		employeeCountType: string,
		e: React.SyntheticEvent<EventTarget>
	): void {
		e.preventDefault();
		if (disableLinks) return;

		navigateToDetail(
			lifecycle,
			attributeName,
			employeeAttributeValue,
			diversityAttributeValue,
			employees,
			employeeCountType
		);
	}

	function renderHeader(attr: string) {
		let label = attr.toLowerCase();
		label = label.replace(/ /g, '-');
		return !expandable || expanded ? (
			columnProps.map(columnProp => (
				<SortableHead
					key={label + '-' + columnProp.source}
					label={columnProp.header}
					columnName={label + '-' + columnProp.source}
					sortCriteria={sortCriteria}
					onSort={onSort}
					className="attr-table__divider"
				/>
			))
		) : (
			<SortableHead
				key={label + '-' + columnProps[0].source}
				label={columnProps[0].header}
				columnName={label + '-' + columnProps[0].source}
				sortCriteria={sortCriteria}
				onSort={onSort}
				className="attr-table__divider"
			/>
		);
	}

	function renderData(dataItem: any, attr: string, navProps: any, odd: boolean) {
		let label = attr.toLowerCase();
		label = label.replace(/ /g, '-');

		const className = classNames('attr-table__divider', {
			clickable: !disableLinks,
			'attr-table__odd': odd,
		});
		const empClassName = classNames('attr-table__divider', {
			'attr-table__odd': odd,
		});

		let empCount = 0;
		columnProps.slice(1).forEach(columnProp => {
			empCount += dataItem[label + '-' + columnProp.source];
		});

		return (
			<Fragment key={attr}>
				<td className={className} onClick={e => handleNavigate(lifecycle, dataItem.id, navProps, false, '', e)}>
					{empCount ? getFormattedValue(analysisType, dataItem[label + '-' + columnProps[0].source]) : '--'}
				</td>
				{(!expandable || expanded) &&
					columnProps.slice(1).map(columnProp => (
						<td key={columnProp.header} className={empClassName}>
							<Masking
								value={dataItem[label + '-' + columnProp.source]}
								unit={columnProp.unit}
								hiddenDisplay={
									<Stack
										style={{
											color: 'var(--color-ui-20)',
										}}
									>
										<Eye />
									</Stack>
								}
							/>
						</td>
					))}
			</Fragment>
		);
	}

	function renderSummary(attr: string, odd: boolean, summary: any) {
		let label = attr.toLowerCase();
		label = label.replace(/ /g, '-');
		const className = odd ? 'attr-table__divider attr-table__odd' : 'attr-table__divider';

		return (
			<Fragment key={attr}>
				<td className={className}>{getFormattedValue(analysisType, summary[label][columnProps[0].source])}</td>
				{(!expandable || expanded) &&
					columnProps.slice(1).map(columnProp => (
						<td className={className}>
							<Masking
								value={summary[label][columnProp.source]}
								unit={columnProp.unit}
								hiddenDisplay={
									<Stack
										style={{
											color: 'var(--color-ui-20)',
										}}
									>
										<Eye />
									</Stack>
								}
							/>
						</td>
					))}
			</Fragment>
		);
	}

	return (
		<table className="attr-table__table">
			<thead>
				<tr ref={headerRef} style={{ height: headerHeight }}>
					{diversityAttributes?.map(attr => (
						<th key={attr.label} colSpan={getColspan()} className="attr-table__divider">
							<div className="attr-table__flexhead">
								<span
									className="attr-table__flexhead__text"
									dangerouslySetInnerHTML={{
										__html: attr.segment.html,
									}}
								/>
								{expandable && (
									<span className="attr-table__flexhead__expand">
										{expanded ? (
											<MdChevronLeft size={18} onClick={toggleExpansion} />
										) : (
											<MdChevronRight size={18} onClick={toggleExpansion} />
										)}
									</span>
								)}
							</div>
						</th>
					))}
					<th />
				</tr>
				<tr>
					{diversityAttributes?.map(attr => renderHeader(attr.label))}
					<th />
				</tr>
			</thead>
			<tbody>
				{data.map(d => {
					const isActive = d.label === hoveredItem;
					const isSelected = selection.includes(d.label);
					return (
						<tr
							key={d.id}
							className={isActive || isSelected ? 'attr-table__selected' : ''}
							style={{
								opacity: tableActive ? (isActive || isSelected ? 1 : 0.24) : 1,
							}}
						>
							{diversityAttributes?.map((attr, index) =>
								renderData(
									d,
									attr.label,
									attr.navProps,
									diversityAttributes?.length > 1 && index % 2 === 0
								)
							)}
							<td />
						</tr>
					);
				})}
				{showGroupSummary && groupSummary && Object.keys(groupSummary).length > 0 && (
					<tr className="attr-table__bold">
						{diversityAttributes?.map((attr, index) =>
							renderSummary(attr.label, diversityAttributes?.length > 1 && index % 2 === 0, groupSummary)
						)}
						<td />
					</tr>
				)}
				{summary && (
					<tr className="attr-table__bold">
						{diversityAttributes?.map((attr, index) =>
							renderSummary(attr.label, diversityAttributes?.length > 1 && index % 2 === 0, summary)
						)}
						<td />
					</tr>
				)}
			</tbody>
		</table>
	);
}
