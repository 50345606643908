import qs from 'qs';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import getUiValue from '../common/getUiValue';
import {
	clearData,
	setActiveTab,
	setDescription,
	setEmailBody,
	setEmailSubject,
	setEmailTitle,
	setEnterpriseId,
	setImageId,
	setImageUrl,
	setInitialised,
	setQuestionOrder,
	setQuestions,
	setThankDesc,
	setThankImageUrl,
	setThankTitle,
	setTitle,
	setWelcomeDesc,
	setWelcomeTitle,
} from './editReducer';
import Questionnaire from './Questionnaire';
import { fetchSurveys } from './reducer';
import SurveyDetails from './SurveyDetails';

export default function TemplateEdit() {
	const dispatch = useAppDispatch();
	const initialised = useAppSelector(state => state.datastudio.survey.surveyEdit.initialised);
	const activeTab = useAppSelector(state => state.datastudio.survey.surveyEdit.activeTab);

	const { data, loaded } = useAppSelector(state => state.datastudio.survey.surveys);

	const { globalSurveyId } = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	});

	useEffect(() => {
		async function setSurvey() {
			const survey = data.find(d => d.globalSurveyId === globalSurveyId);
			if (survey) {
				dispatch(setActiveTab('surveydetails'));
				dispatch(setTitle(survey.title));
				dispatch(setDescription(survey.description));
				const imageUrl = getUiValue(survey.meta, 'imageUrl');
				if (imageUrl) {
					dispatch(setImageUrl(imageUrl));
				}
				dispatch(setEnterpriseId(survey.enterpriseId));
				dispatch(setEmailSubject(getUiValue(survey.meta, 'emailSubject')));
				dispatch(setEmailTitle(getUiValue(survey.meta, 'emailTitle')));
				dispatch(setEmailBody(getUiValue(survey.meta, 'emailBody')));
				dispatch(setWelcomeTitle(getUiValue(survey.meta, 'welcomeTitle')));
				dispatch(setWelcomeDesc(getUiValue(survey.meta, 'welcomeDesc')));
				dispatch(setThankTitle(getUiValue(survey.meta, 'thankTitle')));
				dispatch(setThankDesc(getUiValue(survey.meta, 'thankDesc')));
				const thankImageUrl = getUiValue(survey.meta, 'thankImageUrl');
				if (thankImageUrl) {
					dispatch(setThankImageUrl(thankImageUrl));
				}
				dispatch(setImageId(getUiValue(survey.meta, 'imageId')));

				const questions = survey.categories.map(c => c.questions).flat();
				dispatch(setQuestions(questions));

				const questionOrderStr = getUiValue(survey.meta, 'questionOrder');
				let questionOrder: string[] = questionOrderStr ? JSON.parse(questionOrderStr) : [];
				if (questionOrder.length === 0) {
					questionOrder = questions.map(q => q.globalQuestionId);
				}
				dispatch(setQuestionOrder(questionOrder));
			}
		}

		if (!loaded) {
			dispatch(fetchSurveys());
			return;
		}

		if (!initialised) {
			if (globalSurveyId) {
				setSurvey();
			} else {
				dispatch(clearData());
			}
		}

		dispatch(setInitialised(true));
	}, [data, loaded, globalSurveyId]);

	return (
		<div className="survey-content survey-edit">
			{activeTab === 'surveydetails' ? <SurveyDetails /> : <Questionnaire />}
		</div>
	);
}
