import { ReactNode, useState } from 'react';

import ListItem from '../common/ListItem';

interface ListItemStateWrapperProps {
	children: ReactNode;
	initialState: boolean;
	type: string;
}

const ListItemStateWrapper = ({ children, initialState, type }: ListItemStateWrapperProps) => {
	const [activated, setActivated] = useState(initialState);

	const onClick = () => {
		setActivated(!activated);
	};

	return (
		<ListItem activated={activated} onClick={onClick} type={type}>
			{children}
		</ListItem>
	);
};

export default ListItemStateWrapper;
