import PropTypes from 'prop-types';
import qs from 'qs';
import { Component } from 'react';
import { withRouter } from 'react-router';

import Button from '../common/Button';
import FormError from '../common/FormError';
import TextField from '../common/TextField';

const PasswordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');

class CreatePasswordForm extends Component {
	constructor(props) {
		super();
		const { email } = qs.parse(props.location.search, {
			ignoreQueryPrefix: true,
		});
		this.state = {
			email,
			tempPassword: '',
			newPassword: '',
			confirmPassword: '',
		};
	}

	handleNext(e) {
		e.preventDefault();
		const { email, tempPassword, newPassword, confirmPassword } = this.state;
		const passwordError = PasswordRegex.test(newPassword) ? '' : 'Password should conform to the password policy';
		const confirmError = newPassword !== confirmPassword ? 'Passwords do not match' : '';
		this.setState({
			passwordError,
			confirmError,
		});
		if (!passwordError && !confirmError) {
			this.props.onNext(email, tempPassword, newPassword);
		}
	}

	handleTempPasswordChange(e) {
		const tempPassword = e.target.value;
		this.setState({ tempPassword });
	}

	handleNewPasswordChange(e) {
		const newPassword = e.target.value;
		this.setState({ newPassword });
	}

	handleConfirmPasswordChange(e) {
		const confirmPassword = e.target.value;
		this.setState({ confirmPassword });
	}

	render() {
		const { tempPassword, newPassword, confirmPassword, passwordError, confirmError } = this.state;
		const disabled = !tempPassword || !newPassword || !confirmPassword;

		return (
			<form className="password-form" onSubmit={e => e.preventDefault()}>
				<div className="password-form__title">Welcome to Dandi.</div>
				<div className="password-form__row mb16">
					<TextField readOnly={true} value={this.state.email}></TextField>
				</div>
				<div className="password-form__row mb16">
					<TextField
						type="password"
						placeholder="Temporary Password"
						autoFocus={true}
						value={tempPassword}
						onChange={this.handleTempPasswordChange.bind(this)}
					></TextField>
				</div>
				<div className="password-form__row">
					<TextField
						type="password"
						danger={passwordError}
						placeholder="Create New Password"
						value={newPassword}
						onChange={this.handleNewPasswordChange.bind(this)}
					></TextField>
					<FormError error={passwordError} />
				</div>
				<div className="password-form__row">
					<TextField
						type="password"
						danger={confirmError}
						placeholder="Confirm New Password"
						value={confirmPassword}
						onChange={this.handleConfirmPasswordChange.bind(this)}
					></TextField>
					<FormError error={confirmError} />
				</div>
				<div className="password-form__row--create">
					<div className="password-form__prompt">
						* Passwords need to consist of <br />
						one number and one capital letter.
					</div>
					<div className="button-col-right">
						<Button disabled={disabled} onClick={this.handleNext.bind(this)}>
							Next
						</Button>
					</div>
				</div>
			</form>
		);
	}
}

CreatePasswordForm.propTypes = {
	location: PropTypes.object,
	onNext: PropTypes.func,
};

export default withRouter(CreatePasswordForm);
