function SvgComponent(props: any) {
	return (
		<svg
			width={21}
			height={21}
			viewBox="0 0 21 21"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M3.5 10.4668H7.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M3.5 5.4668H7.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M3.5 15.4668H7.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M10.5 10.4668L18.5 10.4668" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M10.5 5.4668L18.5 5.4668" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M10.5 15.4668L18.5 15.4668" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
}

export default SvgComponent;
