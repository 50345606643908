import { createAction, createSlice } from '@reduxjs/toolkit';

import ActionTypes from '../../constants/ActionTypes';
import {
	deleteChatConversation,
	getAllChatConversationMetadata,
	getChatConversationMetadata,
	getChatConversationsList,
	removeChatConversationMetadata,
	setChatConversationMetadata,
} from './actions';

export interface Conversation {
	[key: string]: {
		[key: string]: any;
	};
}

interface ConversationsState {
	pending?: boolean;
	error?: string;
	loaded: boolean;
	conversations: Conversation;
	conversationsIds: string[];
	metadata?: {
		[key: string]: any;
	};
}

const initialState: ConversationsState = {
	loaded: false,
	conversations: {},
	conversationsIds: [],
};

const resetConversations = createAction(ActionTypes.SetEnterpriseIdComplete);

const conversationsSlice = createSlice({
	name: 'ai/conversations',
	initialState: initialState,
	reducers: {
		addConversationId(state, action) {
			state.conversationsIds.push(action.payload);
		},
		addConversationMetadata(state, action) {
			state.conversations[action.payload.conversationId] = action.payload.metadata;
		},
		deleteConversation(state, action) {
			state.conversationsIds = state.conversationsIds.filter(conversation => conversation != action.payload);
			delete state.conversations[action.payload];
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getChatConversationsList.pending, state => {
				state.pending = true;
			})
			.addCase(getChatConversationsList.fulfilled, (state, action) => {
				state.pending = false;
				state.conversationsIds = action.payload;
				state.loaded = true;
			})
			.addCase(deleteChatConversation.pending, state => {
				state.pending = true;
			})
			.addCase(deleteChatConversation.fulfilled, state => {
				state.pending = false;
			})
			.addCase(setChatConversationMetadata.pending, state => {
				state.pending = true;
			})
			.addCase(setChatConversationMetadata.fulfilled, state => {
				state.pending = false;
			})
			.addCase(getAllChatConversationMetadata.pending, state => {
				state.pending = true;
			})
			.addCase(getAllChatConversationMetadata.fulfilled, (state, action) => {
				state.pending = false;
				state.conversations = action.payload;
				state.loaded = true;
			})
			.addCase(getChatConversationMetadata.pending, state => {
				state.pending = true;
			})
			.addCase(getChatConversationMetadata.fulfilled, (state, action) => {
				state.pending = false;
				state.metadata = action.payload;
			})
			.addCase(removeChatConversationMetadata.pending, state => {
				state.pending = true;
			})
			.addCase(removeChatConversationMetadata.fulfilled, (state, action) => {
				state.pending = false;
				state.metadata = action.payload;
			})
			.addCase(resetConversations, state => {
				state.loaded = false;
				state.conversations = {};
				state.conversationsIds = [];
			});
	},
});

export const { addConversationId, addConversationMetadata, deleteConversation } = conversationsSlice.actions;
export default conversationsSlice.reducer;
