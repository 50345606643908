import produce from 'immer';
import { orderBy } from 'lodash';
import { useMemo, useState } from 'react';

import analysisTypes from '../constants/AnalysisTypes';
import { setSortCriteriaInReport } from '../dashboard/view/actions';
import cn from '../lib/cn';
import getChartData from '../lib/getChartData';
import getPeriod from '../lib/getPeriod';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import ResponsiveContainer from './ResponsizeContainer';
import ScrollableChartArea from './ScrollableChartArea';
import Table from './Table';

const TableWrapper = ({
	reportId,
	onHover,
	onHoverEnd,
	onToggle,
	hoveredItem,
	selection,
}: {
	reportId?: string;
	onHover?: any;
	onHoverEnd?: any;
	onToggle?: any;
	hoveredItem?: any;
	selection?: any;
}) => {
	const dispatch = useAppDispatch();
	const {
		data,
		dates,
		generatePending,
		lifecycle,
		currentIndex,
		interval,
		columnProps,
		analysisType,
		sortCriteria: sortCriteriaStored,
	} = useAppSelector(state => getChartData(state, reportId));

	const [expanded, setExpanded] = useState(false);
	const [sortCriteria, setSortCriteria] = useState(sortCriteriaStored || { columnName: '', ascending: true });

	const tableData = useMemo(() => {
		if (interval && data?.length > 0) {
			// @ts-ignore
			let tableData: any[] = produce(data, (draftState: any) => {
				draftState.forEach((dataItem: any) => {
					// fix for a crash when switching from matrix report to table report
					if (!dataItem.series) return;
					dataItem.series.forEach((seriesItem: any) => {
						const label = getPeriod(seriesItem.date, interval?.toLowerCase());
						const prefix = label?.toLowerCase().replace(/ /g, '-');
						columnProps.forEach((columnProp: any) => {
							dataItem[prefix + '-' + columnProp.source] = seriesItem[columnProp.source];
						});
					});
				});
			});

			let removedItems;
			if (analysisType === analysisTypes.Index || analysisType === analysisTypes.LinearRegression) {
				const index = tableData.findIndex(d => d.isEqualityIndex || d.isAttritionIndex);
				if (index !== -1) {
					tableData = tableData.slice();
					removedItems = tableData.splice(index, 1);
				}
			}
			tableData = orderBy(tableData, [sortCriteria.columnName], [sortCriteria.ascending ? 'asc' : 'desc']);
			if (removedItems) {
				tableData.unshift(...removedItems);
			}

			return tableData;
		}

		return [];
	}, [data, interval, columnProps, analysisType, sortCriteria]);

	function handleSort(columnName: string) {
		let ascending = true;
		if (columnName === sortCriteria.columnName) {
			ascending = !sortCriteria.ascending;
		}

		if (reportId) {
			dispatch(
				setSortCriteriaInReport(reportId, {
					columnName,
					ascending,
				})
			);
		} else {
			setSortCriteria({
				columnName,
				ascending,
			});
		}
	}

	return (
		<div className={cn('flex-1 flex flex-col h-full justify-between')}>
			<ScrollableChartArea>
				<div className={cn('flex-1 relative')}>
					<div className={cn('relative w-full h-auto', reportId && '!h-full')}>
						<ResponsiveContainer className="explore-chart__main items-start justify-start relative">
							{({ width, height }: { width: number; height: number }) => (
								<Table
									dashboardName="dashboardName"
									title="Title"
									reportId={reportId || 'explore-chart'}
									width={width}
									height={height}
									dates={dates}
									interval={interval}
									lifecycle={lifecycle}
									analysisType={analysisType}
									currentIndex={currentIndex}
									pending={generatePending}
									tableRowHeight={44}
									options={{}}
									useMaxHeightOnTable={false}
									navigate={() => {}}
									expanded={expanded}
									toggleExpansion={() => setExpanded(!expanded)}
									sortCriteria={sortCriteria}
									onSort={handleSort}
									columnProps={columnProps}
									data={tableData}
									onHover={onHover}
									onHoverEnd={onHoverEnd}
									onToggle={onToggle}
									hoveredItem={hoveredItem}
									selection={selection}
								/>
							)}
						</ResponsiveContainer>
					</div>
				</div>
			</ScrollableChartArea>
		</div>
	);
};

export default TableWrapper;
