import { selectCurrentTime as selectCurrentTimeInReport } from '../dashboard/view/actions';
import { selectCurrentTime } from '../explore/actions';
import cn from '../lib/cn';
import getChartData from '../lib/getChartData';
import getDynamicDataProp from '../lib/getDynamicDataProp';
import hasHiddenEmployeeCount from '../lib/hasHiddenEmployeeCount';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import Events from './Events';
import Legend from './Legend';
import LineChartOverlay from './LineChartOverlay';
import ResponsiveContainer from './ResponsizeContainer';
import ScrollableChartArea from './ScrollableChartArea';
import XAxis from './XAxis';

const LineChartOverlayWrapper = ({
	hoveredItem,
	onHover,
	onHoverEnd,
	onToggle,
	reportId,
	selection,
	status,
}: {
	hoveredItem?: string;
	onHover?: any;
	onHoverEnd?: any;
	onToggle?: any;
	reportId?: string;
	selection: string[];
	status?: any;
}) => {
	const dispatch = useAppDispatch();
	const {
		dynamicPermissions,
		userInfo: { lightMode },
	} = useAppSelector(state => state.auth);

	const {
		analysisName,
		analysisType,
		columnProps,
		currentIndex,
		data,
		dates,
		eventIds,
		events,
		indexType,
		interval,
		lifecycle,
		metricOverlay,
		metricOverlayLifecycle,
		overlayAnalysisType,
		overlayIndexType,
		overlayYAxisType,
		yAxisType,
	} = useAppSelector(state => getChartData(state, reportId));

	const overlayColumnProps = metricOverlay?.columnProps ?? [];
	const overlayData = metricOverlay?.data ?? [];
	const overlayAnalysisName = metricOverlay?.analysisName;

	const dataProp = getDynamicDataProp(yAxisType, indexType, columnProps);
	const dataOverlayProp = getDynamicDataProp(overlayYAxisType, overlayIndexType, overlayColumnProps);
	const employeeCountHidden = hasHiddenEmployeeCount(dynamicPermissions);

	function handleSelectCurrentTime(currentIndex: number) {
		if (reportId) {
			dispatch(selectCurrentTimeInReport(reportId, currentIndex));
		} else {
			dispatch(selectCurrentTime(currentIndex));
		}
	}

	return (
		<div className={cn('flex-1 flex flex-col h-full justify-between')}>
			<Legend
				data={data?.map((d: any) => ({
					...d,
					analysisName: analysisName,
				}))}
				hoveredItem={hoveredItem}
				onHover={onHover}
				onHoverEnd={onHoverEnd}
				onToggle={onToggle}
				overlayData={overlayData?.map((d: any) => ({
					...d,
					analysisName: overlayAnalysisName,
				}))}
				selection={selection}
				status={status}
			/>
			<ScrollableChartArea>
				<div className={cn('flex-1 relative')}>
					<div className={cn('relative w-full h-[250px] wide:h-[360px]')}>
						<ResponsiveContainer className="explore-chart__main">
							{({ width, height }: { width: number; height: number }) => (
								<LineChartOverlay
									analysisType={analysisType}
									columnProps={columnProps}
									currentIndex={currentIndex}
									data={data}
									dataOverlayProp={dataOverlayProp}
									dataProp={dataProp}
									dates={dates}
									employeeCountHidden={employeeCountHidden}
									height={height}
									hoveredItem={hoveredItem}
									indexType={indexType}
									interval={interval}
									lifecycle={lifecycle}
									lightMode={lightMode}
									metricOverlayLifecycle={metricOverlayLifecycle}
									onHover={onHover}
									onHoverEnd={onHoverEnd}
									onToggle={onToggle}
									overlayAnalysisType={overlayAnalysisType}
									overlayColumnProps={overlayColumnProps}
									overlayData={overlayData}
									overlayIndexType={overlayIndexType}
									overlayYAxisType={overlayYAxisType}
									reportId={reportId || 'explore-chart'}
									selectCurrentTime={handleSelectCurrentTime}
									selection={selection}
									width={width}
									yAxisType={yAxisType}
								/>
							)}
						</ResponsiveContainer>
					</div>
				</div>
				{eventIds?.length > 0 && events?.length > 0 && dates?.length > 1 && (
					<Events
						events={events.filter((event: any) => eventIds.includes(event.eventId))}
						dates={dates}
						interval={interval}
						analysisType={analysisType}
					/>
				)}
			</ScrollableChartArea>
			<XAxis reportId={reportId} />
		</div>
	);
};

export default LineChartOverlayWrapper;
