import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={28} height={16} viewBox="0 0 28 16" fill="none" {...props}>
		<path
			fill="currentColor"
			d="M7.93 16c-2.03 0-3.8-.663-5.31-1.99C1.11 12.685.237 11.015 0 9h1.012c.271 1.72 1.055 3.15 2.35 4.29C4.659 14.43 6.181 15 7.931 15c1.95 0 3.604-.68 4.962-2.037C14.252 11.604 14.931 9.95 14.931 8c0-1.95-.68-3.604-2.038-4.963C11.535 1.68 9.881 1 7.931 1a6.652 6.652 0 0 0-2.918.656 7.782 7.782 0 0 0-2.41 1.806h2.482v1H.93V.308h1v2.388A8.309 8.309 0 0 1 4.649.712 7.812 7.812 0 0 1 7.931 0c1.109 0 2.148.209 3.118.626.97.417 1.817.988 2.542 1.713a8.066 8.066 0 0 1 1.714 2.543c.417.97.626 2.009.626 3.118a7.805 7.805 0 0 1-.626 3.118 8.066 8.066 0 0 1-1.714 2.543 8.066 8.066 0 0 1-2.542 1.713c-.97.417-2.01.626-3.118.626Zm3.205-4.146L7.488 8.208V3h1v4.792l3.354 3.354-.707.708Z"
		/>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M18.957 9c.03-.33.044-.663.044-1H28v1h-9.043Zm-.705-5a10.928 10.928 0 0 0-.45-1H28v1h-9.748Zm-1.03 10c.21-.322.404-.656.58-1H28v1H17.223Z"
			clipRule="evenodd"
		/>
	</svg>
);

export default SvgComponent;
