import Modal from '../../../common/Modal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { closeModal } from '../../actions';

const AddTracker = () => {
	const dispatch = useAppDispatch();
	const addTrackerModalOpen = useAppSelector(state => state.measure.settings.addTrackerModalOpen);
	const handleSave = () => {
		console.warn('Saved');
		handleClose();
	};

	const handleClose = () => {
		dispatch(closeModal('tracker'));
	};

	return (
		<Modal
			visible={addTrackerModalOpen}
			width={700}
			footer={null}
			closable={false}
			maskClosable
			destroyOnClose
			onCancel={handleClose}
		>
			<div className="measure-modal">
				<form onSubmit={handleSave}>
					<div className="modal__header">
						<div className="modal__title">Add Tracker</div>
					</div>
					<div className="modal__content"></div>
				</form>
			</div>
		</Modal>
	);
};

export default AddTracker;
