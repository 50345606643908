import Menu from 'antd/lib/menu';
import classNames from 'classnames';
import qs from 'qs';
import { Fragment, useEffect, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';

import Button from '../../common/Button';
import ChartContainer from '../../common/ChartContainer';
import HorizontalBarChart from '../../common/HorizontalBarChart';
import Legend from '../../common/Legend';
import Popover from '../../common/Popover';
import ScrollableChartArea from '../../common/ScrollableChartArea';
import Stack from '../../common/Stack';
import WithPermissions from '../../common/WithPermissions';
import useInteractiveChart from '../../common/useInteractiveChart';
import ButtonTypes from '../../constants/ButtonTypes';
import ChartTypes from '../../constants/ChartTypes';
import MixPanel from '../../constants/MixPanel';
import RbacActions from '../../constants/RbacActions';
import Download from '../../icons/Download';
import MenuIcon from '../../icons/Menu';
import HorizontalBarIcon from '../../icons/StackedHorizontalBar';
import { getFormattedDate } from '../../lib/chartUtils';
import getPeriod from '../../lib/getPeriod';
import { track } from '../../lib/segment';
import { useAppDispatch } from '../../store/hooks';
import exportChart from '../chart/exportChart';
import CreateDashboardModal from '../dashboard/CreateDashboardModal';
import ReportNameModal from '../dashboard/ReportNameModal';
import SelectDashboardModal from '../dashboard/SelectDashboardModal';
import FilterPopover from './FilterPopover';
import Table from './Table';
import { setChartTypeInDetailSection } from './actions';
import { DynamicSection } from './dynamicReducer';
import exportCsv from './exportCsv';
import exportDetailData from './exportDetailData';

interface DynamicSectionProps extends DynamicSection {
	disabled: boolean;
	enterpriseName: string;
	summary: any;
	onFetch: any;
	onClose: any;
	emptyState: any;
	navigateToDetail: any;
	dashboards: any[];
	addReportToDashboard: any;
	createDashboardAndAddReport: any;
	queryString: any;
	generated: boolean;
	role: string;
	confidence: number;
	rowFilter: any;
	setRowFilter: any;
	setColumnFilter: any;
	clearFilter: any;
	setSortCriteria: any;
	groupSummary: any;
}

export default function Section(props: DynamicSectionProps) {
	const {
		open,
		disabled,
		enterpriseName,
		lifecycle,
		analysisType,
		employeeAttribute,
		attributeName,
		employeeAttributes,
		summary,
		pending,
		onFetch,
		onClose,
		date,
		interval,
		emptyState,
		navigateToDetail,
		dashboards,
		addReportToDashboard,
		createDashboardAndAddReport,
		queryString,
		generated,
		confidence,
		columnProps,
		rowFilter,
		columnFilter,
		sortCriteria,
		setRowFilter,
		setColumnFilter,
		clearFilter,
		setSortCriteria,
		data,
		diversityAttributes,
		groupSummary,
		showGroupSummary,
		unfilteredDiversityAttributes,
		chartType,
		allowChartToggle,
	} = props;

	const dispatch = useAppDispatch();
	const [expanded, setExpanded] = useState(false);
	const [exportOpen, setExportOpen] = useState(false);
	const [exportKeys, setExportKeys] = useState([]);
	const [reportNameModalIsOpen, setReportNameModalIsOpen] = useState(false);
	const [selectDashboardModalIsOpen, setSelectDashboardModalIsOpen] = useState(false);
	const [createDashboardModalIsOpen, setCreateDashboardModalIsOpen] = useState(false);
	const [reportName, setReportName] = useState('');
	const { onHover, onHoverEnd, onToggle, hoveredItem, selection, status } = useInteractiveChart({
		mode: 'standalone',
		lockedSelection: false,
	});

	useEffect(() => {
		if (queryString) {
			const queryObject = qs.parse(queryString, {
				ignoreQueryPrefix: true,
				arrayLimit: 10000,
			});

			if (
				queryObject.employeeAttribute &&
				queryObject.employeeAttribute === employeeAttribute &&
				!disabled &&
				!generated
			) {
				track(MixPanel.Events.ExploreAttributeTableExpand, {
					employeeAttribute: attributeName,
				});
				window.scrollTo(0, 0);
				onFetch(employeeAttribute).then(() => {
					const element = document.getElementById(employeeAttribute);
					if (element) {
						const top = element.getBoundingClientRect().top + window.pageYOffset - 160;

						window.scrollTo({
							top,
							behavior: 'smooth',
						});
					}
				});
			}
		}
	}, [queryString, disabled, generated]);

	function handleFetch() {
		if (disabled) return;
		if (!open) {
			track(MixPanel.Events.ExploreAttributeTableExpand, {
				employeeAttribute: attributeName,
			});
			onFetch(employeeAttribute);
		} else {
			onClose(employeeAttribute);
		}
	}

	function handleSort(columnName: any) {
		setSortCriteria(employeeAttribute, columnName);
	}

	function handleExportClick() {
		setExportOpen(!exportOpen);
		if (!exportOpen) {
			setExportKeys([]);
		}
	}

	function handleExportMenuSelect({ selectedKeys }: any) {
		setExportKeys(selectedKeys);
	}

	function handleExportImage() {
		track(MixPanel.Events.ExploreAttributeTableImageExport, {
			employeeAttribute: attributeName,
		});
		const period = getPeriod(date as string, interval as string);
		if (chartType === ChartTypes.HorizontalBar) {
			exportChart({
				lifecycle,
				analysisType,
				columnProps,
				nodeClass: '.detail-chart',
				data,
				dates: [],
				currentIndex: 0,
				interval,
				chartType: ChartTypes.HorizontalBar,
				cb: () => {
					setExportOpen(false);
					setExportKeys([]);
				},
			});
		} else {
			exportDetailData(
				enterpriseName,
				period,
				data,
				summary,
				groupSummary,
				null,
				lifecycle,
				analysisType,
				columnProps,
				attributeName,
				diversityAttributes,
				sortCriteria,
				expanded
			);

			setExportOpen(false);
			setExportKeys([]);
		}
	}

	function handleExportCSV() {
		track(MixPanel.Events.ExploreAttributeTableCSVExport, {
			employeeAttribute: attributeName,
		});
		const period = getPeriod(date as any as Date, interval as string);
		exportCsv(
			data,
			summary,
			groupSummary,
			lifecycle,
			analysisType,
			columnProps,
			attributeName,
			diversityAttributes,
			period
		);
		setExportOpen(false);
		setExportKeys([]);
	}

	function handleFilter(rowFilter: any, columnFilter: any) {
		let rows: any = [];
		rowFilter?.forEach((r: any) => {
			const row = employeeAttributes?.find(ea => ea.value === r);
			if (row) {
				rows.push(row.text);
			}
		});
		rows = rows.join(', ');
		track(MixPanel.Events.ExploreAttributeTableFilter, {
			employeeAttribute: attributeName,
			rows,
			columns: columnFilter.join(', '),
		});
		setRowFilter(employeeAttribute, rowFilter);
		setColumnFilter(employeeAttribute, columnFilter);
	}

	function handleClear() {
		clearFilter(employeeAttribute);
	}

	function handleAddToDashboardClick() {
		track(MixPanel.Events.ExploreAttributeTableAddToDashboardClick);
		setReportNameModalIsOpen(true);
	}

	function handleReportNameSave(reportName: string) {
		setReportName(reportName);
		setReportNameModalIsOpen(false);
		setSelectDashboardModalIsOpen(true);
	}

	function handleDashboardAdd(dashboardIds: string[]) {
		dashboardIds.forEach(dashboardId => {
			addReportToDashboard(reportName, dashboardId, {
				employeeAttribute,
				date,
				rowFilter,
				columnFilter,
				isDetailSection: true,
			});
		});
		setSelectDashboardModalIsOpen(false);
	}

	function handleDashboardCreate() {
		setSelectDashboardModalIsOpen(false);
		setCreateDashboardModalIsOpen(true);
	}

	function handleDashboardSave(dashboardName: any) {
		createDashboardAndAddReport(dashboardName, reportName, {
			employeeAttribute,
			date,
			rowFilter,
			columnFilter,
		});
		setCreateDashboardModalIsOpen(false);
	}

	function renderExportMenu() {
		return (
			<Menu className="widget-menu" selectedKeys={exportKeys} onSelect={handleExportMenuSelect}>
				<Menu.Item onClick={handleExportCSV}>Export as CSV</Menu.Item>
				<Menu.Item onClick={handleExportImage}>Export as Image</Menu.Item>
			</Menu>
		);
	}

	if (emptyState) {
		return (
			<>
				<div
					className={classNames('attr-section', {
						' attr-section--open': open,
						'attr-section--disabled': disabled,
					})}
				>
					<div className="attr-section__title">
						<span onClick={handleFetch}>{attributeName}</span>
					</div>
					<span className="attr-section__date">
						{getFormattedDate(date as any as Date, interval as string)}
					</span>
					<div className="attr-section__icon" onClick={handleFetch}>
						<MdKeyboardArrowRight size={24} />
					</div>
				</div>
				<div className="attr-section__empty">There is no data for the selected filter</div>
			</>
		);
	}

	const defaultReportName = `${lifecycle} Table by ${attributeName}`;

	return (
		<>
			<div
				className={classNames('attr-section', {
					' attr-section--open': open,
					'attr-section--disabled': disabled,
				})}
			>
				<a id={employeeAttribute} />
				{data && data?.length > 0 && (
					<Popover
						placement="bottom"
						visible={exportOpen}
						onVisibleChange={handleExportClick}
						content={renderExportMenu()}
						overlayClassName="popover--no-padding"
					>
						<Download className="attr-section__download btn-icon" />
					</Popover>
				)}
				<div className="attr-section__title">
					<span onClick={handleFetch}>
						{attributeName}
						{data && data?.length ? (
							<span className="attr-section__count">({props?.data?.length})</span>
						) : null}
					</span>
				</div>
				{confidence && <span className="attr-section__confidence">Confidence {confidence}%</span>}
				{open && !pending && (
					<span className="attr-section__date">
						{getFormattedDate(date as any as Date, interval as string)}
					</span>
				)}
				<div className="attr-section__icon" onClick={handleFetch}>
					<MdKeyboardArrowRight size={24} />
				</div>
			</div>
			{open && (
				<>
					{!pending && (
						<div className="attr-section__filter">
							<Stack
								flexDirection="row"
								alignItems="center"
								gap=".8rem"
								justifyContent="flex-end"
								style={{
									width: '100%',
								}}
							>
								<WithPermissions actions={[RbacActions['Dashboards/Edit']]}>
									<Button
										componentType={ButtonTypes.type.SECONDARY}
										onClick={handleAddToDashboardClick}
									>
										Add to Dashboard
									</Button>
								</WithPermissions>
								{rowFilter.length || columnFilter?.length ? (
									<Button componentType={ButtonTypes.type.TERTIARY} onClick={handleClear}>
										Clear
									</Button>
								) : null}
								<FilterPopover
									employeeAttribute={employeeAttribute}
									rowData={employeeAttributes}
									columnData={unfilteredDiversityAttributes?.map(da => ({
										value: da.label,
										text: da.segment,
									}))}
									onFilter={handleFilter}
									rowFilter={rowFilter}
									columnFilter={columnFilter}
								/>

								{allowChartToggle && (
									<Fragment>
										<Button
											componentType={
												chartType === ChartTypes.Matrix
													? ButtonTypes.type.PRIMARY
													: ButtonTypes.type.TERTIARY
											}
											circle={true}
											onClick={() =>
												dispatch(
													setChartTypeInDetailSection(
														employeeAttribute as string,
														ChartTypes.Matrix
													)
												)
											}
										>
											<MenuIcon />
										</Button>
										<Button
											componentType={
												chartType === ChartTypes.HorizontalBar
													? ButtonTypes.type.PRIMARY
													: ButtonTypes.type.TERTIARY
											}
											circle={true}
											onClick={() =>
												dispatch(
													setChartTypeInDetailSection(
														employeeAttribute as string,
														ChartTypes.HorizontalBar
													)
												)
											}
										>
											<HorizontalBarIcon />
										</Button>
									</Fragment>
								)}
							</Stack>
						</div>
					)}
					{chartType === ChartTypes.Matrix ? (
						<Table
							lifecycle={lifecycle}
							analysisType={analysisType}
							employeeAttribute={employeeAttribute}
							attributeName={attributeName}
							employeeAttributes={employeeAttributes}
							diversityAttributes={diversityAttributes}
							data={data}
							summary={summary}
							groupSummary={groupSummary}
							showGroupSummary={showGroupSummary}
							pending={pending}
							emptyState={emptyState}
							expanded={expanded}
							toggleExpansion={() => setExpanded(!expanded)}
							onSort={handleSort}
							sortCriteria={sortCriteria}
							navigateToDetail={navigateToDetail}
							onHover={onHover}
							onHoverEnd={onHoverEnd}
							onToggle={onToggle}
							hoveredItem={hoveredItem}
							selection={selection}
							status={status}
							columnProps={columnProps}
						/>
					) : (
						<div
							style={{
								margin: '1.6rem 2rem 2.4rem ',
							}}
						>
							<div
								className="detail-chart"
								style={{
									overflow: 'hidden',
									borderRadius: '.8rem',
								}}
							>
								<ChartContainer>
									<div
										style={{
											padding: '1.6rem',
										}}
									>
										<Legend
											multiLine={false}
											data={diversityAttributes?.map((attr: any) => ({
												...attr,
												color: attr.segment.tokens[0].color,
											}))}
											onHover={onHover}
											onHoverEnd={onHoverEnd}
											onToggle={onToggle}
											hoveredItem={hoveredItem}
											selection={selection}
											status={status}
										/>
									</div>
									<ScrollableChartArea>
										<HorizontalBarChart
											data={data}
											diversityAttributes={diversityAttributes}
											columnProps={columnProps}
											onHover={onHover}
											onHoverEnd={onHoverEnd}
											onToggle={onToggle}
											selection={selection}
											status={status}
											hoveredItem={hoveredItem}
										/>
									</ScrollableChartArea>
								</ChartContainer>
							</div>
						</div>
					)}
				</>
			)}
			<ReportNameModal
				defaultReportName={defaultReportName}
				isOpen={reportNameModalIsOpen}
				onSave={handleReportNameSave}
				onCancel={() => setReportNameModalIsOpen(false)}
			/>
			<SelectDashboardModal
				isOpen={selectDashboardModalIsOpen}
				dashboards={dashboards}
				onAdd={handleDashboardAdd}
				onCreate={handleDashboardCreate}
				onCancel={() => setSelectDashboardModalIsOpen(false)}
			/>
			<CreateDashboardModal
				isOpen={createDashboardModalIsOpen}
				onSave={handleDashboardSave}
				onCancel={() => setCreateDashboardModalIsOpen(false)}
			/>
		</>
	);
}

Section.defaultProps = {
	diversityAttributes: [],
	data: [],
	rowFilter: [],
	columnFilter: [],
};
