import { useRef, useState } from 'react';

import Avatar from '../common/Avatar';
import Popover from '../common/Popover';
import AvatarTypes from '../constants/AvatarTypes';
import MixPanel from '../constants/MixPanel';
import cn from '../lib/cn';
import getAvatarFill from '../lib/getAvatarFill';
import { track } from '../lib/segment';
import { useAppSelector } from '../store/hooks';
import PhotoPopover from './PhotoPopover';

function Photo() {
	const { photoURL, email } = useAppSelector(state => state.auth.userInfo);

	const comp = useRef<HTMLDivElement>(null);
	const [visible, setVisible] = useState<boolean>();
	function handleVisibleChange(visible: boolean) {
		if (visible) {
			track(MixPanel.Events.ViewProfile);
		}
		setVisible(visible);
	}

	return (
		<div ref={comp} className={cn('flex items-center')}>
			<Popover
				placement="bottomRight"
				content={<PhotoPopover />}
				trigger="click"
				visible={visible}
				onVisibleChange={handleVisibleChange}
				overlayClassName="popover--no-padding"
				getPopupContainer={() => comp.current}
				closeOnScroll={false}
			>
				{photoURL ? (
					<img className="header-links__photo" src={photoURL} alt="Avatar" />
				) : (
					<Avatar type={AvatarTypes.type.DEFAULT} colorCode={getAvatarFill(email ?? '')} />
				)}
			</Popover>
		</div>
	);
}

export default Photo;
