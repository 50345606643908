import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { orderBy } from 'lodash';

import Urls from '../../../constants/Urls';
import { AsyncThunkConfig } from '../../../store/store';
import { Audience } from '../types';
import { IAudienceDeleteProps, IAudiencesRequestProps } from './types';

export const getAudiences = createAsyncThunk<any, void, AsyncThunkConfig>(
	'audiences/enterprise',
	async (_, { getState }) => {
		const { enterpriseId } = getState().account;

		const payload: IAudiencesRequestProps = {
			enterpriseId,
		};
		const response = await axios.post(`${Urls.SurveyStudioApi}audiences/enterprise`, payload);

		const sortedAudiences = orderBy(
			response.data?.audiences,
			(audience: Audience) => new Date(audience.meta.updatedAt),
			'desc'
		);

		const data: Audience[] = [];
		const archived: Audience[] = [];
		sortedAudiences.forEach(aud => {
			const uiElement = aud.meta.uiElements.find((u: any) => u.key === 'archive');
			if (uiElement?.value === 'true') {
				archived.push(aud);
			} else {
				data.push(aud);
			}
		});

		return { data, archived };
	}
);

export const getAudienceById = createAsyncThunk<any, void, AsyncThunkConfig>('audiences/enterprise/id', async () => {
	const payload: any = {};
	const response = await axios.post(`${Urls.SurveyStudioApi}audiences/enterprise/id`, payload);
	return response.data;
});

export const getAudienceUsers = createAsyncThunk<any, void, AsyncThunkConfig>(
	'audiences/enterprise/id/users',
	async () => {
		const payload: any = {};
		const response = await axios.post(`${Urls.SurveyStudioApi}audiences/enterprise/id/users`, payload);
		return response.data;
	}
);

export const deleteAudience = createAsyncThunk<any, string, AsyncThunkConfig>(
	'audiences/delete',
	async (audienceId, { getState, dispatch }) => {
		const { enterpriseId } = getState().account;
		const { data } = getState().audiences.list;
		const audience = data.find((audience: any) => audience.audienceId === audienceId);
		const payload: IAudienceDeleteProps = {
			audienceId,
			enterpriseId,
		};
		const response = await axios.put(`${Urls.SurveyStudioApi}audiences/delete`, payload);

		dispatch(getAudiences());
		return {
			...response.data,
			title: audience?.title,
		};
	}
);
