import { addMessage } from '../../../common/actions';
import Button from '../../../common/Button';
import Modal from '../../../common/Modal';
import ButtonTypes from '../../../constants/ButtonTypes';
import MixPanel from '../../../constants/MixPanel';
import { track } from '../../../lib/segment';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { deleteGoal, setToDelete } from '../../actions';

const DeleteGoal = () => {
	const dispatch = useAppDispatch();
	const goal = useAppSelector(state => state.measure.goals.toDelete);
	const handleDelete = async () => {
		dispatch(setToDelete());
		await dispatch(
			deleteGoal(
				goal!.goalId,
				goal!.metricTrajectoryValues.map((value: any) => value.metricRegistrationId)
			)
		);
		dispatch(addMessage(`Goal "${goal!.goalName}" deleted`));
		track(MixPanel.Events.GoalDelete);
	};

	const handleCancel = () => {
		dispatch(setToDelete());
	};

	return (
		<Modal
			visible={!!goal}
			width={450}
			footer={null}
			closable={false}
			maskClosable={true}
			onCancel={handleCancel}
			zIndex={3000}
			destroyOnClose
		>
			<div className="modal" data-test="delete-measure-modal">
				<div className="modal__header">
					<div className="modal__title">Delete Goal</div>
				</div>
				<div className="modal__content">
					<p>Click Delete to remove this goal</p>
					<p>IMPORTANT: This action cannot be undone</p>
				</div>
				<div className="modal__footer">
					<Button componentType={ButtonTypes.type.TERTIARY} onClick={handleCancel}>
						Cancel
					</Button>
					<Button onClick={handleDelete}>Delete</Button>
				</div>
			</div>
		</Modal>
	);
};

export default DeleteGoal;
