import MessageBar from '../common/MessageBar';
import TopBar from '../layout/TopBar';
import Forbidden from '../routes/Forbidden';
import { useAppSelector } from '../store/hooks';
import ContentArea from './ContentArea';
import LeftNav from './LeftNav';
import './styles.scss';

const permittedUids = [
	'3gbDJG1B0DTFcRizrQ1gnT0oRQk2', //Mamdouh
	'wyn0BLY2MkcgXbwTKwY6VL71OUT2', //Surag
	'qFVzK0HkSTS9lVbzOX7WX8Mkng62', //Ryan Schell
	'iIpYhbELDlc1wLc4hpaSnuH0xtd2', //Vijay
	'rQEiHq1dZgYx9jtgcgBqs4Kh3uk1', //Oscar
	'7a3GRTVIKFcLufm59sNsy3CW7ph2', //Eric,
	'NwuL670xkPdVoVw3kIbwSbNt7Jm1', // Viktor
	'4xXurZ9RxxY21L2wUuZvMxIPpZl2', // Nov3

	//Dev
	'JqpNZLF49GNZZSdTOpH80YDMURC2', //Mamdouh Dev
	'A9lqy7OurKbNeZiOGpEw1Q4KUQn2', //Surag Dev
	'I0UhYkVaRgQ4VX5OvUS27TWk2Hb2', //Ryan Schell Dev
	's0mPp0jVBMOV8u2WSUlk0Euzqmv1', //Vijay Dev
	'COWd68XIf9WSpt9h8og57ziAGBG2', //Viktor Dev
	'0poRruTvgUNMoojJeESCUDR3qZ73', //Oscar Dev
	'XhcRPl8CHTh00bCZSceJ9Ozmp3b2', //Eric Dev
	'8Eco6OHkgLROfUxQaGkDSsPF41v2', // Alex Dev
	'GrXlX5upygaypBdKg31I1WE73Lj2', // Pentest user
];

function DataStudio() {
	const userInfo = useAppSelector(state => state.auth.userInfo);
	if (!permittedUids.includes(userInfo.uid!)) {
		return (
			<div style={{ width: '100vw', height: '100vh' }}>
				<Forbidden />;
			</div>
		);
	}

	return (
		<div className="layout">
			<TopBar TopMenu={LeftNav} />
			<LeftNav />
			<ContentArea />
			<MessageBar />
		</div>
	);
}

export default DataStudio;
