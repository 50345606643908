import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={52} height={52} viewBox="0 0 52 52" fill="none" {...props}>
		<path
			fill="currentColor"
			d="M17.28 38.458h17.44v-3.25H17.28v3.25Zm0-9.208h17.44V26H17.28v3.25Zm-5.363 18.417c-.867 0-1.625-.325-2.275-.975-.65-.65-.975-1.409-.975-2.275V7.583c0-.866.325-1.625.975-2.275.65-.65 1.408-.975 2.275-.975H31.47l11.862 11.863v28.22c0 .867-.325 1.626-.975 2.276-.65.65-1.408.975-2.275.975H11.917Zm17.929-30.009V7.583h-17.93v36.834h28.167V17.658H29.846Z"
		/>
	</svg>
);
export default SvgComponent;
