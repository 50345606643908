import cn from '../lib/cn';
import getChartData from '../lib/getChartData';
import { useAppSelector } from '../store/hooks';
import GenericTable from './GenericTable';
import ScrollableChartArea from './ScrollableChartArea';

const GenericTableWrapper = ({
	reportId,
	onHover,
	onHoverEnd,
	onToggle,
	hoveredItem,
	selection,
	status,
}: {
	reportId?: string;
	onHover?: any;
	onHoverEnd?: any;
	onToggle?: any;
	hoveredItem?: any;
	selection: string[];
	status?: any;
	[key: string]: any;
}) => {
	const { data, subAnalysisFields, subAnalysisMapping } = useAppSelector(state => getChartData(state, reportId));

	return (
		<div className={cn('flex-1 flex flex-col h-full justify-between')}>
			<ScrollableChartArea>
				<div className={cn('flex-1 relative')}>
					<div className={cn('relative w-full')}>
						<div className="explore-chart__main">
							<GenericTable
								data={data}
								subAnalysisFields={subAnalysisFields}
								subAnalysisMapping={subAnalysisMapping}
								onHover={onHover}
								onHoverEnd={onHoverEnd}
								onToggle={onToggle}
								status={status}
								hoveredItem={hoveredItem}
								selection={selection}
							/>
						</div>
					</div>
				</div>
			</ScrollableChartArea>
		</div>
	);
};

export default GenericTableWrapper;
