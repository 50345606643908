import {
	CollisionDetection,
	DndContext,
	DragOverlay,
	getFirstCollision,
	KeyboardSensor,
	MeasuringStrategy,
	PointerSensor,
	rectIntersection,
	UniqueIdentifier,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { debounce } from 'lodash';
import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import * as uuid from 'uuid';

import Button from '../common/Button';
import ButtonTypes from '../constants/ButtonTypes';
import { autosaveDashboard } from '../dashboards/actions';
import LockIcon from '../icons/Lock';
import cn from '../lib/cn';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import Rows from './Rows';
import { setHasHiddenReports, setNotifyAboutHiddenReports } from './edit/actions';
import { getDashboardForAutosave, getGlobalLayoutState, globalLayoutIsEqual } from './helpers';

const DEFAULT_ROW_HEIGHT = 500;
const TOP_ON_FILTER_DISPLAY = 50;

export interface LayoutRow {
	rowId: string;
	rowSize: number;
	columns: {
		id: string;
		columnWidthPerc: number;
		isHidden?: boolean;
	}[];
}

interface DashboardLayoutContextProps {
	filteredReports: any[];
	dashboard: any;
	globalLayoutState: any;
	setGlobalLayoutState: (globalLayoutState: any) => void;
	setShouldAutosave: (shouldAutosave: boolean) => void;
}

export const DashboardLayoutContext = createContext<DashboardLayoutContextProps>({
	filteredReports: [],
	dashboard: {},
	globalLayoutState: new Map(),
	setGlobalLayoutState: () => {},
	setShouldAutosave: () => {},
});

export default function DashboardLayout({ filteredReports, dashboard }: any) {
	const dispatch = useAppDispatch();
	const reports = useAppSelector(state => state.reports);
	const { hasHiddenReports, notifyAboutHiddenReports } = useAppSelector(state => state.dashboard.view);
	const [globalLayoutState, setGlobalLayoutState] = useState<LayoutRow[]>([]);
	const [shouldAutosave, setShouldAutosave] = useState(false);
	const [isDroppingNotAllowed, setIsDroppingNotAllowed] = useState(false);
	const [top, setTop] = useState(0);
	const isDashboardFiltersShown = useAppSelector(state => state.dashboard.view.showDashboardFilters);

	useEffect(() => {
		setTop(isDashboardFiltersShown ? TOP_ON_FILTER_DISPLAY : 0);
	}, [isDashboardFiltersShown]);

	useEffect(() => {
		const isLayoutV2 = filteredReports.every((r: any) =>
			r.configuration.find((c: any) => c.name === 'layoutVersion' && c.value === 'v2')
		);
		const newGlobalLayoutState = getGlobalLayoutState(filteredReports, reports, isLayoutV2);

		// To avoid uneccessary re-renders after autosaving
		const hasChanges = !globalLayoutIsEqual(globalLayoutState, newGlobalLayoutState);
		if (hasChanges) {
			setGlobalLayoutState(newGlobalLayoutState);
		}
	}, [filteredReports, reports]);

	const debouncedAutosave = useCallback(
		debounce(() => {
			const updatedDashboard = getDashboardForAutosave(globalLayoutState, dashboard);

			setShouldAutosave(false);
			dispatch(autosaveDashboard(updatedDashboard));
		}, 2000),
		[globalLayoutState, dashboard, shouldAutosave]
	);

	useEffect(() => {
		if (!shouldAutosave) {
			return;
		}

		debouncedAutosave();
		return () => debouncedAutosave.cancel();
	}, [debouncedAutosave, globalLayoutState, shouldAutosave]);

	useEffect(() => {
		const foundHiddenReports = filteredReports.some((r: any) => reports[r.reportId]?.generateNotAllowed);

		if (foundHiddenReports && !hasHiddenReports) {
			dispatch(setHasHiddenReports(true));
		}
	}, [filteredReports, reports, hasHiddenReports]);

	const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
	const recentlyMovedToNewContainer = useRef(false);

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);
	const collisionDetectionStrategy: CollisionDetection = useCallback(
		args => {
			const { pointerCoordinates } = args;

			const overElements = rectIntersection(args);

			const dividerElements = overElements.filter((element: any) => element.id.startsWith('newRow'));

			const dividerUnderMouse = dividerElements.find(divider => {
				const rect = divider.data?.droppableContainer?.rect.current;
				if (!rect || !pointerCoordinates) {
					return false;
				}
				return (
					pointerCoordinates.x >= rect.left &&
					pointerCoordinates.x <= rect.right &&
					pointerCoordinates.y >= rect.top &&
					pointerCoordinates.y <= rect.bottom
				);
			});
			const overId = dividerUnderMouse ? dividerUnderMouse.id : getFirstCollision(overElements, 'id');

			if (!overId || overId === activeId) {
				return [];
			}

			const overContainer = findContainer(overId);

			if (
				overContainer &&
				globalLayoutState.find((row: LayoutRow) => row.rowId === overContainer.rowId)?.columns.length === 4
			) {
				return [];
			}

			return [
				{
					id: overId,
				},
			];
		},
		[activeId, globalLayoutState]
	);
	const onDragCancel = () => {
		if (globalLayoutState) {
			setGlobalLayoutState(globalLayoutState);
		}

		setActiveId(null);
	};
	useEffect(() => {
		requestAnimationFrame(() => {
			recentlyMovedToNewContainer.current = false;
		});
	}, [globalLayoutState]);

	const findContainer = (id: UniqueIdentifier) => {
		if (id in globalLayoutState.map((row: LayoutRow) => row.rowId)) {
			return globalLayoutState.find((row: LayoutRow) => row.rowId === id);
		}

		return globalLayoutState.find((row: LayoutRow) => row.columns.map((col: any) => col.id).includes(id));
	};

	return (
		<DashboardLayoutContext.Provider
			value={{
				filteredReports,
				dashboard,
				globalLayoutState,
				setGlobalLayoutState,
				setShouldAutosave,
			}}
		>
			<DndContext
				measuring={{
					droppable: {
						strategy: MeasuringStrategy.Always,
					},
				}}
				onDragCancel={onDragCancel}
				onDragStart={({ active }) => {
					setActiveId(active.id);
				}}
				onDragOver={({ over }) => {
					if (over && over?.data?.current?.isDisabled) {
						setIsDroppingNotAllowed(true);
					} else {
						setIsDroppingNotAllowed(false);
					}
				}}
				onDragEnd={({ active, over }) => {
					if (over && over?.data?.current?.isDisabled) {
						return;
					}
					setShouldAutosave(true);
					const activeContainer = findContainer(active.id);

					if (!activeContainer) {
						setActiveId(null);
						return;
					}

					const overId: string = over?.id as string;

					if (overId == null) {
						setActiveId(null);
						return;
					}

					let overContainer: LayoutRow | undefined;

					if (overId.startsWith('newRow')) {
						overContainer = {
							rowId: `row-${uuid.v4()}`,
							rowSize: DEFAULT_ROW_HEIGHT,
							columns: [],
						};
					} else {
						overContainer = findContainer(overId);
					}

					if (overContainer) {
						let overIndex: number;
						const activeIndex = activeContainer.columns.map((col: any) => col.id).indexOf(activeId);
						if (overId.startsWith('newRow')) {
							overIndex = 0;
						} else {
							overIndex = overContainer.columns.map((col: any) => col.id).indexOf(overId);
						}

						if (activeIndex === -1 || overIndex === -1) {
							setActiveId(null);
							return;
						}

						// Moving to a newly created row
						if (overId.startsWith('newRow')) {
							setGlobalLayoutState((currentRows: LayoutRow[]) => {
								const rowAboveId = overId.split('_')[1];
								const insertAtTop = rowAboveId === 'top';

								const insertAfterIndex = currentRows.findIndex(
									(row: LayoutRow) => row.rowId === rowAboveId
								);
								if (insertAfterIndex === -1 && !insertAtTop) {
									return currentRows;
								}

								const activeRowIndex = globalLayoutState.findIndex(
									(row: LayoutRow) => row.rowId === activeContainer.rowId
								);

								const activeRow = currentRows[activeRowIndex];

								const updatedActiveRowColumns = activeRow.columns.filter(
									(_, index: number) => index !== activeIndex
								);

								const itemToMove = activeRow.columns[activeIndex];
								const newRow: LayoutRow = {
									rowId: `row-${uuid.v4()}`,
									rowSize: DEFAULT_ROW_HEIGHT,
									columns: [itemToMove],
								};
								const updatedRows = [...currentRows];
								updatedRows[activeRowIndex] = {
									...activeRow,
									columns: updatedActiveRowColumns,
								};

								if (insertAtTop) {
									updatedRows.unshift(newRow);
								} else {
									updatedRows.splice(insertAfterIndex + 1, 0, newRow);
								}
								return updatedRows.filter((row: LayoutRow) => {
									return row.columns.length > 0;
								});
							});
						}
						// Moving within the same container
						else if (activeContainer === overContainer) {
							const rowIndex = globalLayoutState.findIndex(
								(row: LayoutRow) => row.rowId === activeContainer.rowId
							);
							const row = globalLayoutState[rowIndex];
							const finalOverIndex = activeIndex < overIndex ? overIndex - 1 : overIndex;
							const updatedColumns = arrayMove(row.columns, activeIndex, finalOverIndex);

							setGlobalLayoutState((currentRows: LayoutRow[]) => {
								const updatedRow = {
									...row,
									columns: updatedColumns,
								};

								const updatedRows = [...currentRows];
								updatedRows[rowIndex] = updatedRow;

								return updatedRows;
							});
							// Moving to a new container
						} else {
							setGlobalLayoutState((currentRows: LayoutRow[]) => {
								const activeRowIndex = currentRows.findIndex(
									(row: LayoutRow) => row.rowId === activeContainer.rowId
								);
								const overRowIndex = currentRows.findIndex(
									(row: LayoutRow) => row.rowId === overContainer?.rowId
								);

								if (activeRowIndex === -1 || overRowIndex === -1) {
									return currentRows;
								}

								const activeRow = currentRows[activeRowIndex];
								const overRow = currentRows[overRowIndex];

								const itemToMove = activeRow.columns[activeIndex];

								const newActiveColumns = activeRow.columns.filter((_, index) => index !== activeIndex);

								const newOverColumns = [
									...overRow.columns.slice(0, overIndex),
									itemToMove,
									...overRow.columns.slice(overIndex),
								];
								const updatedRows = currentRows.map((row: LayoutRow, index: number) => {
									if (index === activeRowIndex) {
										return {
											...row,
											columns: newActiveColumns,
										};
									} else if (index === overRowIndex) {
										return {
											...row,
											columns: newOverColumns,
										};
									} else {
										return row;
									}
								});

								return updatedRows.filter((row: LayoutRow) => row.columns.length);
							});
						}
					}

					setActiveId(null);
				}}
				collisionDetection={collisionDetectionStrategy}
				sensors={sensors}
			>
				<div
					className={cn('h-auto w-avail mx-[-10px] relative pt-[16px]')}
					data-name="dnd-wrapper"
					style={{
						transform: top ? `translate3d(0px,${top}px,0px)` : 'none',
						transformOrigin: 'left top',
						transition: 'transform .2s ease-in-out 0s',
					}}
				>
					<Rows />
				</div>
				<DragOverlay>
					<div className={cn('w-full h-full rounded-[2rem] scale-95 bg-ui-20 p-[3.2rem]')}>
						{isDroppingNotAllowed && (
							<LockIcon className={cn('text-ui-50-inverted h-full max-h-[168px] w-auto')} />
						)}
					</div>
				</DragOverlay>
			</DndContext>
			<AlertDialog.Root
				open={notifyAboutHiddenReports && hasHiddenReports}
				onOpenChange={open => dispatch(setNotifyAboutHiddenReports(open))}
			>
				<AlertDialog.Portal>
					<AlertDialog.Overlay className={cn('inset-0 fixed')}></AlertDialog.Overlay>
					<AlertDialog.Content
						className={cn(
							'data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-shade-h3 p-[1.6rem] shadow-lg focus:outline-none',
							'flex flex-col gap-[1.6rem] items-start'
						)}
					>
						<h3 className={cn('font-subtitle')}>Hidden reports</h3>
						<p className={cn('text-ui-50')}>
							{`Based on your permissions, some reports in this dashboard are hidden. You will not be able to edit rows that contain hidden reports.`}
						</p>
						<div className={cn('self-end mt-[1.6rem]')}>
							<Button
								componentType={ButtonTypes.type.PRIMARY}
								onClick={() => dispatch(setNotifyAboutHiddenReports(false))}
							>
								<span className={cn('font-bold')}>Continue</span>
							</Button>
						</div>
					</AlertDialog.Content>
				</AlertDialog.Portal>
			</AlertDialog.Root>
		</DashboardLayoutContext.Provider>
	);
}
