import { useAppSelector } from '../../../store/hooks';
import { AudienceType } from '../types';
import AudienceTypeModal from './AudienceTypeModal';
import DynamicAudienceModal from './DynamicAudienceModal';
import StaticAudienceModal from './StaticAudienceModal';

const EditFlow = () => {
	const { selectedIndex, type } = useAppSelector(state => state.audiences.edit);

	if (selectedIndex === 0) {
		return <AudienceTypeModal />;
	} else if (selectedIndex === 1) {
		if (type === AudienceType.DYNAMIC) {
			return <DynamicAudienceModal />;
		} else if (type === AudienceType.STATIC) {
			return <StaticAudienceModal />;
		}
	}

	return null;
};

export default EditFlow;
