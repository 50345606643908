import { createSlice } from '@reduxjs/toolkit';

import actionTypes from '../../constants/ActionTypes';
import { generateAiInsights } from './actions';

interface AiInsightsState {
	insights: string;
	pending?: boolean;
	error?: string;
	loaded: boolean;
	aiInsightsModalOpen: boolean;
	reportIds: string[];
	reportSelectMode: boolean;
	isSummary: boolean;
	cancelled?: boolean;
	hoverMode: boolean;
	hoverReportIds: string[];
	regenerateMode: boolean;
	regenerateReportId?: string;
	conversationId?: string;
}

const initialState: AiInsightsState = {
	insights: '',
	aiInsightsModalOpen: false,
	loaded: false,
	reportSelectMode: false,
	reportIds: [],
	isSummary: false,
	hoverMode: false,
	hoverReportIds: [],
	regenerateMode: false,
	conversationId: '',
};

const aiInsightsSlice = createSlice({
	name: 'aiInsights',
	initialState: initialState,
	reducers: {
		setAiInsightsModelOpen: (state, action) => {
			// reset state on modal close.
			if (!action.payload) {
				state.error = undefined;
				state.loaded = false;
				state.reportIds = [];
				state.aiInsightsModalOpen = false;
				state.insights = '';
				state.reportSelectMode = false;
				state.isSummary = false;
				state.regenerateMode = false;
				state.regenerateReportId = undefined;
				state.conversationId = undefined;
				if (state.pending) {
					state.cancelled = true;
				} else {
					state.cancelled = undefined;
				}
				state.pending = false;
			} else {
				state.aiInsightsModalOpen = true;
			}
		},
		setReportSelectMode: (state, action) => {
			if (action.payload) {
				// no hovermode allowed
				state.hoverMode = false;
				state.hoverReportIds = [];
			}
			state.reportSelectMode = action.payload;
		},
		selectReport: (state, action) => {
			const index = state.reportIds.indexOf(action.payload);
			if (index !== -1) {
				state.reportIds.splice(index, 1);
			} else {
				state.reportIds.push(action.payload);
			}
		},
		selectReports: (state, action) => {
			state.reportIds = action.payload;
		},
		setIsSummary: (state, action) => {
			state.isSummary = action.payload;
		},
		setHoverMode: (state, action) => {
			if (state.reportSelectMode) {
				// not allowed
				state.hoverMode = false;
				state.hoverReportIds = [];
				return;
			}

			if (action.payload.hoverMode) {
				state.hoverMode = true;
				state.hoverReportIds = action.payload.reportIds;
			} else {
				state.hoverMode = false;
				state.hoverReportIds = [];
			}
		},
		setRegenerateMode: (state, action) => {
			state.regenerateMode = action.payload.regenerateMode;
			state.regenerateReportId = action.payload.regenerateReportId;
		},
		resetAiInsights: () => initialState,
		setConversationId: (state, action) => {
			state.conversationId = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(generateAiInsights.pending, (state, _) => {
			state.pending = true;
			state.aiInsightsModalOpen = true;
		});
		builder.addCase(generateAiInsights.fulfilled, (state, action) => {
			if (state.cancelled) {
				state.cancelled = undefined;
				return;
			}
			state.pending = false;
			state.loaded = true;
			state.insights = action.payload.insights;
		});
		builder.addCase(generateAiInsights.rejected, state => {
			state.error =
				'We apologize, but it seems there is an internal issue preventing us from displaying your AI-generated insights at the moment. Our team is actively working to resolve this. Please try again later. If the issue persists, feel free to contact our support team for assistance.';

			state.pending = false;
			state.loaded = true;
		});
		builder.addCase(actionTypes.ChangeLocation, () => initialState);
	},
});

export const {
	resetAiInsights,
	selectReport,
	selectReports,
	setAiInsightsModelOpen,
	setConversationId,
	setHoverMode,
	setIsSummary,
	setRegenerateMode,
	setReportSelectMode,
} = aiInsightsSlice.actions;
export default aiInsightsSlice.reducer;
