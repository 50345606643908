import Tooltip from 'antd/lib/tooltip';
import { useEffect, useRef, useState } from 'react';

import CanvasFonts from '../constants/CanvasFonts';
import measureText from '../lib/measureText';

interface LongTextProps {
	text: string;
	tooltipClassName: string;
	fontSize?: string;
	refreshKey: string | boolean;
}

export default function LongText({ text, tooltipClassName, fontSize, refreshKey, ...props }: LongTextProps) {
	const [showTooltip, setShowTooltip] = useState(false);
	const textRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (textRef.current) {
			const expectedWidth = measureText(text, fontSize).width;
			const actualWidth = textRef.current.offsetWidth;
			setShowTooltip(expectedWidth > actualWidth);
		}
	}, [text, refreshKey]);

	return (
		<div className="long-text" ref={textRef} {...props}>
			{showTooltip ? (
				<Tooltip title={text} overlayClassName={tooltipClassName}>
					{text}
				</Tooltip>
			) : (
				text
			)}
		</div>
	);
}

LongText.defaultProps = {
	fontSize: CanvasFonts.Regular14,
};
