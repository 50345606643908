import shouldShowBanner from '../lib/shouldShowBanner';

export default function Banner() {
	return shouldShowBanner() ? (
		<div className="banner">
			We are performing planned maintenance as we upgrade our infrastructure. You may notice some disruptions
			during this time. We'll be complete by 5pm ET. Thanks for your understanding!
		</div>
	) : null;
}
