import Modal from 'antd/lib/modal';
import Tooltip from 'antd/lib/tooltip';
import classNames from 'classnames';
import validator from 'email-validator';
import { useEffect, useRef, useState } from 'react';

import ActionDropdown from '../../admin/Dropdown';
import Button from '../../common/Button';
import Checkbox from '../../common/Checkbox';
import TextArea from '../../common/TextArea';
import TextField from '../../common/TextField';
import { READ_ONLY_ROLE_IDS } from '../../constants/RoleIds';
import TextFieldTypes from '../../constants/TextFieldTypes';
import Close from '../../icons/Close';
import sanitize from '../../lib/sanitize';
import { useAppSelector } from '../../store/hooks';
import { Role } from '../roles/model';
import { SSO } from './models';

interface CreateUserModalProps {
	isOpen: boolean;
	onCancel: () => void;
	onAction: (emails: string[], selectedRoles: string[], sanitizedNote: string, sendNotification: boolean) => void;
	addUsersPending: boolean;
	sso: SSO[];
}

function CreateUserModal(props: CreateUserModalProps) {
	const { allRoles } = useAppSelector(state => state.admin.roles);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [emails, setEmails] = useState<string[]>([]);
	const [note, setNote] = useState('');
	const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
	const [error, setError] = useState('');
	const [sendNotification, setSendNotification] = useState(true);
	const [disabled, setDisabled] = useState(true);
	const [value, setValue] = useState('');
	const sortedRoles = [...allRoles]
		.sort((a, b) => a.name.localeCompare(b.name))
		.filter(r => !READ_ONLY_ROLE_IDS.includes(r.id));

	const containerRef = useRef<HTMLDivElement>(null);
	const textFieldRef = useRef<HTMLInputElement>(null);

	const { isOpen, sso, onAction, addUsersPending, onCancel } = props;

	useEffect(() => {
		setEmails([]);
		setNote('');
		setSelectedRoles([]);
		setError('');
		setSendNotification(true);
		setDisabled(true);
		setValue('');
	}, [isOpen]);

	function setCheckboxState(emails: string[]) {
		const domains = sso.map(s => s.domain);
		const disabled = emails.some(e => {
			const emailDomain = e.split('@')[1];
			return !domains.includes(emailDomain);
		});
		setEmails(emails);
		setDisabled(disabled);
		setSendNotification(disabled ? true : sendNotification);
	}

	function handleSelect(email: string) {
		if (validator.validate(email)) {
			setCheckboxState([...emails, email]);
			setError('');
			setValue('');
		} else {
			setError(`${email} is not a valid email`);
		}
	}

	function handleDeselect(email: string) {
		const index = emails.findIndex(e => e === email);
		if (index !== -1) {
			const newEmails = emails.slice();
			newEmails.splice(index, 1);
			setCheckboxState(newEmails);
		}
	}

	function handleInputChange(e: any) {
		setValue(e.target.value);
	}

	function handleEmailBlur() {
		const emailInputs = value.split(',');
		const validEmails: string[] = [];
		const invalidEmails: string[] = [];
		emailInputs.forEach(emailInput => {
			const emailTrimmed = emailInput.trim();
			if (emailTrimmed) {
				if (validator.validate(emailTrimmed)) {
					validEmails.push(emailTrimmed);
				} else {
					invalidEmails.push(emailTrimmed);
				}
			}
		});
		if (validEmails.length > 0) {
			let newEmails = emails.slice();
			newEmails = newEmails.concat(validEmails);
			setCheckboxState(newEmails);
		}
		if (invalidEmails.length === 0) {
			setValue('');
			setError('');
		} else if (invalidEmails.length === 1) {
			setError(`${invalidEmails[0]} is not a valid email`);
			setValue(invalidEmails[0]);
		} else {
			setError(`${invalidEmails.join(', ')} are not valid emails`);
			setValue(invalidEmails.join(', '));
		}
	}

	function handleNoteChange(e: any) {
		setNote(e.target.value);
	}

	function handleSend() {
		const sanitizedNote = sanitize(note);
		onAction(emails, selectedRoles, sanitizedNote, sendNotification);
	}

	function handleCheckboxChange(newState: boolean) {
		setSendNotification(newState);
	}

	return (
		<Modal
			open={isOpen}
			width={600}
			footer={null}
			closable={false}
			maskClosable={true}
			onCancel={onCancel}
			destroyOnClose={true}
			zIndex={3000}
		>
			<div className="modal user-modal" ref={containerRef}>
				<div className="modal__header">
					<div className="modal__title">Create User</div>
					<div className="close">
						<Close width={24} className="btn-icon" onClick={onCancel} />
					</div>
				</div>
				<div className="modal__content">
					<div className="user-modal__row">
						<div className="user-modal__email">
							<TextField
								componentType={TextFieldTypes.type.TAGS}
								autoFocus={true}
								large={true}
								placeholder="Enter email addresses"
								value={value}
								tags={emails}
								ref={textFieldRef}
								onChange={handleInputChange}
								onBlur={handleEmailBlur}
								onRemoveTag={handleDeselect}
								onKeyDown={e => {
									if (e.key === 'Enter') {
										handleSelect(value);
									} else if (e.key === 'Backspace' && value === '') {
										handleDeselect(emails[emails.length - 1]);
									}
								}}
							></TextField>
						</div>
						<div className="user-modal__role">
							<ActionDropdown
								onClick={() => {
									setIsDropdownOpen(!isDropdownOpen);
								}}
								onClearSelection={() => {}}
								placeholder="Select roles"
								buttonLarge
								allowCancel
								isOpen={isDropdownOpen}
								onMouseLeaveDropdown={() => {
									setIsDropdownOpen(false);
								}}
								alignDropdownRight={true}
								label={
									selectedRoles.length === 0
										? 'Select roles'
										: selectedRoles.length === 1
										? sortedRoles.find((role: Role) => role.id === selectedRoles[0])?.name
										: 'Multiple'
								}
							>
								<div className="filter-dropdown__list">
									<ul
										style={{
											maxHeight: '30rem',
										}}
									>
										{sortedRoles?.map((role: Role) => (
											<li key={role.id}>
												<Checkbox
													disabled={false}
													onClick={() => {
														if (selectedRoles.includes(role.id)) {
															setSelectedRoles(selectedRoles.filter(r => r !== role.id));
														} else {
															setSelectedRoles(selectedRoles.concat(role.id));
														}
													}}
													checked={selectedRoles.includes(role.id)}
												>
													{role.name}
												</Checkbox>
											</li>
										))}
									</ul>
								</div>
							</ActionDropdown>
						</div>
					</div>
					{error && <div className="user-modal__error">{error}</div>}
					<div className="user-modal__note">
						<TextArea rows={4} placeholder="Add a note" value={note} onChange={handleNoteChange}></TextArea>
					</div>
				</div>
				<div
					className={classNames('modal__footer', {
						'modal__footer--between': emails.length > 0,
					})}
				>
					{emails.length > 0 && (
						<Tooltip
							title="Email notification can be suppressed only if SSO is enabled for all recipients"
							placement="top"
							getPopupContainer={() => containerRef.current as HTMLDivElement}
						>
							<Checkbox checked={sendNotification} onClick={handleCheckboxChange} disabled={disabled}>
								Send email notification
							</Checkbox>
						</Tooltip>
					)}
					<Button loading={addUsersPending} disabled={!emails.length} onClick={handleSend}>
						{sendNotification ? 'Send' : 'Create'}
					</Button>
				</div>
			</div>
		</Modal>
	);
}

export default CreateUserModal;
