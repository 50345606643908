import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={58} height={58} viewBox="0 0 58 58" fill="none" {...props}>
		<path
			fill="currentColor"
			d="M27.308 45.796h3.625V33.652l4.955 4.954 2.537-2.537L29 26.885l-9.304 9.305 2.537 2.537 5.075-5.075v12.144Zm-14.016 7.37c-.967 0-1.813-.362-2.538-1.087-.725-.725-1.087-1.57-1.087-2.537V8.458c0-.966.362-1.812 1.087-2.537.725-.725 1.571-1.088 2.538-1.088h21.81l13.231 13.232v31.477c0 .966-.362 1.812-1.087 2.537-.725.725-1.57 1.088-2.538 1.088H13.292Zm19.998-33.47V8.458H13.292v41.084h31.416V19.696H33.29Z"
		/>
	</svg>
);
export default SvgComponent;
