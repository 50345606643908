import Modal from 'antd/lib/modal';
import { useEffect, useRef, useState } from 'react';

import Button from '../../common/Button';
import OpenText from '../../common/OpenText';
import ButtonTypes from '../../constants/ButtonTypes';
import useDebounce from '../../lib/useDebounce';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setDashboardName, updateDashboard } from '../edit/actions';

interface EditDashboardNameModalProps {
	isOpen: boolean;
	onClose: () => void;
	dashboardId: string;
}

export default function EditDashboardNameModal({ isOpen, onClose, dashboardId }: EditDashboardNameModalProps) {
	const dispatch = useAppDispatch();
	const dashboardName = useAppSelector(state => state.dashboards[dashboardId]?.name);
	const [name, setName] = useState(dashboardName || '');
	const debouncedName: string = useDebounce(name, 300);
	const inputRef = useRef<any>();

	useEffect(() => {
		dispatch(setDashboardName(debouncedName));
	}, [debouncedName]);

	function handleNameChange(name: string) {
		setName(name);
	}

	function handleKeyDown(e: any) {
		if (e.key === 'Tab') {
			setTimeout(() => {
				if (inputRef.current) {
					inputRef.current?.focus();
				}
			}, 0);
		}
	}

	function handleSave() {
		dispatch(updateDashboard(false));
		onClose();
	}

	return (
		<Modal
			open={isOpen}
			width={450}
			footer={null}
			closable={false}
			maskClosable={true}
			onCancel={onClose}
			zIndex={3000}
			destroyOnClose
		>
			<h3 className="mb40 font-subtitle">Name your dashboard</h3>
			<div>
				<OpenText
					value={name}
					onChange={handleNameChange}
					onKeyDown={handleKeyDown}
					inputRef={inputRef}
					placeholder="Enter name here"
					tabIndex={2}
				/>
				<div className="mt-[24px] flex justify-end gap-[8px]">
					<Button tabIndex={3} componentType={ButtonTypes.type.TERTIARY} onClick={onClose}>
						Cancel
					</Button>
					<Button type="submit" tabIndex={4} disabled={!name} onClick={handleSave}>
						Save
					</Button>
				</div>
			</div>
		</Modal>
	);
}
