import Modal from 'antd/lib/modal';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import Button from '../../common/Button';
import OpenText from '../../common/OpenText';
import ButtonTypes from '../../constants/ButtonTypes';
import sanitize from '../../lib/sanitize';

export default function ReportNameModal(props) {
	const { isOpen, onSave, onCancel, defaultReportName } = props;
	const [name, setName] = useState(defaultReportName);

	useEffect(() => {
		if (isOpen) {
			setName(defaultReportName);
		}
	}, [isOpen]);

	function handleNameChange(name) {
		setName(name);
	}

	function handleSave(e) {
		e.preventDefault();
		let reportName = name.trim();
		reportName = sanitize(reportName);
		onSave(reportName);
	}

	return (
		<Modal
			visible={isOpen}
			width={450}
			footer={null}
			closable={false}
			maskClosable={true}
			onCancel={onCancel}
			zIndex={3000}
			destroyOnClose
		>
			<div className="report-modal modal">
				<form onSubmit={handleSave}>
					<div className="modal__header">
						<div className="modal__title">Name your report</div>
						<div className="modal__buttons">
							<Button componentType={ButtonTypes.type.TERTIARY} onClick={onCancel} classes={['mr8']}>
								Cancel
							</Button>
							<Button onClick={handleSave} disabled={!name}>
								Save
							</Button>
						</div>
					</div>
					<div className="modal__content">
						<OpenText value={name} onChange={handleNameChange} placeholder="Enter name here" />
					</div>
				</form>
			</div>
		</Modal>
	);
}

ReportNameModal.propTypes = {
	isOpen: PropTypes.bool,
	onSave: PropTypes.func,
	onCancel: PropTypes.func,
	defaultReportName: PropTypes.string,
};
