import { useEffect, useRef } from 'react';

import Dropdown from '../../../../common/DropdownV2';
import cn from '../../../../lib/cn';

interface FilterDropdownProps {
	children: any;
	disabled: boolean;
	isOpen: boolean;
	label: string;
	onClearSelection: any;
	onClick?: () => {};
	placeholder: string;
	setIsOpen: any;
}

export default function FilterDropdown({
	children,
	disabled,
	isOpen,
	label,
	onClearSelection,
	onClick,
	placeholder,
	setIsOpen,
}: FilterDropdownProps) {
	useEffect(() => {
		if (isOpen) {
			window.addEventListener('keydown', handleKeyDown);
			window.addEventListener('mousedown', handleClickOutside);
			return () => {
				window.removeEventListener('keydown', handleKeyDown);
				window.removeEventListener('mousedown', handleClickOutside);
			};
		}
	}, [isOpen]);

	const containerRef = useRef<HTMLDivElement>(null);

	function handleKeyDown(e: any) {
		if (e.keyCode === 27) {
			setIsOpen(false);
		}
	}

	function handleClickOutside(e: any) {
		if (isOpen) {
			if (containerRef.current && !containerRef.current.contains(e.target)) {
				e.stopPropagation();
				setIsOpen(false);
			}
		}
	}

	return (
		<div style={{ display: 'flex', flexShrink: '1', minWidth: '0' }}>
			<Dropdown
				onClick={onClick}
				disabled={disabled}
				label={label}
				placeholder={placeholder}
				buttonLarge={true}
				data-test={`dropdown-${placeholder}`}
				ref={containerRef}
				isOpen={isOpen}
				onClearSelection={onClearSelection}
			>
				<div className={cn('flex items-start')}>{children}</div>
			</Dropdown>
		</div>
	);
}
