function SvgComponent(props: any) {
	return (
		<svg width={12} height={19} viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.51172 4.5C7.51172 5.32843 6.84015 6 6.01172 6C5.18329 6 4.51172 5.32843 4.51172 4.5C4.51172 3.67157 5.18329 3 6.01172 3C6.84015 3 7.51172 3.67157 7.51172 4.5ZM7.48828 9.5C7.48828 10.3284 6.81671 11 5.98828 11C5.15985 11 4.48828 10.3284 4.48828 9.5C4.48828 8.67157 5.15985 8 5.98828 8C6.81671 8 7.48828 8.67157 7.48828 9.5ZM5.98828 16C6.81671 16 7.48828 15.3284 7.48828 14.5C7.48828 13.6716 6.81671 13 5.98828 13C5.15985 13 4.48828 13.6716 4.48828 14.5C4.48828 15.3284 5.15985 16 5.98828 16Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default SvgComponent;
