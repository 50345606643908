import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Fragment, useEffect, useState } from 'react';
import Button from '../../common/Button';
import RbacActions from '../../constants/RbacActions';
import usePermissions from '../../hooks/usePermissions';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { checkout, fetchBillingInfo } from './actions';

function CheckoutForm() {
	const dispatch = useAppDispatch();
	const lightMode = useAppSelector(state => state.auth.userInfo.lightMode);
	const allowBillingEdit = usePermissions({
		actions: [RbacActions['Billing/Edit']],
	});
	const [pending, setPending] = useState(false);
	const [OPTIONS, setOptions] = useState({});
	const stripe = useStripe();
	const elements = useElements();

	useEffect(() => {
		setOptions({
			style: {
				base: {
					fontSize: '14px',
					color: lightMode ? '#111112' : '#F2F2FB',
				},
				invalid: {
					color: lightMode ? '#EA6D65' : '#E85B52',
				},
			},
		});
	}, [lightMode]);

	const CARDNUMBER_OPTIONS = {
		...OPTIONS,
		placeholder: 'Card Number',
	};

	function handleSubmit(e: any) {
		e.preventDefault();
		if (!stripe || !elements) {
			return;
		}
		setPending(true);
		const cardElement = elements.getElement(CardNumberElement) as any;
		stripe
			.createToken(cardElement)
			.then(({ token }) => {
				return dispatch(checkout((token as any).id));
			})
			.then(() => {
				return dispatch(fetchBillingInfo());
			})
			.then(() => {
				setPending(false);
			})
			.catch(() => {
				setPending(false);
			});
	}

	return (
		<form className="billing__form" onSubmit={handleSubmit}>
			<div className="billing__form__header">Add credit card</div>
			{allowBillingEdit ? (
				<Fragment>
					<div className="billing__form__field billing__form__field--card">
						<CardNumberElement options={CARDNUMBER_OPTIONS} />
					</div>
					<div className="billing__form__field">
						<CardExpiryElement options={OPTIONS} />
					</div>
					<div className="billing__form__field">
						<CardCvcElement options={OPTIONS} />
					</div>
					<div className="billing__form__actions">
						<Button loading={pending} onClick={handleSubmit}>
							Save
						</Button>
					</div>
				</Fragment>
			) : (
				<div>You do not have permissions to enter a payment method. Please contact your administrator.</div>
			)}
		</form>
	);
}

export default CheckoutForm;
