import { useState } from 'react';

import RadioButton from '../common/RadioButton';

interface RadioButtonStateWrapperProps {
	type: string;
}

const RadioButtonStateWrapper = ({ type }: RadioButtonStateWrapperProps) => {
	const [currentChecked, setCurrentChecked] = useState(-1);

	const onClick = (idx: number) => {
		setCurrentChecked(idx);
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				gap: '8px',
			}}
		>
			{Array(3)
				.fill(0)
				.map((_, idx) => (
					<RadioButton
						key={idx}
						checked={currentChecked === idx}
						onClick={() => onClick(idx)}
						componentType={type}
					/>
				))}
		</div>
	);
};

export default RadioButtonStateWrapper;
