import { useEffect } from 'react';

import { addMessage } from '../common/actions';
import WithPermissions from '../common/WithPermissions';
import MixPanel from '../constants/MixPanel';
import RbacActions from '../constants/RbacActions';
import { track } from '../lib/segment';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { deleteEvent, getEvents, setDeleteEvent, setInfoModalOpen } from './actions';
import AddEvent from './AddEvent';
import EmptyState from './EmptyState';
import InfoModal from './InfoModal';
import TimelineChart from './TimelineChart';
import DeleteConfirmation from './TimelineChart/DeleteConfirmation';

const Timeline = () => {
	const dispatch = useAppDispatch();
	const { events, addEventModalTab: activeTab, toDelete, infoModalOpen } = useAppSelector(state => state.timeline);

	useEffect(() => {
		if (!events || events.length === 0) {
			dispatch(getEvents());
		}
	}, []);

	const handleDelete = () => {
		dispatch(deleteEvent(toDelete!.eventId));
		dispatch(addMessage(`Event "${toDelete?.name}" has been deleted.`));
		dispatch(setDeleteEvent(undefined));

		track(MixPanel.Events.TimelineEventDelete, {
			'Event Name': toDelete?.name,
		});
	};

	const handleCancel = () => {
		dispatch(setDeleteEvent(undefined));
	};

	const handleCloseInfoModal = () => {
		dispatch(setInfoModalOpen(false));
	};

	return (
		<WithPermissions actions={[RbacActions['Events/View']]} showMessage={true}>
			<div className="timeline">
				{events.length === 0 && <EmptyState />}
				<TimelineChart />
				<WithPermissions actions={[RbacActions['Events/Edit']]}>
					<AddEvent activeTab={activeTab} />
				</WithPermissions>
				<DeleteConfirmation isOpen={!!toDelete} handleDelete={handleDelete} handleCancel={handleCancel} />
				<InfoModal isOpen={infoModalOpen} onClose={handleCloseInfoModal} />
			</div>
		</WithPermissions>
	);
};

export default Timeline;
