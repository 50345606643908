import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { setEnterpriseId } from './actions';

class Accounts extends Component {
	render() {
		const { enterprises, onEnterpriseSet } = this.props;
		return (
			<div className="accounts">
				<div className="accounts__inner">
					<div className="accounts__title">Choose Account</div>
					{enterprises.length === 0 && (
						<div className="accounts__message">
							Your account is not properly configured. Please contact your administrator.
						</div>
					)}
					<ul className="accounts__list">
						{enterprises.map(({ enterpriseId, enterpriseName }) => (
							<li
								key={enterpriseId}
								data-test={`enterprise-${enterpriseId}`}
								onClick={onEnterpriseSet.bind(null, enterpriseId, true)}
							>
								{enterpriseName}
							</li>
						))}
					</ul>
				</div>
			</div>
		);
	}
}

Accounts.defaultProps = {
	enterprises: [],
};

Accounts.propTypes = {
	enterprises: PropTypes.array,
	onEnterpriseSet: PropTypes.func,
};

function mapStateToProps(state) {
	return {
		enterprises: state.account.enterprises,
	};
}

const dispatchProps = {
	onEnterpriseSet: setEnterpriseId,
};

export default connect(mapStateToProps, dispatchProps)(Accounts);
