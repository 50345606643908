import { useState } from 'react';

import ChartTypes from '../../constants/ChartTypes';
import MixPanel from '../../constants/MixPanel';
import { DashboardReport, Report } from '../../dashboard/models';
import { showDeleteReportModal } from '../../dashboard/view/actions';
import { track } from '../../lib/segment';
import { useAppDispatch } from '../../store/hooks';
import ChartEmptyState from './ChartEmptyState';
import ChartPicker from './ChartPicker';

interface ChartProps {
	dashboard: any;
	report: Report;
	dashboardReport: DashboardReport;
	isLocked?: boolean;
}

const defaultProps = {
	data: [],
};

export default function Chart({ dashboard, report, dashboardReport, isLocked }: ChartProps & typeof defaultProps) {
	const dispatch = useAppDispatch();

	const textChartType = dashboardReport?.configuration?.find((c: any) => c.name === 'chartType')?.value;
	const { dashboardName } = dashboard;

	const { generated, generateError, generateNoData, generatePending, generateInvalidVariation, generateNotAllowed } =
		report;

	const { reportId, name: title } = dashboardReport;

	const isTextChart = textChartType === ChartTypes.Text;
	const isInsightsChart = textChartType === ChartTypes.InsightsText;

	const [isHovered, setIsHovered] = useState(false);
	function handleDelete() {
		track(MixPanel.Events.EditDashboardDeleteReportClick, {
			'Dashboard Name': dashboardName,
			'Report Name': title,
		});
		dispatch(showDeleteReportModal(reportId));
	}

	const isEmpty =
		!isTextChart &&
		!isInsightsChart &&
		(generatePending || generateError || generateNoData || generateInvalidVariation || !generated);

	return (
		<div
			className="editor__chart"
			id={`chart-${dashboardReport?.reportId}`}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<ChartEmptyState
				generated={generated}
				generateError={generateError}
				generateInvalidVariation={generateInvalidVariation}
				generateNoData={generateNoData}
				generateNotAllowed={generateNotAllowed}
				generatePending={generatePending}
				isEmptyState={isEmpty}
				onDelete={handleDelete}
				title={title}
				isTextChart={isTextChart || isInsightsChart}
			>
				<ChartPicker
					dashboard={dashboard}
					report={report}
					dashboardReport={dashboardReport}
					isLocked={isLocked}
					isHovered={isHovered}
				/>
			</ChartEmptyState>
		</div>
	);
}

Chart.defaultProps = defaultProps;
