import axios from 'axios';
import { push, replace } from 'connected-react-router';
import moment from 'moment';
import qs from 'qs';
import ActionTypes from '../../constants/ActionTypes';
import ChartTypes from '../../constants/ChartTypes';
import MixPanel from '../../constants/MixPanel';
import Urls from '../../constants/Urls';
import errorHandler from '../../lib/errorHandler';
import { track } from '../../lib/segment';
import { YAxisType } from '../../types';
import { ExploreChartUrlState } from './types';

export function setActiveLabel(label: string) {
	return {
		type: ActionTypes.SetActiveLabel,
		label,
	};
}

export function clearActiveLabel() {
	return {
		type: ActionTypes.ClearActiveLabel,
	};
}

export function toggleLabel(label: string) {
	return {
		type: ActionTypes.ToggleLabel,
		label,
	};
}

export function toggleLockedSelection(label: string) {
	return {
		type: ActionTypes.ToggleLockedSelection,
		label,
	};
}

export function clearSelection() {
	return {
		type: ActionTypes.ClearSelection,
	};
}

export function setChartType(chartType: ChartTypes) {
	return (dispatch: any, getState: any) => {
		const { state, search } = getState().router.location;
		const queryObject: any = qs.parse(search, {
			ignoreQueryPrefix: true,
			arrayLimit: 10000,
		});

		queryObject.chart.chartType = chartType;
		let url = '/explore?';
		const string = qs.stringify(queryObject, {
			arrayFormat: 'indices',
			encodeValuesOnly: true,
		});
		url += string;
		dispatch(replace(url, { source: 'explore' }));

		if (queryObject.ct || queryObject.from) {
			delete queryObject.ct;
			delete queryObject.from;
			let url = '/explore?';
			const queryString = qs.stringify(queryObject, {
				encodeValuesOnly: true,
				arrayFormat: 'repeat',
			});
			url += queryString;
			dispatch(replace(url, { source: state.source }));
		}

		dispatch({
			type: ActionTypes.SetChartType,
			chartType,
		});
	};
}

export function fetchBenchmarkData(benchmarkId: string, query: any) {
	return async (dispatch: any) => {
		dispatch({
			type: ActionTypes.FetchBenchmarkDataPending,
			benchmarkId,
		});
		return axios
			.post(`${Urls.BenchmarkApi}benchmark/query`, query)
			.then(response => {
				const { data } = response;
				if (data.length === 0) {
					dispatch({
						type: ActionTypes.FetchBenchmarkDataNoData,
						benchmarkId,
					});
				} else {
					dispatch({
						type: ActionTypes.FetchBenchmarkDataFulfilled,
						benchmarkId,
						data: data[0].level1Results,
					});
				}
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.FetchBenchmarkDataRejected,
					benchmarkId,
				});
			});
	};
}

export function setBenchmark(benchmark: any) {
	return (dispatch: any, getState: any) => {
		const { search } = getState().router.location;
		const queryObject: any = qs.parse(search, {
			ignoreQueryPrefix: true,
			arrayLimit: 10000,
		});

		queryObject.chart.benchmark = {
			query: benchmark.query,
			benchmarkId: benchmark.benchmarkId,
			benchmarkName: benchmark.benchmarkName,
			attributes: benchmark.attributes,
			data: benchmark.data,
		};
		let url = '/explore?';
		const string = qs.stringify(queryObject, {
			arrayFormat: 'indices',
			encodeValuesOnly: true,
		});
		url += string;
		dispatch(replace(url, { source: 'explore' }));

		dispatch({
			type: ActionTypes.SetBenchmark,
			benchmark,
		});
		const { benchmarkId, data, query } = benchmark;
		if (!data) {
			dispatch(fetchBenchmarkData(benchmarkId, query));
		}
	};
}

export function removeBenchmark() {
	return (dispatch: any, getState: any) => {
		const { search } = getState().router.location;
		const queryObject: any = qs.parse(search, {
			ignoreQueryPrefix: true,
			arrayLimit: 10000,
		});

		if (queryObject.chart.benchmark) {
			delete queryObject.chart.benchmark;
		}

		let url = '/explore?';
		const string = qs.stringify(queryObject, {
			arrayFormat: 'indices',
			encodeValuesOnly: true,
		});
		url += string;
		dispatch(replace(url, { source: 'explore' }));

		dispatch({
			type: ActionTypes.RemoveBenchmark,
		});
	};
}

export function setHidePie(hidePie: boolean) {
	return {
		type: ActionTypes.SetHidePie,
		hidePie,
	};
}

export function openEventsModal() {
	return (dispatch: any) => {
		dispatch({
			type: ActionTypes.OpenEventsModal,
		});
	};
}

export function closeEventsModal() {
	return (dispatch: any) => {
		dispatch({
			type: ActionTypes.CloseEventsModal,
		});
	};
}

export function toggleChartEvent(eventId: string) {
	return (dispatch: any) => {
		dispatch({
			type: ActionTypes.ToggleChartEvent,
			eventId,
		});
	};
}

export function addAllChartEvents(allEvents: any) {
	return (dispatch: any) => {
		dispatch({
			type: ActionTypes.AddAllChartEvents,
			allEvents,
		});
	};
}

export function setChartEvents(eventIds: string[]) {
	return (dispatch: any) => {
		dispatch({
			type: ActionTypes.SetChartEvents,
			eventIds,
		});
	};
}

export function removeAllChartEvents() {
	return (dispatch: any) => {
		dispatch({
			type: ActionTypes.RemoveAllChartEvents,
		});
	};
}

export function clearMetricOverlay() {
	return (dispatch: any) => {
		dispatch({
			type: ActionTypes.ClearMetricOverlay,
		});
	};
}

export function setYAxisType(yAxisType: YAxisType) {
	return (dispatch: any, getState: any) => {
		const { state, search } = getState().router.location;

		const queryObject = qs.parse(search, {
			ignoreQueryPrefix: true,
			// @ts-ignore
			arrayFormat: 'repeat',
			comma: false,
		});

		queryObject.chart = {
			...(queryObject.chart as qs.ParsedQs[]),
			yAxisType,
		};
		let url = '/explore?';
		const string = qs.stringify(queryObject, {
			arrayFormat: 'indices',
			encodeValuesOnly: true,
		});
		url += string;
		dispatch(replace(url, { source: state?.source || 'explore' }));

		dispatch({
			type: ActionTypes.SetYAxisType,
			payload: yAxisType,
		});
	};
}

export function setIndexType(indexType: 'indexed' | 'percentage') {
	return (dispatch: any, getState: any) => {
		const { state, search } = getState().router.location;

		const queryObject: any = qs.parse(search, {
			ignoreQueryPrefix: true,
			// @ts-ignore
			arrayFormat: 'repeat',
			comma: false,
		});

		queryObject.chart.indexType = indexType;
		let url = '/explore?';
		const string = qs.stringify(queryObject, {
			arrayFormat: 'indices',
			encodeValuesOnly: true,
		});
		url += string;
		dispatch(replace(url, { source: state?.source || 'explore' }));

		dispatch({
			type: ActionTypes.SetIndexType,
			payload: indexType,
		});
	};
}

export function navigate(navProps: any) {
	return (dispatch: any, getState: any) => {
		let { mainSegments, filterSegment, attributes, interval, intervalCount, startDate, endDate, showSubtable } =
			getState().explore.filter;
		const { lifecycle, analysisType, dates, currentIndex } = getState().explore.chart;

		const navPropKeys = Object.keys(navProps);

		const mixPanelObject = {};
		mainSegments = mainSegments.map((s: any) => {
			const navPropKey = navPropKeys.find(npk => npk.toLowerCase() === s.name.replace(/ /g, '').toLowerCase());
			const newSegment = {
				name: s.name,
				// @ts-ignore
				values: [navProps[navPropKey]],
			};

			const attribute = attributes.find(
				(a: any) =>
					a.attributeName.replace(/ /g, '').toLowerCase() ===
					// @ts-ignore
					navPropKey.toLowerCase()
			);
			if (attribute) {
				const attributeValue = attribute.attributeValues.find(
					// @ts-ignore
					av => av.value === navProps[navPropKey]
				);
				if (attributeValue) {
					// @ts-ignore
					mixPanelObject[s.name] = attributeValue.text;
				}
			}
			return newSegment;
		});

		if (filterSegment) {
			const attribute = attributes.find((a: any) => a.attributeName === filterSegment.name);
			if (attribute) {
				const attributeValue = attribute.attributeValues.find(
					(av: any) => av.value === filterSegment.values[0]
				);
				if (attributeValue) {
					// @ts-ignore
					mixPanelObject[filterSegment.name] = attributeValue.text;
				}
			}
		}

		const mixPanelProps = {
			lifecycle,
			analysisType,
			...mixPanelObject,
		};

		track(MixPanel.Events.ExploreSegmentNav, mixPanelProps);

		const date = moment.utc(dates[currentIndex]).format('YYYY/MM/DD');

		const queryObject = {
			filter: {
				mainSegments,
				filterSegment,
				date,
				interval,
				intervalCount,
				startDate,
				endDate,
				showSubtable,
			},
			chart: {
				analysisType,
				lifecycle,
			},
			subtable: {},
			dashboard: {
				...getState().explore.dashboardReport,
			},
		};
		const queryString = qs.stringify(queryObject, {
			encodeValuesOnly: true,
		});

		dispatch(push(`/explore?${queryString}`));
		window.scrollTo(0, 0);
	};
}

export function setIsSettingsOpen(isSettingsOpen: boolean) {
	return {
		type: ActionTypes.SetIsSettingsOpen,
		isSettingsOpen,
	};
}

export function syncExploreChartState(chart: ExploreChartUrlState) {
	return {
		type: ActionTypes.SyncExploreChartState,
		payload: chart,
	};
}
