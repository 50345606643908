import { MdDelete, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

interface AccordionProps {
	label: string;
	className?: string;
	children: React.ReactNode;
	expanded: boolean;
	setExpanded: (expanded: boolean) => void;
	remove?: () => void;
}

Accordion.defaultProps = {
	className: '',
};

export default function Accordion({ label, className, children, expanded, setExpanded, remove }: AccordionProps) {
	return (
		<div className={`ds-accordion ${className}`}>
			<div className="ds-accordion__label">
				<div className="ds-accordion__label__left">{label}</div>
				<div className="ds-accordion__label__right">
					<MdDelete className="icon" size={16} style={{ marginRight: 8 }} onClick={remove} />
					{expanded ? (
						<MdKeyboardArrowUp className="icon" size={20} onClick={() => setExpanded(false)} />
					) : (
						<MdKeyboardArrowDown className="icon" size={20} onClick={() => setExpanded(true)} />
					)}
				</div>
			</div>
			{expanded && <div className="ds-accordion__detail">{children}</div>}
		</div>
	);
}
