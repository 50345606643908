import * as Tooltip from '@radix-ui/react-tooltip';
import { max, min } from 'd3-array';
import { NumberValue, scaleLinear } from 'd3-scale';
import { curveMonotoneX, line } from 'd3-shape';
import produce from 'immer';
import sortBy from 'lodash/sortBy';
import { Fragment, useMemo, useRef, useState } from 'react';
import { Animate, NodeGroup } from 'react-move';

import AnalysisTypes from '../constants/AnalysisTypes';
import CanvasFonts from '../constants/CanvasFonts';
import { X_AXIS_PADDING } from '../constants/ChartSettings';
import Constants from '../constants/Constants';
import FontFamilies from '../constants/FontFamilies';
import inlineFont from '../constants/InlineFont';
import MixPanel from '../constants/MixPanel';
import { getFormattedDate, getIsActive, getIsFaded, getYAxisRangeValues, getYAxisValues } from '../lib/chartUtils';
import cn from '../lib/cn';
import getFormattedValue from '../lib/getFormattedValue';
import getInterpolator from '../lib/getInterpolator';
import measureText from '../lib/measureText';
import precisionRound from '../lib/precisionRound';
import { track } from '../lib/segment';
import { IndexType, ISegmentData, YAxisType } from '../types';
import ChartTooltip from './ChartTooltip';
import YAxisBar from './YAxisBar';

const AXIS_PADDING = 18;
const PADDING = 16;
const RADIUS = 4;
const ARROW_WIDTH = 8;
const ARROW_HEIGHT = 6;
const XAXIS_FONT_SIZE = 11;
const MIN_VALUE_SPACE = 17;

interface ILineChartProps {
	analysisType?: any;
	columnProps?: any;
	currentIndex?: number;
	data?: ISegmentData[];
	dataOverlayProp?: any;
	dataProp?: any;
	dates?: Date[];
	employeeCountHidden?: any;
	hasInvalidValue?: any;
	height?: any;
	hoveredItem?: string;
	indexType?: IndexType;
	interval?: any;
	lifecycle?: any;
	metricOverlayLifecycle: string;
	onHover?: any;
	onHoverEnd?: any;
	onToggle?: any;
	overlayAnalysisType: AnalysisTypes;
	overlayData?: ISegmentData[];
	overlayIndexType: IndexType;
	overlayYAxisType: YAxisType;
	reportId?: any;
	selectCurrentTime?: any;
	selection: string[];
	title?: any;
	width?: any;
	yAxisType: YAxisType;
}

function LineChart(props: ILineChartProps) {
	const {
		analysisType,
		columnProps,
		currentIndex,
		dataOverlayProp,
		dataProp,
		dates,
		employeeCountHidden,
		hasInvalidValue,
		height,
		hoveredItem,
		indexType,
		interval,
		lifecycle,
		metricOverlayLifecycle,
		onHover,
		onHoverEnd,
		onToggle,
		overlayAnalysisType,
		overlayData = [],
		overlayIndexType,
		overlayYAxisType,
		reportId,
		selectCurrentTime,
		selection,
		title,
		width,
		yAxisType,
	} = props;
	let { data = [] } = props;
	const bodyRef = useRef<SVGGElement>(null);
	const [hoverIndex, setHoverIndex] = useState<number>();
	const [hoverLabel, setHoverLabel] = useState<string>();

	if (!data?.length || !dates?.length) {
		return null;
	}

	const data2 = useMemo(() => {
		return data.filter((d: any) => !d.isEqualityIndex && !d.isAttritionIndex);
	}, [data]);
	const overlayData2 = useMemo(() => {
		return overlayData.filter((d: any) => !d.isEqualityIndex && !d.isAttritionIndex);
	}, [overlayData]);
	const singleSegment = data2.length === 1;
	const isSomeActive = getIsActive([...data, ...overlayData], selection, hoveredItem);
	const isLinearRegressionGeneral =
		analysisType === AnalysisTypes.LinearRegression || overlayAnalysisType === AnalysisTypes.LinearRegression;
	const isIndexed = overlayData?.length
		? analysisType === AnalysisTypes.Index || overlayAnalysisType === AnalysisTypes.Index
		: analysisType === AnalysisTypes.Index;
	const isIndexType = overlayData?.length
		? indexType === IndexType.Indexed || overlayIndexType === IndexType.Indexed
		: indexType === IndexType.Indexed;
	const showIndexLine = isSomeActive && (isLinearRegressionGeneral || (isIndexed && isIndexType));

	const isNonIndexActive = getIsActive([...data2, ...overlayData2], selection, hoveredItem);

	const getDataProp = (isOverlay: boolean) => (isOverlay ? dataOverlayProp : dataProp);

	// Compute maxValue and valueWidth
	const { valueWidth } = useMemo(() => {
		let max = '';
		data.forEach((d: any) => {
			const seriesItem = d.series[dates.length - 1];
			if (seriesItem) {
				const value = getFormattedValue(analysisType, [seriesItem[getDataProp(d.isOverlay)]] as any as number);
				if (value.length > max.length) {
					max = value;
				}
			}
		});
		const maxTextSize = measureText(max, singleSegment ? CanvasFonts.Medium24 : CanvasFonts.Medium13);
		return { valueWidth: maxTextSize.width + 8 };
	}, [data, dates, analysisType, dataProp, dataOverlayProp, singleSegment]);

	const { xScale, yScale, overlayYScale, yAxisRangeValues, overlayYAxisRangeValues } = useMemo(() => {
		const minArray = data.map((d: any) => min(d.series, (s: any) => precisionRound(s[dataProp], 1)));
		const maxArray = data.map((d: any) => max(d.series, (s: any) => precisionRound(s[dataProp], 1)));
		const minValue = min(minArray as any as number[]) as number;
		const maxValue = max(maxArray as any as number[]) as number;
		const minOverlayArray = data.map((d: any) => min(d.series, (s: any) => precisionRound(s[dataOverlayProp], 1)));
		const maxOverlayArray = data.map((d: any) => max(d.series, (s: any) => precisionRound(s[dataOverlayProp], 1)));
		const minOverlayValue = min(minOverlayArray as any as number[]) as number;
		const maxOverlayValue = max(maxOverlayArray as any as number[]) as number;

		let yAxisValues: [number, number] = [0, 0];
		let overlayYAxisValues: [number, number] = [0, 0];

		if (
			analysisType === overlayAnalysisType &&
			((overlayAnalysisType !== AnalysisTypes.Index && overlayYAxisType === yAxisType) ||
				(overlayAnalysisType === AnalysisTypes.Index && overlayIndexType === indexType))
		) {
			const minOverallValue = min([minValue, minOverlayValue]);
			const maxOverallValue = max([maxValue, maxOverlayValue]);

			yAxisValues = getYAxisValues({
				analysisType,
				indexType,
				lifecycle,
				maxValue: maxOverallValue!,
				minValue: minOverallValue!,
				yAxisType,
			});

			overlayYAxisValues = getYAxisValues({
				analysisType: overlayAnalysisType,
				indexType: overlayIndexType,
				lifecycle: metricOverlayLifecycle,
				maxValue: maxOverallValue!,
				minValue: minOverallValue!,
				yAxisType: overlayYAxisType,
			});
		} else {
			yAxisValues = getYAxisValues({
				analysisType,
				indexType,
				lifecycle,
				maxValue,
				minValue,
				yAxisType,
			});

			overlayYAxisValues = getYAxisValues({
				analysisType: overlayAnalysisType,
				indexType: overlayIndexType,
				lifecycle: metricOverlayLifecycle,
				maxValue: maxOverlayValue,
				minValue: minOverlayValue,
				yAxisType: overlayYAxisType,
			});
		}

		const yAxisRangeValues = getYAxisRangeValues(yAxisValues, yAxisType);

		const xScale = scaleLinear()
			.domain([dates[0], dates[dates.length - 1]])
			.range([64, width - PADDING - valueWidth]);

		const yScale = scaleLinear()
			.domain(yAxisValues)
			.range([height - 2 * PADDING, 2 * PADDING]);

		const overlayYAxisRangeValues = getYAxisRangeValues(overlayYAxisValues, overlayYAxisType);

		const overlayYScale = scaleLinear()
			.domain(overlayYAxisValues)
			.range([height - 2 * PADDING, 2 * PADDING]);

		return { xScale, yScale, overlayYScale, yAxisRangeValues, overlayYAxisRangeValues };
	}, [
		data,
		dataOverlayProp,
		dataProp,
		dates,
		height,
		metricOverlayLifecycle,
		overlayAnalysisType,
		overlayData,
		overlayIndexType,
		overlayYAxisType,
		valueWidth,
		width,
	]);

	// Compute valueArray
	const valueArray = useMemo(() => {
		let valueArray: any[] = [];
		data2.forEach((d: any) => {
			const { color, active, selected, series, isOverlay } = d;
			const seriesItem = typeof currentIndex !== 'undefined' && series[currentIndex];
			if (seriesItem) {
				const value = seriesItem[getDataProp(isOverlay)];
				const dataYAxisType = isOverlay ? overlayYAxisType : yAxisType;

				const labelValue =
					dataYAxisType === YAxisType.Count
						? seriesItem.totalCount || seriesItem.includedCount
						: seriesItem[getDataProp(isOverlay)];

				const { date } = seriesItem;
				const valueText =
					dataYAxisType === YAxisType.Count
						? employeeCountHidden
							? ''
							: labelValue
						: getFormattedValue(
								isOverlay
									? overlayIndexType === IndexType.Percentage
										? AnalysisTypes.Percentage
										: overlayAnalysisType
									: indexType === IndexType.Percentage
									? AnalysisTypes.Percentage
									: analysisType,
								labelValue
						  );
				const valueY = yScale(precisionRound(value, 1)) + 5;
				valueArray.push({
					label: d.label,
					valueText,
					valueY,
					date,
					color,
					active,
					selected,
				});
			}
		});

		valueArray = sortBy(valueArray, va => va.valueY);

		let setBackward = false;
		for (let i = 1; i < valueArray.length; i++) {
			const { valueY } = valueArray[i];
			const { valueY: prevValueY } = valueArray[i - 1];
			if (valueY - prevValueY < MIN_VALUE_SPACE) {
				valueArray[i].valueY = prevValueY + MIN_VALUE_SPACE;
				if (valueArray[i].valueY > height - 2 * PADDING - AXIS_PADDING) {
					setBackward = true;
					break;
				}
			}
		}
		if (setBackward) {
			for (let i = valueArray.length - 2; i >= 0; i--) {
				const { valueY } = valueArray[i];
				const { valueY: prevValueY } = valueArray[i + 1];
				if (prevValueY - valueY < MIN_VALUE_SPACE) {
					valueArray[i].valueY = prevValueY - MIN_VALUE_SPACE;
				}
			}
		}

		return valueArray;
	}, [
		analysisType,
		currentIndex,
		data2,
		employeeCountHidden,
		height,
		indexType,
		overlayAnalysisType,
		overlayIndexType,
		overlayYAxisType,
		yAxisType,
		yScale,
	]);

	const isFaded = (item: any) => getIsFaded(item, isNonIndexActive, selection, hoveredItem);

	function getTextAnchor(index: number) {
		if (index === 0) {
			return 'start';
		} else if (!!dates?.length && index === dates.length - 1) {
			return 'end';
		} else {
			return 'middle';
		}
	}

	function getCursor(index: number) {
		return index === currentIndex ? 'undefined' : 'pointer';
	}

	function getFill(index: number) {
		return index === currentIndex ? '#f2f2f2' : 'rgba(242,242,242,.70)';
	}

	function getStyle(index: number) {
		return {
			fontSize: XAXIS_FONT_SIZE,
			fontFamily: index === currentIndex ? FontFamilies.Medium : FontFamilies.Regular,
		};
	}

	function getPoints(data: NumberValue) {
		const x = xScale(data);
		const y = height - AXIS_PADDING - PADDING + 1;
		return `${x} ${y + ARROW_HEIGHT}, ${x - ARROW_WIDTH / 2} ${y}, ${x + ARROW_WIDTH / 2} ${y}`;
	}

	function handleTimeSelect(index: number, e: React.MouseEvent<SVGGElement, MouseEvent>) {
		e.stopPropagation();
		if (index !== currentIndex) {
			track(MixPanel.Events.DashboardReportLineGraphXAxisClick, {
				'Report Name': title,
			});
			setHoverIndex(undefined);
			selectCurrentTime(index);
		}
	}

	function handleToggle(label: string) {
		track(MixPanel.Events.DashboardReportLineGraphLineSegmentClick, {
			'Report Name': title,
		});
		onToggle(label);
	}

	function handleMouseOverOnPoint(label: string, index?: number) {
		setHoverIndex(index);
		setHoverLabel(label);
	}

	function handleMouseOutOnPoint() {
		setHoverIndex(undefined);
		setHoverLabel(undefined);
	}

	function handleClickOnPoint(_: any, index: number, e: React.MouseEvent<SVGGElement, MouseEvent>) {
		e.stopPropagation();
		if (index !== currentIndex) {
			track(MixPanel.Events.DashboardReportLineGraphXAxisClick, {
				'Report Name': title,
			});

			setHoverIndex(undefined);
			setHoverLabel(undefined);
			selectCurrentTime(index);
		}
	}

	if (hasInvalidValue) {
		data = produce(data, (draftState: any) => {
			draftState.forEach((ds: any) => {
				for (let i = ds.series.length - 1; i >= 0; i--) {
					if (ds.series[i][getDataProp(ds.isOverlay)] === Constants.InvalidValue) {
						ds.series.splice(i, 1);
					}
				}
			});
		});
	}

	const minArray: any[] = [];
	const maxArray: any[] = [];
	data.forEach((d: any) => {
		minArray.push(min(d.series, (s: any) => precisionRound(s[getDataProp(d.isOverlay)], 1)));
		maxArray.push(max(d.series, (s: any) => precisionRound(s[getDataProp(d.isOverlay)], 1)));
	});
	const minValue = min(minArray);
	const maxValue = max(maxArray);

	const { showCondensed, indexes } = useMemo(() => {
		const bandwidthCalc = (width - 2 * PADDING - valueWidth) / (dates.length - 1);
		let intervalLength =
			measureText(getFormattedDate(dates[0], interval), CanvasFonts.Medium11).width * 1.5 + PADDING;
		const skipCount = Math.ceil(intervalLength / bandwidthCalc) - 1;

		const indexes = dates.map((_: any, i: number) => i).filter((_: any, i: number) => i % (skipCount + 1) === 0);

		const showCondensed = skipCount > 0;

		return { showCondensed, indexes };
	}, [width, valueWidth, dates, interval, currentIndex]);

	const avgValue = (minValue + maxValue) / 2;

	const getYScale = (scale: number) =>
		analysisType === AnalysisTypes.LinearRegression || analysisType === AnalysisTypes.Index
			? yScale(scale)
			: overlayYScale(scale);
	const getYScaleByOverlay = (isOverlay: boolean, scale: number) =>
		isOverlay ? overlayYScale(scale) : yScale(scale);
	const lineFn = (isOverlay: boolean, prop: any) =>
		line()
			.x((d: any) => xScale(d.date))
			.y((d: any) => (isOverlay ? overlayYScale(precisionRound(d[prop], 1)) : yScale(precisionRound(d[prop], 1))))
			.curve(curveMonotoneX) || undefined;
	const getLineFnByOverlay = (isOverlay: boolean) => lineFn(isOverlay, getDataProp(isOverlay));

	const currentIndexScale = useMemo(() => {
		return typeof currentIndex !== undefined ? xScale(dates[currentIndex!]) : 0;
	}, [currentIndex, dates, xScale]);

	return (
		<Tooltip.Provider>
			<svg className="linechart" data-export-type="linechart" width={width} height={height} id={reportId}>
				<defs>
					<style type="text/css">{inlineFont}</style>
				</defs>
				{isLinearRegressionGeneral && (
					<rect
						className="linechart__linear-regression-background"
						x={0}
						y={getYScale(0)}
						width={width}
						height={height - getYScale(0)}
					/>
				)}
				{isIndexed && isIndexType && (
					<rect
						className="linechart__index-background"
						x={0}
						y={getYScale(1)}
						width={width}
						height={height - getYScale(1)}
					/>
				)}
				<clipPath id={`${reportId}_main`}>
					<rect x={0} y={0} width={currentIndexScale} height={height} />
				</clipPath>
				<clipPath id={`${reportId}_fade`}>
					<rect x={currentIndexScale} y={0} width={width - currentIndexScale} height={height} />
				</clipPath>
				<NodeGroup
					keyAccessor={(d: any) => d}
					data={dates}
					start={() => ({
						opacity: 0,
					})}
					enter={() => ({
						opacity: [1],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
					update={() => ({
						opacity: [0, 1],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
					interpolation={getInterpolator}
				>
					{nodes => (
						<g className="linechart__body" ref={bodyRef}>
							{nodes.map(({ state, data, key }, index) => (
								<Fragment key={key}>
									<line
										className={cn('linechart__body__outline', {
											'linechart__body__outline--selected':
												currentIndex === index || hoverIndex === index,
										})}
										x1={xScale(data)}
										y1={PADDING}
										x2={xScale(data)}
										y2={height - AXIS_PADDING}
										opacity={state.opacity}
									/>
									<line
										x1={xScale(data) + 2}
										y1={PADDING}
										x2={xScale(data)}
										y2={height - AXIS_PADDING}
										stroke="transparent"
										strokeWidth={5}
										cursor="pointer"
										onMouseOver={setHoverIndex.bind(null, index)}
										onMouseOut={setHoverIndex.bind(null, undefined)}
										onClick={handleTimeSelect.bind(null, index)}
									/>
								</Fragment>
							))}
						</g>
					)}
				</NodeGroup>
				<NodeGroup
					keyAccessor={(d: any) => d}
					data={dates}
					start={() => ({
						opacity: 0,
					})}
					enter={() => ({
						opacity: [1],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
					update={() => ({
						opacity: [0, 1],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
					interpolation={getInterpolator}
				>
					{nodes => (
						<g
							className="linechart__x-labels"
							style={{
								display: 'none',
							}}
						>
							{nodes.map(({ state, data, key }, index) => {
								if (index === currentIndex || index === hoverIndex || !indexes.includes(index)) {
									return null;
								}
								const text = getFormattedDate(data, interval, showCondensed);
								const x = xScale(data);

								return (
									<text
										className={cn('linechart__x-labels__text', {
											'linechart__x-labels__text--selected': currentIndex === index,
										})}
										key={key}
										x={x}
										y={height - PADDING - AXIS_PADDING + ARROW_HEIGHT + 14}
										textAnchor={getTextAnchor(index)}
										opacity={state.opacity}
									>
										{text}
									</text>
								);
							})}
						</g>
					)}
				</NodeGroup>
				<NodeGroup
					keyAccessor={(d: any) => d}
					data={dates}
					start={() => ({
						opacity: 0,
					})}
					enter={() => ({
						opacity: [1],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
					update={() => ({
						opacity: [0, 1],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
					interpolation={getInterpolator}
				>
					{nodes => (
						<g
							className="linechart__x-labels"
							style={{
								display: 'none',
							}}
						>
							{nodes.map(({ state, data, key }, index) => {
								const text = getFormattedDate(data, interval, showCondensed);
								const x = xScale(data);
								let width = 12;
								let xRect = x - width / 2;
								if (index === currentIndex || index === hoverIndex || indexes.includes(index)) {
									width = measureText(text, CanvasFonts.Regular12).width + 16;
									xRect = x - width / 2;
									if (index === 0) {
										xRect = x - 8;
									}
									if (index === dates.length - 1) {
										xRect = x - width + 8;
									}
								}
								return (
									<Fragment key={key}>
										{index === currentIndex && (
											<rect
												className="linechart__x-labels__rect"
												x={xRect}
												y={height - PADDING - AXIS_PADDING + ARROW_HEIGHT + 1}
												width={width}
												height={19}
												rx={8}
												opacity={state.opacity}
											/>
										)}
										{index === hoverIndex && (
											<rect
												className="linechart__x-labels__rect--selected"
												x={xRect}
												y={height - PADDING - AXIS_PADDING + ARROW_HEIGHT + 1}
												width={width}
												height={19}
												rx={8}
												opacity={state.opacity}
											/>
										)}
										{(index === currentIndex || index === hoverIndex) && (
											<text
												className={cn('linechart__x-labels__text', {
													'linechart__x-labels__text--selected': currentIndex === index,
												})}
												x={x}
												y={height - PADDING - AXIS_PADDING + ARROW_HEIGHT + 14}
												textAnchor={getTextAnchor(index)}
												opacity={state.opacity}
											>
												{text}
											</text>
										)}
										{index === currentIndex && (
											<polygon
												className="linechart__x-labels__arrow"
												points={getPoints(data)}
												opacity={state.opacity}
											/>
										)}
									</Fragment>
								);
							})}
						</g>
					)}
				</NodeGroup>
				<NodeGroup
					keyAccessor={(d: any) => d}
					data={dates}
					start={() => ({
						opacity: 0,
					})}
					enter={() => ({
						opacity: [1],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
					update={() => ({
						opacity: [0, 1],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
					interpolation={getInterpolator}
				>
					{nodes => (
						<g
							className="linechart__x-labels"
							style={{
								display: 'none',
							}}
						>
							{nodes.map(({ state, data, key }, index) => {
								if (index !== hoverIndex) {
									return null;
								}
								const text = getFormattedDate(data, interval, showCondensed);
								const x = xScale(data);
								const width = measureText(text, CanvasFonts.Regular11).width + 16;
								let xRect = x - width / 2;
								if (index === 0) {
									xRect = x - 8;
								}
								if (index === dates.length - 1) {
									xRect = x - width + 8;
								}

								return (
									<Fragment key={key}>
										<rect
											className="linechart__x-labels__hover-rect"
											x={xRect}
											y={height - PADDING - AXIS_PADDING + ARROW_HEIGHT + 1}
											width={width}
											height={19}
											rx={8}
											opacity={state.opacity}
										/>
										<text
											className="linechart__x-labels__hover-text"
											x={x}
											y={height - PADDING - AXIS_PADDING + ARROW_HEIGHT + 14}
											textAnchor={getTextAnchor(index)}
											opacity={state.opacity}
											cursor={getCursor(index)}
											fill={getFill(index)}
											style={getStyle(index)}
										>
											{text}
										</text>
									</Fragment>
								);
							})}
						</g>
					)}
				</NodeGroup>
				<NodeGroup
					keyAccessor={(d: any) => d}
					data={dates}
					start={() => ({
						opacity: 0,
					})}
					enter={() => ({
						opacity: [1],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
					update={() => ({
						opacity: [0, 1],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
					interpolation={getInterpolator}
				>
					{nodes => (
						<g className="linechart__x-labels">
							{nodes.map(({ state, data, key }, index) => {
								const text = getFormattedDate(data, interval, showCondensed);
								const x = xScale(data);
								let width = 12;
								let xRect = x - width / 2;
								if (index === currentIndex || index === hoverIndex || indexes.includes(index)) {
									width = measureText(text, CanvasFonts.Regular11).width + 16;
									xRect = x - width / 2;
									if (index === 0) {
										xRect = x - 8;
									}
									if (index === dates.length - 1) {
										xRect = x - width + 8;
									}
								}
								return (
									<Fragment key={key}>
										{index !== currentIndex && (
											<rect
												className="linechart__x-labels__rect--transparent"
												x={xRect}
												y={height - PADDING - AXIS_PADDING + ARROW_HEIGHT + 1}
												width={width}
												height={19}
												rx={8}
												opacity={state.opacity}
												cursor="pointer"
												onMouseOver={setHoverIndex.bind(null, index)}
												onMouseOut={setHoverIndex.bind(null, undefined)}
												onClick={handleTimeSelect.bind(null, index)}
											/>
										)}
									</Fragment>
								);
							})}
						</g>
					)}
				</NodeGroup>
				<Animate
					show={showIndexLine}
					start={() => ({
						opacity: 0,
					})}
					enter={() => ({
						opacity: [1],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
					update={() => ({
						opacity: [1],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
					leave={() => ({
						opacity: [0],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
				>
					{state => {
						const indexText = isLinearRegressionGeneral ? 'Index (0%)' : 'Index (1x)';
						const yIndex = isLinearRegressionGeneral ? getYScale(0) : getYScale(1);
						const xIndex = PADDING + measureText(indexText, CanvasFonts.Medium13).width + 8;
						return (
							<Fragment>
								<text
									className="linechart__index-text"
									x={PADDING}
									y={yIndex + 3}
									opacity={state.opacity}
								>
									{indexText}
								</text>
								<line
									className="linechart__index-line"
									x1={xIndex}
									y1={yIndex}
									x2={width - PADDING - valueWidth}
									y2={yIndex}
									opacity={state.opacity}
								/>
							</Fragment>
						);
					}}
				</Animate>
				<NodeGroup
					keyAccessor={d => `${d.label}`}
					data={data2}
					start={(d: any) => {
						const initial = d.series.map(({ date }: { date: Date }) => ({
							[dataProp]: avgValue,
							date,
						}));
						return {
							opacity: 1,
							series: initial,
						};
					}}
					enter={(d: any) => {
						return {
							series: [d.series],
							timing: {
								duration: Constants.AnimationDuration,
								ease: Constants.EasingFn,
							},
						};
					}}
					update={(d: any) => {
						return {
							series: [d.series],
							opacity: [isFaded(d) ? 0.05 : 1],
							timing: {
								duration: Constants.AnimationDuration,
								ease: Constants.EasingFn,
							},
						};
					}}
					interpolation={getInterpolator}
				>
					{nodes => (
						<g className="linechart__lines">
							{nodes.map(
								({ state, data, key }) =>
									!data.isEqualityIndex &&
									!data.isAttritionIndex && (
										<g data-label="line" key={key}>
											<path
												className="linechart__lines__line"
												d={
													getLineFnByOverlay(data.isOverlay)(state.series) as
														| string
														| undefined
												}
												strokeOpacity={state.opacity}
												stroke={data.color}
												clipPath={`url(#${reportId}_main)`}
												onMouseOver={() => onHover(data.label)}
												onMouseOut={() => onHoverEnd(null)}
												onClick={() => handleToggle(data.label)}
												data-label="line-primary"
											/>
											<path
												className="linechart__lines__line"
												d={
													getLineFnByOverlay(data.isOverlay)(state.series) as
														| string
														| undefined
												}
												strokeOpacity={0.05}
												stroke={data.color}
												clipPath={`url(#${reportId}_fade)`}
												data-label="line-secondary"
											/>
										</g>
									)
							)}
						</g>
					)}
				</NodeGroup>
				<NodeGroup
					keyAccessor={d => `${d.label}`}
					data={data2}
					start={(d: any) => {
						const initial = d.series.map(({ date }: { date: Date }) => ({
							[dataProp]: avgValue,
							date,
						}));
						return {
							opacityCircle: 0,
							opacityText: 0,
							series: initial,
						};
					}}
					enter={(d: any) => {
						return {
							opacityCircle: [1],
							opacityText: [1],
							series: [d.series],
							timing: {
								duration: Constants.AnimationDuration,
								ease: Constants.EasingFn,
							},
						};
					}}
					update={(d: any) => {
						return {
							opacityCircle: isFaded(d) ? [0.05] : [1],
							opacityText: isFaded(d) ? [0.24] : [1],
							timing: {
								duration: Constants.AnimationDuration,
								ease: Constants.EasingFn,
							},
						};
					}}
					interpolation={getInterpolator}
				>
					{nodes => (
						<g className="linechart__circles">
							{nodes.map(({ state, data, key }) => {
								if (data.isEqualityIndex || data.isAttritionIndex) {
									return null;
								}
								const currentDate =
									typeof currentIndex !== 'undefined' &&
									getFormattedDate(dates[currentIndex], interval);
								const item = state.series.find(
									(s: any) => getFormattedDate(s.date, interval) === currentDate
								);
								let anotherItem;
								if (currentIndex !== dates.length - 1) {
									const lastDate = getFormattedDate(dates[dates.length - 1], interval);
									anotherItem = state.series.find(
										(s: any) => getFormattedDate(s.date, interval) === lastDate
									);
								}
								const cx = xScale(item.date);
								const cy = getYScaleByOverlay(
									data.isOverlay,
									precisionRound(item[getDataProp(data.isOverlay)], 1)
								);

								return (
									<Fragment key={key}>
										{item && (
											<g data-label="data-point-current-index">
												<circle
													className="linechart__circles__circle"
													stroke="var(--color-shade-h4)"
													fill="var(--color-shade-h4)"
													style={{
														pointerEvents: 'none',
													}}
													cx={cx}
													cy={cy}
													r={RADIUS}
													data-label="data-point-mask"
												/>
												<circle
													className="linechart__circles__circle"
													fill={data.color}
													cx={cx}
													cy={cy}
													r={RADIUS}
													// cursor="pointer"
													opacity={state.opacityCircle}
													style={{
														pointerEvents: 'none',
													}}
													data-label="data-point-primary"
												/>
												<Tooltip.Root
													delayDuration={0}
													open={data.label === hoverLabel && hoverIndex === currentIndex}
												>
													<Tooltip.Trigger asChild>
														<circle
															className="linechart__circles__circle"
															fill={data.color}
															cx={cx}
															cy={cy}
															r={RADIUS * 2}
															cursor="pointer"
															opacity={0}
															onMouseOver={() => {
																handleMouseOverOnPoint(data.label, currentIndex);
																onHover(data.label);
															}}
															onMouseOut={() => {
																handleMouseOutOnPoint();
																onHoverEnd();
															}}
															onClick={() => {
																handleToggle(data.label);
															}}
															data-label="data-point-hover-trigger"
														/>
													</Tooltip.Trigger>
													<Tooltip.Portal>
														<Tooltip.Content side="bottom" sideOffset={16}>
															<ChartTooltip
																dataPoint={data}
																seriesItem={item}
																columnProps={columnProps}
																interval={interval}
															/>
														</Tooltip.Content>
													</Tooltip.Portal>
												</Tooltip.Root>
											</g>
										)}
										{anotherItem && (
											<g data-label="data-point-another-item">
												<circle
													className="linechart__circles__circle"
													fill="var(--color-shade-h4)"
													cx={xScale(anotherItem.date)}
													cy={getYScaleByOverlay(
														data.isOverlay,
														precisionRound(anotherItem[getDataProp(data.isOverlay)], 1)
													)}
													r={RADIUS}
												/>
												<circle
													className="linechart__circles__circle"
													stroke={data.color}
													strokeWidth={1}
													fill="var(--color-shade-h4)"
													cx={xScale(anotherItem.date)}
													cy={getYScaleByOverlay(
														data.isOverlay,
														precisionRound(anotherItem[getDataProp(data.isOverlay)], 1)
													)}
													strokeOpacity={0.05}
													r={RADIUS}
													cursor="pointer"
													onClick={handleToggle.bind(null, data.label)}
												/>
											</g>
										)}
										{state.series.map((s: any, i: number) => {
											const cx = xScale(s.date);
											const cy = getYScaleByOverlay(
												data.isOverlay,
												precisionRound(s[getDataProp(data.isOverlay)], 1)
											);

											return i !== currentIndex ? (
												<Tooltip.Root
													open={data.label === hoverLabel && hoverIndex === i}
													key={i}
												>
													<Tooltip.Trigger asChild>
														<g data-label="data-point-tooltip-trigger">
															<circle
																className="linechart__circles__circle"
																data-label="tooltip-item-big"
																stroke={data.color}
																strokeWidth={1}
																fill="var(--color-shade-h4)"
																cx={cx}
																cy={cy}
																r={RADIUS * 2}
																cursor="pointer"
																opacity={0}
																onMouseOver={() => {
																	handleMouseOverOnPoint(data.label, i);
																	onHover(data.label);
																}}
																onMouseOut={() => {
																	handleMouseOutOnPoint();
																	onHoverEnd(data.label);
																}}
																onClick={e => handleClickOnPoint(data.label, i, e)}
															/>
															<circle
																className="linechart__circles__circle"
																data-label="tooltip-item"
																stroke={data.color}
																strokeWidth={1}
																fill="var(--color-shade-h4)"
																cx={cx}
																cy={cy}
																r={RADIUS}
																cursor="pointer"
																opacity={
																	data.label === hoverLabel && hoverIndex === i
																		? 1
																		: i > (currentIndex || -1)
																		? 0.05
																		: state.opacityCircle
																}
																style={{
																	pointerEvents: 'none',
																}}
															/>
														</g>
													</Tooltip.Trigger>
													<Tooltip.Portal>
														<Tooltip.Content side="bottom" sideOffset={16}>
															<ChartTooltip
																dataPoint={data}
																seriesItem={s}
																columnProps={columnProps}
																interval={interval}
															/>
														</Tooltip.Content>
													</Tooltip.Portal>
												</Tooltip.Root>
											) : null;
										})}
									</Fragment>
								);
							})}
						</g>
					)}
				</NodeGroup>
				<YAxisBar
					analysisType={analysisType}
					indexType={indexType}
					isFaded={getIsActive(overlayData2, selection, hoveredItem)}
					xScale={PADDING}
					yAxisRangeValues={yAxisRangeValues}
					yAxisType={yAxisType}
					yScale={yScale}
				/>
				{(analysisType !== overlayAnalysisType ||
					(overlayAnalysisType !== AnalysisTypes.Index && overlayYAxisType !== yAxisType) ||
					(overlayAnalysisType === AnalysisTypes.Index && overlayIndexType !== indexType)) && (
					<YAxisBar
						analysisType={overlayAnalysisType!}
						indexType={overlayIndexType}
						isFaded={getIsActive(data2, selection, hoveredItem)}
						xScale={width - PADDING - X_AXIS_PADDING}
						yAxisRangeValues={overlayYAxisRangeValues}
						yAxisType={
							overlayAnalysisType !== AnalysisTypes.Index ? overlayYAxisType : YAxisType.Percentage
						}
						yScale={overlayYScale}
					/>
				)}
				<NodeGroup
					keyAccessor={(d: any) => {
						return d.label;
					}}
					data={valueArray}
					start={() => {
						return {
							opacity: 0,
						};
					}}
					enter={() => {
						return {
							opacity: [1],
							timing: {
								duration: Constants.AnimationDuration,
								ease: Constants.EasingFn,
							},
						};
					}}
					update={(d: any) => {
						return {
							opacity: isFaded(d) ? [0.24] : [1],
							timing: {
								duration: Constants.AnimationDuration,
								ease: Constants.EasingFn,
							},
						};
					}}
					interpolation={getInterpolator}
				>
					{nodes => (
						<g>
							{nodes.map(({ state, data, key }) => {
								return (
									<text
										key={key}
										x={xScale(data.date) + 8}
										y={data.valueY}
										textAnchor="start"
										opacity={state.opacity}
										fill={data.color}
										cursor="pointer"
										style={{
											fontFamily: FontFamilies.Medium,
											fontSize: singleSegment ? 24 : 13,
										}}
										onMouseOver={() => onHover(data.label)}
										onMouseOut={() => onHoverEnd()}
										onClick={() => handleToggle(data.label)}
									>
										{data.valueText}
									</text>
								);
							})}
						</g>
					)}
				</NodeGroup>
				<line
					className="linechart__outline"
					x1={PADDING}
					y1={height - AXIS_PADDING - PADDING}
					x2={width - PADDING - valueWidth}
					y2={height - AXIS_PADDING - PADDING}
				/>
			</svg>
		</Tooltip.Provider>
	);
}

export default LineChart;
