import axios from 'axios';
import { push } from 'connected-react-router';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import qs from 'qs';
import { Link } from 'react-router-dom';
import * as uuid from 'uuid';

import { addMessage } from '../../common/actions';
import ActionTypes from '../../constants/ActionTypes';
import analysisTypes from '../../constants/AnalysisTypes';
import ChartTypes from '../../constants/ChartTypes';
import Constants from '../../constants/Constants';
import MixPanel from '../../constants/MixPanel';
import Urls from '../../constants/Urls';
import errorHandler from '../../lib/errorHandler';
import getMixPanelObjectForFilters from '../../lib/getMixPanelObjectForFilters';
import objectifyArray from '../../lib/objectifyArray';
import { track } from '../../lib/segment';
import { generate, generateOverlay, getAllSegments, getTemplateFromAnalysisName } from '../../store/actionUtils';
import { getSegmentsFromV1Report, getSegmentsFromV2Report } from '../../store/commonUtils';

function parseDate(dateValue) {
	const quarterMatch = dateValue.match(/^Q([1-4])\s(\d{4})$/);
	if (quarterMatch) {
		const quarter = parseInt(quarterMatch[1], 10);
		const year = parseInt(quarterMatch[2], 10);
		return moment.utc().year(year).quarter(quarter).format('YYYY-MM-DD');
	}

	return moment.utc(dateValue).format('YYYY-MM-DD');
}

function getReport(report, dashboardId) {
	return (dispatch, getState) => {
		const { version, configuration } = report;
		const chartType = configuration.find(c => c.name === 'chartType')?.value;
		if (chartType === ChartTypes.Text || chartType === ChartTypes.InsightsText) {
			return;
		}

		const { attributes: allAttributes } = getState().explore.filter;

		const { reportId, analysisName, attributes, filter, breakdown, analysis, eventIds, isFilterApplied } = report;

		const subAnalyses = analysis?.map(a => ({
			...objectifyArray(a.configuration),
		}));

		const intervalConfig = configuration.find(c => c.name === 'interval');
		const interval = intervalConfig ? intervalConfig.value : Constants.DefaultInterval;
		const startDateConfig = configuration.find(c => c.name === 'startDate');
		const startDate = startDateConfig ? startDateConfig.value : undefined;
		let endDate;
		let intervalCount;
		if (startDate) {
			const endDateConfig = configuration.find(c => c.name === 'endDate');
			endDate = endDateConfig ? endDateConfig.value : undefined;
		} else {
			const intervalCountConfig = configuration.find(c => c.name === 'intervalCount');
			intervalCount = intervalCountConfig ? parseInt(intervalCountConfig.value) : Constants.DefaultIntervalCount;
		}

		let employeeAttribute;
		const employeeAttributeConfig = configuration.find(c => c.name === 'employeeAttribute');
		if (employeeAttributeConfig) {
			employeeAttribute = employeeAttributeConfig.value;
		}

		let date;
		const dateConfig = configuration.find(c => c.name === 'date');
		if (dateConfig) {
			date = parseDate(dateConfig.value);
		}

		const rowFilterConfig = configuration.find(c => c.name === 'rowFilter');
		const rowFilter = rowFilterConfig ? JSON.parse(rowFilterConfig.value) : [];

		let benchmark;
		const benchmarkConfig = configuration.find(c => c.name === 'benchmark');
		if (benchmarkConfig) {
			benchmark = JSON.parse(benchmarkConfig.value);
		}

		const yAxisType = configuration.find(c => c.name === 'yAxisType')?.value;
		const indexType = configuration.find(c => c.name === 'indexType')?.value;
		const subtableChartType = configuration.find(c => c.name === 'subtableChartType')?.value;
		const showSubtable = configuration.find(c => c.name === 'showSubtable')?.value === 'true' ? true : false;
		const overlayAnalysisName = configuration.find(c => c.name === 'overlayAnalysisName')?.value;
		const overlayAnalysisType = configuration.find(c => c.name === 'overlayAnalysisType')?.value;
		const overlayChartType = configuration.find(c => c.name === 'overlayChartType')?.value;
		const overlayColumnProps =
			configuration.find(c => c.name === 'overlayColumnProps')?.value &&
			JSON.parse(configuration.find(c => c.name === 'overlayColumnProps')?.value);
		const overlayDisplay = configuration.find(c => c.name === 'overlayDisplay')?.value === 'true' ? true : false;
		const overlayFilterNames =
			configuration.find(c => c.name === 'overlayFilterNames')?.value &&
			JSON.parse(configuration.find(c => c.name === 'overlayFilterNames')?.value);
		const overlayFilterSegment =
			configuration.find(c => c.name === 'overlayFilterSegment')?.value &&
			JSON.parse(configuration.find(c => c.name === 'overlayFilterSegment')?.value);
		const overlayIndexType = configuration.find(c => c.name === 'overlayIndexType')?.value;
		const overlayMainNames =
			configuration.find(c => c.name === 'overlayMainNames')?.value &&
			JSON.parse(configuration.find(c => c.name === 'overlayMainNames')?.value);
		const overlayMainSegments =
			configuration.find(c => c.name === 'overlayMainSegments')?.value &&
			JSON.parse(configuration.find(c => c.name === 'overlayMainSegments')?.value);
		const overlayYAxisType = configuration.find(c => c.name === 'overlayYAxisType')?.value;

		return dispatch(getTemplateFromAnalysisName(analysisName))
			.then(template => {
				const { variations } = template;
				let mainSegments, filterSegment, breakdownSegment;
				if (version === '2') {
					({ mainSegments, filterSegment, breakdownSegment } = getSegmentsFromV2Report(
						attributes,
						filter,
						breakdown
					));
				} else {
					({ mainSegments, filterSegment, breakdownSegment } = getSegmentsFromV1Report(
						attributes,
						employeeAttribute,
						rowFilter,
						allAttributes,
						variations
					));
				}

				const state = {
					analysisName,
					attributes: allAttributes,
					benchmark,
					breakdownSegment,
					chartType,
					dashboardId,
					date,
					endDate,
					eventIds,
					filterSegment,
					indexType,
					interval,
					intervalCount,
					isFilterApplied,
					mainSegments,
					reportId,
					startDate,
					subAnalyses: subAnalyses || [],
					yAxisType,
					subtableChartType,
					showSubtable,
					overlayAnalysisName,
					overlayAnalysisType,
					overlayChartType,
					overlayColumnProps,
					overlayDisplay,
					overlayFilterNames,
					overlayFilterSegment,
					overlayIndexType,
					overlayMainNames,
					overlayMainSegments,
					overlayYAxisType,
				};

				const actionTypes = {
					reportPending: ActionTypes.GetReportPending,
					reportNoPending: ActionTypes.GetReportNoPending,
					subtablePending: ActionTypes.GetMatrixPending,
					reportRejected: ActionTypes.GetReportRejected,
					subtableRejected: ActionTypes.GetMatrixRejected,
					invalidVariation: ActionTypes.GetReportInvalidVariation,
					invalidAppliedFilterStatus: ActionTypes.SetInvalidAppliedFilterStatus,
					noData: ActionTypes.GetReportNoData,
					subtableFulfilled: ActionTypes.GetMatrixFulfilled,
					reportFulfilled: ActionTypes.GetReportFulfilled,
					setBenchmark: ActionTypes.SetBenchmarkInReport,
					primaryDataFulfilled: ActionTypes.GeneratePrimaryDataFulfilledInDashboard,
				};

				return generate(getState, dispatch, state, actionTypes);
			})
			.catch(error => {
				console.log(error);
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.GetReportRejected,
				});
			});
	};
}

export function getReports(reports, forceGet = false, dashboardId = '') {
	return (dispatch, getState) => {
		const { reports: reportsState } = getState();
		reports.forEach(report => {
			if (forceGet || !reportsState[report.reportId]) {
				dispatch(getReport(report, dashboardId));
			}
		});
	};
}

export function navigateToDashboards() {
	return dispatch => {
		const url = '/';
		dispatch(push(url));
	};
}

export function showDeleteReportModal(reportId) {
	return {
		type: ActionTypes.ShowDeleteReportModal,
		reportId,
	};
}

export function showSelectDashboardModal(dashboardId, reportId) {
	return {
		type: ActionTypes.ShowSelectDashboardModal,
		dashboardId,
		reportId,
	};
}

export function showCreateDashboardModalForCopy() {
	return {
		type: ActionTypes.ShowCreateDashboardModalForCopy,
	};
}

export function closeDeleteReportModal() {
	return {
		type: ActionTypes.CloseDeleteReportModal,
	};
}

export function closeSelectDashboardModal() {
	return {
		type: ActionTypes.CloseSelectDashboardModal,
	};
}

export function closeCreateDashboardModalForCopy() {
	return {
		type: ActionTypes.CloseCreateDashboardModalForCopy,
	};
}

export function deleteReport(dashboard, reportId) {
	return dispatch => {
		dispatch({
			type: ActionTypes.DeleteReportPending,
		});

		const newDashboard = {
			...dashboard,
			lastUpdatedAt: moment.utc().toISOString(),
		};
		newDashboard.reports = dashboard.reports.filter(r => r.reportId !== reportId);

		return axios
			.put(`${Urls.DashboardsApi}dashboard`, { dashboard: newDashboard })
			.then(() => {
				dispatch({
					type: ActionTypes.DeleteReportFulfilled,
					dashboardId: dashboard.dashboardId,
					reportId,
				});
				dispatch(closeDeleteReportModal());
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.DeleteReportRejected,
				});
			});
	};
}

export function checkVault(vaultId) {
	return (dispatch, getState) => {
		const { enterpriseId } = getState().account;

		dispatch({
			type: ActionTypes.CheckVaultPending,
		});

		return axios
			.post(`${Urls.DashboardsApi}vault`, {
				enterpriseId,
				vaultId,
			})
			.then(response => {
				const dashboardId = response.data;
				dispatch({
					type: ActionTypes.CheckVaultFulfilled,
					dashboardId,
				});
				return dashboardId;
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.CheckVaultRejected,
				});
			});
	};
}

export function navigateToEditMode(dashboardId) {
	return (dispatch, getState) => {
		const dashboard = getState().dashboards[dashboardId];
		if (dashboard) {
			dispatch({
				type: ActionTypes.EditDashboard,
				dashboard,
			});
			const url = `/dashboard/${dashboardId}/edit`;
			const scrollPos = window.pageYOffset;
			dispatch(
				push(url, {
					scrollPos,
				})
			);
		}
	};
}

export function setSearchText(searchText) {
	return {
		type: ActionTypes.SetSearchTextInDashboard,
		searchText,
	};
}

export function selectCurrentTime(reportId, currentIndex) {
	return {
		type: ActionTypes.SelectCurrentTimeInReport,
		reportId,
		currentIndex,
	};
}

export function navigate(reportId, navProps, employees, employeeCountType, currentIndex) {
	return (dispatch, getState) => {
		const { attributes } = getState().explore.filter;
		const report = getState().reports[reportId];
		if (!report) {
			return;
		}

		const {
			lifecycle,
			analysisType,
			dates,
			interval,
			intervalCount,
			startDate,
			endDate,
			employeeAttribute,
			filterSegment,
		} = report;

		let { mainSegments, chartType } = report;

		// should be table
		if (chartType === 'Table') {
			chartType = 'Line';
		}

		const navPropKeys = Object.keys(navProps);
		mainSegments = mainSegments.map(ms => {
			const navPropKey = navPropKeys.find(npk => ms.name === npk);
			if (navPropKey) {
				const values = ms.values.filter(v => v === navProps[navPropKey]);
				return {
					name: ms.name,
					values,
				};
			}
			// should not come here!
			return ms;
		});

		const queryObject = {
			filter: {
				mainSegments,
				filterSegment,
				interval,
			},
			chart: {
				lifecycle,
				analysisType,

				chartType,
			},
			subtable: {},
			dashboard: {},
			employeeAttribute,
		};

		if (startDate) {
			queryObject.filter.startDate = startDate;
			queryObject.filter.endDate = endDate;
		} else {
			queryObject.filter.intervalCount = intervalCount;
		}

		queryObject.filter.date = moment.utc(dates[currentIndex]).format('YYYY/MM/DD');

		const allSegments = getAllSegments({
			mainSegments,
			filterSegment,
		});

		const mixPanelObject = getMixPanelObjectForFilters(lifecycle, allSegments, attributes);
		const mixPanelProps = {
			analysisType,
			...mixPanelObject,
		};

		track(MixPanel.Events.DashboardReportTableColumnClick, mixPanelProps);

		let url = '/explore?';
		const queryString = qs.stringify(queryObject, {
			encodeValuesOnly: true,
		});
		url += queryString;
		dispatch(
			push(url, {
				source: 'report',
			})
		);
		window.scrollTo(0, 0);
	};
}

export function copyReportToDashboards(dashboardIds) {
	return (dispatch, getState) => {
		const state = getState();
		const { selectDashboardId, selectReportId } = state.reports;
		const dashboard = state.dashboards[selectDashboardId];
		const report = dashboard.reports.find(r => r.reportId === selectReportId);
		const reportIds = [];
		const promises = [];
		dashboardIds.forEach(dashboardId => {
			const newReport = cloneDeep(report);
			newReport.reportId = uuid.v4();
			reportIds.push({
				from: report.reportId,
				to: newReport.reportId,
			});
			const promise = dispatch(copyReportToDashboard(newReport, dashboardId));
			promises.push(promise);
		});
		Promise.all(promises).then(() => {
			dispatch({ type: ActionTypes.CloneReportData, reportIds });
		});
	};
}

function copyReportToDashboard(report, dashboardId) {
	return (dispatch, getState) => {
		const { enterpriseId } = getState().account;
		const dashboard = getState().dashboards[dashboardId];

		dispatch({ type: ActionTypes.SaveReportPending });

		const newRowIndex = getRowIndex(dashboard.reports)?.toString();

		const dashboardRow = dashboard.reports.filter(
			r => r.configuration.find(c => c.name === 'rowIndex')?.value === newRowIndex
		);

		const newColumnIndex = (dashboardRow.length || 0)?.toString();

		const currRowIndex = report.configuration.find(c => c.name === 'rowIndex');
		const currColumnIndex = report.configuration.find(c => c.name === 'columnIndex');
		currRowIndex.value = newRowIndex;
		currColumnIndex.value = newColumnIndex;

		const columnConfig = report.configuration.find(c => c.name === 'column');
		if (!columnConfig) {
			report.configuration.push({ name: 'column', value: newRowIndex.toString() });
		} else {
			columnConfig.value = newRowIndex.toString();
		}

		const rowConfig = report.configuration.find(c => c.name === 'row');
		if (!rowConfig) {
			report.configuration.push({ name: 'row', value: newColumnIndex.toString() });
		} else {
			rowConfig.value = newColumnIndex.toString();
		}

		return axios
			.put(`${Urls.DashboardsApi}report`, {
				enterpriseId,
				dashboardId,
				report,
			})
			.then(() => {
				dispatch({
					type: ActionTypes.SaveReportFulfilled,
					dashboardId,
					report,
				});
				dispatch(closeSelectDashboardModal());
				dispatch(
					addMessage(
						<span>
							{`Report added to ${dashboard.name}. View in `}
							<Link to={`/dashboard/${dashboardId}`}> Dashboard </Link>
						</span>
					)
				);
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.SaveReportRejected,
				});
			});
	};
}

function getRowIndex(reports = []) {
	let rowIndex = 0;

	reports.forEach(report => {
		const currentRowIndex = report.configuration.find(c => c.name === 'rowIndex')?.value;

		if (currentRowIndex && parseInt(currentRowIndex) > rowIndex) {
			rowIndex = parseInt(currentRowIndex);
		}
	});

	return rowIndex + 1;
}

export function createDashboardAndCopyReport(dashboardName) {
	return (dispatch, getState) => {
		const { enterpriseId } = getState().account;
		const { uid, firstName, lastName } = getState().auth.userInfo;

		dispatch({
			type: ActionTypes.CreateDashboardPending,
		});

		const dashboard = {
			enterpriseId,
			dashboardId: uuid.v4(),
			name: dashboardName,
			reports: [],
			dateAdded: moment.utc().toISOString(),
			lastUpdatedAt: moment.utc().toISOString(),
			createdById: uid,
			createdByName: `${firstName} ${lastName}`,
		};

		return axios
			.put(`${Urls.DashboardsApi}dashboard`, { dashboard })
			.then(() => {
				dispatch({
					type: ActionTypes.CreateDashboardFulfilled,
					dashboard,
				});
				return dispatch(copyReportToDashboards([dashboard.dashboardId]));
			})
			.then(() => {
				return dispatch(closeCreateDashboardModalForCopy());
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.CreateDashboardRejected,
				});
			});
	};
}

export function setRowHeightInReport(reportId, tableRowHeight) {
	return {
		type: ActionTypes.SetRowHeightInReport,
		reportId,
		tableRowHeight,
	};
}

export function setSortCriteriaInReport(reportId, sortCriteria) {
	return {
		type: ActionTypes.SetSortCriteriaInReport,
		reportId,
		sortCriteria,
	};
}

export function setExpandedInReport(reportId, expanded) {
	return {
		type: ActionTypes.SetExpandedInReport,
		reportId,
		expanded,
	};
}

export function navigateFromMatrix(reportId, lifecycle, employeeAttribute, employeeAttributeValue, navProps) {
	return (dispatch, getState) => {
		const { attributes } = getState().explore.filter;
		const report = getState().reports[reportId];
		if (!report) return;

		const { lifecycle, analysisType, interval, employeeAttribute, filterSegment } = report;

		let { breakdownSegment } = report;

		let { mainSegments, date } = report;

		const navPropKeys = Object.keys(navProps);
		mainSegments = mainSegments.map(ms => {
			const navPropKey = navPropKeys.find(npk => ms.name === npk);
			if (navPropKey) {
				const values = ms.values.filter(v => v === navProps[navPropKey]);
				return {
					name: ms.name,
					values,
				};
			}
			// should not come here!
			return ms;
		});

		breakdownSegment = {
			name: breakdownSegment.name,
			values: [employeeAttributeValue],
		};

		date = moment.utc(date).format('YYYY/MM/DD');

		const queryObject = {
			filter: {
				mainSegments,
				filterSegment,
				breakdownSegment,
				interval,
				date,
			},
			chart: {
				analysisType,

				lifecycle,
			},
			subtable: {},
			dashboard: {},

			employeeAttribute,
		};

		const allSegments = getAllSegments({
			mainSegments,
			filterSegment,
			breakdownSegment,
		});

		const mixPanelObject = getMixPanelObjectForFilters(lifecycle, allSegments, attributes);
		const mixPanelProps = {
			analysisType,
			...mixPanelObject,
		};

		track(MixPanel.Events.DashboardReportSubtableColumnClick, mixPanelProps);

		let url = '/explore?';
		const queryString = qs.stringify(queryObject, {
			encodeValuesOnly: true,
		});
		url += queryString;
		dispatch(
			push(url, {
				source: 'report',
			})
		);
		window.scrollTo(0, 0);
	};
}

export function setActiveLabel(label, reportId) {
	return {
		type: ActionTypes.SetActiveLabel,
		label,
		reportId,
	};
}

export function clearActiveLabel(label, reportId) {
	return {
		type: ActionTypes.ClearActiveLabel,
		label,
		reportId,
	};
}

export function toggleLabel(label, reportId) {
	return {
		type: ActionTypes.ToggleLabel,
		label,
		reportId,
	};
}

export function toggleLockedSelection(label, reportId) {
	return {
		type: ActionTypes.ToggleLockedSelection,
		label,
		reportId,
	};
}

export function clearSelection(reportId) {
	return {
		type: ActionTypes.ClearSelection,
		reportId,
	};
}

export function showDashboardFilters() {
	return {
		type: ActionTypes.ShowDashboardFilters,
	};
}

export function setShowHiddenReportsNotification(show) {
	return {
		type: ActionTypes.SetShowHiddenReportsNotification,
		show,
	};
}

export function navigateToExplore({ dashboardId, reportId, editMode = false, rowIndex }) {
	return (dispatch, getState) => {
		const dashboard = getState().dashboards[dashboardId];
		if (!dashboard) return;
		let url = '/explore?';

		const report = dashboard.reports.find(r => r.reportId === reportId);

		let benchmark;
		let subtableChartType = ChartTypes.Matrix;
		let queryObject = {
			dashboard: {
				editMode,
				dashboardId,
				reportId,
				rowIndex,
			},
		};

		if (report) {
			const reportData = getState().reports[reportId];

			const { analysis } = report;
			const {
				analysisType,
				dates,
				currentIndex,
				date,
				interval,
				intervalCount,
				startDate,
				endDate,
				benchmark: reportBenchmark,
				mainSegments,
				filterSegment,
				breakdownSegment,
				breakdownNames,
				filterNames,
				eventIds,
				yAxisType,
				indexType,
				analysisName,
				analysisDisplayName,
				mainNames,
				allowIndexToggle,
				allowYAxisToggle,
				columnProps,
				lifecycle,
			} = reportData;

			benchmark = reportBenchmark;
			const formattedDate = dates
				? moment.utc(dates[currentIndex]).format('YYYY-MM-DD')
				: moment.utc(date).format('YYYY-MM-DD');

			const c = report.configuration;

			const chartType = c.find(c => c.name === 'chartType')?.value;
			const showSubtable = c.find(c => c.name === 'showSubtable')?.value === 'true' ? true : false;
			subtableChartType = c.find(c => c.name === 'subtableChartType')?.value;

			const overlayAnalysisName = c.find(c => c.name === 'overlayAnalysisName')?.value;
			const overlayAnalysisDisplayName = c.find(c => c.name === 'overlayAnalysisDisplayName')?.value;
			const overlayAnalysisType = c.find(c => c.name === 'overlayAnalysisType')?.value;
			const overlayChartType = c.find(c => c.name === 'overlayChartType')?.value;
			const showOverlaySubtable = c.find(c => c.name === 'overlayShowSubtable')?.value === 'true' ? true : false;
			const overlayColumnProps =
				c.find(c => c.name === 'overlayColumnProps')?.value &&
				JSON.parse(c.find(c => c.name === 'overlayColumnProps')?.value);
			const overlayDisplay = c.find(c => c.name === 'overlayDisplay')?.value === 'true' ? true : false;
			const overlayFilterNames =
				c.find(c => c.name === 'overlayFilterNames')?.value &&
				JSON.parse(c.find(c => c.name === 'overlayFilterNames')?.value);
			const overlayFilterSegment =
				c.find(c => c.name === 'overlayFilterSegment')?.value &&
				JSON.parse(c.find(c => c.name === 'overlayFilterSegment')?.value);
			const overlayIndexType = c.find(c => c.name === 'overlayIndexType')?.value;
			const overlayMainNames =
				c.find(c => c.name === 'overlayMainNames')?.value &&
				JSON.parse(c.find(c => c.name === 'overlayMainNames')?.value);
			const overlayMainSegments =
				c.find(c => c.name === 'overlayMainSegments')?.value &&
				JSON.parse(c.find(c => c.name === 'overlayMainSegments')?.value);
			const overlayYAxisType = c.find(c => c.name === 'overlayYAxisType')?.value;
			const overlaySubAnalyses =
				c.find(c => c.name === 'overlaySubAnalyses')?.value &&
				JSON.parse(c.find(c => c.name === 'overlaySubAnalyses')?.value);

			const subAnalyses = analysis.map(a => ({
				...objectifyArray(a.configuration),
			}));

			const isSubtable = showSubtable || !!breakdownSegment;

			queryObject = {
				filter: {
					analysisName: isSubtable ? lifecycle : analysisName,
					analysisDisplayName,
					breakdownNames,
					breakdownSegment,
					date,
					endDate,
					filterNames,
					filterSegment,
					interval,
					intervalCount,
					mainNames,
					overlayAnalysisDisplayName,
					overlayAnalysisName,
					overlayAnalysisType,
					overlayChartType,
					overlayDisplay,
					overlayFilterNames,
					overlayColumnProps,
					overlayFilterSegment,
					overlayIndexType,
					overlayYAxisType,
					overlayMainNames,
					overlayMainSegments,
					showOverlaySubtable,
					overlaySubAnalyses,
					showSubtable: isSubtable,
					startDate,
					subAnalyses,
					mainSegments,
					isSavedSearch: false,
				},
				chart: {
					lifecycle: analysisName,
					yAxisType,
					indexType,
					chartType,
					allowIndexToggle,
					allowYAxisToggle,
					eventIds,
					columnProps,
					currentIndex,
					benchmark: !!benchmark && {
						query: benchmark.query,
						benchmarkId: reportId,
						benchmarkName: benchmark.benchmarkName,
						data: benchmark.data,
					},
				},
				subtable: {
					subtableChartType: subtableChartType || chartType,
					analysisType: isSubtable ? analysisType : undefined,
					date: isSubtable ? formattedDate : undefined,
					columnProps: isSubtable ? columnProps : undefined,
					interval: isSubtable ? interval : undefined,
					allowChartToggle: isSubtable ? true : undefined,
				},
				dashboard: {
					editMode,
					dashboardId,
					reportId,
					rowIndex,
				},
			};

			if (analysisType === analysisTypes.Arithmetic || breakdownSegment) {
				queryObject.filter.date = formattedDate;
			} else if (startDate) {
				queryObject.filter.startDate = startDate;
				queryObject.filter.endDate = endDate;
			} else {
				queryObject.filter.intervalCount = intervalCount;
			}
		}

		const queryString = qs.stringify(queryObject, {
			encodeValuesOnly: true,
		});

		url += queryString;

		dispatch(
			push(url, {
				source: 'report',
			})
		);
	};
}
