import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getPackages } from './actions';
import PackageFragment from './PackageFragment';

export function PackageDetail({ history, match }: RouteComponentProps<{ id: string }>) {
	const dispatch = useAppDispatch();
	const id = match.params.id;
	const { loaded, data } = useAppSelector(state => state.datastudio.packages);
	const shouldFetch = !loaded;
	const packageObj = data?.find((c: any) => c.packageId === id);

	useEffect(() => {
		if (shouldFetch) {
			dispatch(getPackages());
		}
	}, [shouldFetch]);

	function handleEdit() {
		history.push(`/datastudio/packages/${match.params.id}/edit`);
	}

	return packageObj ? (
		<div className="ds-container">
			<Button componentType={ButtonTypes.type.PRIMARY} classes={['ds-edit']} onClick={handleEdit}>
				Edit
			</Button>
			<PackageFragment packageObj={packageObj} />
		</div>
	) : null;
}

export default PackageDetail;
