import validator from 'email-validator';
import PropTypes from 'prop-types';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { replaceRoute } from '../common/actions';
import Button from '../common/Button';
import TextField from '../common/TextField';
import MixPanel from '../constants/MixPanel';
import { page } from '../lib/segment';
import { setWinked } from '../routes/actions';
import WaitPage from '../routes/WaitPage';
import { getSSOOptions } from './actions';

function Login({ getSSOOptions, replaceRoute, setWinked, location, loginPending, loggedIn, firstName, lastName }) {
	const [email, setEmail] = useState(getEmail());
	const [wink, setWink] = useState(false);
	const redirectToEditUser = !firstName && !lastName;

	useEffect(() => {
		page(MixPanel.Pages.Welcome);
	}, []);

	useEffect(() => {
		if (loggedIn) {
			setWink(true);
			setTimeout(() => {
				setWink(false);
				const redirectUrl = getRedirectUrl();
				if (redirectToEditUser) {
					replaceRoute(`/edituser?redirectUrl=${encodeURIComponent(redirectUrl)}`);
				} else {
					replaceRoute(redirectUrl);
				}
				setWinked();
			}, 3000);
		}
	}, [loggedIn, redirectToEditUser]);

	function getEmail() {
		let email = '';
		const { search } = location;
		const query = qs.parse(search, { ignoreQueryPrefix: true });
		if (query.email) {
			email = decodeURIComponent(query.email);
		}
		return email;
	}

	function getRedirectUrl() {
		let redirectUrl = '/';
		const { search } = location;
		const query = qs.parse(search, { ignoreQueryPrefix: true });
		if (query.redirectUrl) {
			redirectUrl = decodeURIComponent(query.redirectUrl);
		}
		return redirectUrl;
	}

	function handleSubmit(e) {
		e.preventDefault();
		getSSOOptions(email, getRedirectUrl());
	}

	if (loginPending || wink) {
		return <WaitPage wink={wink} />;
	}

	return (
		<div className="login">
			<div className="login__title">Log in to Dandi</div>
			<div className="login__form">
				<form onSubmit={e => e.preventDefault()}>
					<TextField value={email} onChange={e => setEmail(e.target.value)} placeholder="Type Email" />
					<Button
						type="submit"
						large
						disabled={!validator.validate(email)}
						classes={['mt24']}
						onClick={handleSubmit}
					>
						Log in
					</Button>
				</form>
			</div>
		</div>
	);
}

Login.propTypes = {
	getSSOOptions: PropTypes.func,
	replaceRoute: PropTypes.func,
	setWinked: PropTypes.func,
	loginPending: PropTypes.bool,
	loggedIn: PropTypes.bool,
	location: PropTypes.object,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
};

function mapStateToProps(state) {
	return {
		loginPending: state.auth.loginPending,
		loggedIn: state.auth.loggedIn,
		firstName: state.auth.userInfo.firstName,
		lastName: state.auth.userInfo.lastName,
	};
}

const dispatchProps = {
	getSSOOptions,
	replaceRoute,
	setWinked,
};

export default connect(mapStateToProps, dispatchProps)(Login);
