import { useEffect, useState } from 'react';

import Button from '../../common/Button';
import Dropdown from '../../common/Dropdown';
import TextField from '../../common/TextField';
import ButtonTypes from '../../constants/ButtonTypes';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { createAccount } from './actions';
import { fetchHRIS } from './slice';

export default function CreateAccount() {
	const [enterpriseName, setEnterpriseName] = useState('');
	const [hris, setHris] = useState('');
	const dispatch = useAppDispatch();
	const hrisOptions = useAppSelector(state => state.datastudio.account.hris);

	useEffect(() => {
		dispatch(fetchHRIS());
	}, []);

	function handleCreate() {
		dispatch(createAccount({ enterpriseName, hris }));
	}

	return (
		<div className="ds-container">
			<div className="ds-label">Enterprise Name</div>
			<div className="ds-input">
				<TextField
					value={enterpriseName}
					onChange={e => setEnterpriseName(e.target.value)}
					placeholder="Enterprise name"
				/>
			</div>
			<div className="ds-label">HRIS</div>
			<div className="ds-input">
				<Dropdown
					placeholder="Select"
					options={hrisOptions || []}
					selectedOption={hris}
					onClick={(value: any) => setHris(value)}
				/>
			</div>
			<div className="mt16">
				<Button componentType={ButtonTypes.type.PRIMARY} onClick={handleCreate}>
					Create Account
				</Button>
			</div>
		</div>
	);
}
