import AntModal from 'antd/lib/modal';
import { ReactNode } from 'react';

interface ModalProps {
	visible: boolean;
	width?: number;
	footer?: any;
	closable?: boolean;
	maskClosable?: boolean;
	onCancel: () => void;
	zIndex?: number;
	destroyOnClose?: boolean;
	centered?: boolean;
	children: ReactNode;
}

const Modal = ({
	visible,
	width,
	footer,
	closable,
	maskClosable,
	onCancel,
	zIndex,
	destroyOnClose,
	centered,
	children,
}: ModalProps) => {
	return (
		<AntModal
			open={visible}
			width={width}
			footer={footer}
			closable={closable}
			maskClosable={maskClosable}
			onCancel={onCancel}
			zIndex={zIndex}
			destroyOnClose={destroyOnClose}
			centered={centered}
		>
			{children}
		</AntModal>
	);
};

export default Modal;
