import produce from 'immer';

import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import ExclusionRule from './ExclusionRule';

export default function ExclusionRules({ exclusionRules, setConfigurationVariables, index }: any) {
	function handleNew() {
		setConfigurationVariables((configurationVariables: any) =>
			produce(configurationVariables, (draftState: any) => {
				draftState[index].exclusionRules.push({
					exclusionType: 'LIMIT_MAIN',
					limitMain: 0,
				});
			})
		);
	}

	return (
		<>
			<div className="ds-label" style={{ marginBottom: 8 }}>
				<span style={{ marginRight: 16 }}>Exclusion rules</span>
				<Button componentType={ButtonTypes.type.SECONDARY} onClick={handleNew} large={false}>
					Add new
				</Button>
			</div>
			{exclusionRules.map((er: any, i: number) => (
				<ExclusionRule
					key={i}
					index={index}
					exclusionRule={er}
					setConfigurationVariables={setConfigurationVariables}
					innerIndex={i}
				/>
			))}
		</>
	);
}
