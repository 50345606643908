import { useContext } from 'react';

import Button from '../../common/Button';
import WithPermissions from '../../common/WithPermissions';
import RbacActions from '../../constants/RbacActions';
import { AdminHeaderContext } from './AdminHeader';
import Header from './Header';
import Sync from './Sync';

const UsersHeader = () => {
	const { handleShowCreate } = useContext(AdminHeaderContext);

	return (
		<Header>
			<Header.Title></Header.Title>
			<Header.Buttons>
				<Sync />
				<WithPermissions actions={[RbacActions['Roles/Edit']]}>
					<Button onClick={handleShowCreate}>Create User</Button>
				</WithPermissions>
			</Header.Buttons>
		</Header>
	);
};

export default UsersHeader;
