import { RouteComponentProps, withRouter } from 'react-router';

import Button from '../../../common/Button';
import ButtonTypes from '../../../constants/ButtonTypes';
import './styles.scss';

const ROUTE_PREFIX = '/datastudio/survey/';

function HeaderNav({ history, location }: RouteComponentProps) {
	function navigate(routePart: string) {
		if (!isActivated(routePart)) {
			history.push(ROUTE_PREFIX + routePart);
		}
	}

	function isActivated(routePart: string) {
		return location.pathname.includes(ROUTE_PREFIX + routePart);
	}

	return (
		<div className="survey-header__nav">
			<Button
				componentType={ButtonTypes.type.SECONDARY}
				activated={isActivated('templates')}
				onClick={() => navigate('templates')}
			>
				Campaign Templates
			</Button>
			<Button
				componentType={ButtonTypes.type.SECONDARY}
				activated={isActivated('questions')}
				onClick={() => navigate('questions')}
			>
				Questions
			</Button>
			<Button
				componentType={ButtonTypes.type.SECONDARY}
				activated={isActivated('categories')}
				onClick={() => navigate('categories')}
			>
				Categories
			</Button>
		</div>
	);
}

export default withRouter(HeaderNav);
