import { IconProps } from './types';

const ArrowUpRight = ({ size, color }: IconProps) => {
	return (
		<svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="1" y="10" width="22" height="4" fill={color} />
		</svg>
	);
};

export default ArrowUpRight;
