import { useEffect } from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';

import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getConfigurations } from './actions';

function Configurations({ history }: RouteComponentProps<{}>) {
	const dispatch = useAppDispatch();
	const { data = [] } = useAppSelector(state => state.datastudio.configurations);
	useEffect(() => {
		dispatch(getConfigurations());
	}, []);

	function handleAdd() {
		history.push(`/datastudio/config/new`);
	}

	function handleDuplicate(configId: string) {
		history.push(`/datastudio/config/new?copyFrom=${configId}`);
	}

	return (
		<div className="ds-container">
			<Button componentType={ButtonTypes.type.PRIMARY} classes={['ds-add']} onClick={handleAdd}>
				Add
			</Button>
			<table className="ds-table ds-table--config">
				<tbody>
					{data.map((c, i) => (
						<tr key={i}>
							<td>
								<NavLink
									className="link--bright"
									to={`/datastudio/config/${c.templateConfigurationId}`}
								>
									{c.templateName}
								</NavLink>
							</td>
							<td>
								<Button
									componentType={ButtonTypes.type.SECONDARY}
									onClick={() => handleDuplicate(c.templateConfigurationId)}
								>
									Duplicate
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

export default Configurations;
