import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { goBack } from 'connected-react-router';
import cloneDeep from 'lodash/cloneDeep';

import Urls from '../../../constants/Urls';
import { AsyncThunkConfig } from '../../../store/store';
import { QuestionArchiveState, QuestionEditState, SavableQuestion, UiQuestion } from './types';

export const questionTypeOptions = ['Single-select', 'Multi-select'];
export const inputTypeOptions: any = {
	'Single-select': ['Radio', 'Search'],
	'Multi-select': ['Check', 'Search'],
};

const initialQuestion: UiQuestion = {
	title: '',
	description: '',
	fieldName: '',
	values: [],
	questionType: questionTypeOptions[0],
	inputType: inputTypeOptions[questionTypeOptions[0]][0],
	enterpriseId: '',
	globalCategoryId: '',
};

export const saveQuestion = createAsyncThunk<void, SavableQuestion, AsyncThunkConfig>(
	'survey/saveQuestion',
	async (question, { dispatch }) => {
		if (question.globalQuestionId) {
			await axios.put(`${Urls.SurveyStudioApi}questions/update`, question);
		} else {
			await axios.put(`${Urls.SurveyStudioApi}questions/create`, question);
		}
		dispatch(goBack());
	}
);

export const duplicateQuestion = createAsyncThunk<void, SavableQuestion, AsyncThunkConfig>(
	'survey/duplicateQuestion',
	async question => {
		await axios.put(`${Urls.SurveyStudioApi}questions/create`, {
			...question,
			title: `Copy of ${question.title}`,
		});
	}
);

export const archiveQuestion = createAsyncThunk<void, QuestionArchiveState, AsyncThunkConfig>(
	'survey/archiveQuestion',
	async ({ question, shouldArchive }) => {
		const questionToSave: SavableQuestion = {
			globalQuestionId: question.globalQuestionId,
			title: question.title,
			fieldName: question.fieldName,
			valueType: question.valueType,
			values: question.values,
			globalCategoryId: question.globalCategoryId,
			uiElements: cloneDeep(question.meta.uiElements),
			enterpriseId: question.enterpriseId,
			isPrivate: question.isPrivate,
		};
		const archiveValue = shouldArchive ? 'true' : 'false';
		const elem = questionToSave.uiElements.find(u => u.key === 'archive');
		if (elem) {
			elem.value = archiveValue;
		} else {
			questionToSave.uiElements.push({
				key: 'archive',
				value: archiveValue,
			});
		}

		try {
			await axios.put(`${Urls.SurveyStudioApi}questions/update`, questionToSave);
		} catch (error: any) {
			throw new Error(error.response.data);
		}
	}
);

export const deleteQuestion = createAsyncThunk<void, string, AsyncThunkConfig>(
	'survey/deleteQuestion',
	async globalQuestionId => {
		try {
			await axios.put(`${Urls.SurveyStudioApi}questions/delete`, {
				globalQuestionId,
			});
		} catch (error: any) {
			throw new Error(error.response.data);
		}
	}
);

export const questionEditSlice = createSlice({
	name: 'editQuestion',
	initialState: {
		questionToEdit: initialQuestion,
	} as QuestionEditState,
	reducers: {
		setTitle: (state, action) => {
			state.questionToEdit.title = action.payload;
		},
		setDescription: (state, action) => {
			state.questionToEdit.description = action.payload;
		},
		setGlobalCategoryId: (state, action) => {
			state.questionToEdit.globalCategoryId = action.payload;
		},
		setFieldName: (state, action) => {
			state.questionToEdit.fieldName = action.payload;
		},
		setQuestionType: (state, action) => {
			state.questionToEdit.questionType = action.payload;
		},
		setInputType: (state, action) => {
			state.questionToEdit.inputType = action.payload;
		},
		setValues: (state, action) => {
			state.questionToEdit.values = action.payload;
		},
		addAnswer: state => {
			state.questionToEdit.values.push({
				value: '',
				score: 0,
			});
		},
		updateValue: (state, action) => {
			const { index, value } = action.payload;
			state.questionToEdit.values[index].value = value;
		},
		updateScore: (state, action) => {
			const { index, score } = action.payload;
			state.questionToEdit.values[index].score = score;
		},
		updateDesc: (state, action) => {
			const { index, desc } = action.payload;
			state.questionToEdit.values[index].description = desc;
		},
		deleteAnswer: (state, action) => {
			state.questionToEdit.values.splice(action.payload, 1);
		},
		moveUp: (state, action) => {
			const values = state.questionToEdit.values;
			const i = action.payload;
			[values[i - 1], values[i]] = [values[i], values[i - 1]];
		},
		moveDown: (state, action) => {
			const values = state.questionToEdit.values;
			const i = action.payload;
			[values[i], values[i + 1]] = [values[i + 1], values[i]];
		},
		setEnterpriseId: (state, action) => {
			state.questionToEdit.enterpriseId = action.payload;
		},
		clearData: state => {
			state.questionToEdit = initialQuestion;
		},
	},
	extraReducers: builder => {
		builder.addCase(saveQuestion.fulfilled, state => {
			state.pending = false;
		});
		builder.addCase(saveQuestion.pending, state => {
			state.error = false;
			state.pending = true;
		});
		builder.addCase(saveQuestion.rejected, state => {
			state.error = true;
			state.pending = false;
		});
	},
});

export const {
	setTitle,
	setDescription,
	setGlobalCategoryId,
	setFieldName,
	setQuestionType,
	setInputType,
	addAnswer,
	updateValue,
	updateScore,
	updateDesc,
	deleteAnswer,
	moveUp,
	moveDown,
	setEnterpriseId,
	clearData,
	setValues,
} = questionEditSlice.actions;
export default questionEditSlice.reducer;
