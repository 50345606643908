import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={17} height={15} viewBox="0 0 17 15" fill="none" {...props}>
		<path
			fill="currentColor"
			d="M12.551 14.667H4.186V5.5L9.41.34l.369.368c.076.076.14.173.191.292a.824.824 0 0 1 .078.327v.131L9.195 5.5h5.792c.348 0 .66.137.934.412.275.275.412.586.412.934v1.026a1.582 1.582 0 0 1-.093.497l-2.275 5.394a1.48 1.48 0 0 1-.577.644c-.27.173-.55.26-.837.26Zm-7.532-.834h7.532a.776.776 0 0 0 .36-.096.618.618 0 0 0 .281-.32L15.5 8V6.846a.5.5 0 0 0-.144-.368.5.5 0 0 0-.369-.145H8.16l.965-4.55-4.106 4.073v7.977ZM4.186 5.5v.833H1.333v7.5h2.853v.834H.5V5.5h3.686Z"
		/>
	</svg>
);
export default SvgComponent;
