function SvgComponent(props: any) {
	return (
		<svg
			width={21}
			height={21}
			viewBox="0 0 21 21"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M3.83073 15.5002L12.1641 15.5002"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.83073 10.5002L17.1641 10.5002"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.83594 5.50024L8.83594 5.50024"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default SvgComponent;
