import { setExpandedInReport, setSortCriteriaInReport } from '../dashboard/view/actions';
import { toggleExpansion } from '../explore/detail/actions';
import Table from '../explore/detail/Table';
import cn from '../lib/cn';
import getChartData from '../lib/getChartData';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import ScrollableChartArea from './ScrollableChartArea';

const MatrixWrapper = ({
	reportId,
	onHover,
	onHoverEnd,
	onToggle,
	hoveredItem,
	selection,
	status,
}: {
	reportId?: string;
	onHover?: any;
	onHoverEnd?: any;
	onToggle?: any;
	hoveredItem?: any;
	selection?: any;
	status?: any;
	[key: string]: any;
}) => {
	const dispatch = useAppDispatch();
	const {
		generatePending,
		lifecycle,
		generateNoData,
		expanded,
		analysisType,
		employeeAttribute,
		diversityAttributes,
		employeeAttributes,
		summary,
		data,
		showGroupSummary,
		groupSummary,
		columnProps,
		attributeName,
		sortCriteria,
	} = useAppSelector(state => getChartData(state, reportId));

	function handleSort(columnName: string) {
		let ascending = true;
		if (columnName === sortCriteria.columnName) {
			ascending = !sortCriteria.ascending;
		}

		dispatch(
			setSortCriteriaInReport(reportId, {
				columnName,
				ascending,
			})
		);
	}

	function handleToggleExpansion() {
		if (reportId) {
			dispatch(setExpandedInReport(reportId, !expanded));
		} else {
			dispatch(toggleExpansion(employeeAttribute));
		}
	}

	return (
		<div className={cn('flex-1 flex flex-col h-full justify-between')}>
			<ScrollableChartArea>
				<div className={cn('flex-1 relative')}>
					<div className={cn('relative w-full h-[250px] wide:h-[360px]', reportId && '!h-full')}>
						<Table
							lifecycle={lifecycle}
							analysisType={analysisType}
							employeeAttribute={employeeAttribute}
							attributeName={attributeName}
							employeeAttributes={employeeAttributes}
							diversityAttributes={diversityAttributes}
							data={data}
							summary={summary}
							showGroupSummary={showGroupSummary}
							groupSummary={groupSummary}
							pending={generatePending}
							emptyState={generateNoData}
							expanded={expanded}
							toggleExpansion={handleToggleExpansion}
							onSort={handleSort}
							sortCriteria={sortCriteria}
							navigateToDetail={() => {}}
							columnProps={columnProps}
							onHover={onHover}
							onHoverEnd={onHoverEnd}
							onToggle={onToggle}
							hoveredItem={hoveredItem}
							selection={selection}
							status={status}
						/>
					</div>
				</div>
			</ScrollableChartArea>
		</div>
	);
};

export default MatrixWrapper;
