import moment from 'moment';
import { useState } from 'react';

import ColumnsDropdown from '../../../common/ColumnsDropdown';

interface QuarterMonthPickerProps {
	quarter?: string;
	month?: string;
	setQuarter: (quarter?: string) => void;
	setMonth: (month?: string) => void;
	selectedCurrentYear?: boolean;
}

const QuarterMonthPicker = ({ quarter, month, setQuarter, setMonth, selectedCurrentYear }: QuarterMonthPickerProps) => {
	const [isOpen, setIsOpen] = useState(false);

	const MONTHS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
	const QUARTERS = ['Q1', 'Q2', 'Q3', 'Q4'];

	const monthFromQuarter = (quarter: string) => {
		const quarterNumber = parseInt(quarter[1], 10);
		const monthNumber = quarterNumber * 3;
		return moment(monthNumber, 'M').format('M');
	};

	const handleSelect = (option: any) => {
		setIsOpen(false);

		if (option === quarter || option === month || option === moment(month, 'M').format('MMMM')) {
			setMonth(undefined);
			setQuarter(undefined);
			return;
		}

		if (option.startsWith('Q')) {
			setQuarter(option);
			setMonth(undefined);
			return;
		}

		setMonth(moment(option, 'MMMM').format('M'));
		setQuarter(undefined);
	};

	return (
		<ColumnsDropdown
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			setSelectedOption={handleSelect}
			selectedOption={quarter ? quarter : month ? moment(month, 'M').format('MMMM') : 'Quarter or Month'}
		>
			<ColumnsDropdown.Column
				header="Quarter"
				options={QUARTERS.filter(quarter => {
					return selectedCurrentYear
						? moment(monthFromQuarter(quarter), 'M').month() >= moment().month()
						: true;
				})}
			/>

			<ColumnsDropdown.Column
				header="Month"
				options={MONTHS.filter(month =>
					selectedCurrentYear ? moment(month, 'M').isAfter(moment()) : true
				).map(month => moment(month, 'M').format('MMMM'))}
			/>
		</ColumnsDropdown>
	);
};

export default QuarterMonthPicker;
