import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { useState } from 'react';

import Button from '../../common/Button';
import AnalysisTypes from '../../constants/AnalysisTypes';
import ButtonTypes from '../../constants/ButtonTypes';
import MixPanel from '../../constants/MixPanel';
import RbacActions from '../../constants/RbacActions';
import usePermissions from '../../hooks/usePermissions';
import { track } from '../../lib/segment';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { TemplateGroup } from '../../templates/models';
import { AnalysisName, FilterSegment, Segment } from '../../types';
import AnalysisDropdown from './AnalysisDropdown';
import SegmentDropdown from './SegmentDropdown';
import {
	clearFilters,
	setAnalysisName,
	updateBreakdownSegment,
	updateFilterSegment,
	updateMainSegments,
} from './actions';
import './styles.scss';
import useUrlStateSync from './useUrlStateSync';

interface FiltersProps {
	show: boolean;
	docked?: boolean;
}

function Filters({ show, docked }: FiltersProps) {
	const dispatch = useAppDispatch();
	const { dynamicGroups } = useAppSelector(state => state.templates);
	const { analysisNamesTree } = useAppSelector(state => state.account);
	const initialAnalysisNames = getInitialAnalysisNames(analysisNamesTree!, dynamicGroups);
	const { generatePending } = useAppSelector(state => state.explore.chart);

	const {
		analysisName,
		analysisDisplayName,
		analysisType,
		mainSegments,
		mainNames,
		filterNames,
		breakdownNames,
		filterSegment,
		breakdownSegment,
		attributes,
		generateDisabled,
	} = useAppSelector(state => state.explore.filter);

	const disabled = !usePermissions({
		actions: [RbacActions['Explore/Generate']],
	});

	const [shouldOpen, setShouldOpen] = useState(false);
	const { handleGenerate } = useUrlStateSync({ docked });
	const currentTemplate = dynamicGroups.find((t: TemplateGroup) => t.name === analysisName)?.templates?.[0];

	function handleClear() {
		track(MixPanel.Events.ExploreClear);
		dispatch(clearFilters());
	}

	function handleAnalysisChange() {
		dispatch(updateMainSegments([]));
		dispatch(updateFilterSegment([]));
		dispatch(updateBreakdownSegment([]));
	}

	return (
		<div className="filters" style={{ visibility: show ? 'visible' : 'hidden' }}>
			<div className="filters__dropdowns">
				<AnalysisDropdown
					initialAnalysisNames={initialAnalysisNames}
					analysisDisplayName={analysisDisplayName!}
					analysisName={analysisName}
					analysisType={analysisType}
					disabled={disabled}
					shouldOpen={false}
					setAnalysisName={(props: any) => dispatch(setAnalysisName(props))}
					onValueChange={handleAnalysisChange}
					setShouldOpen={setShouldOpen}
				/>
				<SegmentDropdown
					placeholder="Segment"
					attributes={attributes}
					dynamicPermissionsFilter={(currentTemplate?.mainNames as any) || []}
					availableSegmentNames={mainNames}
					selection={mainSegments}
					update={segments => dispatch(updateMainSegments(segments as Segment[]))}
					disabled={!analysisName || disabled}
					shouldOpen={shouldOpen}
					setShouldOpen={setShouldOpen}
				/>
				<SegmentDropdown
					placeholder="Filter"
					attributes={attributes}
					dynamicPermissionsFilter={(currentTemplate?.filterNames as any) || []}
					availableSegmentNames={filterNames}
					selection={filterSegment ? [filterSegment] : []}
					update={segments => dispatch(updateFilterSegment(segments as FilterSegment[]))}
					disabled={
						(!filterSegment && currentTemplate?.filterNames?.length === 0) ||
						!analysisName ||
						mainSegments.length === 0 ||
						disabled
					}
					useRadioOnSegment
					useRadioOnValue
					isSecondary
				/>
				<SegmentDropdown
					placeholder="Breakdown"
					attributes={attributes}
					dynamicPermissionsFilter={(currentTemplate?.breakdownNames as any) || []}
					availableSegmentNames={breakdownNames}
					selection={breakdownSegment ? [breakdownSegment] : []}
					update={segments => dispatch(updateBreakdownSegment(segments as Segment[]))}
					disabled={
						(!breakdownSegment && currentTemplate?.breakdownNames?.length === 0) ||
						!analysisName ||
						mainSegments.length === 0 ||
						disabled ||
						analysisType === AnalysisTypes.Arithmetic
					}
					useRadioOnSegment
					isSecondary
				/>
			</div>
			{!disabled && (
				<div className="filters__buttons" data-test="filters-actions">
					<Button componentType={ButtonTypes.type.TERTIARY} onClick={handleClear} tabIndex={-1}>
						Clear
					</Button>
					<Button
						disabled={!analysisName || !mainSegments || !mainSegments.length || generateDisabled}
						onClick={handleGenerate}
						loading={generatePending}
						testId="generate"
					>
						<span style={{ opacity: generatePending ? 0 : 1 }}>Generate</span>
					</Button>
				</div>
			)}
		</div>
	);
}

export function getAnalysisNamesFromTree(
	analysisNamesTree: AnalysisName[],
	templates: TemplateGroup[],
	analysisTypeToExclude?: AnalysisTypes
): AnalysisName[] {
	return analysisNamesTree?.reduce<AnalysisName[]>((acc, analysisObj) => {
		const { name } = analysisObj;
		const newObj: AnalysisName = { name };
		if (analysisObj.children) {
			newObj.children = getAnalysisNamesFromTree(analysisObj.children, templates, analysisTypeToExclude);
			if (newObj.children?.length === 0) {
				return acc;
			}
		} else {
			const template = templates.find(t => t.name === name)?.templates[0];

			newObj.isLeaf = true;
			if (template) {
				if (template.analysisType === analysisTypeToExclude) {
					return acc;
				}
				newObj.isFunnel = template.supportedGraphs?.includes('funnel');
				if (!isEmpty(template.uiFieldMapping.subAnalysisFields)) {
					newObj.children = Object.keys(template.uiFieldMapping.subAnalysisFields).map(k => ({
						name: k,
						isSubAnalysis: true,
						source: template.uiFieldMapping.subAnalysisFields[k].source,
					}));
				}
			}
		}

		acc.push(newObj);

		return acc;
	}, []);
}

export function getInitialAnalysisNames(
	analysisNamesTree: AnalysisName[],
	templates: TemplateGroup[],
	analysisTypeToExclude?: AnalysisTypes
): AnalysisName[] {
	const initialAnalysisNames = analysisNamesTree?.length
		? getAnalysisNamesFromTree(analysisNamesTree, templates, analysisTypeToExclude)
		: templates.map((t: TemplateGroup) => {
				const template = t.templates[0];
				return {
					name: t.name,
					isFunnel: template.supportedGraphs?.includes('funnel'),
					isLeaf: true,
					children: !isEmpty(template.uiFieldMapping.subAnalysisFields)
						? sortBy(
								Object.keys(template.uiFieldMapping.subAnalysisFields).map<AnalysisName>(k => ({
									name: k,
									isSubAnalysis: true,
									...template.uiFieldMapping.subAnalysisFields[k],
								})),
								'order'
						  )
						: undefined,
				};
		  });
	return initialAnalysisNames;
}

export default Filters;
