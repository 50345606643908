import Modal from 'antd/lib/modal';
import PropTypes from 'prop-types';

import Button from '../common/Button';
import ButtonTypes from '../constants/ButtonTypes';

export default function DeleteDashboardModal(props) {
	const { isOpen, actionPending, onAction, onCancel } = props;
	return (
		<Modal
			open={isOpen}
			width={400}
			footer={null}
			closable={false}
			maskClosable={true}
			onCancel={onCancel}
			zIndex={3000}
		>
			<div className="modal modal--delete">
				<div className="modal__header">
					<div className="modal__title">Delete Dashboard</div>
				</div>
				<div className="modal__content">
					<p>Click Delete to permanently delete this dashboard.</p>
					<p>IMPORTANT: This action cannot be undone.</p>
				</div>
				<div className="modal__footer">
					<Button componentType={ButtonTypes.type.TERTIARY} onClick={onCancel}>
						Cancel
					</Button>
					<Button loading={actionPending} onClick={onAction} danger>
						Delete
					</Button>
				</div>
			</div>
		</Modal>
	);
}

DeleteDashboardModal.propTypes = {
	isOpen: PropTypes.bool,
	onCancel: PropTypes.func,
	onAction: PropTypes.func,
	actionPending: PropTypes.bool,
};
