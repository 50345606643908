import Modal from 'antd/lib/modal';

import Stack from '../../../../common/Stack';
import Close from '../../../../icons/Close';

interface InfoModalProps {
	isOpen: boolean;
	onClose: () => void;
	children: React.ReactNode;
	width?: number;
}

function InfoModal({ isOpen, onClose, children, width = 500 }: InfoModalProps) {
	function handleInfoClose(e: React.MouseEvent) {
		e.preventDefault();
		onClose();
	}

	return (
		<Modal
			open={isOpen}
			width={width}
			bodyStyle={{ padding: 0 }}
			zIndex={3000}
			footer={null}
			closable={false}
			maskClosable={true}
			destroyOnClose={true}
			onCancel={handleInfoClose}
			centered={true}
		>
			<div className="info-modal">
				<div
					style={{
						position: 'relative',
					}}
				>
					<div
						style={{
							position: 'absolute',
							top: 0,
							right: 0,
						}}
					>
						<Close width={24} height={24} className="btn-icon" onClick={handleInfoClose} />
					</div>
					<Stack
						style={{
							paddingRight: '1.6rem',
						}}
					>
						{children}
					</Stack>
				</div>
			</div>
		</Modal>
	);
}

InfoModal.defaultProps = {
	isOpen: false,
};

export default InfoModal;
