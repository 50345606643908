import { Fragment, useEffect, useState } from 'react';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';
import { RouteComponentProps } from 'react-router-dom';

import AiInsightsModal from '../common/AiInsightsModal';
import Button from '../common/Button';
import ChartContainer from '../common/ChartContainer';
import GenericTable from '../common/GenericTable';
import GradientButton from '../common/GradientButton';
import LottieAnimation from '../common/LottieAnimation';
import MessageBar from '../common/MessageBar';
import Stack from '../common/Stack';
import WithPermissions from '../common/WithPermissions';
import { addMessage, showInfo } from '../common/actions';
import useInteractiveChart from '../common/useInteractiveChart';
import analysisTypes from '../constants/AnalysisTypes';
import ButtonTypes from '../constants/ButtonTypes';
import ChartTypes from '../constants/ChartTypes';
import MixPanel from '../constants/MixPanel';
import RbacActions from '../constants/RbacActions';
import { saveReport } from '../editor/buttons/actions';
import EditIcon from '../icons/Edit';
import Info from '../icons/Info';
import MenuIcon from '../icons/Menu';
import StackedHorizontalBarIcon from '../icons/StackedHorizontalBar';
import cn from '../lib/cn';
import { page, track } from '../lib/segment';
import Loader from '../lottie/graph-loader.json';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { getEvents } from '../timeline/actions';
import DetailSection from './DetailSection';
import ExploreSettingsPanel from './ExploreSettingsPanel';
import { closeDeleteSearchModal, closeEditSearchModal, createSearch, getSearches, removeSearch } from './actions';
import { generateAiInsightsInExplore } from './aiInsights/actions';
import { setAiInsightsModelOpen } from './aiInsights/reducer';
import Chart from './chart/Chart';
import TableChart from './chart/TableChart';
import { clearSelection, setIsSettingsOpen } from './chart/actions';
import AddToDashboardButton from './dashboard/AddToDashboardButton';
import DeleteSearch from './dashboard/DeleteSearch';
import EditReportNameModal from './dashboard/EditReportNameModal';
import EditSearch from './dashboard/EditSearch';
import SaveButton from './dashboard/SaveSearch/SaveButton';
import SaveModal from './dashboard/SaveSearch/SaveModal';
import EditModeEmptyState from './filter/EditModeEmptyState';
import EmptyStateImage from './filter/EmptyStateImage';
import EmptyStateText from './filter/EmptyStateText';
import SearchLibrary from './searches/SearchLibrary';
import { setSubtableChartType } from './subtable/actions';

function Explore({ location }: RouteComponentProps) {
	const [showSavePopup, setShowSavePopup] = useState(false);
	const [benchmarkEdit, setBenchmarkEdit] = useState(false);
	const [benchmarkToEdit, setBenchmarkToEdit] = useState();
	const [titleEditing, setTitleEditing] = useState(false);

	const { featureFlags } = useAppSelector(state => state.account);
	const {
		aiInsightsModalOpen,
		error,
		insights: aiInsights,
		loaded,
		pending,
	} = useAppSelector(state => state.explore.aiInsights);
	const {
		deleteReportId: reportToDelete,
		editReportId: reportToEdit,
		pending: searchesPending,
		searches,
		showDelete,
		showEdit,
	} = useAppSelector(state => state.explore.searches);
	const { editMode, dashboardName = '', title } = useAppSelector(state => state.explore.dashboardReport);
	const noFilters = useAppSelector(state => state.explore.filter.analysisName === '');
	const { analysisType, showSubtable, analysisName } = useAppSelector(state => state.explore.filter);
	const { empty } = useAppSelector(state => state.explore.subtable);
	const isEmpty = !analysisName;

	const {
		chartType,
		data,
		generated,
		generateError,
		generateNoData,
		generatePending,
		isSettingsOpen,
		lifecycle,
		subAnalysisMapping,
		subAnalysisFields,
	} = useAppSelector(state => state.explore.chart);
	const {
		subtableChartType,
		allowChartToggle,

		generated: subtableGenerated,
		empty: subtableEmpty,
		error: subtableError,
		generatePending: subtableGeneratePending,
	} = useAppSelector(state => state.explore.subtable);
	const { events } = useAppSelector(state => state.timeline);
	const { pending: savePending } = useAppSelector(state => state.editor.save);

	const { onHover, onHoverEnd, onToggle, hoveredItem, selection, status } = useInteractiveChart({
		mode: 'explore',
		lockedSelection: chartType === ChartTypes.Funnel,
	});
	const dispatch = useAppDispatch();

	const isGenerated = showSubtable
		? subtableGenerated && !subtableGeneratePending && !subtableEmpty && !subtableError
		: !generatePending && !generateNoData && !generateError && generated;

	useEffect(() => {
		page(MixPanel.Pages.Explore);
	}, []);

	const getReportFromId = (id: string) => {
		const report = searches.find((r: any) => {
			return id === r.reportId;
		});

		if (report) return report.name;
		return '';
	};

	const handleDelete = () => {
		if (!reportToDelete) return;

		dispatch(removeSearch(reportToDelete));
		dispatch(closeDeleteSearchModal());

		const reportName = getReportFromId(reportToDelete);

		dispatch(addMessage(`Search "${reportName}" deleted successfully`));
		track(MixPanel.Events.ExploreSavedSearchReportDelete, {
			'Report Name': reportName,
		});
	};

	const handleCloseDeleteModal = () => {
		dispatch(closeDeleteSearchModal());
	};

	const handleEdit = (newName: string) => {
		const searchToEdit = JSON.parse(JSON.stringify(findSearchToEdit()));
		if (newName !== searchToEdit.name) {
			if (!searchToEdit) return;

			searchToEdit.name = newName;
			dispatch(createSearch(searchToEdit));
		}
		dispatch(closeEditSearchModal());

		dispatch(addMessage(`Search renamed successfully to "${newName}"`));
		track(MixPanel.Events.ExploreSavedSearchReportRename, {
			'New Report Name': newName,
		});
	};
	const handleCloseEditModal = () => {
		dispatch(closeEditSearchModal());
	};

	const handleClearSelection = () => {
		dispatch(clearSelection());
	};

	function handleInfo() {
		track(MixPanel.Events.ExploreInfo);
		dispatch(showInfo(lifecycle, analysisType!, false));
	}

	const handleSwitchSettingsDisplay = () => dispatch(setIsSettingsOpen(!isSettingsOpen));

	const findSearchToEdit = () => searches.find((search: any) => search.reportId === reportToEdit);

	const findOldName = () => {
		const searchToEdit = findSearchToEdit();
		if (searchToEdit) return searchToEdit.name;
	};

	useEffect(() => {
		if (searches.length === 0) {
			dispatch(getSearches());
		}
		if (events.length === 0) {
			dispatch(getEvents());
		}
	}, []);

	const showSettingsPanel = showSubtable ? true : generated || generatePending || generateNoData || empty;

	return (
		<WithPermissions
			actions={[RbacActions['Explore/Generate'], RbacActions['Dashboards/Explore']]}
			showMessage={true}
		>
			<div className="explore">
				{editMode ? (
					<EditModeEmptyState />
				) : (
					<WithPermissions actions={[RbacActions['Dashboards/ViewAll'], RbacActions['Explore/SaveReport']]}>
						{!searchesPending ? (
							<>
								<SearchLibrary />
								{searches.length === 0 && (
									<>
										<EmptyStateText queryString={location.search} />
										<EmptyStateImage queryString={location.search} />
									</>
								)}
							</>
						) : noFilters ? (
							<div className="dashboards__empty">
								<LottieAnimation
									animation={Loader}
									autoplay={true}
									loop={true}
									width={100}
									height={100}
								></LottieAnimation>
							</div>
						) : null}
					</WithPermissions>
				)}
				{showSettingsPanel && (
					<ExploreSettingsPanel
						isSettingsOpen={isSettingsOpen}
						setBenchmarkEdit={setBenchmarkEdit}
						setBenchmarkToEdit={setBenchmarkToEdit}
						switchSettingsDisplay={handleSwitchSettingsDisplay}
					/>
				)}

				<div
					className={cn('explore__content', isEmpty && !editMode && 'hidden', {
						'explore__content--settings-opened': isSettingsOpen && showSettingsPanel,
					})}
				>
					{isGenerated && (
						<div
							className="explore-chart-header flex justify-between items-center"
							style={{
								margin: '0 2rem 1.6rem',
							}}
						>
							<Stack
								flexDirection="row"
								alignItems="center"
								gap=".8rem"
								style={{
									color: 'var(--color-ui-50)',
								}}
							>
								{!isSettingsOpen && (
									<MdKeyboardDoubleArrowRight
										size={24}
										style={{
											display: 'flex',
											cursor: 'pointer',
											alignSelf: 'center',
										}}
										onClick={handleSwitchSettingsDisplay}
									/>
								)}
								{editMode ? (
									<div className="font-title flex items-center gap-[5px] ">
										<div>{`${dashboardName} /`}</div>
										<div className={cn('flex items-center gap-[8px]')}>
											<ReportTitle
												titleEditing={titleEditing}
												setTitleEditing={setTitleEditing}
											/>
										</div>
									</div>
								) : (
									<div className="font-title">Report</div>
								)}
							</Stack>
							<div className={cn('flex items-center gap-[8px]')}>
								<Button componentType={ButtonTypes.type.SECONDARY} onClick={handleInfo} tabIndex={-1}>
									What&apos;s the Math?
									<Info
										className="btn-icon measure-header__info-icon"
										width={24}
										height={24}
										style={{ margin: '0 -0.8rem 0 0.8rem' }}
									/>
								</Button>
								{!editMode && (
									<WithPermissions actions={[RbacActions['Explore/SaveReport']]}>
										<SaveButton
											large={true}
											// @ts-ignore
											onClick={show => setShowSavePopup(show)}
											text={'Save'}
										/>
									</WithPermissions>
								)}

								{featureFlags.aiInsights && (
									<GradientButton
										onClick={() => {
											dispatch(generateAiInsightsInExplore());
										}}
										size="md"
									>
										<div className={cn('flex items-center gap-[.4rem]')}>
											<span>Insights</span>
										</div>
									</GradientButton>
								)}

								<WithPermissions actions={[RbacActions['Dashboards/Edit']]}>
									{editMode ? (
										<Button onClick={() => dispatch(saveReport(title, {}))} loading={savePending}>
											<span style={{ opacity: savePending ? 0 : 1 }}>Save report</span>
										</Button>
									) : (
										<AddToDashboardButton />
									)}
									{showSubtable && allowChartToggle && (
										<Fragment>
											<Button
												componentType={
													subtableChartType === ChartTypes.Matrix
														? ButtonTypes.type.PRIMARY
														: ButtonTypes.type.TERTIARY
												}
												circle={true}
												onClick={() => dispatch(setSubtableChartType(ChartTypes.Matrix))}
											>
												<MenuIcon />
											</Button>
											<Button
												componentType={
													subtableChartType === ChartTypes.HorizontalBar
														? ButtonTypes.type.PRIMARY
														: ButtonTypes.type.TERTIARY
												}
												circle={true}
												onClick={() => dispatch(setSubtableChartType(ChartTypes.HorizontalBar))}
											>
												<StackedHorizontalBarIcon />
											</Button>
										</Fragment>
									)}
								</WithPermissions>
							</div>
						</div>
					)}
					{showSavePopup && (
						<SaveModal
							// @ts-ignore
							showPopup={show => setShowSavePopup(show)}
						/>
					)}
					{aiInsightsModalOpen && (
						<AiInsightsModal
							onClose={() => dispatch(setAiInsightsModelOpen(false))}
							aiInsights={aiInsights}
							pending={pending}
							loaded={loaded}
							error={error}
						/>
					)}

					<Chart benchmarkEdit={benchmarkEdit} benchmarkToEdit={benchmarkToEdit} />

					{!showSubtable &&
						chartType !== ChartTypes.Stats &&
						chartType !== ChartTypes.ArithmeticTable &&
						chartType !== ChartTypes.Funnel &&
						chartType !== ChartTypes.Table &&
						!editMode &&
						isGenerated && <TableChart />}

					{!showSubtable && analysisType !== analysisTypes.Arithmetic && (
						<DetailSection queryString={location.search} />
					)}
					{chartType === ChartTypes.Funnel &&
						!generatePending &&
						!generateNoData &&
						!generateError &&
						!editMode && (
							<Fragment>
								<div
									className="explore-chart mx-[2rem]"
									onClick={handleClearSelection}
									style={{
										paddingTop: '1.6rem',
									}}
								>
									<ChartContainer>
										<GenericTable
											data={data}
											subAnalysisMapping={subAnalysisMapping}
											subAnalysisFields={subAnalysisFields}
											template="funnel"
											onHover={onHover}
											onHoverEnd={onHoverEnd}
											onToggle={onToggle}
											hoveredItem={hoveredItem}
											selection={selection}
											status={status}
										/>
									</ChartContainer>
								</div>
							</Fragment>
						)}
					<DeleteSearch isOpen={showDelete} onCancel={handleCloseDeleteModal} onDelete={handleDelete} />
					<EditSearch
						isOpen={showEdit}
						onCancel={handleCloseEditModal}
						onSubmit={handleEdit}
						oldName={findOldName()}
					/>
					<MessageBar />
				</div>
			</div>
		</WithPermissions>
	);
}

const ReportTitle = ({ titleEditing, setTitleEditing }: { titleEditing: any; setTitleEditing: any }) => {
	const { title } = useAppSelector(state => state.explore.dashboardReport);

	return (
		<div className={cn('flex items-center relative gap-[8px]')}>
			<div className={cn('text-ui-100')}>{title}</div>
			{!titleEditing && (
				<button
					onClick={() => {
						setTitleEditing(true);
					}}
					className={cn(
						'flex items-center justify-center w-[28px] h-[28px] rounded-full bg-shade-4 cursor-pointer',
						'hover:bg-shade-5'
					)}
				>
					<EditIcon className="w-[12px] h-[12px]" />
				</button>
			)}
			<EditReportNameModal isOpen={titleEditing} onClose={() => setTitleEditing(false)} />
		</div>
	);
};

export default Explore;
