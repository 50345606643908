import Tooltip from 'antd/lib/tooltip';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

import Popover from '../common/Popover';
import CanvasFonts from '../constants/CanvasFonts';
import ChartTypes from '../constants/ChartTypes';
import Constants from '../constants/Constants';
import Chart from '../explore/searches/SearchLibrary/Search/Chart';
import HMore from '../icons/HMore';
import measureText from '../lib/measureText';
import MoreMenu from './MoreMenu';

interface CardProps {
	width: number;
	dashboard: any;
	onClick: any;
	role: string;
}

export default function Card(props: CardProps) {
	const { width, dashboard, onClick, role } = props;
	const [moreOpen, setMoreOpen] = useState(false);
	const [reports, setReports] = useState<any[]>([]);
	const [scale, setScale] = useState(1);
	const [showTooltip, setShowTooltip] = useState(false);

	const titleRef = useRef<HTMLDivElement>(null);
	const ownerRef = useRef<HTMLDivElement>(null);

	function generatePreviewReports(reports: any[]) {
		const nonTextReports = reports.filter(r => {
			const chartType = r.configuration.find((c: any) => c.name === 'chartType');
			return chartType && chartType.value !== ChartTypes.Text;
		});

		let firstRow: any;
		let index = 0;

		while (index < nonTextReports.length) {
			const r = nonTextReports[index];
			const rowConfig = r.configuration.find((c: any) => c.name === 'row');
			if (rowConfig) {
				const row = parseInt(rowConfig.value);
				if (!firstRow || row < firstRow) {
					firstRow = row;
				}
			}

			index++;
		}

		return nonTextReports;
	}

	useEffect(() => {
		if (titleRef.current) {
			const titleWidth = titleRef.current.offsetWidth;
			const expectedWidth = measureText(dashboard.name, CanvasFonts.Medium18).width;
			setShowTooltip(expectedWidth > titleWidth);
		}
	}, [width]);

	useEffect(() => {
		const reports = generatePreviewReports(dashboard.reports);
		setReports(reports);
	}, [dashboard.reports]);

	const chartHeight = 0.27 * width;

	return (
		<div
			className="card"
			style={{
				width,
				minHeight: (2 * width) / 3 + 50,
				transform: `scale(${scale})`,
			}}
			onClick={onClick}
			onMouseEnter={() => setScale(1.03)}
			onMouseLeave={() => setScale(1)}
		>
			<div className="card__title" ref={titleRef}>
				{showTooltip ? (
					<Tooltip title={dashboard.name} placement="topLeft" overlayClassName="card__tooltip">
						<span>{dashboard.name}</span>
					</Tooltip>
				) : (
					<span>{dashboard.name}</span>
				)}
			</div>
			<div className="card__menu" onClick={e => e.stopPropagation()}>
				<Popover
					placement="bottomRight"
					visible={moreOpen}
					onVisibleChange={() => {
						if (!moreOpen) {
							setScale(1.03);
						}
						setMoreOpen(!moreOpen);
					}}
					content={
						<MoreMenu
							dashboardId={dashboard.dashboardId}
							dashboardName={dashboard.name}
							onClose={() => setMoreOpen(false)}
							role={role}
							source="Recent"
						/>
					}
					overlayClassName="popover--no-padding"
				>
					<HMore className="icon" />
				</Popover>
			</div>
			<div className="card__owner" ref={ownerRef}>
				Created by {dashboard.createdByName}
			</div>
			<div className="card__date">{moment.utc(dashboard.dateAdded).format(Constants.DateFormat)}</div>
			<div className="card__charts">
				{reports.length > 0 && (
					<>
						<Chart
							type={reports[0]?.configuration[0]?.value?.toLowerCase()}
							count={reports[0]?.attributes[0]?.value?.length}
							style={{
								borderRadius: '.4rem',
								maxHeight: chartHeight,
							}}
						></Chart>
						{reports.length > 1 && (
							<Chart
								type={reports[1]?.configuration[0]?.value?.toLowerCase()}
								count={reports[1]?.attributes[0]?.value?.length}
								style={{
									borderRadius: '.4rem',
									maxHeight: chartHeight,
								}}
							></Chart>
						)}
					</>
				)}
			</div>
		</div>
	);
}
