import Modal from 'antd/lib/modal';

import Close from '../icons/Close';

interface InfoModalProps {
	isOpen: boolean;
	onClose: () => void;
}

function InfoModal({ isOpen, onClose }: InfoModalProps) {
	function handleInfoClose(e: React.MouseEvent) {
		e.preventDefault();
		onClose();
	}

	return (
		<Modal
			open={isOpen}
			width={712}
			bodyStyle={{ padding: 0 }}
			zIndex={3000}
			footer={null}
			closable={false}
			maskClosable={true}
			destroyOnClose={true}
			onCancel={handleInfoClose}
		>
			<div className="info-modal">
				<div className="info-modal__inner">
					<div className="info-modal__header">
						<div className="info-modal__title">Collect</div>
						<div>
							<Close width={24} height={24} className="btn-icon" onClick={handleInfoClose} />
						</div>
					</div>
					<div className="info-modal__content">
						<div className="info-modal__content-col">
							<div className="info-modal__subtitle">About this report</div>
							<div>
								<p>
									Your company’s overall data completion is provided as a percentage. To the right of
									that, each bar is an indicator of your company’s data completion for each data field
									Dandi uses in its analyses. The percentage value in parentheses tracks progress
									towards completing your company’s data set for this data field compared to the
									previous month’s data. The field and completion columns can be sorted by clicking on
									the headers.
								</p>
								<p>
									For reference, Dandi refreshes your data through your integrations on a monthly
									basis by default. Note that Dandi only displays active employees’ data completion.
								</p>
								<p>
									Track how the data collection of your HR data is progressing and gain visibility
									into areas of data collection that need it most.
								</p>
							</div>
						</div>
						<div className="info-modal__content-col" style={{ paddingLeft: 24 }}>
							<div className="info-modal__subtitle">How the analysis is performed</div>
							<div>
								This report is a graphical representation of the data available in your HR systems.
							</div>
						</div>
					</div>
					<div className="cover-bar" />
				</div>
			</div>
		</Modal>
	);
}

InfoModal.defaultProps = {
	isOpen: false,
};

export default InfoModal;
