import { useEffect, useState } from 'react';

import Button from '../../../common/Button';
import TextField from '../../../common/TextField';
import ButtonTypes from '../../../constants/ButtonTypes';

interface DesiredValueInputProps {
	value: number;
	onChange: (value: number) => void;
	unit: string;
	step?: number;
	canBeNegative?: boolean;
	maxValue?: number | undefined;
	minValue?: number | undefined;
}

const DesiredValueInput = ({
	value,
	onChange,
	unit,
	step = 1,
	canBeNegative = true,
	maxValue,
	minValue,
}: DesiredValueInputProps) => {
	const [displayedValue, setDisplayedValue] = useState<string>(value.toString() + unit);
	const [lastValueNegative, setLastValueNegative] = useState<boolean>(false);

	useEffect(() => {
		const roundedValue = Math.round(value * 10) / 10;
		let displayedString = roundedValue.toString() + unit;
		if (lastValueNegative && canBeNegative) displayedString = '-' + displayedString;
		setDisplayedValue(displayedString);
	}, [value]);

	const calculateNewValue = (newValue: string) => {
		setLastValueNegative(Math.sign(value) === -1);
		if (newValue.charAt(newValue.length - 1) !== unit && newValue.length < displayedValue.length && unit !== '') {
			newValue = newValue.slice(0, newValue.length - 1);
		}
		if (newValue.length < displayedValue.length) {
			if (newValue.charAt(0) === '-' && displayedValue === '-0' + unit) {
				newValue = '0';
				setLastValueNegative(false);
			}
		}
		let rawValue = newValue.replace(/[^\d.-]/g, '');
		if (rawValue.includes('-')) {
			rawValue = rawValue.replace(/-/g, '');
			rawValue = '-' + rawValue;
			setLastValueNegative(true);
		}
		if (rawValue === '' || rawValue === '.' || rawValue !== rawValue) {
			rawValue = '0';
		}
		if (rawValue === '-' || rawValue === '-.' || rawValue === '0-') {
			setLastValueNegative(true);
			rawValue = '-0';
		}

		if (maxValue && Number(rawValue) > maxValue) {
			rawValue = maxValue.toString();
		}
		if (minValue && Number(rawValue) < minValue) {
			rawValue = minValue.toString();
		}

		return rawValue;
	};
	return (
		<div className="desired-value-container">
			<Button
				componentType={ButtonTypes.type.TERTIARY}
				large
				type="button"
				onClick={() => {
					if (value === 0 && !canBeNegative) {
						onChange(0);
						return;
					}
					onChange(
						Number.isInteger(value)
							? value - step < minValue!
								? minValue!
								: value - step
							: Math.trunc(value)
					);
				}}
			>
				<span className="button-text">–</span>
			</Button>
			<TextField
				classes={['input-field']}
				value={displayedValue}
				onChange={e => {
					let newValue = e.target.value;
					const rawValue = calculateNewValue(newValue);
					onChange(Number(rawValue));
				}}
			/>
			<Button
				componentType={ButtonTypes.type.TERTIARY}
				type="button"
				onClick={() =>
					onChange(
						Number.isInteger(value)
							? value + step > maxValue!
								? maxValue!
								: value + step
							: Math.trunc(value) + 1
					)
				}
			>
				<span className="button-text">+</span>
			</Button>
		</div>
	);
};

export default DesiredValueInput;
