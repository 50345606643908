import { useEffect } from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';

import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getPackages } from './actions';

function Packages({ history }: RouteComponentProps<{}>) {
	const dispatch = useAppDispatch();
	const { data = [] } = useAppSelector(state => state.datastudio.packages);

	useEffect(() => {
		dispatch(getPackages());
	}, []);

	function handleAdd() {
		history.push(`/datastudio/packages/new`);
	}

	return (
		<div className="ds-container">
			<Button componentType={ButtonTypes.type.PRIMARY} classes={['ds-add']} onClick={handleAdd}>
				Add
			</Button>
			<table className="ds-table">
				<tbody>
					{data.map((c, i) => (
						<tr key={i}>
							<td>
								<NavLink className="link--bright" to={`/datastudio/packages/${c.packageId}`}>
									{c.packageName}
								</NavLink>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

export default Packages;
