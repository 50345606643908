import { Fragment } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import constants from '../constants/Constants';

import MixPanel from '../constants/MixPanel';
import Eye from '../icons/Eye';
import cn from '../lib/cn';
import getFormattedValue from '../lib/getFormattedValue';
import getPeriod from '../lib/getPeriod';
import precisionRound from '../lib/precisionRound';
import { track } from '../lib/segment';
import Masking from './Masking';
import ScrollArrows from './ScrollArrows';
import SortableHead, { SortCriteria } from './SortableHead';
import Stack from './Stack';
import useTableScroll from './useTableScroll';

interface ILeftTableProps {
	data: any[];
	sortCriteria: SortCriteria;
	onSort: (columnName: string) => void;
	tableRowHeight: number;
	showSinglePeriod: boolean;
	onHover: (label: string) => void;
	onHoverEnd: (label: string) => void;
	onToggle: (label: string) => void;
	tableActive: boolean;
	hoveredItem?: string;
	selection: string[];
}

function LeftTable({
	data,
	sortCriteria,
	onSort,
	tableRowHeight,
	showSinglePeriod,
	onHover,
	onHoverEnd,
	onToggle,
	tableActive,
	hoveredItem,
	selection,
}: ILeftTableProps) {
	return (
		<table className="table__left-table">
			<thead onClick={e => e.stopPropagation()}>
				{!showSinglePeriod && (
					<tr style={{ height: tableRowHeight }}>
						<th className="table__divider" />
					</tr>
				)}
				<tr style={{ height: tableRowHeight }}>
					<SortableHead
						label="Segment"
						columnName="text"
						sortCriteria={sortCriteria}
						onSort={onSort}
						className="table__divider"
					/>
				</tr>
			</thead>
			<tbody>
				{data.map(d => {
					const isActive = hoveredItem === d.label;
					const isSelected = selection.includes(d.label);
					return (
						<tr
							key={d.label}
							className={isActive || isSelected ? 'table__selected' : ''}
							style={{
								height: tableRowHeight,
								opacity: tableActive ? (isActive || isSelected ? 1 : 0.24) : 1,
							}}
							onMouseOver={() => onHover(d.label)}
							onMouseOut={() => onHoverEnd(d.label)}
							onClick={() => onToggle(d.label)}
						>
							<td className="table__divider" style={{ cursor: 'pointer' }}>
								<span
									dangerouslySetInnerHTML={{
										__html: d.segment.html,
									}}
								/>
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
}

interface IHeaderRowProps {
	date: any;
	interval: string;
	sortCriteria: SortCriteria;
	onSort: (columnName: string) => void;
	expanded?: boolean;
	columnProps: any[];
}

function HeaderRow(props: IHeaderRowProps) {
	const { date, interval, sortCriteria, onSort, expanded, columnProps } = props;

	const label = getPeriod(date, interval.toLowerCase());
	const colPrefix = label?.toLowerCase().replace(/ /g, '-');
	const expandable = columnProps.length > 2;
	return !expandable || expanded ? (
		<>
			{columnProps.map(columnProp => (
				<SortableHead
					key={columnProp.header}
					label={columnProp.header}
					columnName={colPrefix + '-' + columnProp.source}
					sortCriteria={sortCriteria}
					onSort={onSort}
					className="table__divider"
				/>
			))}
		</>
	) : (
		<SortableHead
			label={columnProps[0].header}
			columnName={colPrefix + '-' + columnProps[0].source}
			sortCriteria={sortCriteria}
			onSort={onSort}
			className="table__divider"
		/>
	);
}

interface ITableRowProps {
	reportId?: string;
	dataItem: any;
	index: number;
	odd?: boolean;
	lifecycle: string;
	analysisType: string;
	navigate: (...args: any[]) => void;
	expanded?: boolean;
	disableLinks?: boolean;
	changeOption?: string;
	columnProps: any[];
}

function TableRow(props: ITableRowProps) {
	const {
		reportId,
		dataItem,
		index,
		odd,
		analysisType,
		navigate,
		expanded,
		disableLinks,
		changeOption = 'None',
		columnProps,
	} = props;

	function handleNavigate(navProps: any) {
		if (disableLinks) return;
		if (reportId) {
			navigate(reportId, navProps, index);
		} else {
			navigate(navProps, index);
		}
	}

	function getChange(current: { [x: string]: number }, previous: { [x: string]: number }, propName: string | number) {
		if (!previous || changeOption === 'None' || dataItem.isEqualityIndex || dataItem.isAttritionIndex) return '';
		if (changeOption === 'Percentage' && precisionRound(previous[propName], 1) === 0) {
			return '(---)';
		}
		let text;
		if (changeOption === 'Value') {
			const change = precisionRound(current[propName] - previous[propName], 1);
			text = change > 0 ? ` (+${change})` : ` (${change})`;
		}
		if (changeOption === 'Percentage') {
			const change = precisionRound(((current[propName] - previous[propName]) * 100) / previous[propName], 1);
			text = change > 0 ? ` (+${change}%)` : ` (${change}%)`;
		}
		return <span className="table__text table__text--secondary">{text}</span>;
	}

	const seriesItem = dataItem.series[index];
	let prevSeriesItem;
	if (index > 0) {
		prevSeriesItem = dataItem.series[index - 1];
	}

	const className = cn('table__divider', {
		table__odd: !!odd,
		clickable: !disableLinks && seriesItem,
	});
	const empClassName = cn('table__divider', {
		table__odd: !!odd,
	});

	const expandable = columnProps.length > 2;

	if (!seriesItem) {
		return (
			<Fragment>
				<td className={className}>
					<span>---</span>
				</td>
				{(!expandable || expanded) &&
					columnProps.slice(1).map(columnProp => <td key={columnProp.header} className={empClassName}></td>)}
			</Fragment>
		);
	}

	const primaryFieldKey = columnProps[0]?.source;

	return (
		<Fragment>
			<td className={className} onClick={() => handleNavigate(dataItem.navProps)}>
				<span>
					{seriesItem[primaryFieldKey] !== constants.InvalidValue &&
						getFormattedValue(analysisType, seriesItem[primaryFieldKey])}
					{getChange(seriesItem, prevSeriesItem, primaryFieldKey)}
				</span>
			</td>
			{(!expandable || expanded) &&
				columnProps.slice(1).map(columnProp => (
					<td className={empClassName}>
						<Masking
							value={seriesItem[columnProp.source]}
							unit={columnProp.unit}
							hiddenDisplay={
								<Stack
									style={{
										color: 'var(--color-ui-20)',
									}}
								>
									<Eye />
								</Stack>
							}
						/>
					</td>
				))}
		</Fragment>
	);
}

interface IRightTableProps {
	reportId?: string;
	lifecycle: string;
	analysisType: string;
	data: any[];
	dates: any[];
	interval: string;
	onSort: (columnName: string) => void;
	sortCriteria: SortCriteria;
	navigate: (...args: any[]) => void;
	expanded?: boolean;
	toggleExpansion?: () => void;
	tableRowHeight: number;
	changeOption: string;
	false?: boolean;
	tableActive: boolean;
	columnProps: any[];
	hoveredItem?: string;
	selection: string[];
}

function RightTable(props: IRightTableProps) {
	const {
		hoveredItem,
		selection,
		reportId,
		lifecycle,
		analysisType,
		data,
		dates,
		interval,
		onSort,
		sortCriteria,
		navigate,
		expanded,
		toggleExpansion,
		tableRowHeight,
		changeOption,
		tableActive,
		columnProps,
	} = props;

	const expandable = columnProps.length > 2;

	function getColspan() {
		if (columnProps.length > 2) {
			return expanded ? columnProps.length : 1;
		} else {
			return columnProps.length;
		}
	}

	return (
		<table className="table__right-table">
			<thead onClick={e => e.stopPropagation()}>
				<tr style={{ height: tableRowHeight }}>
					{dates.map(date => (
						<th key={date} colSpan={getColspan()} className="table__divider">
							<div className="table__flexhead">
								<span className="table__flexhead__text">{getPeriod(date, interval.toLowerCase())}</span>
								{expandable && (
									<span className="table__flexhead__expand">
										{expanded ? (
											<MdChevronLeft size={18} onClick={toggleExpansion} />
										) : (
											<MdChevronRight size={18} onClick={toggleExpansion} />
										)}
									</span>
								)}
							</div>
						</th>
					))}
					<th />
				</tr>
				<tr style={{ height: tableRowHeight }}>
					{dates.map(date => (
						<HeaderRow
							key={date}
							date={date}
							interval={interval}
							sortCriteria={sortCriteria}
							onSort={onSort}
							expanded={expanded}
							columnProps={columnProps}
						/>
					))}
					<th />
				</tr>
			</thead>
			<tbody>
				{data.map(d => {
					const isActive = d.label === hoveredItem;
					const isSelected = selection.includes(d.label);
					return (
						<tr
							key={d.label}
							style={{
								height: tableRowHeight,
								opacity: tableActive ? (isActive || isSelected ? 1 : 0.24) : 1,
							}}
							className={isActive || isSelected ? 'table__selected' : ''}
						>
							{dates.map((date, index) => {
								const label = getPeriod(date, interval.toLowerCase());
								return (
									<TableRow
										reportId={reportId}
										key={label}
										dataItem={d}
										index={index}
										odd={dates.length > 1 && index % 2 === 0}
										lifecycle={lifecycle}
										analysisType={analysisType}
										navigate={navigate}
										expanded={expanded}
										changeOption={changeOption}
										columnProps={columnProps}
									/>
								);
							})}
							<td />
						</tr>
					);
				})}
			</tbody>
		</table>
	);
}

interface IRightTableSingleProps {
	reportId?: string;
	lifecycle: string;
	analysisType: string;
	data: any[];
	date: any;
	index: number;
	interval: string;
	onSort: (columnName: string) => void;
	sortCriteria: SortCriteria;
	navigate: (...args: any[]) => void;
	disableLinks?: boolean;
	tableRowHeight: number;
	currentIndex: number;
	tableActive: boolean;
	columnProps: any[];
	hoveredItem?: string;
	selection: string[];
}

function RightTableSingle(props: IRightTableSingleProps) {
	const {
		hoveredItem,
		selection,
		reportId,
		lifecycle,
		analysisType,
		data,
		date,
		interval,
		onSort,
		sortCriteria,
		navigate,
		tableRowHeight,
		currentIndex,
		disableLinks,
		tableActive,
		columnProps,
	} = props;

	return (
		<table className="table__right-table">
			<thead>
				<tr style={{ height: tableRowHeight }}>
					<HeaderRow
						date={date}
						interval={interval}
						sortCriteria={sortCriteria}
						onSort={onSort}
						expanded
						columnProps={columnProps}
					/>
					<th />
				</tr>
			</thead>
			<tbody>
				{data.map(d => {
					const isActive = d.label === hoveredItem;
					const isSelected = selection.includes(d.label);
					return (
						<tr
							key={d.label}
							style={{
								height: tableRowHeight,
								opacity: tableActive ? (isActive || isSelected ? 1 : 0.24) : 1,
							}}
							className={isActive || isSelected ? 'table__selected' : ''}
						>
							<TableRow
								reportId={reportId}
								dataItem={d}
								index={currentIndex}
								lifecycle={lifecycle}
								analysisType={analysisType}
								navigate={navigate}
								expanded
								disableLinks={disableLinks}
								columnProps={columnProps}
							/>
							<td />
						</tr>
					);
				})}
			</tbody>
		</table>
	);
}

interface ITableProps {
	reportId?: string;
	data: any[];
	dates: any[];
	currentIndex: number;
	lifecycle: string;
	analysisType: string;
	interval: string;
	pending: boolean;
	width: number;
	tableRowHeight: number;
	options: any;
	height?: number;
	useMaxHeightOnTable?: boolean;
	navigate: (...args: any[]) => void;
	disableLinks?: boolean;
	onHover: any;
	onHoverEnd: any;
	onToggle: any;
	expanded?: boolean;
	toggleExpansion?: () => void;
	sortCriteria: SortCriteria;
	onSort: (columnName: string) => void;
	dashboardName?: string;
	title?: string;
	columnProps: any[];
	hoveredItem?: string;
	selection: string[];
}

export default function Table({
	hoveredItem,
	selection,
	reportId,
	lifecycle,
	analysisType,
	dates,
	currentIndex,
	interval,
	pending,
	width,
	tableRowHeight,
	options,
	height,
	useMaxHeightOnTable,
	navigate,
	disableLinks,
	onHover,
	onHoverEnd,
	onToggle,
	expanded,
	toggleExpansion,
	data,
	sortCriteria,
	onSort,
	dashboardName,
	title,
	columnProps,
}: ITableProps) {
	const [showLeft, showRight, paddingTop, containerRef, tableRef] = useTableScroll(!pending);

	const tableActive = !!hoveredItem || !!selection.length;

	function handleToggle(...args: any[]) {
		track(MixPanel.Events.DashboardReportTableRowHighlight, {
			'Dashboard Name': dashboardName,
			'Report Name': title,
		});
		onToggle(...args);
	}

	function handleNavigate(...args: any[]) {
		track(MixPanel.Events.DashboardReportTableValueClick, {
			'Dashboard Name': dashboardName,
			'Report Name': title,
		});
		navigate(...args);
	}

	const showSinglePeriod =
		typeof options.showSinglePeriod === 'string' ? options.showSinglePeriod === 'true' : options.showSinglePeriod;

	const { changeOption = 'None' } = options;

	return (
		<div
			className={showLeft || showRight ? 'table table--extended' : 'table'}
			// @ts-ignore
			ref={containerRef}
			style={{
				width,
				maxHeight: useMaxHeightOnTable ? height : undefined,
			}}
		>
			<div className="table__left">
				<LeftTable
					data={data}
					onSort={onSort}
					sortCriteria={sortCriteria}
					tableRowHeight={tableRowHeight}
					showSinglePeriod={showSinglePeriod}
					onHover={onHover}
					onHoverEnd={onHoverEnd}
					onToggle={handleToggle}
					tableActive={tableActive}
					hoveredItem={hoveredItem}
					selection={selection}
				/>
			</div>
			<div
				className="table__right"
				// @ts-ignore
				ref={tableRef}
			>
				{showSinglePeriod ? (
					<RightTableSingle
						reportId={reportId}
						lifecycle={lifecycle}
						analysisType={analysisType}
						data={data}
						currentIndex={currentIndex}
						date={dates[currentIndex]}
						index={dates.length - 1}
						interval={interval}
						onSort={onSort}
						sortCriteria={sortCriteria}
						tableRowHeight={tableRowHeight}
						navigate={handleNavigate}
						disableLinks={disableLinks}
						tableActive={tableActive}
						columnProps={columnProps}
						hoveredItem={hoveredItem}
						selection={selection}
					/>
				) : (
					<RightTable
						hoveredItem={hoveredItem}
						selection={selection}
						reportId={reportId}
						lifecycle={lifecycle}
						analysisType={analysisType}
						data={data}
						dates={dates}
						interval={interval}
						onSort={onSort}
						sortCriteria={sortCriteria}
						expanded={expanded}
						toggleExpansion={toggleExpansion}
						tableRowHeight={tableRowHeight}
						navigate={handleNavigate}
						changeOption={changeOption}
						tableActive={tableActive}
						columnProps={columnProps}
					/>
				)}
			</div>
			<ScrollArrows
				showLeft={showLeft}
				showRight={showRight}
				paddingTop={paddingTop}
				scrollRef={tableRef}
				scrollWidth={145}
			/>
		</div>
	);
}
