import { useAppSelector } from '../store/hooks';

function IEBar() {
	const ie = useAppSelector(state => state.appState.ie);
	return ie ? (
		<div className="ie-bar">
			<div className="ie-bar__text">
				You are using an out-of-date web browser that is not supported by Dandi. Please upgrade to a modern
				browser, including:{' '}
				<a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
					Google Chrome
				</a>
				,{' '}
				<a
					href="https://www.mozilla.org/en-US/firefox/download/thanks/"
					target="_blank"
					rel="noopener noreferrer"
				>
					Firefox
				</a>
				,{' '}
				<a href="https://www.microsoft.com/en-us/edge" target="_blank" rel="noopener noreferrer">
					Microsoft Edge
				</a>
				, or{' '}
				<a href="https://support.apple.com/downloads/safari" target="_blank" rel="noopener noreferrer">
					Safari
				</a>
			</div>
		</div>
	) : null;
}

export default IEBar;
