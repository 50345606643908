import { Fragment, useContext, useRef } from 'react';

import { useAppSelector } from '../../store/hooks';
import { appModes } from '../../types';
import { EventsContext } from './Events';

const NormalizedEvents = () => {
	const { isEventHidden, getLabelPosition, dispatch, isSinglePoint, hoverId, selection, getCorrectDate, xScale } =
		useContext(EventsContext);
	const titleRef = useRef(null);
	const { interval, events } = useAppSelector(state => {
		switch (state.appState.mode) {
			case appModes.explore: {
				return {
					analysisType: state.explore.chart.analysisType,
					interval: state.explore.chart.interval,
					dates: state.explore.chart.dates,
					events: state.timeline.events.filter(ev => state.explore.chart.eventIds.includes(ev.eventId)) || [],
				};
			}
			case appModes.measure: {
				return {
					analysisType: state.measure.goal.analysisType,
					interval: state.measure.goal.interval,
					dates: state.measure.goal.dates,
					events: state.timeline.events.filter(ev => state.measure.goal.eventIds.includes(ev.eventId)) || [],
				};
			}
			default: {
				return {
					analysisType: state.editor.chart.analysisType,
					interval: state.editor.chart.interval,
					dates: state.editor.chart.dates,
					events: state.timeline.events.filter(ev => state.editor.chart.eventIds.includes(ev.eventId)) || [],
				};
			}
		}
	});

	return (
		<Fragment>
			{events.map((event: any, i) => (
				<div key={i} className="chart-events__row" data-hidden={isEventHidden(event)}>
					<div
						className="chart-events__event"
						data-label-position={getLabelPosition(event)}
						data-single-point={isSinglePoint(event)}
						onMouseEnter={() => {
							dispatch({
								type: 'onHover',
								payload: event.eventId,
							});
						}}
						onMouseLeave={() => {
							dispatch({
								type: 'onLeave',
							});
						}}
						onClick={e => {
							e.stopPropagation();
							dispatch({
								type: 'onToggle',
								payload: event.eventId,
							});
						}}
						data-active={hoverId === event.eventId || selection.includes(event.eventId)}
						style={
							{
								'--xStart': xScale(getCorrectDate(event, interval, true)) + '%',
								'--xEnd': xScale(getCorrectDate(event, interval, false)) + '%',
							} as React.CSSProperties
						}
					>
						<span className="chart-events__title" ref={titleRef}>
							{event.name}
						</span>
					</div>
				</div>
			))}
		</Fragment>
	);
};

export default NormalizedEvents;
