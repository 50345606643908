import ActionTypes from '../../constants/ActionTypes';
import { generate, getTemplateFromAnalysisName } from '../../store/actionUtils';
import { FilterSegment, Segment } from '../../types';

export function clearFilters() {
	return (dispatch: any) => {
		dispatch({
			type: ActionTypes.ClearFiltersInEditor,
		});
	};
}

export function setIntervalInEditor(interval: string) {
	return (dispatch: any, getState: any) => {
		const { latestDataUpdate } = getState().account;
		dispatch({
			type: ActionTypes.SetIntervalInEditor,
			interval,
			latestDataUpdate,
		});
		dispatch(generateInEditor());
	};
}

export function setIntervalCountInEditor(intervalCount: number) {
	return (dispatch: any) => {
		dispatch({
			type: ActionTypes.SetIntervalCountInEditor,
			intervalCount,
		});
		dispatch(generateInEditor());
	};
}

export function setIntervalRangeInEditor(startDate: string, endDate: string) {
	return (dispatch: any) => {
		dispatch({
			type: ActionTypes.SetIntervalRangeInEditor,
			startDate,
			endDate,
		});
		dispatch(generateInEditor());
	};
}

export function generateInEditor() {
	return (dispatch: any, getState: any) => {
		const {
			analysisName,
			mainSegments,
			filterSegment,
			breakdownSegment,
			attributes,
			interval,
			intervalCount,
			startDate,
			endDate,
			date,
			subAnalyses,
		} = getState().editor.filter;
		let { chartType } = getState().editor.chart;
		const { eventIds } = getState().editor.chart;

		const state = {
			interval,
			intervalCount,
			startDate,
			endDate,
			date,
			analysisName,
			subAnalyses,
			mainSegments,
			filterSegment,
			breakdownSegment,
			attributes,
			eventIds,
			chartType,
		};

		const actionTypes = {
			reportPending: ActionTypes.GeneratePendingInEditor,
			subtablePending: ActionTypes.GenerateMatrixPendingInEditor,
			reportRejected: ActionTypes.GenerateRejectedInEditor,
			subtableRejected: ActionTypes.GenerateMatrixRejectedInEditor,
			invalidVariation: ActionTypes.GenerateInvalidVariationInEditor,
			noData: ActionTypes.GenerateNoDataInEditor,
			subtableFulfilled: ActionTypes.GenerateMatrixFulfilledInEditor,
			reportFulfilled: ActionTypes.GenerateFulfilledInEditor,
			primaryDataFulfilled: ActionTypes.GeneratePrimaryDataFulfilledInEditor,
		};

		return generate(getState, dispatch, state, actionTypes);
	};
}

export function setFiltersFromReport(report: any) {
	return (dispatch: any) => {
		const { analysisName } = report;
		dispatch(getTemplateFromAnalysisName(analysisName)).then((template: any) => {
			dispatch({
				type: ActionTypes.SetFiltersFromReport,
				report,
				template,
			});
		});
	};
}

export function setAnalysisName({ analysisDisplayName, analysisName, subAnalyses }: any) {
	return (dispatch: any) => {
		if (!analysisName) {
			return dispatch({
				type: ActionTypes.ClearFiltersInEditor,
			});
		}
		dispatch({
			type: ActionTypes.SetAnalysisNameInEditor,
			analysisDisplayName,
			analysisName,
			subAnalyses,
		});
		return dispatch(getTemplateFromAnalysisName(analysisName)).then((template: any) => {
			const { mainNames, filterNames, breakdownNames, variations, supportedGraphs, analysisType } = template;
			dispatch({
				type: ActionTypes.SetAnalysisNameInEditor,
				analysisDisplayName,
				analysisName,
				analysisType,
				subAnalyses,
				mainNames,
				filterNames,
				breakdownNames,
				variations,
				supportedGraphs,
			});
		});
	};
}

export function updateMainSegments(fields: Segment[]) {
	return {
		type: ActionTypes.UpdateMainSegmentsInEditor,
		fields,
	};
}

export function updateFilterSegment(fields: FilterSegment[]) {
	return {
		type: ActionTypes.UpdateFilterSegmentInEditor,
		segment: fields.length ? fields[0] : undefined,
	};
}

export function updateBreakdownSegment(fields: Segment[]) {
	return {
		type: ActionTypes.UpdateBreakdownSegmentInEditor,
		segment: fields.length ? fields[0] : undefined,
	};
}
