import { Component, ReactNode, useEffect } from 'react';
import { Animate } from 'react-move';
import { connect } from 'react-redux';

import Constants from '../constants/Constants';
import Close from '../icons/Close';
import getInterpolator from '../lib/getInterpolator';
import { RootState } from '../store/store';
import { closeMessage } from './actions';

interface MessageProps {
	id?: number;
	text?: string;
	onClose?: any;
	error?: boolean;
	opacity?: number;
	node?: ReactNode;
}

function Message(props: MessageProps) {
	const { id, text, onClose, opacity, error, node } = props;

	useEffect(() => {
		const timeout = setTimeout(onClose, 5000);

		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, [id]);

	return (
		<div className={error ? 'message-bar message-bar--error' : 'message-bar'} style={{ opacity }}>
			<div className="message-bar__text">{text || node}</div>
			<div className="message-bar__close">
				<Close width={28} height={28} className="btn-icon--grey" onClick={onClose} />
			</div>
		</div>
	);
}

function mapStateToProps(state: RootState) {
	const { messages } = state;
	return messages.length
		? {
				...messages[0],
				count: messages.length,
		  }
		: { count: 0 };
}

const dispatchProps = {
	onClose: closeMessage,
};

const connector = connect(mapStateToProps, dispatchProps);

class MessageBar extends Component<any, {}> {
	static defaultProps = {
		error: false,
	};

	public shouldComponentUpdate(nextProps: any) {
		// don't update when messages are added when message bar is shown.
		return this.props.count === 0 || nextProps.count < this.props.count;
	}

	public render() {
		const { id, text, node, error, onClose } = this.props;
		return (
			<Animate
				show={!!text || !!node}
				start={{
					opacity: 0,
					error,
				}}
				enter={{
					opacity: [1],
					timing: {
						duration: Constants.AnimationDuration,
						ease: Constants.EasingFn,
					},
				}}
				update={[
					{
						opacity: [0],
						timing: {
							duration: 0,
							ease: Constants.EasingFn,
						},
					},
					{
						opacity: [1],
						error: [error],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					},
				]}
				leave={{
					opacity: [0],
					timing: {
						duration: Constants.AnimationDuration,
						ease: Constants.EasingFn,
					},
				}}
				interpolation={getInterpolator}
			>
				{({ opacity, error }) => (
					<Message id={id} text={text} node={node} opacity={opacity} onClose={onClose} error={error} />
				)}
			</Animate>
		);
	}
}

export default connector(MessageBar);
