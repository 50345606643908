import {
	FormEvent,
	KeyboardEvent,
	PropsWithChildren,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useParams } from 'react-router';

import AiAvatar from '../common/AiAvatar';
import Feedback from '../common/Feedback';
import { GradientLayer } from '../common/GradientButton';
import MixPanel from '../constants/MixPanel';
import ArrowIcon from '../icons/Arrow';
import UserAvatarIcon from '../icons/UserAvatar';
import cn from '../lib/cn';
import { fireDb } from '../lib/firebase';
import getAvatarFill from '../lib/getAvatarFill';
import { track } from '../lib/segment';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { ChatMessage, ChatMessageRoles } from './aiTypes';
import { invokeChat } from './chat/actions';
import { resetChat } from './chat/reducer';
import AiInput from './components/AiInput';
import LoadingSkeleton from './components/LoadingSkeleton';
import PlainText from './components/PlainText';
import LeftPanel from './ConversationsList';
import './styles.scss';
import useChat from './useChat';

const TEXT_AREA_ROWS = 3;
const ERROR_RESPONSE =
	'We apologize, but there is an internal issue preventing us from displaying your AI-generated response at the moment. Please try again and if the issue persists, feel free to contact our support team for assistance.';
const PROMPTS = [
	{ question: 'What is the total headcount?' },
	{ question: 'What is the employee distribution by ethnicity?' },
	{ question: 'How many employees did we hire last month?' },
	{ question: 'Which departments did we hire employees in last month?' },
	{
		question: 'Which location had the highest retention over the last year and what was the retention rate?',
	},
	{
		question: 'Which department has the highest attrition rate over the past year and what was the attrition rate?',
	},
];

export default function AiPage() {
	return (
		<div
			className={cn(
				'w-full grid grid-cols-1 gap-4 px-2 relative overflow-hidden',
				'lg:grid-cols-12 lg:px-8',
				'sm:grid-cols-1 sm:px-2',
				'h-screen'
			)}
			style={{
				'--header-height': '60px',
				background: 'var(--color-shade-h1)',
			}}
		>
			<LeftPanel />
			<ChatWindow />
		</div>
	);
}

interface HTMLDivElementScrollable extends HTMLDivElement {
	scrollTimeout?: number;
}

const ChatWindow: React.FC = () => {
	useChat();
	const comp = useRef<HTMLDivElementScrollable>(null);

	useEffect(() => {
		const handleScroll = () => {
			if (comp.current) {
				comp.current.classList.add('scrolling');
				clearTimeout(comp.current.scrollTimeout);

				comp.current.scrollTimeout = window.setTimeout(() => {
					if (comp.current) {
						comp.current.classList.remove('scrolling');
					}
				}, 2000);
			}
		};

		const container = comp.current;
		if (container) {
			container.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (container) {
				container.removeEventListener('scroll', handleScroll);
			}
		};
	}, []);

	return (
		<div
			className={cn(
				'h-full relative flex flex-col justify-between overflow-hidden',
				'lg:col-[5/-1] sm:col-span-full col-span-full'
			)}
		>
			<div
				className={cn(
					'absolute top-0 right-[18px] bottom-[80px] left-0 overflow-y-auto pt-[88px] pb-[80px]',
					'scrolled-chat-window'
				)}
				ref={comp}
			>
				<div className={cn('flex-1 w-[100%] lg:w-[75%]')}>
					<ChatContainer />
				</div>
			</div>
			<ChatBottom />
		</div>
	);
};

const ChatContainer: React.FC = () => {
	const { conversationId } = useParams<any>();
	const newChat = !conversationId;

	return <div className={cn('flex flex-col p-[16px]')}>{newChat ? <NewChat /> : <MessageContainer />}</div>;
};

const NewChat: React.FC = () => {
	const { firstName } = useAppSelector(state => state.auth.userInfo);
	const { enterpriseId } = useAppSelector(state => state.account);
	const [prompts, setPrompts] = useState<{ question: string }[]>([]);

	const getPrompts = useCallback(async () => {
		try {
			const docRef = fireDb.collection('ai_default_prompts').doc(enterpriseId);
			const doc = await docRef.get();
			const data = doc.data();
			if (doc.exists && data?.prompts?.length) {
				setPrompts(data.prompts.map((prompt: string) => ({ question: prompt })));
			} else {
				setPrompts(PROMPTS);
			}
		} catch (error) {
			console.error('Error fetching prompts:', error);
			setPrompts(PROMPTS);
		}
	}, [enterpriseId]);

	useEffect(() => {
		if (!prompts.length) getPrompts();
	}, [prompts]);

	return (
		<div className={cn('vertical ')}>
			<h1 className={cn('font-display text-ui-100 text-[54px] mb-[72px] leading-tight')}>Hi {firstName}!</h1>
			<div className={cn('px-[16px]')}>
				<AIPlainMessage>
					<p>{`Welcome to Dandi AI. Please note that Dandi AI is currently in preview mode. To get started, choose a sample or enter your own question about your people data below.`}</p>
					<p>{`After Dandi responds, feel free to ask follow up questions and continue the conversation. To start a fresh chat, click on New Chat at the top left of this page.`}</p>
				</AIPlainMessage>
				<div className={cn('grid grid-cols-1 gap-6 wide:gap-8 pt-6 wide:grid-cols-3')}>
					{prompts.map((prompt, index) => (
						<PromptSuggestion key={index} prompt={prompt.question} />
					))}
				</div>
			</div>
		</div>
	);
};

interface PromptSuggestionProps {
	prompt: string;
}

const PromptSuggestion: React.FC<PromptSuggestionProps> = ({ prompt }) => {
	const dispatch = useAppDispatch();
	const { pending } = useAppSelector(state => state.ai.chat);

	const handleSubmit = async (e: React.MouseEvent<HTMLDivElement>) => {
		e.preventDefault();

		if (!pending) {
			track(MixPanel.Events.AIChatPageClickDefaultPrompt, { prompt });
			dispatch(resetChat());
			dispatch(invokeChat({ prompt }));
		}
	};

	return (
		<div
			className={cn(
				'relative group flex p-[16px] wide:p-8 rounded-[2rem] bg-shade-3 text-ui-75',
				'hover:bg-shade-4 hover:text-dark-ui-100',
				'hover:shadow-default hover:animate-fade-in-gradient',
				'group-hover:animate-fade-in-gradient',
				'cursor-pointer',
				'horizontal xl:vertical justify-between gap-0 wide:gap-[2rem]',
				'text-[16px]',
				'transition-all duration-300 ease-in-out overflow-hidden',
				'w-[fit-content] wide:w-full max-w-full',
				'pr-[48px] wide:pr-8'
			)}
			onClick={handleSubmit}
		>
			<GradientLayer
				className={cn(
					'group-hover:visible group-hover:opacity-100 invisible opacity-0 transition-all duration-300 ease-in-out'
				)}
			/>
			<div className={cn('leading-snug w-[fit-content] wide:w-full z-10')}>{prompt}</div>
			<ArrowIcon
				className={cn(
					'-rotate-45 origin-center self-end text-ui-30 group-hover:text-dark-ui-100 z-10',
					'absolute top-[16px] right-[16px]',
					'wide:relative wide:top-0 wide:right-0'
				)}
			/>
		</div>
	);
};

const MessageContainer: React.FC = () => {
	const { chatResponseError, chatResponseLoaded, chatResponsePending, messages } = useAppSelector(
		state => state.ai.chat
	);
	const [historyMessages, lastMessages] = useMemo(() => {
		if (!chatResponseLoaded && !chatResponsePending && !chatResponseError) {
			return [messages, []];
		}
		let lastUserMessageIndex = -1;

		messages.forEach((message, index) => {
			if (message.role === ChatMessageRoles.USER) {
				lastUserMessageIndex = index;
			}
		});

		if (lastUserMessageIndex === -1) {
			return [messages, []];
		}

		const historyMessages = messages.slice(0, lastUserMessageIndex);
		const lastMessages = messages.slice(lastUserMessageIndex, messages.length);

		return [historyMessages, lastMessages];
	}, [chatResponseError, chatResponseLoaded, chatResponsePending, messages]);

	return (
		<div className={cn('vertical gap-[1.6rem] pl-[16px] lg:pl-0')}>
			{historyMessages &&
				historyMessages.map((message, index) => {
					if (message.role === ChatMessageRoles.USER) return <UserMessage message={message} key={index} />;
					if (message.role === ChatMessageRoles.ASSISTANT)
						return <AIMessage message={message} key={index} idx={index} />;
				})}
			{lastMessages.length > 0 && (
				<LastMessage
					chatResponseLoaded={chatResponseLoaded}
					chatResponsePending={chatResponsePending}
					chatResponseError={chatResponseError}
					idx={messages.length - 1}
					lastMessages={lastMessages}
				/>
			)}
		</div>
	);
};

interface LastMessageProps {
	chatResponseError?: string;
	chatResponseLoaded: boolean;
	chatResponsePending: boolean;
	idx: number;
	lastMessages: ChatMessage[];
}

const LastMessage: React.FC<LastMessageProps> = ({
	chatResponseError,
	chatResponseLoaded,
	chatResponsePending,
	idx,
	lastMessages,
}) => {
	const scrollToRef = useRef<HTMLDivElement>(null);
	const loaded = chatResponseLoaded && lastMessages?.[1];

	useLayoutEffect(() => {
		if (scrollToRef.current) {
			scrollToRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
			});
		}
	}, [lastMessages]);

	return (
		<div
			ref={scrollToRef}
			className={cn('vertical gap-[1.6rem]')}
			style={{
				minHeight: 'calc(100vh - 280px)',
			}}
		>
			<UserMessage message={lastMessages[0]} />
			{chatResponsePending && (
				<div className={cn('py-[3.2rem]')}>
					<AIPlainMessage className="!w-avail">
						<LoadingSkeleton />
					</AIPlainMessage>
				</div>
			)}
			{loaded && <AIMessage message={lastMessages[1]} idx={idx} />}
			{chatResponseError && <ErrorMessage message={ERROR_RESPONSE} />}
		</div>
	);
};

const UserMessage: React.FC<{ message: ChatMessage }> = ({ message }) => {
	const { email, lightMode } = useAppSelector(state => state.auth.userInfo);
	const colorCode = getAvatarFill(email || '');

	return (
		<div
			className={cn(
				'w-fit pl-[2rem] pr-[2.4rem] py-[1rem] rounded-[1rem] self-end relative text-[1.6rem] z-auto '
			)}
			style={{
				'--bg': `var(--color-graph-${colorCode})`,
				'--overlayBg': lightMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
				background: `linear-gradient(to right, var(--overlayBg), var(--overlayBg)), linear-gradient(to right, var(--bg), var(--bg))`,
			}}
		>
			<UserAvatarIcon
				className={cn('absolute top-[-16px] right-[-16px] w-[32px] h-[32px] z-[3]')}
				style={{
					color: `var(--color-graph-${colorCode})`,
				}}
			/>
			<span className={cn('relative z-[3]')}>{message?.content}</span>
		</div>
	);
};

const ErrorMessage: React.FC<{ message: string }> = ({ message }) => {
	return (
		<div className={cn('py-[3.2rem] ')}>
			<AIPlainMessage>
				<PlainText>{message}</PlainText>
			</AIPlainMessage>
		</div>
	);
};

const AIMessage: React.FC<{ message: ChatMessage; idx: number }> = ({ message, idx }) => {
	return (
		<div className={cn('py-[3.2rem] ')}>
			<AIPlainMessage>
				<PlainText>{message.content}</PlainText>
			</AIPlainMessage>
			<ActionsBar>
				<div className={cn('ml-[.8rem]')}>
					<Feedback isChat={true} messageIdx={idx} feedback={message.feedback} />
				</div>
			</ActionsBar>
		</div>
	);
};

const AIPlainMessage = ({ children, className }: any) => {
	return (
		<div className={cn('relative text-[16px] w-fit px-[2rem] py-[.8rem]', className)}>
			<div className={cn('absolute top-[-16px] left-[-16px]')}>
				<AiAvatar size={32} />
			</div>
			{children}
		</div>
	);
};

const ActionsBar: React.FC<PropsWithChildren> = ({ children }) => {
	return <div className={cn('flex items-center justify-between pb-[1rem]')}>{children}</div>;
};

const ChatBottom: React.FC = () => {
	const { conversationId } = useParams<{ conversationId: string }>();
	const dispatch = useAppDispatch();
	const [value, setValue] = useState('');
	const { pending } = useAppSelector(state => state.ai.chat);

	const handleSubmit = async (e: KeyboardEvent<Element> | FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!pending && value.trim() !== '') {
			track(MixPanel.Events.AIChatPageEnterPrompt, { prompt: value });
			dispatch(invokeChat({ prompt: value, conversationId }));
			setValue('');
		}
	};

	return (
		<div
			className={cn(
				'pb-[32px] flex justify-center items-center absolute bottom-0 left-0 w-[100%] lg:w-[75%] z-[100] bg-shade-h1',
				'after:[content-""] after:absolute after:top-[-80px] after:left-0 after:w-full after:h-[80px] after:z-[1]',
				'after:bg-gradient-to-t after:from-shade-h1'
			)}
		>
			<div className={cn('w-full px-[16px] flex flex-col items-center relative z-[10]')}>
				<form className={cn('w-full flex items-center gap-2')} onSubmit={handleSubmit}>
					<AiInput
						onSubmit={handleSubmit}
						onChange={e => setValue(e.target.value)}
						placeholder="Enter a prompt for Dandi AI"
						value={value}
						rows={TEXT_AREA_ROWS}
						autoFocus
					/>
					<button
						type="submit"
						className={cn('absolute top-[12px] right-[36px] rounded-full')}
						disabled={value.trim() === ''}
					>
						<ArrowIcon
							className={cn(
								'-rotate-45 origin-center self-end text-ui-30 group-hover:text-ui-100',
								value !== '' ? 'text-ui-100' : 'text-ui-30'
							)}
							size={24}
						/>
					</button>
				</form>
			</div>
		</div>
	);
};
