import produce from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useState } from 'react';

import Button from '../../common/Button';
import Dropdown from '../../common/Dropdown';
import ButtonTypes from '../../constants/ButtonTypes';
import Accordion from '../Accordion';
import { generateTypeOptions } from '../constants';
import ExclusionRules from './ExclusionRules';
import Formulas from './Formulas';
import InnerVariables from './InnerVariables';
import { ConfigurationItem } from './model';

interface VariableEditProps {
	label: string;
	index: number;
	variable: ConfigurationItem;
	setConfigurationVariables: any;
	expandNow: boolean;
	setExpandNow: (val: boolean) => void;
}

export default function VariableEdit({
	label,
	index,
	variable,
	setConfigurationVariables,
	expandNow,
	setExpandNow,
}: VariableEditProps) {
	const [expanded, setExpanded] = useState(expandNow);

	useEffect(() => {
		if (expandNow) {
			setExpandNow(false);
		}
	}, [expandNow]);

	function handleGenerateTypeChange(value: string) {
		setConfigurationVariables((configurationVariables: any) =>
			produce(configurationVariables, (draftState: any) => {
				draftState[index].generateType = value;
			})
		);
	}

	function handleRemove() {
		setConfigurationVariables((configurationVariables: any) =>
			produce(configurationVariables, (draftState: any) => {
				draftState.splice(index, 1);
			})
		);
	}

	function handleCopy() {
		setExpandNow(true);
		setConfigurationVariables((configurationVariables: any) =>
			produce(configurationVariables, (draftState: any) => {
				const configVariable = cloneDeep(draftState[index]);
				draftState.push(configVariable);
			})
		);
	}

	return (
		<div style={{ display: 'flex' }}>
			<Accordion
				className="ds-accordion--cv"
				label={label}
				expanded={expanded}
				setExpanded={setExpanded}
				remove={handleRemove}
			>
				<InnerVariables
					label="Main variables"
					mainVariables={variable.mainVariables}
					propName="mainVariables"
					index={index}
					setConfigurationVariables={setConfigurationVariables}
				/>
				<InnerVariables
					label="Control variables"
					mainVariables={variable.controlVariables}
					propName="controlVariables"
					index={index}
					setConfigurationVariables={setConfigurationVariables}
				/>
				<InnerVariables
					label="Additional variables"
					mainVariables={variable.additionalVariables}
					propName="additionalVariables"
					index={index}
					setConfigurationVariables={setConfigurationVariables}
				/>
				<Formulas
					index={index}
					formulas={variable.formulas}
					setConfigurationVariables={setConfigurationVariables}
				/>
				<ExclusionRules
					exclusionRules={variable.exclusionRules}
					setConfigurationVariables={setConfigurationVariables}
					index={index}
				/>
				<div className="ds-label">Generate type</div>
				<div className="ds-input" style={{ marginTop: 8 }}>
					<Dropdown
						placeholder="Select"
						options={generateTypeOptions}
						selectedOption={variable.generateType}
						onClick={handleGenerateTypeChange}
					/>
				</div>
			</Accordion>
			<Button componentType={ButtonTypes.type.SECONDARY} onClick={handleCopy} style={{ marginLeft: 8 }}>
				Copy
			</Button>
		</div>
	);
}
