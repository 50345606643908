import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={80} height={80} viewBox="0 0 80 80" fill="none" {...props}>
		<path
			fill="currentColor"
			d="M12.178 61.25c-1.174 0-2.178-.408-3.012-1.223-.833-.815-1.25-1.815-1.25-3S8.338 54.833 9.181 54c.843-.833 1.852-1.25 3.026-1.25 1.175 0 2.17.421 2.986 1.264.816.842 1.223 1.842 1.223 3 0 1.213-.406 2.222-1.218 3.028-.812.805-1.819 1.208-3.02 1.208Zm0-17c-1.174 0-2.178-.421-3.012-1.263-.833-.842-1.25-1.842-1.25-3S8.338 37.833 9.181 37c.843-.833 1.852-1.25 3.026-1.25 1.175 0 2.17.421 2.986 1.263.816.842 1.223 1.842 1.223 3S16.01 42.167 15.198 43c-.812.833-1.819 1.25-3.02 1.25Zm0-17.083c-1.174 0-2.178-.408-3.012-1.223-.833-.816-1.25-1.816-1.25-3 0-1.185.422-2.194 1.265-3.027.843-.834 1.852-1.25 3.026-1.25 1.175 0 2.17.42 2.986 1.263.816.843 1.223 1.843 1.223 3.001 0 1.213-.406 2.222-1.218 3.027-.812.806-1.819 1.209-3.02 1.209ZM25 61v-7.917h47.083V61H25Zm0-17.083v-7.834h47.083v7.834H25Zm0-17V19h47.083v7.917H25Z"
		/>
	</svg>
);
export default SvgComponent;
