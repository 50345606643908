import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} viewBox="0 0 21 21" fill="none" {...props}>
		<path
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M3.5 10.467h4M3.5 5.467h4M3.5 15.467h4M10.5 10.467h8M10.5 5.467h8M10.5 15.467h8"
		/>
	</svg>
);
export default SvgComponent;
