import { createContext, useContext } from 'react';

import { ColumnsDropdownProps } from './ColumnsDropdown';

interface IColumnDropdownContext {
	setIsOpen: (isOpen: boolean) => void;
	selectedOption?: any;
	setSelectedOption: (selectedOption: any) => void;
}

const initialContext: IColumnDropdownContext = {
	setIsOpen: () => null,
	selectedOption: null,
	setSelectedOption: () => null,
};

export const ColumnDropdownContext = createContext<IColumnDropdownContext>(initialContext);

const ColumnDropdownContextProvider = ({
	children,
	setIsOpen,
	selectedOption,
	setSelectedOption,
}: ColumnsDropdownProps) => {
	return (
		<ColumnDropdownContext.Provider
			value={{
				setIsOpen,
				selectedOption,
				setSelectedOption,
			}}
		>
			{children}
		</ColumnDropdownContext.Provider>
	);
};

const useColumnDropdownContext = () => useContext(ColumnDropdownContext);

export { ColumnDropdownContextProvider, useColumnDropdownContext };
