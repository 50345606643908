import SortableHead, { OnSortFunc, SortCriteria } from '../common/SortableHead';
import { CollectAttribute } from './model';

interface FieldNamesProps {
	attributes: CollectAttribute[];
	sortCriteria: SortCriteria;
	onSort: OnSortFunc;
}

export default function FieldNames({ attributes, sortCriteria, onSort }: FieldNamesProps) {
	return (
		<table className="collect-card__fields">
			<thead>
				<tr>
					<SortableHead
						label="Field"
						columnName="fieldName"
						sortCriteria={sortCriteria}
						onSort={onSort}
						className="collect-card__fields__title"
					/>
				</tr>
			</thead>
			<tbody>
				{attributes.map(a => (
					<tr key={a.fieldName}>
						<td className="collect-card__fields__field">{a.fieldName}</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}
