import { createSlice } from '@reduxjs/toolkit';

import { saveRole } from '../../roles/createRole/actions';
import { deleteRole, getRoles } from './actions';
import { IRoleState } from './model';

const initialState: IRoleState = {
	allRoles: [],
	pending: false,
	error: null,
	sortCriteria: {
		columnName: 'name',
		ascending: true,
	},
};

const rolesSlice = createSlice({
	name: 'roles',
	initialState,
	reducers: {
		setToDelete: (state, action) => {
			state.toDelete = action.payload;
		},
		clearToDelete: state => {
			state.toDelete = undefined;
		},
	},
	extraReducers: builder => {
		builder.addCase(getRoles.pending, (state, _) => {
			state.pending = true;
		});
		builder.addCase(getRoles.fulfilled, (state, action) => {
			state.allRoles = action.payload;
			state.pending = false;
		});
		builder.addCase(getRoles.rejected, (state, action) => {
			state.error = action.payload;
			state.pending = false;
		});
		builder.addCase(saveRole.fulfilled, (state, action) => {
			if (!state.allRoles.find(role => role.id === action.payload.id.id)) {
				state.allRoles = [...state.allRoles, action.payload.id];
			} else {
				const index = state.allRoles.findIndex(r => r.id === action.payload.id.id);
				state.allRoles[index] = action.payload.id;
			}
		});
		builder.addCase(deleteRole.pending, (state, _) => {
			state.pending = true;
		});
		builder.addCase(deleteRole.rejected, (state, action) => {
			state.error = action.payload;
			state.pending = false;
		});
		builder.addCase(deleteRole.fulfilled, (state, action) => {
			state.allRoles = state.allRoles.filter(role => role.id !== action.payload);
		});
	},
});

export const { setToDelete, clearToDelete } = rolesSlice.actions;

export default rolesSlice.reducer;
