import { RouteComponentProps } from 'react-router';

import Button from '../../../common/Button';
import ButtonTypes from '../../../constants/ButtonTypes';
import { useAppDispatch } from '../../../store/hooks';
import HeaderNav from '../common/HeaderNav';
import { setInitialised } from './editReducer';

export default function TemplatesHeader({ history }: RouteComponentProps) {
	const dispatch = useAppDispatch();

	function handleEdit() {
		dispatch(setInitialised(false));
		history.push('/datastudio/survey/template/edit');
	}

	return (
		<div className="survey-header">
			<HeaderNav />
			<Button componentType={ButtonTypes.type.PRIMARY} onClick={handleEdit}>
				New campaign template
			</Button>
		</div>
	);
}
