import Modal from 'antd/lib/modal';
import produce from 'immer';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import Button from '../../common/Button';
import Checkbox from '../../common/Checkbox';
import TextField from '../../common/TextField';
import ButtonTypes from '../../constants/ButtonTypes';
import MixPanel from '../../constants/MixPanel';
import TextFieldTypes from '../../constants/TextFieldTypes';
import { track } from '../../lib/segment';

export default function SelectDashboardModal(props) {
	const { isOpen, dashboards, onAdd, onCreate, onCancel, showDescription } = props;
	const [dashboardIds, setDashboardIds] = useState([]);
	const [searchText, setSearchText] = useState('');

	useEffect(() => {
		if (isOpen) {
			setDashboardIds([]);
			setSearchText('');
		}
	}, [isOpen]);

	function handleCancel() {
		onCancel();
		track(MixPanel.Events.DashboardAddToModalCancelClick);
	}

	function handleSave(e) {
		e.preventDefault();
		onAdd(dashboardIds);
		track(MixPanel.Events.DashboardAddToModalAddClick);
	}

	function handleCreate() {
		onCreate();
		track(MixPanel.Events.DashboardAddToModalCreateNewDashboardClick);
	}

	function handleChange(dashboardId, checked) {
		setDashboardIds(prevDashboardIds => {
			return produce(prevDashboardIds, draftState => {
				if (checked) {
					draftState.push(dashboardId);
				} else {
					const index = draftState.findIndex(d => d === dashboardId);
					if (index !== -1) {
						draftState.splice(index, 1);
					}
				}
			});
		});
		const dashboard = dashboards.find(d => d.dashboardId === dashboardId);
		if (dashboard) {
			const eventName = checked
				? MixPanel.Events.DashboardAddToModalSelectDashboard
				: MixPanel.Events.DashboardAddToModalDeselectDashboard;
			track(eventName, {
				'Dashboard Name': dashboard.name,
			});
		}
	}

	let filteredDashboards = dashboards;
	if (searchText) {
		filteredDashboards = dashboards.filter(d => d.name.toLowerCase().includes(searchText.toLowerCase()));
	}

	return (
		<Modal
			open={isOpen}
			width={450}
			footer={null}
			closable={false}
			maskClosable={true}
			onCancel={handleCancel}
			zIndex={3000}
		>
			<div className="select-dashboard-modal modal">
				<div className="modal__header">
					<div className="modal__title">Add to dashboard</div>
					<div className="modal__buttons">
						<Button componentType={ButtonTypes.type.TERTIARY} onClick={handleCancel} classes={['mr8']}>
							Cancel
						</Button>
						<Button onClick={handleSave} disabled={dashboardIds.length === 0}>
							Add
						</Button>
					</div>
				</div>
				<div className="modal__content">
					{showDescription && <div>Add this report to another dashboard</div>}
					<div
						className={
							dashboards.length === 0
								? 'select-dashboard-modal__create select-dashboard-modal__create--empty'
								: 'select-dashboard-modal__create'
						}
					>
						<Button onClick={handleCreate}>+ Create new dashboard</Button>
					</div>
					{dashboards.length > 5 && (
						<div className="select-dashboard-modal__search">
							<TextField
								componentType={TextFieldTypes.type.ROUND}
								placeholder="Search"
								onChange={e => setSearchText(e.target.value)}
								value={searchText}
								isSearch={true}
							/>
						</div>
					)}
					<div className="select-dashboard-modal__items">
						{filteredDashboards.length === 0 && dashboards.length !== 0 && (
							<div className="select-dashboard-modal__empty">No results for that search term</div>
						)}
						{filteredDashboards.map(d => {
							const checked = dashboardIds.includes(d.dashboardId);
							return (
								<div key={d.dashboardId} className="select-dashboard-modal__item">
									<Checkbox
										checked={checked}
										onClick={newState => handleChange(d.dashboardId, newState)}
									>
										<span>{d.name}</span>
									</Checkbox>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</Modal>
	);
}

SelectDashboardModal.propTypes = {
	isOpen: PropTypes.bool,
	onAdd: PropTypes.func,
	onCreate: PropTypes.func,
	onCancel: PropTypes.func,
	dashboards: PropTypes.array,
	showDescription: PropTypes.bool,
};
