import classNames from 'classnames';
import { Fragment, useEffect, useRef } from 'react';
import { Animate } from 'react-move';

import ButtonTypes from '../../constants/ButtonTypes';
import Constants from '../../constants/Constants';
import DropdownArrow from '../../icons/DropdownArrow';
import getInterpolator from '../../lib/getInterpolator';
import Button from '../Button';
import { ColumnDropdownContextProvider, useColumnDropdownContext } from './ColumnsDropdownContextProvider';

export interface ColumnsDropdownProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	children?: React.ReactNode;
	selectedOption?: any;
	setSelectedOption: (selectedOption: any) => void;
	dropdownClassName?: string;
}

const ColumnsDropdown = ({
	isOpen,
	setIsOpen,
	children,
	selectedOption,
	setSelectedOption,
	dropdownClassName,
	...rest
}: ColumnsDropdownProps) => {
	const ref = useRef<HTMLDivElement>(null);

	function handleClickOutside(e: any) {
		if (isOpen) {
			if (ref.current && !ref.current.contains(e.target)) {
				e.stopPropagation();
				setIsOpen(false);
			}
		}
	}

	useEffect(() => {
		if (isOpen) {
			window.addEventListener('mousedown', handleClickOutside);
		} else {
			window.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			window.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isOpen]);

	return (
		<ColumnDropdownContextProvider
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			selectedOption={selectedOption}
			setSelectedOption={setSelectedOption}
		>
			<div className="columns-dropdown" ref={ref} {...rest}>
				<div className="columns-dropdown__button">
					<Button
						componentType={ButtonTypes.type.SECONDARY}
						onClick={() => setIsOpen(!isOpen)}
						activated={isOpen}
					>
						{selectedOption || 'Select'}
						<DropdownArrow width={18} height={18} />
					</Button>
				</div>
				<Animate
					show={isOpen}
					start={() => ({
						opacity: 0.01,
						scale: 0.8,
					})}
					enter={() => ({
						opacity: [1],
						scale: [1],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
					leave={{
						opacity: [0.01],
						scale: [0.8],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					}}
					interpolation={getInterpolator}
				>
					{({ opacity, scale }) => {
						return (
							<div
								className={`columns-dropdown__columns menu ${dropdownClassName}`}
								style={{
									opacity,
									transform: `scale(${scale})`,
								}}
							>
								{isOpen && <div className="column-content">{children}</div>}
							</div>
						);
					}}
				</Animate>
			</div>
		</ColumnDropdownContextProvider>
	);
};

interface ColumnProps extends Pick<ColumnsDropdownProps, 'selectedOption'>, React.HtmlHTMLAttributes<HTMLDivElement> {
	options?: any[];
	header?: string;
	// setSelection?: (selection: any) => void;
}

const Column = ({ options, header }: ColumnProps) => {
	const { setIsOpen, selectedOption, setSelectedOption } = useColumnDropdownContext();

	const handleClick = (e: React.MouseEvent<HTMLDivElement>, option: any) => {
		e.stopPropagation();
		setIsOpen(false);
		setSelectedOption(option);
	};

	return (
		<div className="column" onBlur={() => setIsOpen(false)}>
			<span className="column__header">{header}</span>
			{options?.map((option, index) => (
				<Fragment key={index + JSON.stringify(option)}>
					<div
						onClick={e => {
							handleClick(e, option);
						}}
						className={classNames('column__option', {
							'column__option--selected': selectedOption === option,
						})}
					>
						<div className="circle">
							<div
								className={classNames({
									'circle--selected': selectedOption === option,
								})}
							></div>
						</div>
						{option}
					</div>
				</Fragment>
			))}
		</div>
	);
};

const Divider = () => {
	return <div className="column-divider"></div>;
};

ColumnsDropdown.Column = Column;
ColumnsDropdown.Divider = Divider;

export default ColumnsDropdown;
