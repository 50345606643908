import { push } from 'connected-react-router';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MdMenu } from 'react-icons/md';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import LeftPanel from '../ai/ConversationsList';
import { resetChat } from '../ai/chat/reducer';
import { setAiModalOpen } from '../ai/modal/reducer';
import AiAvatar from '../common/AiAvatar';
import Button from '../common/Button';
import ListItem from '../common/ListItem';
import Switch from '../common/Switch';
import WithPermissions from '../common/WithPermissions';
import ButtonTypes from '../constants/ButtonTypes';
import LSKeys from '../constants/LSKeys';
import ListItemTypes from '../constants/ListItemTypes';
import MixPanel from '../constants/MixPanel';
import RbacActions from '../constants/RbacActions';
import CreateDashboardModal from '../dashboards/CreateDashboardModal';
import AdminV2 from '../icons/AdminV2';
import ChevronLeft from '../icons/ChevronLeft';
import Close from '../icons/Close';
import Collect from '../icons/Collect';
import DandiLogo from '../icons/DandiLogo';
import Dashboards from '../icons/Dashboards';
import ExploreV2 from '../icons/ExploreV2';
import Goals from '../icons/Goals';
import MoonIcon from '../icons/Moon';
import Recent from '../icons/Recent';
import SelfId from '../icons/SelfId';
import SunIcon from '../icons/Sun';
import TimelineV2 from '../icons/TimelineV2';
import cn from '../lib/cn';
import hasPermission from '../lib/hasPermission';
import { track } from '../lib/segment';
import shouldShowBanner from '../lib/shouldShowBanner';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import Footer from './Footer';
import Notifications from './Notifications';
import Photo from './Photo';
import { setActorInfo } from './actions';

const DEFAULT_KEY = 'home';

const menu = [
	{
		sectionName: 'Dandi AI',
		route: '/ai',
		key: 'ai',
		icon: AiAvatar,
		routeParts: ['/ai'],
		actions: [RbacActions['Explore/View']],
		featureFlagName: 'aiChat',
	},
	{
		route: '/',
		sectionName: 'Dashboards',
		key: 'home',
		routeParts: [],
		icon: Dashboards,
		actions: [RbacActions['Dashboards/ViewAll'], RbacActions['Dashboards/ViewShared']],
	},
	{
		sectionName: 'Explore',
		route: '/explore',
		routeParts: ['/explore', '/employees'],
		key: 'explore',
		icon: ExploreV2,
		actions: [RbacActions['Explore/Generate']],
	},
	{
		sectionName: 'Self-ID',
		routeParts: ['/self-id'],
		route: '/self-id/campaigns',
		key: 'self-id',
		icon: SelfId,
		actions: [RbacActions['Campaign/View'], RbacActions['Campaign/Edit']],
		featureFlagName: 'selfid',
	},
	{
		sectionName: 'Goals',
		route: '/measure',
		routeParts: ['/measure'],
		key: 'measure',
		icon: Goals,
		actions: [RbacActions['Goals/View']],
	},
	{
		sectionName: 'Timeline',
		route: '/timeline',
		routeParts: ['/timeline'],
		key: 'timeline',
		icon: TimelineV2,
		actions: [RbacActions['Events/View']],
	},
	{
		sectionName: 'Collect',
		routeParts: ['/collect'],
		route: '/collect',
		key: 'collect',
		icon: Collect,
		actions: [RbacActions['Collect/View']],
	},
	{
		sectionName: 'Admin',
		route: '/admin',
		routeParts: ['/admin'],
		key: 'admin',
		icon: AdminV2,
		actions: [
			RbacActions['Users/View'],
			RbacActions['Roles/View'],
			RbacActions['Integrations/View'],
			RbacActions['Billing/View'],
			RbacActions['Users/Edit'],
			RbacActions['Settings/View'],
		],
	},
];

function getMenuItem(url: string) {
	return menu.find(m => {
		return m.routeParts.some(routePart => url.indexOf(routePart) === 0);
	});
}

function getSelectedKeys(url: string) {
	let selectedKeys = [DEFAULT_KEY];
	const menuItem = getMenuItem(url);
	if (menuItem) {
		selectedKeys = [menuItem.key];
	}

	return selectedKeys;
}

function TopMenu({ location, history }: RouteComponentProps) {
	const { pathname } = location;
	const dispatch = useAppDispatch();
	const { staticPermissions, userInfo } = useAppSelector(state => state.auth);
	let { latestDataUpdate, enterpriseName, featureFlags } = useAppSelector(state => state.account);
	latestDataUpdate = moment.utc(latestDataUpdate).format('MM/DD/YY');
	const showCreate = useAppSelector(state => state.dashboards.showCreate);

	const [selectedKeys, setSelectedKeys] = useState(getSelectedKeys(location.pathname));
	const [lightModeChecked, setLightModeChecked] = useState(localStorage.getItem(LSKeys.LightMode) === 'true');

	const [collapsed, setCollapsed] = useState(true);
	const [showConversationHistory, setShowConversationHistory] = useState(false);

	const isAiChat = location?.pathname === '/ai' || location?.pathname.includes('/ai/');
	const isConversationHistoryShowing = !collapsed && showConversationHistory;
	const isMenuShowing = !collapsed && !showConversationHistory;

	useEffect(() => {
		const selectedKeys = getSelectedKeys(location.pathname);
		setSelectedKeys(selectedKeys);
	}, [location.pathname]);
	useEffect(() => {
		setLightModeChecked(userInfo?.lightMode || false);
	}, [userInfo]);

	const menuRef = useRef<HTMLDivElement>(null);

	const handleClickOutside = useCallback((event: Event) => {
		if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
			setCollapsed(true);
		}
	}, []);

	const handleKeyDown = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				setCollapsed(true);
			}
		},
		[handleClickOutside]
	);

	useEffect(() => {
		if (!collapsed) {
			document.addEventListener('click', handleClickOutside);
			document.addEventListener('keydown', handleKeyDown);
			return () => {
				document.removeEventListener('click', handleClickOutside);
				document.removeEventListener('keydown', handleKeyDown);
			};
		}
	}, [collapsed, handleClickOutside]);

	useEffect(() => {
		// zendesk init
		if (window.zE && typeof window.zE === 'function') {
			window.zE(() => {
				setTimeout(() => {
					window.zE.identify({
						name: `${userInfo?.firstName} ${userInfo?.lastName}`,
						email: userInfo?.email,
						organization: enterpriseName,
					});
					window.zE.show();
				}, 2000);
			});
		}
	}, []);

	function handleCollapsedToggle(e: any) {
		e.preventDefault();
		e.stopPropagation();
		setCollapsed(!collapsed);
		setShowConversationHistory(false);
	}

	function handleConversationHistory() {
		track(MixPanel.Events.AIChatPageClickHistoryChat);
		setCollapsed(false);
		setShowConversationHistory(true);
	}

	const handleNewChat = () => {
		track(MixPanel.Events.AIChatPageClickNewChat);
		dispatch(resetChat());
		dispatch(push('/ai'));
	};

	function handleSelect(key: string) {
		setCollapsed(true);
		navigate(key);
	}

	function navigate(key: string) {
		const menuItem: any = menu.find(m => m.key === key);
		const { route, sectionName } = menuItem;
		track(MixPanel.Events.NavBarClick, {
			sectionName,
		});
		if (route !== pathname) {
			history.push(route);
			setSelectedKeys(getSelectedKeys(route));
			if (route === '/admin') {
				window.scrollTo(0, 0);
			}
		} else if (route === '/explore') {
			history.push(route);
		}
	}

	const filteredMenu = menu.filter(m => {
		let allowed = hasPermission(staticPermissions, m.actions);
		if (allowed && m.featureFlagName) {
			allowed = !!(featureFlags as any)[m.featureFlagName];
		}
		return allowed;
	});

	const filteredMenuItems = useMemo(() => {
		return filteredMenu.map(m => {
			const isSelected = selectedKeys.includes(m.key);

			return (
				<div
					key={m.key}
					className={cn('group nav__collapsible-menu__item')}
					style={{
						gap: m.key === 'ai' ? 0 : '1.3rem',
					}}
					onClick={() => handleSelect(m.key)}
				>
					<ListItem
						activated={isSelected}
						type={ListItemTypes.type.DEFAULT}
						// @ts-ignore
						className={cn(
							'pl-[.6rem]',
							m.key === 'ai' && isSelected && '!gap-[.8rem] pl-0',
							isSelected && 'nav__collapsible-menu__selected'
						)}
					>
						<m.icon
							{...(m.key === 'ai'
								? {
										isGray: !selectedKeys.includes(m.key),
										size: selectedKeys.includes(m.key) ? 35 : 24,
								  }
								: {})}
						/>
						{m.sectionName}
					</ListItem>
				</div>
			);
		});
	}, [filteredMenu, selectedKeys]);

	return (
		<div className={cn('layout__nav nav', shouldShowBanner() && 'layout__nav--banner')}>
			<div
				className={cn(
					'nav__header',
					isAiChat && collapsed && 'nav__header--aiChat',
					!collapsed && 'nav__header--active'
				)}
				onClick={handleCollapsedToggle}
			>
				<div className={cn('nav__header__heading gap-[2rem] cursor-pointer')}>
					<Button
						componentType={ButtonTypes.type.SECONDARY}
						classes={[cn('justify-start w-[3.5rem] h-[3.5rem] ml-[0.8rem] pl-[5px] py-0 pr-0')]}
					>
						{collapsed && <MdMenu size={24} className={cn('cursor-pointer')} />}
						{isConversationHistoryShowing && <ChevronLeft size={24} className={cn('cursor-pointer')} />}
						{isMenuShowing && <Close size={24} className={cn('cursor-pointer')} />}
					</Button>
					<span className="explore-header__title">
						{collapsed && (getMenuItem(location.pathname)?.sectionName || 'Dashboards')}
						{isConversationHistoryShowing && 'Back'}
						{isMenuShowing && 'Close'}
					</span>
				</div>
			</div>
			<div
				className={cn('nav__collapsible-menu', {
					'w-0': collapsed,
					'w-full sm:w-[32rem]': !collapsed,
				})}
				ref={menuRef}
			>
				{isConversationHistoryShowing ? (
					<div className={cn('relative w-full h-full left-0', collapsed && 'hidden')}>
						<LeftPanel
							className={cn(
								'absolute top-0 left-0 h-full pt-[70px] px-[2rem] pb-[1.6rem] vertical gap-10',
								'w-full lg:w-[320px]',
								'flex lg:hidden'
							)}
							styles={{ background: 'var(--color-shade-h1)' }}
						/>
					</div>
				) : (
					<div className={cn(collapsed && 'hidden')}>
						{filteredMenuItems}
						<div style={{ height: '100vh' }}></div>
						<div className={cn('absolute bottom-[70px] items-center sm:inline-flex lg:hidden inline-flex')}>
							<div className="nav__menu__toggle">
								<Switch
									checked={lightModeChecked}
									onChange={() =>
										dispatch(
											setActorInfo({
												...userInfo,
												lightMode: !userInfo?.lightMode,
											})
										)
									}
									toggleIcon={lightModeChecked ? <SunIcon /> : <MoonIcon />}
								/>
							</div>
							<Notifications />
							<Photo />
						</div>
						<div className={cn('absolute bottom-[60px] items-center inline-flex')}>
							<Footer />
						</div>
						<DandiLogo
							className={cn('absolute bottom-[30px] right-[20px] lg:bottom-[80px] lg:left-[20px]')}
							fill={'var(--color-ui-100)'}
						/>
					</div>
				)}
			</div>
			<div className={cn('lg:inline-flex hidden h-[60px] right-[2rem] fixed items-center top-0')}>
				{featureFlags.aiChat && !selectedKeys.includes('ai') && (
					<WithPermissions actions={[RbacActions['Dashboards/Edit']]}>
						<Button
							onClick={() => {
								dispatch(resetChat());
								dispatch(setAiModalOpen(true));
							}}
							componentType={ButtonTypes.type.SECONDARY}
							style={{
								paddingLeft: '0px',
								paddingTop: '0px',
								paddingBottom: '0px',
							}}
						>
							<div className={cn('flex items-center gap-[.8rem]')}>
								<AiAvatar size={35} />
								Dandi AI (Preview)
							</div>
						</Button>
					</WithPermissions>
				)}
				<div className={cn('pl-[2rem]')}>
					<Switch
						checked={lightModeChecked}
						onChange={() =>
							dispatch(
								setActorInfo({
									...userInfo,
									lightMode: !userInfo?.lightMode,
								})
							)
						}
						toggleIcon={lightModeChecked ? <SunIcon /> : <MoonIcon />}
					/>
				</div>
				<Notifications />
				<Photo />
			</div>
			<div className={cn('nav__links lg:hidden inline-flex py-[12px]')}>
				{isAiChat && (
					<div className={cn('inline-flex horizontal gap-10 items-center')}>
						<Button
							onClick={handleNewChat}
							componentType={ButtonTypes.type.SECONDARY}
							classes={[cn('justify-start')]}
						>
							<div className={cn('flex items-center gap-[.8rem]')}>
								<span>New chat</span>
							</div>
						</Button>
						<Recent
							width={28}
							height={16}
							style={{ cursor: 'pointer' }}
							onClick={handleConversationHistory}
							fill={'var(--color-ui-100)'}
						/>
					</div>
				)}
			</div>
			<CreateDashboardModal isOpen={showCreate} />
		</div>
	);
}

export default withRouter(TopMenu);
