interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
	children: any;
	base: number;
	gap?: string;
	className?: string;
}
const Grid = (props: GridProps) => {
	const { children, base, gap, className, ...rest } = props;

	return (
		<div
			className={className}
			{...rest}
			style={{
				display: 'grid',
				gridTemplateColumns: `repeat(${base}, 1fr)`,
				gap: gap,
				...rest.style,
			}}
		>
			{children}
		</div>
	);
};

interface GridItemProps extends React.HTMLAttributes<HTMLDivElement> {
	children: any;
	span?: number;
	className?: string;
}

const Item = (props: GridItemProps) => {
	const { children, span, className, ...rest } = props;
	return (
		<div
			className={className || 'grid-item'}
			{...rest}
			style={{
				gridColumnEnd: span ? `span ${span}` : 'auto',
				...rest.style,
			}}
		>
			{children}
		</div>
	);
};

Grid.Item = Item;

export default Grid;
